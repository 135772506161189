import { Maybe, Operation } from '../../../__generated__/globalTypes'

import {
  SponsorFragment,
  sponsorFragmentString,
} from '../../fragments/__generated__/SponsorFragment'

import getSponsorsRawString from '../GetSponsors.graphql'

const getSponsorsString = [getSponsorsRawString, sponsorFragmentString].join(
  '\n\n',
)

export type GetSponsorsData = {
  sponsors: (SponsorFragment & {
    shippingAddress: Maybe<{
      city: string
      state: string
    }>
  })[]
}

export type GetSponsorsVariables = {
  search?: Maybe<string>
}

export type GetSponsors = Operation<GetSponsorsData, GetSponsorsVariables>

export const GetSponsors = ({
  search,
}: GetSponsorsVariables = {}): GetSponsors => ({
  query: getSponsorsString,
  variables: {
    search,
  },
})
