import { Flex, Text } from '@sendoutcards/quantum-design-ui'
import { Button } from 'src/design_system/components/Button'

import React from 'react'

type ContentBlockProps = {
  title: string
  subtitle?: { subtitle: string; color?: string }
  description: string
  primaryAction?: {
    title: string
    bgColor?: string
    textColor?: string
    onClick: () => void
  }
  backgroundColor?: string
  width?: string
  shouldHideShadow?: boolean
}

export const ContentBlock: React.FC<ContentBlockProps> = ({
  title,
  description,
  subtitle,
  primaryAction,
  backgroundColor = '#ffffff99',
  width = '406px',
  shouldHideShadow = false,
}) => {
  return (
    <Flex
      width="100%"
      maxWidth={width}
      backgroundColor={backgroundColor}
      inset="2rem"
      flexDirection="column"
      alignItems="flex-start"
      rowGap="x3"
      style={{
        borderRadius: '1.25rem',
        boxShadow: shouldHideShadow
          ? 'none'
          : '0px 4px 32px 0px rgba(0, 0, 0, 0.04)',
        backdropFilter: 'blur(75px)',
      }}
    >
      <Flex flexDirection="column" alignItems="flex-start" rowGap="x1_5">
        <Text
          content={title}
          type="body"
          style={{
            fontFamily: 'Montserrat',
            fontSize: '1.65rem',
            fontWeight: 600,
            color: '#404040',
            lineHeight: 1.3,
          }}
        />
        {subtitle && (
          <Text
            content={subtitle.subtitle}
            type="caption"
            style={{
              fontFamily: 'Montserrat',

              fontWeight: 500,
              color: subtitle?.color ?? '#404040',
            }}
          />
        )}
      </Flex>
      <Text
        content={description}
        type="body"
        style={{
          fontFamily: 'Montserrat',
          fontSize: '1rem',
          fontWeight: 500,
          color: '#6B7280',
        }}
      />
      {primaryAction && (
        <Button
          title={primaryAction.title}
          backgroundColor={primaryAction.bgColor ?? '#000'}
          textColor={primaryAction.textColor ?? '#fff'}
          onClick={primaryAction.onClick}
          padding="1rem 1.25rem"
        />
      )}
    </Flex>
  )
}
