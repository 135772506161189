import React from 'react'
import { ContentType } from '../ContentPop'
import { Flex, Spacer, Text } from '@sendoutcards/quantum-design-ui'

type ContentBlockProps = Omit<ContentType, 'type'>

export const ContentBlock: React.FC<ContentBlockProps> = ({
  title,
  description,
}) => {
  return (
    <Flex flexDirection={'column'} width="100%">
      {title && (
        <>
          <Text
            type="largeBody"
            weight="bold"
            color="primaryHeading"
            content={title}
          />
          <Spacer space="x1" />
        </>
      )}
      <Text type="body" color="primaryBody" content={description} />
    </Flex>
  )
}
