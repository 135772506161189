import React from 'react'

import { Icon, Icons, IconType } from 'src/chrome'
import { SmallOrLarge } from 'src/app/types'

type DetailWithIconProps = {
  icon: IconType | Icons
  title?: string
  readonly size?: SmallOrLarge
  readonly color?: string
}

const DetailWithIcon: React.FC<DetailWithIconProps> = props => {
  const { children, icon, title, color = 'pink', size = 'small' } = props
  const sizes = {
    detail: {
      small: 13,
      large: 18,
    },
    title: {
      small: 18,
      large: 22,
    },
    icon: {
      small: 16,
      large: 25,
    },
  }
  return (
    <div>
      {title && (
        <div
          style={{
            color: 'rgba(0, 0, 0, 0.8)',
            fontWeight: 600,
            fontSize: 20,
          }}
        >
          {title}
        </div>
      )}
      <div
        style={{
          display: 'flex',
          padding: `${size === 'small' ? 0 : '10px 0'}`,
        }}
      >
        <Icon
          icon={icon}
          size={size === 'large' ? sizes.icon.large : sizes.icon.small}
          color={color}
        />
        <p
          style={{
            marginLeft: 15,
            fontSize: sizes.detail[size],
            color: 'rgba(0, 0, 0, 0.5)',
          }}
        >
          {children}
        </p>
      </div>
    </div>
  )
}

export default DetailWithIcon
