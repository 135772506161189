import React from 'react'
import { SVGProps } from './types'

export const FaceBook: React.FC<SVGProps> = ({ size, color, onClick }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M11.942 12.812h1.95l.441-2.814h-2.967c0-.39-.002-.758 0-1.126.002-.35-.03-.705.021-1.048.12-.795.64-1.203 1.524-1.222.604-.014 1.21-.003 1.831-.003V4.122c-.387-.045-.783-.1-1.18-.134-.793-.069-1.584-.105-2.367.095-1.423.362-2.4 1.44-2.572 2.897-.066.559-.066 1.126-.081 1.69-.012.43-.002.86-.002 1.317H6.292v2.826h2.237v7.183C4.304 19.442.038 15.803 0 10.079-.036 4.502 4.358.259 9.495.012c5.688-.273 10.313 4.162 10.498 9.643.205 6.066-4.396 9.68-8.05 10.345v-7.188h-.001Z"
        fill={color}
      />
    </svg>
  )
}
