import React, { useEffect, useRef, useState } from 'react'
import { Flex, Text } from '@sendoutcards/quantum-design-ui'

import { BentoCard, BentoCardData, BentoGridArea } from './BentoCard'

import { ContentPop } from 'src/home_page/components/ContentPop/ContentPop'
import { ProductPop } from 'src/home_page/components/ProductPop/ProductPop'
import { ImageDiffSlider } from 'src/design_system/components/ImageDiffSlider/ImageDiffSlider'
import { BlobSettingType } from 'src/design_system/components/GlassBackgroundSvgContainer/GlassBackgroundSvgContainer'
import { ExpandableItem } from '../ExpandableItem/ExpandableItem'

type BentoGridTexts = {
  title: string
  description?: string
  gridArea: BentoGridArea
}

type BentoGridContainerProps = {
  cards: BentoCardData[]
  gridTexts?: BentoGridTexts
  isMobile?: boolean
  isFlexOverride?: boolean
  blobs?: BlobSettingType
  activeBentoCallback?: (id: number) => void
  isComponentRight?: boolean
  childrenGridArea?: BentoGridArea
  maxHeight?: string
  gridRowOverride?: string
  gridColumnOverride?: string
  textHeightOverride?: string
  textWidthOverride?: string
}

// tslint:disable-next-line: cyclomatic-complexity score 25
export const BentoGridContainer: React.FC<BentoGridContainerProps> = ({
  cards,
  gridTexts,
  blobs,
  activeBentoCallback,
  children,
  isComponentRight = false,
  childrenGridArea,
  maxHeight = '620px',
  gridRowOverride,
  gridColumnOverride,
  isFlexOverride,
  textHeightOverride,
  textWidthOverride,
}) => {
  const [activeCardId, setActiveCardId] = useState<number | null>(
    cards.length > 0 ? cards[0].id : null,
  )
  const [expandedCardId, setExpandedCardId] = useState<number | null>()

  const handleCardClick = (id: number, index: number) => {
    setActiveCardId(id)
    if (!!activeBentoCallback) {
      activeBentoCallback(index)
    }
  }

  const bentoContainerRef = useRef(null)
  const [isMobile, setIsMobile] = useState(false)
  const [isSmallMobile, setIsSmallMobile] = useState(false)
  useEffect(() => {
    const bentoContainer = bentoContainerRef.current
    const resizeObserver = new ResizeObserver(entries => {
      for (const entry of entries) {
        setIsMobile(entry.contentRect.width <= 860)
        setIsSmallMobile(entry.contentRect.width <= 500)
      }
    })

    if (bentoContainer) {
      resizeObserver.observe(bentoContainer)
    }

    return () => {
      if (bentoContainer) {
        resizeObserver.unobserve(bentoContainer)
      }
    }
  }, [])

  return (
    <div
      css={{
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        height: '100%',
      }}
    >
      <div
        css={{
          display: isFlexOverride ? 'flex' : 'grid',
          flexDirection: isFlexOverride ? 'column' : undefined,
          maxHeight: maxHeight,
          gridTemplateColumns: gridColumnOverride ?? 'repeat(20, 1fr)',
          gridTemplateRows: gridRowOverride ?? 'repeat(20, 1fr)',
          gap: '32px',
          height: '100%',
          width: '100%',
          maxWidth: '1300px',
          justifyItems: 'space-between',
        }}
        ref={bentoContainerRef}
      >
        <div
          css={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            gridColumnStart: childrenGridArea ? childrenGridArea.colStart : '',
            gridColumnEnd: childrenGridArea ? childrenGridArea.colEnd : '',
            gridRowStart: childrenGridArea ? childrenGridArea.rowStart : '',
            gridRowEnd: childrenGridArea ? childrenGridArea.rowEnd : '',
          }}
        >
          {children}
        </div>
        {gridTexts && (
          <div
            css={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              maxWidth: textWidthOverride ?? '1000px',
              height: 'inherit',
              minHeight: textHeightOverride ?? '90px',
              gridColumnStart: gridTexts?.gridArea
                ? gridTexts.gridArea.colStart
                : '',
              gridColumnEnd: gridTexts.gridArea
                ? gridTexts.gridArea.colEnd
                : '',
              gridRowStart: gridTexts.gridArea
                ? gridTexts.gridArea.rowStart
                : '',
              gridRowEnd: gridTexts.gridArea ? gridTexts.gridArea.rowEnd : '',
            }}
          >
            <Text
              type="largeBody"
              content={gridTexts.title}
              style={{
                fontSize: isMobile ? '16px' : '20px',
                fontWeight: 700,
                color: '#404040',
              }}
              outset={{ bottom: '8px' }}
            />
            {gridTexts.description && (
              <Text
                type="body"
                content={gridTexts.description}
                style={{
                  fontSize: isMobile ? '12px ' : '16px',
                  fontWeight: 400,
                  color: '#404040',
                }}
              />
            )}
          </div>
        )}
        {cards.map((card, index) => {
          return (
            <div
              key={card.id}
              css={{
                height: 'auto',
                minWidth: '220px',
                width: '100%',
                gridColumnStart: card.grid ? card.grid.colStart : '',
                gridColumnEnd: card.grid ? card.grid.colEnd : '',
                gridRowStart: card.grid ? card.grid.rowStart : '',
                gridRowEnd: card.grid ? card.grid.rowEnd : '',
              }}
            >
              <ExpandableItem
                closedState={
                  <BentoCard
                    blobs={blobs}
                    activeGradient="linear-gradient(126deg, #FF00F5, #25C1F3, #6B7280"
                    isMobile={isMobile}
                    isSmallMobile={isSmallMobile}
                    height="auto"
                    width={card.isFullWidth ? '100%' : '220px'}
                    bento={card}
                    onSelect={() => handleCardClick(card.id, index)}
                    onLearnMore={
                      card.details
                        ? () => setExpandedCardId(card.id)
                        : undefined
                    }
                    isActive={card.id === activeCardId}
                  />
                }
                isExpanded={card.id === expandedCardId}
                handleOnClose={() => setExpandedCardId(null)}
                openState={
                  card.details ? (
                    <Flex
                      width="100%"
                      height="100%"
                      maxWidth={
                        isSmallMobile && expandedCardId === card.id
                          ? '95vw'
                          : undefined
                      }
                      backgroundColor="background"
                      borderRadius="medium"
                    >
                      {card.details.type === 'content' ? (
                        <ContentPop
                          {...card.details}
                          shouldStackColumns={false}
                          action={{
                            title: 'Click me',
                            onClick: () => alert('Content Pop Action'),
                          }}
                          isMobile={isMobile}
                          isSmallMobile={isSmallMobile}
                        >
                          {card.details.imageDiff && (
                            <Flex width="100%">
                              <ImageDiffSlider {...card.details.imageDiff} />
                            </Flex>
                          )}
                        </ContentPop>
                      ) : (
                        <ProductPop
                          product={{ ...card.details }}
                          action={{
                            title: 'Click me',
                            onClick: () => console.log('clicked'),
                          }}
                        />
                      )}
                    </Flex>
                  ) : (
                    <></>
                  )
                }
                modalOptions={{
                  maxWidth:
                    expandedCardId !== card.id
                      ? 'initial'
                      : card.details?.type === 'content'
                      ? '900px'
                      : '700px',
                  maxHeight: card.details?.type === 'content' ? '90vh' : '70vh',
                  margin: '0 auto',
                  width:
                    isMobile && expandedCardId === card.id ? '95vw' : undefined,
                }}
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}
