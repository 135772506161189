import { Maybe } from '../../../__generated__/globalTypes'

import {
  ElementPictureFragment,
  isElementPictureFragment,
} from './ElementPictureFragment'

import {
  ElementTextFragment,
  isElementTextFragment,
} from './ElementTextFragment'

import elementFragmentRawString from '../ElementFragment.graphql'

export const elementFragmentString = elementFragmentRawString

export type ElementFragment = {
  x: number
  y: number
  z: number
  rotation: number
  width: number
  height: number
  locked: boolean
  image: Maybe<ElementPictureFragment>
  text: Maybe<ElementTextFragment>
}

export const isElementFragment = (fragment: any): fragment is ElementFragment =>
  fragment &&
  typeof fragment.x === 'number' &&
  typeof fragment.y === 'number' &&
  typeof fragment.z === 'number' &&
  typeof fragment.rotation === 'number' &&
  typeof fragment.width === 'number' &&
  typeof fragment.height === 'number' &&
  typeof fragment.locked === 'boolean' &&
  (fragment.image === null ||
    (isElementPictureFragment(fragment.image) as boolean)) &&
  (fragment.text === null || (isElementTextFragment(fragment.text) as boolean))
