import React from 'react'
import { SVGProps } from './types'

export const Brochure: React.FC<SVGProps> = ({
  size = 24,
  color = '#fff',
  onClick,
}) => {
  const originalWidth = 64
  const originalHeight = 64
  const ratio = originalWidth / originalHeight
  const scaledWidth = size
  const scaledHeight = size / ratio
  return (
    <svg
      width={scaledWidth}
      height={scaledHeight}
      viewBox={`0 0 ${originalWidth} ${originalHeight}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick && (() => onClick())}
    >
      <path
        d="M1.33668 63.9967C1.12669 63.9967 0.920037 63.9467 0.726714 63.85C0.283403 63.62 0.00341797 63.1634 0.00341797 62.6634V14.6659C0.00341797 14.2359 0.210074 13.8326 0.560056 13.5826L19.2258 0.249977C19.6457 -0.0500073 20.199 -0.0800025 20.649 0.169984L43.9111 13.0927L61.8902 0.249977C62.2968 -0.0400079 62.8335 -0.0800017 63.2768 0.149986C63.7201 0.379974 64.0001 0.836616 64.0001 1.33659V49.3341C64.0001 49.7641 63.7934 50.1674 63.4435 50.4174L44.7778 63.75C44.3578 64.05 43.8045 64.08 43.3545 63.83L20.0924 50.9073L2.11331 63.75C1.88332 63.9133 1.61 64 1.33668 64V63.9967ZM20.0024 47.9975C20.2257 47.9975 20.449 48.0542 20.649 48.1642L43.9111 61.0868L61.3336 48.6441V3.92313L44.7778 15.7492C44.3578 16.0492 43.8045 16.0792 43.3545 15.8292L20.0924 2.90652L2.66995 15.3492V60.0702L19.2258 48.2441C19.4557 48.0808 19.7291 47.9942 20.0024 47.9942V47.9975Z"
        fill={color}
      />
      <path
        d="M36.0015 51.5441C35.7815 51.5441 35.5616 51.4907 35.3582 51.3774L27.3587 46.9509C26.7154 46.5943 26.482 45.7843 26.8387 45.1377C27.1953 44.4944 28.0086 44.2611 28.6519 44.6177L36.6515 49.0442C37.2948 49.4008 37.5281 50.2108 37.1715 50.8574C36.9282 51.2974 36.4715 51.5441 36.0049 51.5441H36.0015Z"
        fill={color}
      />
      <path
        d="M36.0015 40.878C35.7815 40.878 35.5616 40.8247 35.3582 40.7114L27.3587 36.2849C26.7154 35.9283 26.482 35.1183 26.8387 34.4717C27.1953 33.8284 28.0086 33.5951 28.6519 33.9517L36.6515 38.3782C37.2948 38.7348 37.5281 39.5448 37.1715 40.1914C36.9282 40.6314 36.4715 40.878 36.0049 40.878H36.0015Z"
        fill={color}
      />
      <path
        d="M36.0015 30.212C35.7815 30.212 35.5616 30.1587 35.3582 30.0454L27.3587 25.6189C26.7154 25.2623 26.482 24.4523 26.8387 23.8057C27.1953 23.1624 28.0086 22.9291 28.6519 23.2857L36.6515 27.7121C37.2948 28.0688 37.5281 28.8788 37.1715 29.5254C36.9282 29.9654 36.4715 30.212 36.0049 30.212H36.0015Z"
        fill={color}
      />
      <path
        d="M36.0015 19.5455C35.7815 19.5455 35.5616 19.4922 35.3582 19.3789L27.3587 14.9524C26.7154 14.5958 26.482 13.7858 26.8387 13.1392C27.1953 12.4959 28.0086 12.2626 28.6519 12.6192L36.6515 17.0456C37.2948 17.4023 37.5281 18.2123 37.1715 18.8589C36.9282 19.2989 36.4715 19.5455 36.0049 19.5455H36.0015Z"
        fill={color}
        fillOpacity={0.1}
      />
      <path
        d="M49.3342 52.1841C49.1243 52.1841 48.9176 52.1341 48.7243 52.0375C48.281 51.8075 48.001 51.3508 48.001 50.8509V18.8525C48.001 18.4226 48.2076 18.0192 48.5609 17.7659L56.5605 12.0595C56.9672 11.7696 57.5005 11.7296 57.9438 11.9596C58.3871 12.1895 58.6671 12.6462 58.6671 13.1462V45.1445C58.6671 45.5745 58.4604 45.9778 58.1071 46.2311L50.1075 51.9375C49.8775 52.1008 49.6076 52.1841 49.3342 52.1841ZM50.6675 19.5392V48.261L56.0006 44.4545V15.7327L50.6675 19.5392Z"
        fill={color}
      />
      <path
        d="M6.66965 20.1858C6.25301 20.1858 5.84303 19.9925 5.58304 19.6259C5.1564 19.0259 5.29305 18.1926 5.89302 17.766L13.8926 12.0596C14.4926 11.633 15.3225 11.7696 15.7525 12.3696C16.1792 12.9696 16.0425 13.8028 15.4425 14.2295L7.44294 19.9359C7.20962 20.1025 6.9363 20.1825 6.66965 20.1825V20.1858Z"
        fill={color}
      />
      <path
        d="M6.66969 52.1841C6.4597 52.1841 6.25304 52.1341 6.05972 52.0374C5.61641 51.8074 5.33643 51.3508 5.33643 50.8508V26.852C5.33643 26.4221 5.54308 26.0187 5.8964 25.7654L13.896 20.0591C14.3026 19.7691 14.8359 19.7291 15.2792 19.9591C15.7226 20.1891 16.0025 20.6457 16.0025 21.1457V45.1444C16.0025 45.5744 15.7959 45.9777 15.4426 46.231L7.44298 51.9374C7.21299 52.1007 6.94301 52.1841 6.66969 52.1841ZM8.00295 27.5387V48.2609L13.336 44.4545V23.7322L8.00295 27.5387Z"
        fill={color}
      />
      <path
        d="M20.0025 5.33306C19.2658 5.33306 18.6692 4.73642 18.6692 3.99979V1.33326C18.6692 0.596636 19.2658 0 20.0025 0C20.7391 0 21.3357 0.596636 21.3357 1.33326V3.99979C21.3357 4.73642 20.7391 5.33306 20.0025 5.33306Z"
        fill={color}
      />
      <path
        d="M20.0025 13.3326C19.2658 13.3326 18.6692 12.7359 18.6692 11.9993V9.33278C18.6692 8.59615 19.2658 7.99951 20.0025 7.99951C20.7391 7.99951 21.3357 8.59615 21.3357 9.33278V11.9993C21.3357 12.7359 20.7391 13.3326 20.0025 13.3326Z"
        fill={color}
      />
      <path
        d="M20.0025 21.3321C19.2658 21.3321 18.6692 20.7354 18.6692 19.9988V17.3323C18.6692 16.5957 19.2658 15.999 20.0025 15.999C20.7391 15.999 21.3357 16.5957 21.3357 17.3323V19.9988C21.3357 20.7354 20.7391 21.3321 20.0025 21.3321Z"
        fill={color}
      />
      <path
        d="M20.0025 29.3316C19.2658 29.3316 18.6692 28.735 18.6692 27.9983V25.3318C18.6692 24.5952 19.2658 23.9985 20.0025 23.9985C20.7391 23.9985 21.3357 24.5952 21.3357 25.3318V27.9983C21.3357 28.735 20.7391 29.3316 20.0025 29.3316Z"
        fill={color}
      />
      <path
        d="M20.0025 37.3316C19.2658 37.3316 18.6692 36.735 18.6692 35.9983V33.3318C18.6692 32.5952 19.2658 31.9985 20.0025 31.9985C20.7391 31.9985 21.3357 32.5952 21.3357 33.3318V35.9983C21.3357 36.735 20.7391 37.3316 20.0025 37.3316Z"
        fill={color}
        fillOpacity={0.1}
      />
      <path
        d="M20.0025 45.3311C19.2658 45.3311 18.6692 44.7345 18.6692 43.9978V41.3313C18.6692 40.5947 19.2658 39.998 20.0025 39.998C20.7391 39.998 21.3357 40.5947 21.3357 41.3313V43.9978C21.3357 44.7345 20.7391 45.3311 20.0025 45.3311Z"
        fill={color}
      />
      <path
        d="M44.0012 23.9986C43.2646 23.9986 42.668 23.4019 42.668 22.6653V19.9988C42.668 19.2622 43.2646 18.6655 44.0012 18.6655C44.7379 18.6655 45.3345 19.2622 45.3345 19.9988V22.6653C45.3345 23.4019 44.7379 23.9986 44.0012 23.9986Z"
        fill={color}
      />
      <path
        d="M44.0012 31.9981C43.2646 31.9981 42.668 31.4015 42.668 30.6648V27.9983C42.668 27.2617 43.2646 26.665 44.0012 26.665C44.7379 26.665 45.3345 27.2617 45.3345 27.9983V30.6648C45.3345 31.4015 44.7379 31.9981 44.0012 31.9981Z"
        fill={color}
      />
      <path
        d="M44.0012 39.9981C43.2646 39.9981 42.668 39.4015 42.668 38.6648V35.9983C42.668 35.2617 43.2646 34.665 44.0012 34.665C44.7379 34.665 45.3345 35.2617 45.3345 35.9983V38.6648C45.3345 39.4015 44.7379 39.9981 44.0012 39.9981Z"
        fill={color}
      />
      <path
        d="M44.0012 47.9976C43.2646 47.9976 42.668 47.401 42.668 46.6643V43.9978C42.668 43.2612 43.2646 42.6646 44.0012 42.6646C44.7379 42.6646 45.3345 43.2612 45.3345 43.9978V46.6643C45.3345 47.401 44.7379 47.9976 44.0012 47.9976Z"
        fill={color}
      />
      <path
        d="M44.0012 55.9971C43.2646 55.9971 42.668 55.4005 42.668 54.6639V51.9973C42.668 51.2607 43.2646 50.6641 44.0012 50.6641C44.7379 50.6641 45.3345 51.2607 45.3345 51.9973V54.6639C45.3345 55.4005 44.7379 55.9971 44.0012 55.9971Z"
        fill={color}
      />
      <path
        d="M44.0012 63.9966C43.2646 63.9966 42.668 63.4 42.668 62.6634V59.9968C42.668 59.2602 43.2646 58.6636 44.0012 58.6636C44.7379 58.6636 45.3345 59.2602 45.3345 59.9968V62.6634C45.3345 63.4 44.7379 63.9966 44.0012 63.9966Z"
        fill={color}
      />
    </svg>
  )
}
