import calendarDateFragmentRawString from '../CalendarDateFragment.graphql'

export const calendarDateFragmentString = calendarDateFragmentRawString

export type CalendarDateFragment = {
  day: number
  month: number
  year: number
}

export const isCalendarDateFragment = (
  fragment: any,
): fragment is CalendarDateFragment =>
  fragment &&
  typeof fragment.day === 'number' &&
  typeof fragment.month === 'number' &&
  typeof fragment.year === 'number'
