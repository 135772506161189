import { Reducer } from 'redux'

import Action from 'src/redux/action'

export const isMobile = (window: Window) => window.innerWidth <= 568

export const WINDOW_RESIZE = 'WINDOW_RESIZE'

export type WindowResize = {
  type: typeof WINDOW_RESIZE
  width: number
  isMobile: boolean
}

type WindowState = {
  isMobile: boolean
  width: number
}

const initialWindowState = {
  isMobile: isMobile(window),
  width: window.innerWidth,
}

export const windowReducer: Reducer<WindowState, Action> = (
  state: WindowState = initialWindowState,
  action: Action,
): WindowState =>
  action.type === WINDOW_RESIZE
    ? { isMobile: action.isMobile, width: action.width }
    : state
