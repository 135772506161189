import { ParagraphFragment, isParagraphFragment } from './ParagraphFragment'

import elementTextFragmentRawString from '../ElementTextFragment.graphql'

export const elementTextFragmentString = elementTextFragmentRawString

export type ElementTextFragment = {
  paragraphs: ParagraphFragment[]
}

export const isElementTextFragment = (
  fragment: any,
): fragment is ElementTextFragment =>
  fragment &&
  Array.isArray(fragment.paragraphs) &&
  fragment.paragraphs
    .slice(0, 5)
    .reduce(
      (accum: any, next: any) =>
        accum && (isParagraphFragment(next) as boolean),
      true,
    )
