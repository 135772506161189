import expenseReloadRawString from '../ExpenseReload.graphql'

export const expenseReloadString = expenseReloadRawString

export type ExpenseReload = {
  purchaseAmount: number
  threshold: number
  isEnabled: boolean
  isRequired: boolean
}

export const isExpenseReload = (fragment: any): fragment is ExpenseReload =>
  fragment &&
  typeof fragment.purchaseAmount === 'number' &&
  typeof fragment.threshold === 'number' &&
  typeof fragment.isEnabled === 'boolean' &&
  typeof fragment.isRequired === 'boolean'
