import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'

// A component that is wrapped around a Portal will be rendered
// on the bottom of the document.body, if we need to portal it
// somewhere else we can use the attachToContainerId prop and
// the component will be rendered within that DOM element.
type PortalProps = {
  attachToContainerId?: string
}

export const Portal: React.FC<PortalProps> = props => {
  const { attachToContainerId, children } = props
  const [container] = useState(() => {
    const element = document.createElement('div')
    return element
  })

  const attachToContainer = attachToContainerId
    ? document.getElementById(attachToContainerId)
    : undefined

  useEffect(() => {
    if (attachToContainer) {
      attachToContainer?.appendChild(container)
    } else {
      document.body.appendChild(container)
    }
    return () => {
      if (attachToContainer) {
        attachToContainer?.removeChild(container)
      } else {
        document.body.removeChild(container)
      }
    }
  }, [container, attachToContainer])

  return ReactDOM.createPortal(children, container)
}
