import React from 'react'
import { CreateAccountFormState } from '../components/CreateAccount'
import { AccountFragment, AddressFragment, ExpenseReload } from 'src/graphql'
import { emptyShippingAddress } from 'src/app/constants'

export type PaymentFormState = {
  firstName: string
  lastName: string
  shippingAddress: AddressFragment
}

type PromoState = {
  create: {
    form: CreateAccountFormState
    isCheckingUsername: boolean
    isUsernameTaken: boolean
    isCheckingEmail: boolean
    emailValidation: {
      isEmailTaken: boolean
      errorMsg?: string
    }
  }
  account: AccountFragment | undefined
  payment: {
    form: PaymentFormState
    isUpdatingAccount: boolean
    expenseReload: ExpenseReload
    shouldHaveExpenseBucket: boolean
  }
}

type ActionType =
  | {
      type: 'SET_CREATE_FORM'
      payload: { email: string; username: string; password: string }
    }
  | {
      type: 'SET_IS_CHECKING_USERNAME'
      payload: { isCheckingUsername: boolean }
    }
  | { type: 'SET_IS_USERNAME_TAKEN'; payload: { isUsernameTaken: boolean } }
  | {
      type: 'SET_IS_CHECKING_EMAIL'
      payload: { isCheckingEmail: boolean }
    }
  | {
      type: 'SET_EMAIL_VALIDATION'
      payload: { emailValidation: { isEmailTaken: boolean; errorMsg?: string } }
    }
  | { type: 'SET_IS_UPDATING_ACCOUNT'; payload: { isUpdatingAccount: boolean } }
  | { type: 'SET_ACCOUNT'; payload: { account: AccountFragment } }
  | {
      type: 'SET_PAYMENT_FORM'
      payload: PaymentFormState
    }
  | {
      type: 'SET_EXPENSE_RELOAD'
      payload: ExpenseReload
    }
  | {
      type: 'SET_SHOULD_HAVE_EXPENSE_BUCKET'
      payload: {
        planId: string
      }
    }

const initialState: PromoState = {
  create: {
    form: { email: '', username: '', password: '' },
    isCheckingUsername: false,
    isUsernameTaken: false,
    isCheckingEmail: false,
    emailValidation: {
      isEmailTaken: false,
      errorMsg: undefined,
    },
  },
  account: undefined,
  payment: {
    isUpdatingAccount: false,
    expenseReload: {
      purchaseAmount: 10,
      threshold: 100,
      isEnabled: false,
      isRequired: true,
    },
    shouldHaveExpenseBucket: false,
    form: {
      firstName: '',
      lastName: '',
      shippingAddress: {
        ...emptyShippingAddress,
      },
    },
  },
}

function reducer(state: PromoState, action: ActionType): PromoState {
  switch (action.type) {
    case 'SET_CREATE_FORM':
      return {
        ...state,
        create: {
          ...state.create,
          form: {
            ...action.payload,
          },
        },
      }
    case 'SET_PAYMENT_FORM':
      return {
        ...state,
        payment: {
          ...state.payment,
          form: {
            ...action.payload,
          },
        },
      }
    case 'SET_IS_CHECKING_USERNAME':
      return {
        ...state,
        create: {
          ...state.create,
          isCheckingUsername: action.payload.isCheckingUsername,
        },
      }
    case 'SET_IS_USERNAME_TAKEN':
      return {
        ...state,
        create: {
          ...state.create,
          isUsernameTaken: action.payload.isUsernameTaken,
        },
      }
    case 'SET_IS_CHECKING_EMAIL':
      return {
        ...state,
        create: {
          ...state.create,
          isCheckingEmail: action.payload.isCheckingEmail,
        },
      }
    case 'SET_EMAIL_VALIDATION':
      return {
        ...state,
        create: {
          ...state.create,
          emailValidation: action.payload.emailValidation,
        },
      }
    case 'SET_ACCOUNT':
      return {
        ...state,
        account: {
          ...action.payload.account,
        },
      }
    case 'SET_IS_UPDATING_ACCOUNT':
      return {
        ...state,
        payment: {
          ...state.payment,
          isUpdatingAccount: action.payload.isUpdatingAccount,
        },
      }
    case 'SET_EXPENSE_RELOAD':
      return {
        ...state,
        payment: {
          ...state.payment,
          expenseReload: action.payload,
        },
      }
    case 'SET_SHOULD_HAVE_EXPENSE_BUCKET':
      const {
        payload: { planId },
      } = action
      const shouldHaveExpenseBucket = planId === '4' || planId === '39'
      return {
        ...state,
        payment: {
          ...state.payment,
          shouldHaveExpenseBucket,
        },
      }
    default:
      return { ...state }
  }
}

export const PromoContext = React.createContext<{
  state: PromoState
  dispatch: (action: ActionType) => void
}>({
  state: initialState,
  dispatch: () => {},
})

export const PromoContextProvider: React.FC<{}> = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState)

  return (
    <PromoContext.Provider value={{ state, dispatch }}>
      {children}
    </PromoContext.Provider>
  )
}
