import detailedCountryFragmentRawString from '../DetailedCountryFragment.graphql'

export const detailedCountryFragmentString = detailedCountryFragmentRawString

export type DetailedCountryFragment = {
  postalName: string
  subdivisions: {
    abbreviationOrName: string
  }[]
}

export const isDetailedCountryFragment = (
  fragment: any,
): fragment is DetailedCountryFragment =>
  fragment &&
  typeof fragment.postalName === 'string' &&
  Array.isArray(fragment.subdivisions) &&
  fragment.subdivisions
    .slice(0, 5)
    .reduce(
      (accum: any, next: any) =>
        accum && next && typeof next.abbreviationOrName === 'string',
      true,
    )
