import React, { CSSProperties } from 'react'
import { Set } from 'immutable'
import LazyLoad from 'react-lazy-load'
import Checkbox from '@material-ui/core/Checkbox'
import CircularProgress from '@material-ui/core/CircularProgress'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked'
// @src imports
import { Icon } from 'src/chrome'
import { noop } from 'src/helpers/appHelpers'
// relative imports
import { FilterButton as FilterButtonType } from '../../containers/CardCatalog'
import {
  CollectionFragment,
  CustomCardFragment,
  MinimalSendableCardFragment,
} from 'src/graphql'
import FilterButton from '../FilterButtons/FilterButtons'

import styles from './catalogRibbon.module.scss'

type Item =
  | MinimalSendableCardFragment
  | FilterButtonType
  | CollectionFragment
  | CustomCardFragment

type CatalogRibbonProps = {
  category?: string
  displayTitle?: boolean
  emptySearch?: boolean
  grid?: boolean
  handleBlankCard?: () => void
  handleCategory?: () => void
  handleCheckbox?: (cardId: string) => void
  handleFilter?: (isPremium?: boolean) => void
  handleSearchAll?: () => void
  hasMore?: boolean
  hideScrollBar?: boolean
  isCategory?: boolean
  items: Item[]
  loadMore?: () => void
  loadOnScroll?: boolean
  onClick?: (id: string, typename?: string) => void
  onClose?: () => void
  search?: string
  selectedSet?: Set<string>
  tiles?: boolean // This is a boolean set on the parent explicitly by the developer
  type?: string
  title?: string
}

const CatalogRibbon: React.FC<CatalogRibbonProps> = props => {
  const {
    category,
    displayTitle,
    emptySearch,
    grid,
    handleBlankCard,
    handleCategory,
    handleCheckbox,
    handleFilter,
    handleSearchAll,
    hasMore,
    hideScrollBar,
    isCategory,
    items,
    loadMore,
    loadOnScroll,
    onClick,
    onClose,
    search,
    selectedSet,
    type,
    title,
  } = props
  return (
    <div className={`${styles.catalogRibbon} ${grid && styles.gridPadding}`}>
      <div className={styles.ribbonInfo}>
        {displayTitle !== false && title && (
          <h5
            onClick={() => {
              handleCategory && handleCategory()
            }}
            style={{ cursor: 'pointer' }}
          >
            {`${title}`}
          </h5>
        )}
        {!grid && isCategory && (
          <h6
            className={styles.more}
            onClick={() => {
              handleCategory && handleCategory()
            }}
          >
            SEE ALL <Icon icon={'CARROTRIGHT'} size={12} color={''} />
          </h6>
        )}
        {grid && onClose && (
          <h6 className={styles.closeCollection} onClick={() => onClose()}>
            <Icon icon={'CLOSECIRCLE'} size={18} />
            <span>Close</span>
          </h6>
        )}
      </div>
      <div
        className={styles.ribbonCards}
        style={{
          ...(grid && { flexWrap: 'wrap', justifyContent: 'center' }),
          ...(hideScrollBar && { overflowX: 'hidden' }),
        }}
      >
        {items.map((item, index) => {
          switch (item.__typename) {
            case 'SendableCard':
            case 'Card':
              const cardType = !item.isHorizontal
                ? styles.portrait
                : item.isHorizontal
                ? styles.landscape
                : styles.gift
              const image =
                item.__typename === 'SendableCard'
                  ? item.frontImage.smallThumb
                  : item.frontPreviewUrl
              const iconStyle: CSSProperties = {
                fill: '#f36CC2',
                position: 'absolute',
              }
              const checkboxStyle: CSSProperties = {
                display: 'flex',
                width: 35,
                height: 35,
                position: 'absolute',
                top: 10,
                borderRadius: 100,
                background: '#FFFFFFA3',
                border: '1px solid #fff',
                boxShadow:
                  '0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)',
              }
              return (
                <div
                  id={`${type}_${index}`}
                  className={`${styles.ribbonItem} ${cardType}`}
                  style={{
                    backgroundImage: `url(${image})`,
                  }}
                  key={item.id}
                  onClick={() => onClick && onClick(item.id, item.__typename)}
                >
                  {!!handleCheckbox && (
                    <div
                      className={styles.selectCheckbox}
                      style={
                        selectedSet && selectedSet.size > 0
                          ? { display: 'initial' }
                          : {}
                      }
                    >
                      <Checkbox
                        checked={selectedSet?.has(item.id)}
                        onClick={ev => {
                          ev.stopPropagation()
                          handleCheckbox?.(item.id)
                        }}
                        checkedIcon={
                          <CheckCircleIcon style={iconStyle} fontSize="large" />
                        }
                        icon={
                          <RadioButtonUncheckedIcon
                            style={iconStyle}
                            fontSize="large"
                          />
                        }
                        style={{ ...checkboxStyle, left: 10 }}
                      />
                    </div>
                  )}
                </div>
              )
            case 'Collection':
              const backgroundImage = {
                backgroundImage: `url(${item.backgroundImageUrl})`,
              }
              const backgroundType = item.backgroundImageUrl
                ? backgroundImage
                : {
                    backgroundImage: `linear-gradient(115deg, ${item.backgroundStartColor}, ${item.backgroundEndColor})`,
                  }
              return (
                <div
                  id={`${type}_${index}`}
                  className={styles.tile}
                  style={backgroundType}
                  key={item.id}
                  onClick={() => {
                    onClick && onClick(item.id)
                  }}
                >
                  <span style={{ color: item.textColor }}>{item.title}</span>
                </div>
              )
            case 'FilterButton':
              return (
                <FilterButton
                  id={item.id}
                  key={index}
                  filter={item}
                  onClick={() => {
                    handleFilter ? handleFilter() : noop()
                  }}
                  uniqueHandlerFunction={handleBlankCard ?? noop}
                />
              )
            default:
              return null
          }
        })}
        {emptySearch && (
          <div className={styles.badSearch}>
            <p>Hmmm.. We could not find any cards using your search.</p>
            <h4>
              <span style={{ color: '#333333' }}>
                {category && `${category}: `}
              </span>
              {search}
            </h4>
            {category && (
              <p>
                Try selecting a different category or
                <span
                  onClick={() => {
                    handleSearchAll && handleSearchAll()
                  }}
                  style={
                    {
                      fontWeight: 500,
                      margin: '0 8px',
                      color: '#03A9F4',
                    } as React.CSSProperties
                  }
                >
                  search all
                </span>
                categories for {`${search}`}
              </p>
            )}
          </div>
        )}
        {(!!loadMore || !!hasMore) && (
          <div style={{ background: 'transparent', boxShadow: 'none' }}>
            <LazyLoad
              key={items.length}
              onContentVisible={!loadOnScroll && loadMore ? loadMore : noop}
            >
              <CircularProgress />
            </LazyLoad>
          </div>
        )}
        <div className={styles.overScroll} />
      </div>
    </div>
  )
}

export default CatalogRibbon
