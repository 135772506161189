import React from 'react'

import LoadingIndicator from '../LoadingIndicator/LoadingIndicator'
import styles from './transition.module.scss'

interface TransitionProps {
  message: string
  subMessage?: string
  messageStyle?: React.CSSProperties
  style?: React.CSSProperties
}

const Transition: React.FC<TransitionProps> = props => {
  const { message, messageStyle, subMessage, style } = props
  return (
    <div className={styles.modalWindow} style={style}>
      <LoadingIndicator
        message={message}
        messageStyle={messageStyle}
        subMessage={subMessage}
      />
    </div>
  )
}

export default Transition
