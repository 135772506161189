import { ActionCreator } from '@sendoutcards/core'

import { Result } from 'src/utils/Result'
import {
  DetailedSendableCardFragment,
  GetCatalogData,
  MinimalSendableCardFragment,
} from 'src/graphql'
import { GetSendableCardsTaskType } from 'src/catalog/tasks'

export const loadedCardCatalog = ActionCreator(
  'LOADED_CARD_CATALOG',
  (cardCatalog: Result<GetCatalogData>) => ({ cardCatalog }),
)

export const loadedSendableCards = ActionCreator(
  'LOADED_SENDABLE_CARDS',
  (
    result: Result<MinimalSendableCardFragment[]>,
    search: string,
    taskType: GetSendableCardsTaskType,
  ) => ({
    result,
    search,
    taskType,
  }),
)

export const loadedSendableCard = ActionCreator(
  'LOADED_SENDABLE_CARD',
  (id: string, result: Result<DetailedSendableCardFragment>) => ({
    id,
    result,
  }),
)

export const updatedCatalogSearch = ActionCreator(
  'UPDATED_CATALOG_SEARCH',
  (search: string) => ({ search }),
)

export const selectedCatalogCard = ActionCreator(
  'SELECTED_CATALOG_CARD',
  (dataType: 'SendableCard' | 'Card', id: string) => ({ dataType, id }),
)

export const dismissedCatalogCard = ActionCreator('DISMISSED_CATALOG_CARD')

export const selectedCardCategory = ActionCreator(
  'SELECTED_CARD_CATEGORY',
  (categoryId: string) => ({ categoryId }),
)

export const selectedCardCollection = ActionCreator(
  'SELECTED_CARD_COLLECTION',
  (collectionId: string) => ({ collectionId }),
)

export const selectedPhotoDropCards = ActionCreator('SELECTED_PHOTO_DROP_CARDS')

export const selectedStandardCards = ActionCreator('SELECTED_STANDARD_CARDS')

export const dismissedCatalogSubroute = ActionCreator(
  'DISMISSED_CATALOG_SUBROUTE',
)

export const setIsBulkOrderFromQueryParam = ActionCreator(
  'SET_IS_BULK_ORDER_BY_QUERY_PARAM',
  (isBulkOrder: boolean) => ({ isBulkOrder }),
)
