import { Currency } from '../../../__generated__/globalTypes'

import amountRawString from '../Amount.graphql'

export const amountString = amountRawString

export type Amount = {
  __typename: 'Amount'
  amount: number
  currency: Currency
  description: string
}

export const isAmount = (fragment: any): fragment is Amount =>
  fragment &&
  fragment.__typename === 'Amount' &&
  typeof fragment.amount === 'number' &&
  (fragment.currency === 'SOC' ||
    fragment.currency === 'USD' ||
    fragment.currency === 'UNAVAILABLE') &&
  typeof fragment.description === 'string'
