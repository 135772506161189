import { useSelector as defaultUseSelector } from 'react-redux'

import { State } from 'src/redux/reducers'

const useSelector = <Selected>(
  selector: (state: State) => Selected,
  equalityFn?: (left: Selected, right: Selected) => boolean,
): Selected => defaultUseSelector<State, Selected>(selector, equalityFn)

export default useSelector
