import { ContentMediaType } from 'src/home_page/components/ContentPop/ContentPop'
import { BentoContentExpandType } from 'src/marketing/components/BentoGrid/BentoCard'
import { CardTypeImageDiffData } from 'src/marketing/mockData/CardTypeImageDiffData'

const byoContentStream: ContentMediaType[] = [
  {
    content: {
      type: 'content',
      title: 'Build Your Own Card',
      description:
        'It’s said that a photo is worth a thousand words. So, let your photo do the talking with your cards! With our Build Your Own cards, your photo is the highlight of your message.',
    },
    width: '100%',
    height: 'auto',
  },
  {
    content: {
      type: 'content',
      title: '',
      description:
        'This option gives you the most personal way of sharing celebrations, adoration, and love. Give the gift of a keepsake that your recipient can put in a frame or post on the refrigerator for all to see. ',
    },
    width: '100%',
    height: 'auto',
  },
]
export const buildYourOwnExpandData: BentoContentExpandType = {
  bannerImages: [
    'https://soc-website-public.s3.us-west-2.amazonaws.com/static/soc-website/images/popups/BYO-main-image.png',
  ],
  contentStream: byoContentStream,
  type: 'content',
  imageDiff: CardTypeImageDiffData[2],
}
