import { Operation } from '../../../__generated__/globalTypes'

import {
  SponsorFragment,
  sponsorFragmentString,
} from '../../fragments/__generated__/SponsorFragment'

import getSponsorRawString from '../GetSponsor.graphql'

const getSponsorString = [getSponsorRawString, sponsorFragmentString].join(
  '\n\n',
)

export type GetSponsorData = {
  sponsor: SponsorFragment
}

export type GetSponsorVariables = {
  id: string
}

export type GetSponsor = Operation<GetSponsorData, GetSponsorVariables>

export const GetSponsor = ({ id }: GetSponsorVariables): GetSponsor => ({
  query: getSponsorString,
  variables: {
    id,
  },
})
