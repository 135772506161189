import React from 'react'
import { SVGProps } from './types'

export const Check: React.FC<SVGProps> = ({
  size = 24,
  color = '#3BB07B',
  onClick,
}) => {
  const originalWidth = 21
  const originalHeight = 15
  const ratio = originalWidth / originalHeight

  const scaledWidth = size
  const scaledHeight = size / ratio
  return (
    <svg
      width={scaledWidth}
      height={scaledHeight}
      viewBox={`0 0 ${originalWidth} ${originalHeight}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.4571 1.2675C20.8476 1.65803 20.8476 2.29119 20.4571 2.68172L8.45711 14.6817C8.06658 15.0722 7.43342 15.0722 7.04289 14.6817L1.04289 8.68172C0.652369 8.29119 0.652369 7.65803 1.04289 7.2675C1.43342 6.87698 2.06658 6.87698 2.45711 7.2675L7.75 12.5604L19.0429 1.2675C19.4334 0.876978 20.0666 0.876978 20.4571 1.2675Z"
        fill={color}
      />
    </svg>
  )
}
