import { performOperation } from 'src/app/api'
import { useEffect, useState } from 'react'
import { GetCategoryGifts, GetCategoryGiftsData } from 'src/graphql'

const useGetCategoryGiftQuery = (categoryId: string) => {
  const [categories, setCategories] = useState<
    GetCategoryGiftsData | undefined
  >(undefined)

  useEffect(() => {
    performOperation<GetCategoryGiftsData>(
      GetCategoryGifts({ categoryId: categoryId, offset: 0, limit: 5 }),
      result => {
        if (result.error || !result.value) {
          setCategories(undefined)
        } else {
          const pagesResponse: GetCategoryGiftsData | undefined = result.value
          setCategories(pagesResponse)
        }
      },
    )
  }, [categoryId])
  return categories
}

export default useGetCategoryGiftQuery
