import React, { useState } from 'react'
// @src imports
import connect from 'src/redux/connect'
import { Icon } from 'src/chrome'
import { BaseRoute } from 'src/routes/BaseRoute'
import {
  openBusinessPage,
  openLaunchPage,
  openPlans,
} from 'src/redux/actions/routeAliases'
import { BusinessRoute } from 'src/marketing/routes/BusinessRoute'
import { PricingRoute } from 'src/pricing_page/routes/PricingRoute'
import NavLink from '../NavLink/NavLink'

import styles from './topNav.module.scss'
import { openApp } from 'src/helpers/appHelpers'
import { CatalogRoute } from 'src/catalog/routes/CatalogRoute'
import { AppRoute } from 'src/app/routes/AppRoute'

type TopNavProps = {
  styleId: string
  openBusinessPage: () => void
  openLaunchPage: () => void
  openPlans: () => void
  isCatalog: boolean | undefined
  isMobile: boolean | undefined
}

const TopNav: React.FC<TopNavProps> = props => {
  const [menuVisible, toggleMenuVisible] = useState<boolean>(false)

  const {
    openBusinessPage,
    openLaunchPage,
    openPlans,
    styleId,
    isCatalog,
    isMobile,
  } = props

  const toggleMenu = () => {
    toggleMenuVisible(m => !m)
  }

  const sharedParams = {
    client: 'soc',
    redirect_uri: 'https%3A%2F%2Fapp.sendoutcards.com%2Fcatalog',
  }

  const queryParams = {
    login: {
      ...sharedParams,
    },
    join: {
      ...sharedParams,
      join: '',
    },
  }

  return (
    <div className={styles.topNav} id={styleId}>
      <div className={styles.logo}>
        <NavLink
          activeClassName={styles.colorSelected}
          route={BaseRoute}
          onClick={() => openLaunchPage()}
        >
          <Icon
            icon={'SOCLOGO'}
            viewBox={'0 0 77 72'}
            size={45}
            color={'#e687ff'}
          />
        </NavLink>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {isMobile && isCatalog ? (
            <h2 style={{ lineHeight: isCatalog ? '2rem' : undefined }}>
              Card Catalog
            </h2>
          ) : (
            <h2>SendOutCards</h2>
          )}
          {isMobile && isCatalog && (
            <h6 style={{ marginLeft: '20px', color: 'gray' }}>
              Scroll through and choose a card
            </h6>
          )}
        </div>
      </div>
      <div
        className={styles.mobileTrigger}
        onClick={toggleMenu}
        id={!menuVisible ? 'mobile_menu_btn' : undefined}
      >
        {menuVisible ? 'Close' : 'Menu'}
      </div>
      <div
        className={
          menuVisible
            ? `${styles.accountBar} ${styles.show}`
            : styles.accountBar
        }
      >
        <div className={styles.links}>
          <NavLink
            activeClassName={styles.colorSelected}
            route={BaseRoute}
            onClick={() => openLaunchPage()}
          >
            Home
          </NavLink>
          <NavLink
            activeClassName={styles.colorSelected}
            route={BusinessRoute}
            onClick={() => openBusinessPage()}
          >
            Business
          </NavLink>
          <NavLink
            activeClassName={styles.colorSelected}
            route={PricingRoute}
            onClick={() => openPlans()}
          >
            Pricing
          </NavLink>
        </div>
        <>
          <div className={styles.profileActions}>
            <span
              id={'login_link'}
              onClick={() => openApp('', queryParams.login)}
            >
              Login
            </span>
            <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
            <span
              id={'join_link'}
              onClick={() => openApp('', queryParams.join)}
            >
              Join
            </span>
          </div>
        </>
      </div>
    </div>
  )
}

export default connect(
  state => {
    return {
      isCatalog:
        state.route.subroute &&
        state.route.subroute.path === AppRoute.path &&
        state.route.subroute.subroute &&
        state.route.subroute.subroute.path === CatalogRoute.path,
      isMobile: state.window.isMobile,
    }
  },
  {
    openLaunchPage,
    openBusinessPage,
    openPlans,
  },
)(TopNav)
