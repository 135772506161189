import React from 'react'
import { SVGProps } from './types'

export const Instagram: React.FC<SVGProps> = ({ size, color, onClick }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M0 9.99C0 4.466 4.477-.002 10.01 0c5.534.002 9.993 4.478 9.99 10.03-.003 5.514-4.49 9.975-10.03 9.97C4.462 19.995 0 15.515 0 9.99Zm16.162.02h.008c0-.74.005-1.48-.002-2.22a6.454 6.454 0 0 0-.048-.857c-.186-1.295-.816-2.264-2.073-2.756a4.373 4.373 0 0 0-1.583-.287c-1.64-.008-3.28-.009-4.92 0-.616.004-1.22.108-1.785.369-1.286.595-1.86 1.678-1.908 3.022-.06 1.644-.021 3.293-.02 4.94 0 .285.009.574.05.856.175 1.212.745 2.152 1.896 2.673a4.03 4.03 0 0 0 1.668.356c1.82.006 3.64.005 5.458-.033a3.324 3.324 0 0 0 1.883-.643c.93-.666 1.314-1.627 1.367-2.721.044-.898.009-1.8.009-2.7Z"
        fill={color}
      />
      <path
        d="M10.01 5.043c.853 0 1.706-.015 2.559.008.328.01.665.07.977.172.88.289 1.299.969 1.449 1.84.037.215.043.438.043.657.003 1.56.013 3.12-.007 4.68a3.47 3.47 0 0 1-.18 1.073c-.308.89-.984 1.38-1.905 1.435-1.169.07-2.343.063-3.515.076a29.37 29.37 0 0 1-1.9-.032 4.352 4.352 0 0 1-1.095-.184c-.873-.286-1.281-.968-1.43-1.831a3.895 3.895 0 0 1-.045-.656c-.003-1.587-.01-3.173.007-4.76a3.18 3.18 0 0 1 .173-.975c.306-.89.972-1.382 1.893-1.453.988-.076 1.983-.071 2.975-.102v.053ZM6.754 9.987a3.235 3.235 0 0 0 3.239 3.26 3.234 3.234 0 0 0 3.253-3.224c.003-1.804-1.426-3.26-3.208-3.269-1.825-.008-3.278 1.423-3.284 3.233Zm6.514-2.58a.647.647 0 0 0 .652-.64.659.659 0 0 0-.648-.649.641.641 0 0 0-.643.645c0 .366.274.643.64.644Z"
        fill={color}
      />
      <path
        d="M10.029 7.895c1.168.034 2.102.988 2.077 2.125-.025 1.172-.995 2.12-2.136 2.086-1.169-.035-2.1-.989-2.077-2.125.024-1.17.996-2.119 2.136-2.086Z"
        fill={color}
      />
    </svg>
  )
}
