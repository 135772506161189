import { Maybe, Operation } from '../../../__generated__/globalTypes'

import {
  MinimalSendableCardFragment,
  minimalSendableCardFragmentString,
} from '../../fragments/__generated__/MinimalSendableCardFragment'

import { sendableCardImageFragmentString } from '../../fragments/__generated__/SendableCardImageFragment'

import getSendableCardsRawString from '../GetSendableCards.graphql'

const getSendableCardsString = [
  getSendableCardsRawString,
  minimalSendableCardFragmentString,
  sendableCardImageFragmentString,
].join('\n\n')

export type GetSendableCardsData = {
  sendableCards: MinimalSendableCardFragment[]
}

export type GetSendableCardsVariables = {
  offset: number
  limit: number
  search?: Maybe<string>
  category?: Maybe<string>
  collection?: Maybe<string>
  isPremium?: Maybe<boolean>
  favorite?: Maybe<boolean>
}

export type GetSendableCards = Operation<
  GetSendableCardsData,
  GetSendableCardsVariables
>

export const GetSendableCards = ({
  offset,
  limit,
  search,
  category,
  collection,
  isPremium,
  favorite,
}: GetSendableCardsVariables): GetSendableCards => ({
  query: getSendableCardsString,
  variables: {
    offset,
    limit,
    search,
    category,
    collection,
    isPremium,
    favorite,
  },
})
