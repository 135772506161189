import marketingUrlFragmentRawString from '../MarketingURLFragment.graphql'

export const marketingUrlFragmentString = marketingUrlFragmentRawString

export type MarketingURLFragment = {
  __typename: 'MarketingURL'
  id: string
  title: string
  url: string
}

export const isMarketingURLFragment = (
  fragment: any,
): fragment is MarketingURLFragment =>
  fragment &&
  fragment.__typename === 'MarketingURL' &&
  typeof fragment.id === 'string' &&
  typeof fragment.title === 'string' &&
  typeof fragment.url === 'string'
