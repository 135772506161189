import { TextAlignment } from '../../../__generated__/globalTypes'

import {
  TextFragmentFragment,
  isTextFragmentFragment,
} from './TextFragmentFragment'

import paragraphFragmentRawString from '../ParagraphFragment.graphql'

export const paragraphFragmentString = paragraphFragmentRawString

export type ParagraphFragment = {
  textFragments: TextFragmentFragment[]
  alignment: TextAlignment
}

export const isParagraphFragment = (
  fragment: any,
): fragment is ParagraphFragment =>
  fragment &&
  Array.isArray(fragment.textFragments) &&
  fragment.textFragments
    .slice(0, 5)
    .reduce(
      (accum: any, next: any) =>
        accum && (isTextFragmentFragment(next) as boolean),
      true,
    ) &&
  (fragment.alignment === 'LEFT' ||
    fragment.alignment === 'CENTER' ||
    fragment.alignment === 'RIGHT')
