import { Map } from 'immutable'
// @src imports
import { Future } from 'src/utils/Future'
import { normalize, Normalized } from 'src/normalized-data/normalization'
import {
  ById,
  CardFragment,
  DetailedSendableCardFragment,
  NormalizedSendableCard,
} from 'src/graphql'
import Action from 'src/redux/action'
import {
  loadedCardCatalog,
  loadedSendableCard,
  loadedSendableCards,
  selectedCatalogCard,
  setIsBulkOrderFromQueryParam,
} from 'src/redux/actions/catalog'
import { NormalizedCardCatalog } from 'src/catalog/types'

export type CatalogState = {
  normalizedCardCatalog: Future<NormalizedCardCatalog>
  sendableCards: Map<string, Future<Normalized<DetailedSendableCardFragment>>>
  customCards: Map<string, Future<Normalized<CardFragment>>>
  loadingCardForPreview: boolean
  isBulkOrderFromQueryParam: boolean
}

const initialState: CatalogState = {
  normalizedCardCatalog: Future(),
  sendableCards: Map(),
  customCards: Map(),
  loadingCardForPreview: true,
  isBulkOrderFromQueryParam: false,
}

export const normalizedSendableCardsReducer = (
  data: ById<NormalizedSendableCard>,
  action: Action,
): ById<NormalizedSendableCard> => {
  switch (action.type) {
    default:
      return data
  }
}

// tslint:disable-next-line: cyclomatic-complexity score 21
export default (
  state: CatalogState = initialState,
  action: Action,
): CatalogState => {
  switch (action.type) {
    case loadedCardCatalog.type:
      return {
        ...state,
        normalizedCardCatalog: Future(
          action.cardCatalog.map(NormalizedCardCatalog),
        ),
      }
    case setIsBulkOrderFromQueryParam.type:
      return {
        ...state,
        isBulkOrderFromQueryParam: action.isBulkOrder,
      }
    case loadedSendableCard.type:
      return {
        ...state,
        loadingCardForPreview: false,
        sendableCards: state.sendableCards.set(
          action.id,
          Future(action.result.map(normalize)),
        ),
      }
    case selectedCatalogCard.type:
      return {
        ...state,
        loadingCardForPreview:
          state.sendableCards.get(action.id) || state.customCards.get(action.id)
            ? false
            : true,
      }

    case loadedSendableCards.type:
      return {
        ...state,
        normalizedCardCatalog: state.normalizedCardCatalog.map(cardCatalog => {
          const { result, search, taskType } = action
          const normalizedResult = result.map((cards: any) =>
            cards.map(normalize),
          )
          switch (taskType) {
            case 'all':
              const allCards = cardCatalog.allCards
              return {
                ...cardCatalog,
                allCards: allCards.update(search, cards =>
                  cards.appendResult(normalizedResult, 20),
                ),
              }
            case 'photo-drop':
              const photoDropCards = cardCatalog.photoDropCards
              return {
                ...cardCatalog,
                photoDropCards: photoDropCards.update(search, cards =>
                  cards.appendResult(normalizedResult, 20),
                ),
              }
            case 'standard':
              const standardCards = cardCatalog.standardCards
              return {
                ...cardCatalog,
                standardCards: standardCards.update(search, cards =>
                  cards.appendResult(normalizedResult, 20),
                ),
              }
            default:
              if ('categoryId' in taskType) {
                const categoryId = taskType.categoryId
                const categoryCards = cardCatalog.categoryCards
                return {
                  ...cardCatalog,
                  categoryCards: {
                    ...categoryCards,
                    [categoryId]: categoryCards[categoryId].update(
                      search,
                      cards => cards.appendResult(normalizedResult, 20),
                    ),
                  },
                }
              } else {
                const collectionId = taskType.collectionId
                const collectionCards = cardCatalog.collectionCards
                return {
                  ...cardCatalog,
                  collectionCards: {
                    ...collectionCards,
                    [collectionId]: collectionCards[collectionId].appendResult(
                      normalizedResult,
                      20,
                    ),
                  },
                }
              }
          }
        }),
      }
    default:
      return state
  }
}
