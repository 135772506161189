import { Operation } from '../../../__generated__/globalTypes'

import {
  DetailedCountryFragment,
  detailedCountryFragmentString,
} from '../../fragments/__generated__/DetailedCountryFragment'

import getDetailedCountriesRawString from '../GetDetailedCountries.graphql'

const getDetailedCountriesString = [
  getDetailedCountriesRawString,
  detailedCountryFragmentString,
].join('\n\n')

export type GetDetailedCountriesData = {
  detailedCountries: DetailedCountryFragment[]
}

export type GetDetailedCountries = Operation<GetDetailedCountriesData>

export const GetDetailedCountries = (): GetDetailedCountries => ({
  query: getDetailedCountriesString,
  variables: undefined,
})
