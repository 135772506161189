import { Operation } from '../../../__generated__/globalTypes'

import {
  CardFragment,
  cardFragmentString,
} from '../../fragments/__generated__/CardFragment'

import { colorFragmentString } from '../../fragments/__generated__/ColorFragment'

import { itemizedCostFragmentString } from '../../fragments/__generated__/ItemizedCostFragment'

import { sendableCardWithTemplateFragmentString } from '../../fragments/__generated__/SendableCardWithTemplateFragment'

import { sendableCardFragmentString } from '../../fragments/__generated__/SendableCardFragment'

import { priceString } from '../../fragments/__generated__/Price'

import { imageFragmentString } from '../../fragments/__generated__/ImageFragment'

import { elementFragmentString } from '../../fragments/__generated__/ElementFragment'

import { textFragmentFragmentString } from '../../fragments/__generated__/TextFragmentFragment'

import { elementPictureFragmentString } from '../../fragments/__generated__/ElementPictureFragment'

import { panelFragmentString } from '../../fragments/__generated__/PanelFragment'

import { labeledPriceString } from '../../fragments/__generated__/LabeledPrice'

import { sendableCardImageFragmentString } from '../../fragments/__generated__/SendableCardImageFragment'

import { elementTextFragmentString } from '../../fragments/__generated__/ElementTextFragment'

import { paragraphFragmentString } from '../../fragments/__generated__/ParagraphFragment'

import getCardRawString from '../GetCard.graphql'

const getCardString = [
  getCardRawString,
  cardFragmentString,
  colorFragmentString,
  itemizedCostFragmentString,
  sendableCardWithTemplateFragmentString,
  sendableCardFragmentString,
  priceString,
  imageFragmentString,
  elementFragmentString,
  textFragmentFragmentString,
  elementPictureFragmentString,
  panelFragmentString,
  labeledPriceString,
  sendableCardImageFragmentString,
  elementTextFragmentString,
  paragraphFragmentString,
].join('\n\n')

export type GetCardData = {
  card: CardFragment
}

export type GetCardVariables = {
  id: string
}

export type GetCard = Operation<GetCardData, GetCardVariables>

export const GetCard = ({ id }: GetCardVariables): GetCard => ({
  query: getCardString,
  variables: {
    id,
  },
})
