import customCardFragmentRawString from '../CustomCardFragment.graphql'

export const customCardFragmentString = customCardFragmentRawString

export type CustomCardFragment = {
  __typename: 'Card'
  id: string
  frontPreviewUrl: string
  isHorizontal: boolean
}

export const isCustomCardFragment = (
  fragment: any,
): fragment is CustomCardFragment =>
  fragment &&
  fragment.__typename === 'Card' &&
  typeof fragment.id === 'string' &&
  typeof fragment.frontPreviewUrl === 'string' &&
  typeof fragment.isHorizontal === 'boolean'
