import React from 'react'
import { SVGProps } from './types'

export const SmileCardWHeart: React.FC<SVGProps> = ({ size, color, onClick }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 37 32"
      width={size}
      height={size}
      onClick={onClick}
    >
      <path
        fill={color}
        d="M36.212 11.114a.404.404 0 0 0-.046-.15c-.01-.021-.032-.039-.046-.057a1.937 1.937 0 0 0-1.213-.978c-.057-.043-.11-.085-.182-.128l-1.483-.929v-4.3a2.204 2.204 0 0 0-2.202-2.203h-8.208L19.662.388c-.409-.256-.953-.384-1.494-.384-.54 0-1.081.128-1.494.384l-3.17 1.981H5.037a2.204 2.204 0 0 0-2.202 2.202v4.476c-.8.501-1.387.871-1.405.886-.63.434-.992.683-1.195.96-.022.025-.046.046-.064.078-.004.011-.004.018-.007.029-.121.21-.164.448-.164.797v18.268c0 1.07.868 1.942 1.942 1.942h32.456c1.07 0 1.942-.868 1.942-1.942V11.797c0-.239-.046-.47-.128-.68v-.003Zm-1.466-.31c.14.05.257.132.363.228l-1.867.996V9.872l1.504.932ZM17.144 1.131c.256-.16.63-.252 1.028-.252s.772.092 1.028.252l1.974 1.235H15.17l1.975-1.235ZM3.718 4.568c0-.73.594-1.32 1.32-1.32h26.005c.73 0 1.32.594 1.32 1.32v7.542c0 .124.053.235.135.316l-11.75 6.28a.453.453 0 0 0-.153.142c-1.516-.818-3.213-.83-4.743-.046a.45.45 0 0 0-.117-.096l-11.982-6.34c.01-.342-.003-.854-.039-.925V4.568h.004Zm-1.953 6.2 1.07-.672v1.473c0 .05.015.178.036.33l-1.63-.864c.146-.128.32-.23.524-.266Zm33.697 19.293a1.06 1.06 0 0 1-1.06 1.06H1.941a1.06 1.06 0 0 1-1.06-1.06V11.84l14.134 7.482c-.025.018-.05.032-.074.05l-11.57 9.064a.441.441 0 0 0 .545.694l11.565-9.061c1.687-1.299 3.697-1.299 5.376 0l11.569 9.065a.437.437 0 0 0 .27.092.441.441 0 0 0 .27-.786l-11.572-9.065s-.007-.003-.01-.007l14.073-7.52v18.218l.004-.004Z"
      />
      <path
        fill={color}
        d="M21.11 26.208c.68-.782.904-1.693.602-2.5-.26-.701-.89-1.22-1.598-1.317-.857-.139-1.515.057-1.988.601-.395-.416-1.004-.75-2.007-.608-1.014.15-1.707 1.05-1.615 2.099.004.04.021.078.032.114.085.65.466 1.295 1.16 1.92.452.396 2.085 1.8 2.39 2.046.022.018.047.025.072.04.014.007.028.017.046.024a.432.432 0 0 0 .163.033c.093 0 .175-.04.253-.097.01-.007.021-.003.032-.014.16-.142.612-.573 1.092-1.024.416-.395.836-.794 1.04-.975.113-.1.205-.189.305-.31v-.003c0-.004.007-.007.01-.01 0 0 0-.008.008-.012l.003-.007ZM14.209 10.62a1.761 1.761 0 1 0 0-3.523 1.761 1.761 0 0 0 0 3.522ZM22.131 10.62a1.761 1.761 0 1 0 0-3.523 1.761 1.761 0 0 0 0 3.522ZM21.174 11.612h-5.909c-1.255 0-1.953 1.42-1.17 2.38 2.21 2.696 5.824 2.696 8.03 0l.134-.164c.73-.893.082-2.213-1.088-2.213l.003-.003Zm-3.226 3.522s-1.761 0-2.643-.883c0 0 2.472-2.643 5.638 0 0 0-.356.883-2.999.883h.004Z"
      />
    </svg>
  )
}
