import axios, { AxiosPromise } from 'axios'
import get from 'lodash/get'
import isFunction from 'lodash/isFunction'
import * as Sentry from '@sentry/browser'
import axiosRetry, { exponentialDelay, isRetryableError } from 'axios-retry'
import { Operation } from 'src/graphql'
import { noop } from '../helpers/appHelpers'
import * as Result from '../utils/Result'
import { Store } from 'src/redux/store'

const getHeaders = (isFormData: boolean = false) => {
  const token = Store.getState().user.controlData.token
  return {
    // eslint-disable-next-line no-useless-computed-key
    ...(isFormData ? {} : { ['Content-Type']: 'application/json' }),
    ...(token ? { Authorization: `Token ${token}` } : {}),
  }
}

axiosRetry(axios, {
  retries: 3,
  retryCondition: isRetryableError,
  retryDelay: exponentialDelay,
})

const mutableDefaults = axios.defaults

mutableDefaults.baseURL = process.env.REACT_APP_API_URL || ''
mutableDefaults.headers.common['Content-Type'] = 'application/json'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const APP_URL =
  process.env.REACT_APP_APP_URL || 'https://app.sendoutcards.com'

type Request = AxiosPromise | LazyRequest
type LazyRequest = () => Request

const getPromise = (request: Request): AxiosPromise =>
  isFunction(request) ? getPromise(request()) : request

export const makeApiRequest = (
  request: Request,
  success: (data: any) => void = noop,
  error: (error: any) => void = noop,
): Promise<void> => {
  const promise = getPromise(request)

  function logError(err: any, sentry = false) {
    if (sentry) {
      Sentry.captureException(err)
    }
    console.error(err)
    error(err)
  }

  return promise
    .then(response => {
      const data = response && response.data
      if (data && data.errors) {
        logError(data.errors[0].message)
        return
      }
      return success(data && data.data ? data.data : data)
    })
    .catch(err => {
      if (axios.isCancel(err)) {
        return
      } else if (err) {
        const msg = get(
          err,
          'errors[0].message',
          get(
            err,
            'response.data.errors[0].message',
            'Something went wrong...',
          ),
        )
        // Explicit error returned from the api.
        logError(msg !== '0' ? msg : 'Something went wrong...')
      } else if (err && err.message === 'Network Error') {
        // Just call the error callback.
        error(Error("Huh... We can't seem to connect. Try again in a minute."))
      } else {
        // Most likely, these are other javascript errors.
        logError('Something went wrong...')
      }
    })
}

export const performOperation = <Data>(
  operation: Operation<Data, any>,
  completion: (result: Result.Result<Data>) => void,
  hitCDNCache: boolean = false,
) => {
  const cancelTokenSource = axios.CancelToken.source()
  makeApiRequest(
    hitCDNCache
      ? axios.get('/graphql', {
          headers: getHeaders(),
          params: operation,
          cancelToken: cancelTokenSource.token,
        })
      : axios.post('/graphql', operation, {
          headers: getHeaders(),
          cancelToken: cancelTokenSource.token,
        }),
    data => completion(Result.success(data)),
    error => completion(Result.failure(error)),
  )
  return cancelTokenSource
}
