import { Operation } from '../../../__generated__/globalTypes'

import {
  PanelFragment,
  panelFragmentString,
} from '../../fragments/__generated__/PanelFragment'

import { textFragmentFragmentString } from '../../fragments/__generated__/TextFragmentFragment'

import { colorFragmentString } from '../../fragments/__generated__/ColorFragment'

import { elementFragmentString } from '../../fragments/__generated__/ElementFragment'

import { elementPictureFragmentString } from '../../fragments/__generated__/ElementPictureFragment'

import { imageFragmentString } from '../../fragments/__generated__/ImageFragment'

import { elementTextFragmentString } from '../../fragments/__generated__/ElementTextFragment'

import { paragraphFragmentString } from '../../fragments/__generated__/ParagraphFragment'

import getLayoutRawString from '../GetLayout.graphql'

const getLayoutString = [
  getLayoutRawString,
  panelFragmentString,
  textFragmentFragmentString,
  colorFragmentString,
  elementFragmentString,
  elementPictureFragmentString,
  imageFragmentString,
  elementTextFragmentString,
  paragraphFragmentString,
].join('\n\n')

export type GetLayoutData = {
  layout: {
    panel: PanelFragment
  }
}

export type GetLayoutVariables = {
  id: string
}

export type GetLayout = Operation<GetLayoutData, GetLayoutVariables>

export const GetLayout = ({ id }: GetLayoutVariables): GetLayout => ({
  query: getLayoutString,
  variables: {
    id,
  },
})
