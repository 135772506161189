import inviteFragmentRawString from '../InviteFragment.graphql'

export const inviteFragmentString = inviteFragmentRawString

export type InviteFragment = {
  id: string
  createdAt: string
}

export const isInviteFragment = (fragment: any): fragment is InviteFragment =>
  fragment &&
  typeof fragment.id === 'string' &&
  typeof fragment.createdAt === 'string'
