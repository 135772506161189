// @ts-ignore
import React from 'react'
// tslint:disable-next-line: no-import-side-effect
import '@emotion/core'
import { ThemeProvider } from 'emotion-theming'
import { PersistGate } from 'redux-persist/integration/react'
// tslint:disable-next-line: no-import-side-effect
import 'array-flat-polyfill'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import * as LogRocket from 'logrocket'
import { MuiThemeProvider } from '@material-ui/core/styles'
import * as Sentry from '@sentry/browser'
// @src imports
import muiTheme from 'src/chrome/muiTheme'
import theme from 'src/theme/theme'
import { persistor, Store } from 'src/redux/store'
import App from 'src/app/App'
import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
} from '@apollo/client'
import { StripeProvider } from 'react-stripe-elements'

if (process.env.NODE_ENV === 'production') {
  LogRocket.init('0xqcnb/soc')
}

Sentry.init({
  dsn: 'https://1fd0c4ba1def418ca033a4a802729216@sentry.io/228977',
  environment: process.env.NODE_ENV,
})

Sentry.withScope(scope => {
  const sessionURL = LogRocket.sessionURL
  scope.setExtra('sessionURL', sessionURL)
})

const stripe_public_key = process.env.REACT_APP_STRIPE_KEY

export const client = new ApolloClient({
  link: new HttpLink({
    uri: 'https://bydesign-product-apollo-server.herokuapp.com/',
  }),
  cache: new InMemoryCache(),
})

if (stripe_public_key) {
  render(
    <Provider store={Store}>
      <ApolloProvider client={client}>
        <PersistGate persistor={persistor}>
          <StripeProvider apiKey={stripe_public_key}>
            <MuiThemeProvider theme={muiTheme}>
              <ThemeProvider theme={theme}>
                <App />
              </ThemeProvider>
            </MuiThemeProvider>
          </StripeProvider>
        </PersistGate>
      </ApolloProvider>
    </Provider>,
    document.getElementById('site'),
  )
} else {
  throw Error('REACT_APP_STRIPE_KEY not provided in env')
}

// TypeForm Script
;(() => {
  setTimeout(() => {
    const d = document
    const gi = d.getElementById
    const ce = d.createElement
    const gt = d.getElementsByTagName
    const id = 'typef_orm_share'
    const b = 'https://embed.typeform.com/'
    if (!gi.call(d, id)) {
      const mutableJS: any = ce.call(d, 'script')
      mutableJS.id = id
      mutableJS.src = b + 'embed.js'
      const q = gt.call(d, 'script')[0]
      q && q.parentNode && q.parentNode.insertBefore(mutableJS, q)
    }
  }, 1000)
})()

// @ts-ignore
if (module.hot) {
  // @ts-ignore
  module.hot.accept()
}
