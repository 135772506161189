import {
  StoryCardType,
  StoryGreetingCardType,
} from 'src/home_page/components/CardStoriesRibbon/CardStoriesRibbon'

export const cardStoriesMockData: (StoryGreetingCardType | StoryCardType)[] = [
  {
    frontImage:
      'https://www.sendoutcards.com/thumbnail/Ppw1gFrjTyU-5sDu4pbglBsQKZs=/fit-in/700x0/system_images/2019/12/4/dfc87129-cb8d-4a0b-a874-ba240075279b.png',
    isHorizontal: false,
  },
  {
    title: 'Celebrate people',
    description:
      'Tell the people in your life how you feel about them. Connect on a deeper level through joy, appreciation, and love with our greeting cards.',
    position: 'center',
  },
  {
    frontImage:
      'https://www.sendoutcards.com/thumbnail/cOZXlshr5YdIgyKQUoT_VYMdEiM=/fit-in/700x0/system_images/2020/3/18/3efe9539-16d6-4a6a-a875-d2313aebdf7d.png',
    isHorizontal: true,
  },
  {
    title: 'Share gratitude',
    description:
      'Expressing your appreciation and adoration for you friends, family, clients, or people you meet is a great way to act on your promptings.',
    position: 'top',
  },
  {
    frontImage:
      'https://www.sendoutcards.com/thumbnail/_lg79_YDhXVt47tLlYo0ap96Erc=/fit-in/700x0/system_images/2023/3/17/260c07d4-cff6-4a78-8087-6a3f62389179.png',
    isHorizontal: false,
  },
  {
    title: 'Change lives',
    description:
      'The words of gratitude that you share to the world will inevitably change lives in a positive way. Let’s change the world, one card at a time.',
    position: 'bottom',
  },
]
