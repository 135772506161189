import React from 'react'

import connect from 'src/redux/connect'
import { BaseRoute } from 'src/routes/BaseRoute'
import { RouteMeta } from 'src/routes/Route'
import { findRoute } from 'src/routes/utilities'

interface NavLinkProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  activeClassName?: string // If you specify an activeClassName you must also specify a route
  onClick: () => void
  route?: RouteMeta<any, any, any>
  selectedRoute: BaseRoute
}

const NavLink: React.FC<NavLinkProps> = props => {
  const {
    activeClassName,
    children,
    className,
    id,
    onClick,
    route,
    selectedRoute,
  } = props

  return (
    /* eslint-disable-next-line */
    <a
      id={id}
      className={
        route && findRoute(selectedRoute, route)
          ? activeClassName
            ? activeClassName
            : className
          : className
      }
      onClick={() => onClick()}
      style={{ cursor: 'pointer' }}
    >
      {children}
    </a>
  )
}

export default connect(({ route }) => ({ selectedRoute: route }))(NavLink)
