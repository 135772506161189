import React, { useCallback, useState } from 'react'

import { Icon } from 'src/chrome'

import styles from './floatingDrawer.module.scss'

type FloatingDrawerProps = {
  className: string
  icon?: any
  isOpen: boolean
  onClick: () => void
  style?: React.CSSProperties
  triggerTitle: string
  triggerCloseTitle: string
  viewBox?: any
}

const FloatingDrawer: React.FC<FloatingDrawerProps> = props => {
  const [open, setOpen] = useState<boolean>(props.isOpen)

  const toggleDrawer = useCallback(() => {
    setOpen(prevOpen => !prevOpen)
  }, [])

  const {
    children,
    className,
    icon,
    onClick,
    style,
    triggerCloseTitle,
    triggerTitle,
    viewBox,
  } = props

  return (
    <div
      className={
        open
          ? `${styles.floatingDrawer} ${styles.open}`
          : `${styles.floatingDrawer} ${className}`
      }
    >
      <div
        id={!open ? 'customize_btn' : 'return_to_customize'}
        className={
          className && !open ? `${styles.toggle} ${className}` : styles.toggle
        }
        style={style}
        onClick={
          onClick
            ? () => {
                onClick()
                toggleDrawer()
              }
            : toggleDrawer
        }
      >
        {<Icon icon={icon ? icon : 'PLUS'} viewBox={viewBox} />}
        {triggerTitle && (
          <p>
            {!open
              ? triggerTitle
              : open && triggerCloseTitle
              ? triggerCloseTitle
              : 'Close'}
          </p>
        )}
      </div>
      <div className={styles.floatingMenu}>{children}</div>
    </div>
  )
}

export default FloatingDrawer
