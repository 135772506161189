import socProContactFragmentRawString from '../SocProContactFragment.graphql'

export const socProContactFragmentString = socProContactFragmentRawString

export type SocProContactFragment = {
  __typename: 'SocProContact'
  id: string
  contactId: string
  metadataBlob: string
  createdAt: string
  updatedAt: string
}

export const isSocProContactFragment = (
  fragment: any,
): fragment is SocProContactFragment =>
  fragment &&
  fragment.__typename === 'SocProContact' &&
  typeof fragment.id === 'string' &&
  typeof fragment.contactId === 'string' &&
  typeof fragment.metadataBlob === 'string' &&
  typeof fragment.createdAt === 'string' &&
  typeof fragment.updatedAt === 'string'
