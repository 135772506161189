import { Maybe } from '../../../__generated__/globalTypes'

import {
  MinimalSendableCardFragment,
  isMinimalSendableCardFragment,
} from './MinimalSendableCardFragment'

import collectionFragmentRawString from '../CollectionFragment.graphql'

export const collectionFragmentString = collectionFragmentRawString

export type CollectionFragment = {
  __typename: 'Collection'
  id: string
  title: string
  description: string
  backgroundStartColor: string
  backgroundEndColor: string
  backgroundImageUrl: Maybe<string>
  textColor: string
  reasonToSend: boolean
  cards: MinimalSendableCardFragment[]
}

export const isCollectionFragment = (
  fragment: any,
): fragment is CollectionFragment =>
  fragment &&
  fragment.__typename === 'Collection' &&
  typeof fragment.id === 'string' &&
  typeof fragment.title === 'string' &&
  typeof fragment.description === 'string' &&
  typeof fragment.backgroundStartColor === 'string' &&
  typeof fragment.backgroundEndColor === 'string' &&
  (fragment.backgroundImageUrl === null ||
    typeof fragment.backgroundImageUrl === 'string') &&
  typeof fragment.textColor === 'string' &&
  typeof fragment.reasonToSend === 'boolean' &&
  Array.isArray(fragment.cards) &&
  fragment.cards
    .slice(0, 5)
    .reduce(
      (accum: any, next: any) =>
        accum && (isMinimalSendableCardFragment(next) as boolean),
      true,
    )
