import { createSelector } from 'reselect'

import { Future } from 'src/utils/Future'
import { PaginatedList } from 'src/utils/PaginatedList'
import { State } from 'src/redux/reducers'
import { CardCategoryRoute } from 'src/catalog/routes/CardCategoryRoute'
import { CardCollectionRoute } from 'src/catalog/routes/CardCollectionRoute'
import { CatalogRoute, CatalogSubroute } from 'src/catalog/routes/CatalogRoute'
import { PhotoDropCardsRoute } from 'src/catalog/routes/PhotoDropCardsRoute'
import { SendableCardRoute } from 'src/catalog/routes/SendableCardRoute'
import { StandardCardsRoute } from 'src/catalog/routes/StandardCardsRoute'
import { CardCatalog, DenormalizedCardCatalog } from 'src/catalog/types'
import {
  CardCategoryFragment,
  CollectionFragment,
  CustomCardFragment,
  MinimalSendableCardFragment,
} from 'src/graphql'
import { denormalize } from 'src/normalized-data/normalization'
import { getNormalizedData } from 'src/redux/selectors/normalizedData'
import { findRoute, RouteType } from 'src/routes/utilities'

const getNormalizedCardCatalog = (state: State) =>
  state.cardCatalog.normalizedCardCatalog

const getDenormalizedCardCatalog: (
  state: State,
) => Future<DenormalizedCardCatalog> = createSelector(
  getNormalizedCardCatalog,
  getNormalizedData,
  (normalizedCardCatalog, normalizedData) =>
    normalizedCardCatalog.map(cardCatalog =>
      DenormalizedCardCatalog(cardCatalog, normalizedData),
    ),
)

export const getCardCatalog: (
  state: State,
) => Future<CardCatalog> = createSelector(
  getDenormalizedCardCatalog,
  denormalizedCardCatalog => denormalizedCardCatalog.map(CardCatalog),
)

const getCatalogRoute = ({ route }: State): CatalogRoute | undefined =>
  findRoute(route, CatalogRoute)

const getCatalogSubroute = (state: State): CatalogSubroute | undefined => {
  const catalogRoute = getCatalogRoute(state)
  return catalogRoute && catalogRoute.subroute
}

const getCardCategoryRoute = (state: State): CardCategoryRoute | undefined => {
  const catalogSubroute = getCatalogSubroute(state)
  return catalogSubroute && catalogSubroute.path === CardCategoryRoute.path
    ? catalogSubroute
    : undefined
}

const getCardCollectionRoute = (
  state: State,
): CardCollectionRoute | undefined => {
  const catalogSubroute = getCatalogSubroute(state)
  return catalogSubroute && catalogSubroute.path === CardCollectionRoute.path
    ? catalogSubroute
    : undefined
}

const getSubroutePaginatedCards = (
  subroute: Exclude<CatalogSubroute, CardCollectionRoute>,
  catalog: DenormalizedCardCatalog,
  search: string,
):
  | PaginatedList<MinimalSendableCardFragment>
  | PaginatedList<CustomCardFragment>
  | undefined => {
  switch (subroute.path) {
    case CardCategoryRoute.path:
      return catalog.categoryCards[subroute.cardCategoryId].get(search)
    case PhotoDropCardsRoute.path:
      return catalog.photoDropCards.get(search)
    case StandardCardsRoute.path:
      return catalog.standardCards.get(search)
  }
  return undefined
}

export const getPaginatedCards: (
  state: State,
) =>
  | PaginatedList<MinimalSendableCardFragment>
  | PaginatedList<CustomCardFragment>
  | undefined = createSelector(
  getDenormalizedCardCatalog,
  getCatalogRoute,
  (denormalizedCardCatalog, catalogRoute) => {
    if (
      !denormalizedCardCatalog.value ||
      !catalogRoute ||
      (catalogRoute.subroute &&
        catalogRoute.subroute.path === CardCollectionRoute.path)
    ) {
      return undefined
    }
    const catalog = denormalizedCardCatalog.value
    const { search, subroute } = catalogRoute
    if (!subroute) {
      return search !== '' ? catalog.allCards.get(search) : undefined
    } else if (subroute.path === SendableCardRoute.path) {
      return undefined
    }
    return (
      getSubroutePaginatedCards(subroute, catalog, search) ||
      PaginatedList<MinimalSendableCardFragment>()
    )
  },
)

export const getSelectedCategory: (
  state: State,
) =>
  | CardCategoryFragment
  | undefined = createSelector(
  getDenormalizedCardCatalog,
  getCardCategoryRoute,
  (denormalizedCardCatalog, cardCategoryRoute) =>
    denormalizedCardCatalog.value && cardCategoryRoute
      ? denormalizedCardCatalog.value.categories.find(
          category => category.id === cardCategoryRoute.cardCategoryId,
        )
      : undefined,
)

export const getSelectedCollection: (
  state: State,
) => CollectionFragment | undefined = createSelector(
  getDenormalizedCardCatalog,
  getCardCollectionRoute,
  (denormalizedCardCatalog, cardCollectionRoute) =>
    denormalizedCardCatalog.value && cardCollectionRoute
      ? denormalizedCardCatalog.value.collections.find(
          collection => collection.id === cardCollectionRoute.cardCollectionId,
        )
      : undefined,
)

const getSendableCards = (state: State) => state.cardCatalog.sendableCards

const getDenormalizedSendableCards = createSelector(
  getNormalizedData,
  getSendableCards,
  (normalizedData, sendableCards) =>
    sendableCards.map(sendableCard =>
      sendableCard.map(denormalize(normalizedData)),
    ),
)

export const getSelectedSendableCard = (state: State) => {
  const denormalizedSendableCards = getDenormalizedSendableCards(state)
  const route = getCatalogRoute(state)
  if (!route) {
    return undefined
  }
  const sendableCardRoute = findRoute(route, SendableCardRoute)
  return sendableCardRoute
    ? (denormalizedSendableCards.get(sendableCardRoute.cardId) ?? Future())
        .value
    : undefined
}

const isSpecificCardSubRoute = (route?: RouteType): boolean =>
  route
    ? route.path === SendableCardRoute.path ||
      isSpecificCardSubRoute(route.subroute)
    : false

export const shouldShowCardPreview = (state: State) =>
  isSpecificCardSubRoute(state.route)
