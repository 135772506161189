import React from 'react'
import { IconSizeType } from './PersonalTouch'

export const BirthdayCake: React.FC<IconSizeType> = ({
  width = 104,
  height = 104,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 104 104"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_425_16879)">
        <rect
          x={32}
          y={32}
          width={40}
          height={40}
          rx={4}
          fill="white"
          shapeRendering="crispEdges"
        />
        <path
          d="M62 62.75H42C41.59 62.75 41.25 62.41 41.25 62C41.25 61.59 41.59 61.25 42 61.25H62C62.41 61.25 62.75 61.59 62.75 62C62.75 62.41 62.41 62.75 62 62.75Z"
          fill="#BF9CE4"
        />
        <path
          d="M60.8894 62.75C60.4794 62.75 60.1394 62.41 60.1394 62V53C60.1394 51.76 58.9794 50.75 57.5594 50.75H46.4394C45.0194 50.75 43.8594 51.76 43.8594 53V62C43.8594 62.41 43.5194 62.75 43.1094 62.75C42.6994 62.75 42.3594 62.41 42.3594 62V53C42.3594 50.93 44.1894 49.25 46.4394 49.25H57.5494C59.7994 49.25 61.6294 50.93 61.6294 53V62C61.6394 62.41 61.2994 62.75 60.8894 62.75Z"
          fill="#BF9CE4"
        />
        <path
          d="M58.4405 50.75C58.0305 50.75 57.6905 50.41 57.6905 50V47.17C57.6905 46.39 56.9405 45.75 56.0305 45.75H47.9806C47.0606 45.75 46.3206 46.39 46.3206 47.17V50C46.3206 50.41 45.9806 50.75 45.5706 50.75C45.1606 50.75 44.8106 50.41 44.8106 50V47.17C44.8106 45.56 46.2306 44.25 47.9706 44.25H56.0205C57.7605 44.25 59.1805 45.56 59.1805 47.17V50C59.1905 50.41 58.8505 50.75 58.4405 50.75ZM57.3307 57.75C56.1707 57.75 55.2307 56.81 55.2307 55.65V55.34C55.2307 55.01 54.9607 54.74 54.6307 54.74C54.3007 54.74 54.0307 55.01 54.0307 55.34V55.65C54.0307 56.81 53.0907 57.75 51.9307 57.75C50.7707 57.75 49.8307 56.81 49.8307 55.65V55.34C49.8307 55.01 49.5607 54.74 49.2307 54.74C48.9007 54.74 48.6307 55.01 48.6307 55.34V55.65C48.6307 56.81 47.6907 57.75 46.5307 57.75C45.3707 57.75 44.4307 56.81 44.4307 55.65V55.32C44.4307 55 44.1707 54.73 43.8407 54.72H43.4707C43.0607 54.71 42.7207 54.37 42.7307 53.96C42.7407 53.55 43.0707 53.22 43.4807 53.22H43.4907H43.8607C45.0007 53.24 45.9307 54.18 45.9307 55.32V55.65C45.9307 55.98 46.2007 56.25 46.5307 56.25C46.8607 56.25 47.1307 55.98 47.1307 55.65V55.34C47.1307 54.18 48.0707 53.24 49.2307 53.24C50.3907 53.24 51.3307 54.18 51.3307 55.34V55.65C51.3307 55.98 51.6007 56.25 51.9307 56.25C52.2607 56.25 52.5307 55.98 52.5307 55.65V55.34C52.5307 54.18 53.4707 53.24 54.6307 53.24C55.7907 53.24 56.7307 54.18 56.7307 55.34V55.65C56.7307 55.98 57.0007 56.25 57.3307 56.25C57.6607 56.25 57.9307 55.98 57.9307 55.65V55.34C57.9307 54.18 58.8707 53.24 60.0307 53.24H60.4807C60.8907 53.24 61.2307 53.58 61.2307 53.99C61.2307 54.4 60.8907 54.74 60.4807 54.74H60.0307C59.7007 54.74 59.4307 55.01 59.4307 55.34V55.65C59.4307 56.81 58.4907 57.75 57.3307 57.75Z"
          fill="#BF9CE4"
        />
        <path
          d="M48 45.75C47.59 45.75 47.25 45.41 47.25 45V43C47.25 42.59 47.59 42.25 48 42.25C48.41 42.25 48.75 42.59 48.75 43V45C48.75 45.41 48.41 45.75 48 45.75ZM56 45.75C55.59 45.75 55.25 45.41 55.25 45V43C55.25 42.59 55.59 42.25 56 42.25C56.41 42.25 56.75 42.59 56.75 43V45C56.75 45.41 56.41 45.75 56 45.75ZM52 45.75C51.59 45.75 51.25 45.41 51.25 45V42C51.25 41.59 51.59 41.25 52 41.25C52.41 41.25 52.75 41.59 52.75 42V45C52.75 45.41 52.41 45.75 52 45.75Z"
          fill="#BF9CE4"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_425_16879"
          x={0}
          y={0}
          width={104}
          height={104}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation={16} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_425_16879"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_425_16879"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  )
}
