import { TrainingPageProps } from './TrainingPage'

export const eventData: TrainingPageProps = {
  heroImage:
    'https://greenerstill.com/media/images/A-Better-Way_FB_Fan-cover.max-800x600.png',
  description:
    'Get the latest updates, product information, and training on SendOutCard products over on our Facebook page. To view any past trainings, click the button below!',
  previousTrainingUrl: 'https://www.facebook.com/SendOutCards/videos',
  eventBlockDetails: [
    {
      date: {
        dayOfWeek: 'Wednesday',
        month: 'December',
        time: '2:00 p.m. (MT)',
        day: 15,
      },
      description: 'Join us on the SendoutCards FaceBook page',
      button: { title: 'SendOutCards Facebook page' },
    },
    {
      date: {
        dayOfWeek: 'Wednesday',
        month: 'January',
        time: '2:00 p.m. (MT)',
        day: 19,
      },
      description: 'Join us on the SendoutCards FaceBook page',
      button: { title: 'SendOutCards Facebook page' },
    },
    {
      date: {
        dayOfWeek: 'Wednesday',
        month: 'February',
        time: '2:00 p.m. (MT)',
        day: 23,
      },
      description: 'Join us on the SendoutCards FaceBook page',
      button: { title: 'SendOutCards Facebook page' },
    },
    {
      date: {
        dayOfWeek: 'Wednesday',
        month: 'March',
        time: '2:00 p.m. (MT)',
        day: 30,
      },
      description: 'Join us on the SendoutCards FaceBook page',
      button: { title: 'SendOutCards Facebook page' },
    },
  ],
}
