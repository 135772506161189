import React from 'react'

import { MenuItemLink } from 'src/styled'
import { Icon } from 'src/chrome'

type MenuItemIcon = {
  icon?: any
  color?: string
  size?: number
  viewBox?: string
}

export type MenuItemType = {
  icon: MenuItemIcon
  title: string
  onClick?: () => void
  link?: string
  disabled?: boolean
}

interface MenuItemProps {
  item: MenuItemType
}

const MenuItem: React.FC<MenuItemProps> = props => {
  const { icon, title, link, onClick } = props.item
  return (
    <div
      onClick={() => {
        onClick && onClick()
      }}
    >
      <Icon
        icon={icon.icon}
        color={icon.color}
        size={icon.size}
        viewBox={icon.viewBox}
      />
      <p>{title}</p>
      {link && <MenuItemLink href={link} target="_blank" />}
    </div>
  )
}

export default MenuItem
