import {
  Div,
  Flex,
  LoadingSpinner,
  Select,
} from '@sendoutcards/quantum-design-ui'
import React, { useEffect, useState } from 'react'
import { CardStackCarousel } from 'src/design_system/components/CardStackCarousel/CardStackCarousel'
import { MinimalSendableCardFragment } from 'src/graphql'
import useGetCardCategories from 'src/home_page/hooks/useGetCardCategoriesQuery'

export const CardCategorySlider: React.FC = () => {
  const categories = useGetCardCategories()

  const [selectedOptionIndex, setSelectedOptionIndex] = useState(0)

  const [selectedCards, setSelectedCards] = useState<
    MinimalSendableCardFragment[]
  >([])

  const categoryOptions = categories?.cardCategories.map(category => ({
    value: category.id,
    label: category.description,
  }))

  useEffect(() => {
    const selectedCategoryCards =
      categories?.cardCategories[selectedOptionIndex]?.cards
    setSelectedCards(selectedCategoryCards || [])
  }, [selectedOptionIndex, categories])

  const handleCategoryChange = (index: number) => {
    setSelectedOptionIndex(index)
  }

  const activeCards = selectedCards.map(card => ({
    image: card.frontImage.url,
    isHorizontal: card.isHorizontal,
  }))

  return (
    <Flex
      width="100%"
      backgroundColor="#f9fafbb3"
      flexDirection="column"
      alignItems="center"
      rowGap="x2"
      inset="x2"
    >
      {categories === undefined && (
        <Div outset={{ top: 'x9' }}>
          <LoadingSpinner size="xLarge" />
        </Div>
      )}
      {categories && (
        <>
          <Flex width="240px">
            <Select
              type="text"
              frontLabel="Occasion"
              selectedOptionIndex={selectedOptionIndex}
              onChange={handleCategoryChange}
              options={categoryOptions ?? []}
              selectWidth="240px"
            />
          </Flex>
          <CardStackCarousel initialSlide={2} cardThumbnails={activeCards} />
        </>
      )}
    </Flex>
  )
}
