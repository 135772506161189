import { Interpolation } from '@emotion/css'

const styles = {
  expenseReload: {
    display: 'flex',
    flexDirection: 'row',
    maxWidth: 420,
    justifyContent: 'space-around',
    '@media (max-width: 668px)': {
      flexDirection: 'column',
    },
  } as Interpolation,
  column: {
    position: 'relative',
    padding: '0 10px',
    width: '45%',
    '&:after': {
      content: "''",
      top: '10%',
      height: '50%',
      width: 1,
      background: '#dddddd',
      position: 'absolute',
      right: 0,
    },
    '@media (max-width: 668px)': {
      padding: 0,
      width: 'auto',
      ':after': {
        width: '75%',
        height: 1,
        top: '110%',
        right: 'auto',
        left: '12.5%',
      },
    },
  } as Interpolation,
  threshold: {
    padding: '0 10px',
    width: '45%',
    justifyContent: 'flex-start',
    '@media (max-width: 668px)': {
      padding: 0,
      width: 'auto',
      margin: '20px 0',
    },
  } as Interpolation,
  picka: {
    margin: 'auto auto 0 auto',
  } as Interpolation,
}

export default styles
