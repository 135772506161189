import React, { CSSProperties } from 'react'
import { Icon } from 'src/chrome'
import Badge from '@material-ui/core/Badge'

import { IconTypeOrIconString } from 'src/app/types'
import { CardPaperType, CardType } from 'src/graphql'

import styles from './cardOptions.module.scss'

export type CardOption<Value> = {
  value: Value
  label: string
  gradient?: string
  icon?: IconTypeOrIconString
  iconSize?: number
  viewBox?: string
  upgrade?: string
}

type CardOptionsProps<Value> = {
  selectedOption: Value
  options: CardOption<Value>[]
  optionTitle: string
  selectOption: (value: Value) => void
}

class CardOptions<Value extends {}> extends React.Component<
  CardOptionsProps<Value>
> {
  render() {
    const { optionTitle, options, selectOption } = this.props
    return (
      <div className={styles.CardOptionsToggle}>
        <h6>{`${optionTitle}`}</h6>
        <div className={styles.cardConfigOptions}>
          <div className={styles.overScroll}>
            {options.map(option => {
              const activeColor: CSSProperties =
                option.value === this.props.selectedOption
                  ? {
                      color: '#fff',
                      fontWeight: 'bold',
                    }
                  : {}
              const selectedOption = this.props.selectedOption
              const optionGradient = {
                backgroundImage: option.gradient,
              }
              return (
                <div
                  id={`${option.value}_btn`}
                  className={
                    selectedOption === option.value
                      ? `${styles.CardOptions} ${styles.activeOption}`
                      : styles.CardOptions
                  }
                  onClick={() => selectOption(option.value)}
                  key={option.value.toString()}
                >
                  <div className={styles.CardOptionsOption}>
                    <div className={styles.optionImageWrapper}>
                      <div>
                        {option.icon && (
                          <Icon
                            icon={option.icon}
                            color={
                              option.value === this.props.selectedOption
                                ? '#fff'
                                : '#6F8394'
                            }
                            size={option.iconSize ? option.iconSize : 23}
                            viewBox={option.viewBox && option.viewBox}
                          />
                        )}
                        {option.gradient && <div style={optionGradient} />}
                      </div>
                    </div>
                    <span style={activeColor}>{option.label}</span>
                  </div>
                  {option.upgrade && (
                    <Badge
                      badgeContent={option.upgrade}
                      color="secondary"
                      overlap="circle"
                      children={null}
                      classes={{
                        badge: styles.CardBadge,
                      }}
                    />
                  )}
                </div>
              )
            })}
          </div>
        </div>
      </div>
    )
  }
}

export class CardTypeOptions extends CardOptions<CardType> {}

export class PaperTypeOptions extends CardOptions<CardPaperType> {}
