import { DetailedCountryFragment } from 'src/graphql'

// This helper function takes in an array of detailedCountries objects (via a query)
// as well as the currently selected country from the component (which defaults to
// the USA), and returns all country names as a list of strings and all associated
// regions of that country as a list of strings
const firstCountries = ['United States', 'Australia', 'Canada']

export const formatCountriesAndRegions = (
  detailedCountries: DetailedCountryFragment[],
  selectedCountry?: string,
): {
  countries: string[]
  regions: string[] | undefined
  isRegionsUnavailable: boolean
} => {
  const countries = [
    ...firstCountries,
    ...detailedCountries
      .map(country => country.postalName)
      .filter(country => !firstCountries.includes(country)),
  ]

  const country =
    selectedCountry === 'SendOutCards' ? 'United States' : selectedCountry

  const regions = country
    ? detailedCountries
        .filter(detailedCountry => detailedCountry.postalName === country)[0]
        .subdivisions.map(sub => sub.abbreviationOrName)
        .concat(
          country === 'United States' ? ['AA', 'AE', 'AP'] : [],
          country === 'Hong Kong'
            ? [' ', 'HONG KONG', 'KWOLOON', 'NEW TERRITORIES']
            : [],
          country === 'Northern Ireland' ? ['Down', 'Tyrone'] : [],
        )
    : undefined
  regions?.sort((a, b) => a.localeCompare(b))
  const isRegionsUnavailable = regions?.length === 0 ? true : false

  if (isRegionsUnavailable) {
    regions?.push(' ')
  }

  return {
    countries,
    regions,
    isRegionsUnavailable,
  }
}
