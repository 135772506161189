import React from 'react'

import { Button } from 'src/chrome'

import styles from './search.module.scss'

type SearchTagsProps = {
  buttonClick?: () => void
  categories: any[] ///// array
  className?: string
  onClick: (category: string) => void
  selectedTag: any
}

const SearchTags: React.FC<SearchTagsProps> = props => {
  const { className, categories, selectedTag, onClick, buttonClick } = props
  return (
    <div
      className={
        className ? `${styles.searchTags} ${className}` : styles.searchTags
      }
    >
      <Button
        title={'Build Your Own'}
        id={'create_your_own_card_btn'}
        className={styles.buildYourOwn}
        buttonColor={'blue'}
        style={{ order: 3 }}
        onClick={() => {
          buttonClick && buttonClick()
        }}
      />
      {categories.map((category: any, i: number) => {
        const active = selectedTag === category
        const activeStyle: React.CSSProperties = {
          fontWeight: 'bold',
          color: '#333',
          order: i + 1,
        }
        return (
          <div
            id={`${category.description
              .split(' ')
              .join('_')
              .toLowerCase()}_catalog_btn`}
            key={i}
            onClick={() => {
              onClick(category)
            }}
            style={i === 3 ? {} : active ? activeStyle : { order: i + 1 }}
          >
            {category.description}
          </div>
        )
      })}
    </div>
  )
}

export default SearchTags
