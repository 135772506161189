import axios, { CancelTokenSource as RunningTask } from 'axios'
import { Dispatch, MiddlewareAPI } from 'redux'
// @src imports
import { BaseRoute } from 'src/routes/BaseRoute'
import { catalogPrefetchTasks } from 'src/catalog/tasks'
import { compact } from 'src/helpers'
import { Action } from 'src/redux/action'
import { Middleware } from 'src/redux/store'
import { State } from 'src/redux/reducers'
// relative imports
import { makeApiRequest } from './api'
import { Result } from '../utils/Result'
import { Task } from './types'

type KeyedTask = {
  name: string
  key: string
  start: (dispatch: Dispatch<Action>) => RunningTask
}

const operationName = (operationString: string): string => {
  const regex = /(query|mutation) (\w+)/.exec(operationString)
  return regex ? `${regex[1]} ${regex[2]}` : ''
}

const KeyedTask = ({ operation, completion }: Task): KeyedTask => ({
  name: operationName(operation.query),
  key: JSON.stringify(operation),
  start: dispatch => {
    const cancelToken = axios.CancelToken.source()
    makeApiRequest(
      axios.post('/graphql', operation, {
        cancelToken: cancelToken.token,
      }),
      data => dispatch(completion(Result(data))),
      error => dispatch(completion(Result(error))),
    )
    return cancelToken
  },
})

const mutableRunningTasks: { [key: string]: RunningTask } = {}

const routeTasks = (state: State): Task[] => BaseRoute.tasks(state.route, state)

/*
 Returns a list of tasks that need to be run, in priority order.
*/
const getTasks = (state: State): Task[] => {
  return compact(
    // Route tasks are the next most important.
    // These should load content/perform updates for where the user is in the app.
    ...routeTasks(state),
    // These other tasks are less important but we should prioritize them
    // based on the frequency that users navigate to these modules
    // so that we are likely to have content pre-fetched
    ...catalogPrefetchTasks(state),
  )
}

const isLocalHost =
  process.env.REACT_APP_API_URL &&
  (process.env.REACT_APP_API_URL.includes('localhost') ||
    process.env.REACT_APP_API_URL.includes('0.0.0.0'))

const maximumConcurrentTasks = isLocalHost ? 1 : 6

const updateTasks = (middlewareAPI: MiddlewareAPI<Dispatch<Action>, State>) => {
  const keys = new Set<string>()
  const tasks = getTasks(middlewareAPI.getState())
    .map(KeyedTask)
    .filter(task => {
      // filter out duplicate tasks
      if (keys.has(task.key)) {
        return false
      } else {
        keys.add(task.key)
        return true
      }
    })
    .slice(0, maximumConcurrentTasks) // only keep up to our max

  // console.clear(); tasks.forEach(task => console.log(task.name)) // comment/uncomment to toggle logging tasks to the console

  // Remove tasks no longer running
  for (const key in mutableRunningTasks) {
    if (!keys.has(key)) {
      const cancelToken = mutableRunningTasks[key]
      cancelToken.cancel()
      delete mutableRunningTasks[key]
    }
  }

  // Start tasks not yet running
  tasks.forEach(task => {
    if (!(task.key in mutableRunningTasks)) {
      mutableRunningTasks[task.key] = task.start(middlewareAPI.dispatch)
    }
  })
}

export const tasksMiddleware: Middleware = middlewareAPI => nextDispatch => action => {
  const result = nextDispatch(action)
  updateTasks(middlewareAPI)
  return result
}
