import filterFragmentRawString from '../FilterFragment.graphql'

export const filterFragmentString = filterFragmentRawString

export type FilterFragment = {
  name: string
  urlEncodedValues: string
}

export const isFilterFragment = (fragment: any): fragment is FilterFragment =>
  fragment &&
  typeof fragment.name === 'string' &&
  typeof fragment.urlEncodedValues === 'string'
