import { Maybe } from '../../../__generated__/globalTypes'

import sponsorFragmentRawString from '../SponsorFragment.graphql'

export const sponsorFragmentString = sponsorFragmentRawString

export type SponsorFragment = {
  id: string
  firstName: string
  lastName: string
  email: string
  hasUnlimitedCards: boolean
  genealogyId: number
  profileImageUrl: Maybe<string>
  isAffiliate: boolean
  storefront: Maybe<{
    slug: Maybe<string>
    mugshot: Maybe<{
      url: string
    }>
    biography: Maybe<string>
  }>
}

export const isSponsorFragment = (fragment: any): fragment is SponsorFragment =>
  fragment &&
  typeof fragment.id === 'string' &&
  typeof fragment.firstName === 'string' &&
  typeof fragment.lastName === 'string' &&
  typeof fragment.email === 'string' &&
  typeof fragment.hasUnlimitedCards === 'boolean' &&
  typeof fragment.genealogyId === 'number' &&
  (fragment.profileImageUrl === null ||
    typeof fragment.profileImageUrl === 'string') &&
  typeof fragment.isAffiliate === 'boolean' &&
  (fragment.storefront === null ||
    (fragment.storefront &&
      (fragment.storefront.slug === null ||
        typeof fragment.storefront.slug === 'string') &&
      (fragment.storefront.mugshot === null ||
        (fragment.storefront.mugshot &&
          typeof fragment.storefront.mugshot.url === 'string')) &&
      (fragment.storefront.biography === null ||
        typeof fragment.storefront.biography === 'string')))
