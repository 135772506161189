export const mockReviewsData = [
  {
    title: 'You only need to share your appreciation',
    review:
      '“17 years ago we made a commitment to really understand what a prompting was. Since then, we’ve been reaping the benefits. We have strengthened our relationships  with ourselves, with each other and with everyone we meet. We understand the true value in sending that unexpected heartfelt card. One that says, Thank you”, “We appreciate you”, “You’re doing a great job!” That card just happens to always arrive at the right time in their life. You cannot plan it any better. We realized that self-promoting wasn’t necessary for us, we never needed to ask for a referral, we only needed to share our appreciation for them and be their friends. Acting on a prompting changed our thinking, our habits and most importantly, our lives, forever.” ',
    rating: 5,
    user: {
      title: 'Bob & BettyAnn Golden',
      description: 'Affiliate Marketers',
      profileImage:
        'https://soc-website-public.s3.us-west-2.amazonaws.com/static/soc-website/images/review-avatars/goldens.jpg',
    },
  },
  {
    title: 'Staying top of mind generates referrals',
    review:
      '“The success of my construction and supply showroom has been amazing. I’ve been in contact with a designer for a while hoping we can work together. After sending Just Because, Thinking of You, and Happy Birthday campaign cards, I closed an amazing deal with her, one of my biggest jobs to date. I totally believe that the system is the best for tangible touches as well as a huge growth and referral opportunity for your business. It really keeps you top of mind. My biggest source of referrals are coming because of SendOutCards.”',
    rating: 5,
    user: {
      title: 'Heba Ahmed Malki',
      description: 'Business Owner',
      profileImage:
        'https://soc-website-public.s3.us-west-2.amazonaws.com/static/soc-website/images/review-avatars/Heba.jpg',
    },
  },
  {
    title: 'The power to make an impact',
    review:
      '“I am a special education needs teacher and special needs mother. In my time in the classroom and in my time with my own child, I hear a lot that special needs children are often overlooked during events that other children normally celebrate simply because they are in special needs programs and removed from the rest of the group. I decided to reach out to connect with special needs kids - I began reaching out to the parents requesting to send them cards from my children to their children. I have been sending cards worldwide!”',
    rating: 5,
    user: {
      title: 'Michelle Slaney-Trovato',
      description: 'Teacher',
      profileImage:
        'https://soc-website-public.s3.us-west-2.amazonaws.com/static/soc-website/images/review-avatars/Michelle+Slaney-Trovato.jpeg',
    },
  },
]
