import { Maybe, PlansPlanIconChoices } from '../../../__generated__/globalTypes'

import { Amount, isAmount } from './Amount'

import planFragmentRawString from '../PlanFragment.graphql'

export const planFragmentString = planFragmentRawString

export type PlanFragment = {
  __typename: 'Plan'
  id: string
  title: string
  subtitle: Maybe<string>
  stripeId: string
  price: Amount
  description: Maybe<string>
  isAddon: boolean
  cardCost: number
  bulkCardCost: number
  giftDiscount: number
  isExpenseReloadRequired: boolean
  color: Maybe<string>
  backgroundStartColor: string
  backgroundEndColor: string
  icon: PlansPlanIconChoices
}

export const isPlanFragment = (fragment: any): fragment is PlanFragment =>
  fragment &&
  fragment.__typename === 'Plan' &&
  typeof fragment.id === 'string' &&
  typeof fragment.title === 'string' &&
  (fragment.subtitle === null || typeof fragment.subtitle === 'string') &&
  typeof fragment.stripeId === 'string' &&
  (isAmount(fragment.price) as boolean) &&
  (fragment.description === null || typeof fragment.description === 'string') &&
  typeof fragment.isAddon === 'boolean' &&
  typeof fragment.cardCost === 'number' &&
  typeof fragment.bulkCardCost === 'number' &&
  typeof fragment.giftDiscount === 'number' &&
  typeof fragment.isExpenseReloadRequired === 'boolean' &&
  (fragment.color === null || typeof fragment.color === 'string') &&
  typeof fragment.backgroundStartColor === 'string' &&
  typeof fragment.backgroundEndColor === 'string' &&
  (fragment.icon === 'A_10_CARD' ||
    fragment.icon === 'A_15_CARD' ||
    fragment.icon === 'A_20_CARD' ||
    fragment.icon === 'A_25_CARD' ||
    fragment.icon === 'BROCHURE' ||
    fragment.icon === 'CANCEL' ||
    fragment.icon === 'CARD' ||
    fragment.icon === 'CARD_ERROR' ||
    fragment.icon === 'CHECK' ||
    fragment.icon === 'CONTACT' ||
    fragment.icon === 'DANGER' ||
    fragment.icon === 'DATABASE' ||
    fragment.icon === 'DOUBLE_CHECK' ||
    fragment.icon === 'EDIT' ||
    fragment.icon === 'EMPTY_GIFT' ||
    fragment.icon === 'GIFT' ||
    fragment.icon === 'HEARTFELT' ||
    fragment.icon === 'INFO' ||
    fragment.icon === 'MULTIPLE_CARDS' ||
    fragment.icon === 'PACKAGE' ||
    fragment.icon === 'PAPER_PLANE' ||
    fragment.icon === 'PAPER_PLANE_FLYING' ||
    fragment.icon === 'PAPER_PLANE_WITH_HEART' ||
    fragment.icon === 'REPLACE' ||
    fragment.icon === 'SEARCH' ||
    fragment.icon === 'SHOPPING_CART' ||
    fragment.icon === 'UPLOAD' ||
    fragment.icon === 'USER' ||
    fragment.icon === 'V_CHECK' ||
    fragment.icon === 'X' ||
    fragment.icon === 'SMILE_CARD' ||
    fragment.icon === 'SMILE_CARD_W_HEARTS' ||
    fragment.icon === 'SMILE_CARD_W_HEART' ||
    fragment.icon === 'SOLOPRENEUR' ||
    fragment.icon === 'ENTREPRENEUR' ||
    fragment.icon === 'BUSINESSPRENEUR' ||
    fragment.icon === 'CARD_PACK' ||
    fragment.icon === 'TIME_BLOCK' ||
    fragment.icon === 'EXP_CALENDAR' ||
    fragment.icon === 'CHECK_MARK' ||
    fragment.icon === 'TICKET' ||
    fragment.icon === 'AUTOMATED_CARD' ||
    fragment.icon === 'CAMPAIGN_CARD' ||
    fragment.icon === 'GROUP_SEND_CARD' ||
    fragment.icon === 'SCHEDULE_CARD' ||
    fragment.icon === 'MULTI_TOUCH_CARD' ||
    fragment.icon === 'CATALOG_CARD' ||
    fragment.icon === 'PHOTO_DROP_CARD' ||
    fragment.icon === 'ENHANCED_STORAGE' ||
    fragment.icon === 'PREMIUM_STORAGE' ||
    fragment.icon === 'PERSONAL_SIGNATURE' ||
    fragment.icon === 'PERSONAL_HAND_WRITING' ||
    fragment.icon === 'ESSENTIAL_PACKS')
