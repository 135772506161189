import { Route } from 'src/routes/Route'

type FunnelPageType = 'slug' | 'sponsorId'

type FunnelPageContent = {
  slugOrSponsorId: string
  type: FunnelPageType
}

export const FunnelPageRoute = Route({
  path: '/u/:slugOrSponsorId',
  additionalPatterns: ['/:slugOrSponsorId'],
  init: (slugOrSponsorId: string, type: FunnelPageType): FunnelPageContent => ({
    slugOrSponsorId,
    type,
  }),
  fromPath: ({ slugOrSponsorId }) =>
    slugOrSponsorId
      ? {
          slugOrSponsorId,
          type: (parseInt(slugOrSponsorId, 10)
            ? 'sponsorId'
            : 'slug') as FunnelPageType,
        }
      : undefined,
  hideSidebar: true,
  hideTopBar: true,
})
export type FunnelPageRoute = Route<typeof FunnelPageRoute>
