import {
  AccountInput,
  Maybe,
  SubscriptionInput,
  Operation,
} from '../../../__generated__/globalTypes'

import {
  AccountFragment,
  accountFragmentString,
} from '../../fragments/__generated__/AccountFragment'

import { creditCardFragmentString } from '../../fragments/__generated__/CreditCardFragment'

import { sponsorFragmentString } from '../../fragments/__generated__/SponsorFragment'

import { storefrontString } from '../../fragments/__generated__/Storefront'

import { addressFragmentString } from '../../fragments/__generated__/AddressFragment'

import { stripeSourceFragmentString } from '../../fragments/__generated__/StripeSourceFragment'

import updateAccountRawString from '../UpdateAccount.graphql'

const updateAccountString = [
  updateAccountRawString,
  accountFragmentString,
  creditCardFragmentString,
  sponsorFragmentString,
  storefrontString,
  addressFragmentString,
  stripeSourceFragmentString,
].join('\n\n')

export type UpdateAccountData = {
  updateAccount: {
    account: AccountFragment
  }
}

export type UpdateAccountVariables = {
  account: AccountInput
  subscription?: Maybe<SubscriptionInput>
}

export type UpdateAccount = Operation<UpdateAccountData, UpdateAccountVariables>

export const UpdateAccount = ({
  account,
  subscription,
}: UpdateAccountVariables): UpdateAccount => ({
  query: updateAccountString,
  variables: {
    account: AccountInput(account),
    subscription: subscription && SubscriptionInput(subscription),
  },
})
