import React from 'react'
import { SVGProps } from './types'

export const CardPack: React.FC<SVGProps> = ({ size, color, onClick }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 32"
      width={size}
      height={size}
      onClick={onClick}
    >
      <path
        fill={color}
        fillRule="evenodd"
        d="M13.333 16.23c-1.47 0-2.667-1.034-2.667-2.303 0-1.268 1.197-2.301 2.667-2.301 1.47 0 2.666 1.033 2.666 2.302 0 1.268-1.196 2.301-2.666 2.301zm0-3.453c-.735 0-1.334.516-1.334 1.15 0 .635.599 1.151 1.334 1.151.735 0 1.333-.516 1.333-1.15 0-.635-.598-1.151-1.333-1.151z"
        clipRule="evenodd"
      />
      <path
        fill={color}
        fillRule="evenodd"
        d="M19.333 25.436H4.667c-.369 0-.667-.257-.667-.575V8.75c0-.318.298-.576.667-.576h14.666c.369 0 .667.258.667.576v13.782a.455.455 0 010 .053v2.276c0 .318-.299.575-.667.575zm-14-1.15v-2.64l3.334-2.876 4.861 4.196c.13.112.302.168.472.168a.72.72 0 00.472-.168.522.522 0 000-.814l-.862-.744 1.724-1.487 3.332 2.876v1.489H5.333zm2.862-6.738l-2.862 2.47V9.325h13.334v11.844l-2.862-2.47a.745.745 0 00-.943 0l-2.195 1.895-3.529-3.046a.745.745 0 00-.943 0z"
        clipRule="evenodd"
      />
      <path
        fill={color}
        d="M7.333 28.889h9.334c.368 0 .666-.258.666-.576 0-.318-.298-.575-.666-.575H7.333c-.368 0-.666.257-.666.575 0 .318.298.576.666.576z"
      />
      <path
        fill={color}
        fillRule="evenodd"
        d="M20.001 4.77h3.332c.369 0 .667.258.667.576v26.42c0 .317-.298.575-.667.575H.667c-.369 0-.667-.258-.667-.576V5.345c0-.273.221-.502.518-.56L19.149.143a.747.747 0 01.585.093c.167.11.267.28.267.46V4.77zm-14.62 0h13.287V1.46L5.38 4.77zM1.333 31.19h21.334V5.921H1.333v25.27z"
        clipRule="evenodd"
      />
    </svg>
  )
}
