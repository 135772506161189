import React, { useEffect } from 'react'

import { performOperation } from 'src/app/api'
import Result from 'src/utils/Result'
import { Operation } from 'src/graphql'

export type LoadingTask<Data> = {
  isTask: true
  operation: Operation<Data, any>
  completion: (result: Result<Data>) => void
  hitCDNCache: boolean
}

export function LoadingTask<Data, Variables>(task: {
  operation: Operation<Data, Variables>
  completion: (result: Result<Data>) => void
  hitCDNCache?: boolean
}): LoadingTask<Data> {
  return { isTask: true, hitCDNCache: false, ...task }
}

const LoadingTaskComponent: React.FC<{ task: LoadingTask<any> }> = props => {
  useEffect(() => {
    performOperation(
      props.task.operation,
      props.task.completion,
      props.task.hitCDNCache,
    )
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [])

  const { children } = props
  return <React.Fragment>{children}</React.Fragment>
}

export default LoadingTaskComponent
