import { FC } from 'react'
import { SVGProps } from './types'

export const PromptingsLogo: FC<SVGProps> = ({ size, color, onClick }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 651 260"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <g
        style={{
          mixBlendMode: 'multiply',
        }}
      >
        <path
          d="M544.711 6.05547C457.942 -18.819 380.363 46.0543 313.136 94.4961C287.752 77.4869 262.12 67.044 225.205 58.8103L224.071 59.0782C244.351 70.7782 272.867 96.7054 282.157 117.027C238.155 150.449 193.645 187.825 143.183 205.567C83.4486 228.628 17.0859 181.96 20.3844 117.735C22.0869 57.1681 95.4188 9.88765 150.952 28.7118C91.4674 5.09674 10.5171 50.2285 1.46126 116.407C-9.66647 188.338 56.4294 258.87 127.014 259.384C197.217 256.927 251.681 203.907 303.948 162.451C308.723 158.663 313.448 154.834 318.216 150.954C384.693 200.715 457.004 269.243 549.955 246.489C690.444 212.075 679.771 34.1156 544.711 6.05547ZM502.23 203.315C445.72 193.458 399.005 154.714 351.332 124.61C384.143 99.0427 418.264 74.8105 455.257 57.9958C507.043 34.4001 581.681 52.8161 602.177 107.291C621.841 165.708 555.84 212.759 502.28 203.356L502.23 203.315Z"
          fill={color}
        />
      </g>
    </svg>
  )
}
