import React from 'react'
import { Flex, Text } from '@sendoutcards/quantum-design-ui'

type CustomerTileProps = {
  title: string
  description: string
  profileImage: string
  size?: string
  onSelect?: () => void
  isActive?: boolean
  isReviewBreakPoint?: boolean
}

export const CustomerTile: React.FC<CustomerTileProps> = ({
  title,
  description,
  profileImage,
  size = '50px',
  onSelect,
  isActive,
  isReviewBreakPoint,
}) => {
  const baseTextStyles = {
    color: '#404040',
    fontFamily: 'Montserrat',
  }

  return (
    <Flex
      flexDirection="row"
      backgroundColor={isReviewBreakPoint ? '#fff' : 'rgba(255, 185, 81, 0.05)'}
      inset={'1rem'}
      alignItems="center"
      columnGap="x1_5"
      onClick={onSelect}
      cursor="pointer"
      style={{
        backdropFilter: !isActive ? 'blur(30px)' : 'none',
        borderRadius: '1.25rem',

        border:
          isActive && !isReviewBreakPoint
            ? '2px solid rgba(255, 185, 81, 0.3)'
            : 'none',
      }}
    >
      <div
        style={{
          width: size,
          height: size,
          borderRadius: '50px',
          backgroundImage: `url(${profileImage})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          filter: 'drop-shadow(0px 4px 4px rgba(87,83,83, 25%))',
          aspectRatio: '1/1',
        }}
      />
      <Flex flexDirection="column" style={{}}>
        <Text
          type="body"
          content={title}
          lineHeight={1.3}
          whiteSpace="nowrap"
          style={{
            fontSize: '1rem',
            fontWeight: 700,
            ...baseTextStyles,
            textOverflow: 'ellipsis',
            maxWidth: !isReviewBreakPoint ? '196px' : '220px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
          }}
        />
        <Text
          type="body"
          content={description}
          style={{ fontSize: '.75rem', ...baseTextStyles }}
        />
      </Flex>
    </Flex>
  )
}
