/**
 * Updates a value of an array or object for a given key
 * @param object An array or object
 * @param key An array index or object key
 * @param update A method to update the existing content at the selected index or key
 * @returns A copy with the updated key value
 */
const update = <T extends object, K extends keyof T>(
  object: T,
  key: K,
  update: (value: T[K]) => T[K],
): T => {
  if (Array.isArray(object) && typeof key == 'number') {
    return object.map((value, index) =>
      index === key ? update(value) : value,
    ) as T
  } else {
    return {
      ...object,
      [key]: update(object[key]),
    }
  }
}

export default update
