import React from 'react'
import { Interpolation } from '@emotion/css'
import { formatAmount } from 'src/helpers/payments'
import { FlexRow } from 'src/styled'

interface Props {
  amount: number
  step: number
  min: number
  max: number
  shouldShowDecimal?: boolean
  onChange: (amount: number) => void
}

const DollaPicka: React.FC<Props> = props => {
  const { amount, step, min, max, onChange, shouldShowDecimal, ...rest } = props

  const handleIncrease = () => onChange(Math.min(amount + step, max))
  const handleDecrease = () =>
    onChange(Math.min(Math.max(amount - step, min), max))

  return (
    <FlexRow css={styles.container} {...rest}>
      <div css={styles.plusMinus} onClick={handleDecrease}>
        -
      </div>
      <div css={styles.amount}>{`$${
        shouldShowDecimal ? formatAmount(amount) : amount
      }`}</div>
      <div css={styles.plusMinus} onClick={handleIncrease}>
        +
      </div>
    </FlexRow>
  )
}

export default DollaPicka

const styles = {
  container: {
    height: 40,
    borderRadius: 50,
    width: 90,
    border: '2px solid #80808040',
    justifyContent: 'center',
    alignItems: 'center',
    '@media (max-width: 668px)': {
      marginTop: 15,
    },
  } as Interpolation,
  plusMinus: {
    fontSize: 32,
    margin: '0 5px 10px 5px',
    cursor: 'pointer',
    color: '#80808040',
    userSelect: 'none',
  } as Interpolation,
  amount: {
    fontSize: 18,
    color: 'grey',
  } as Interpolation,
}
