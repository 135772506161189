import { Operation } from '../../../__generated__/globalTypes'

import {
  MarketingParagraphFragment,
  marketingParagraphFragmentString,
} from '../../fragments/__generated__/MarketingParagraphFragment'

import getMarketingParagraphsRawString from '../GetMarketingParagraphs.graphql'

const getMarketingParagraphsString = [
  getMarketingParagraphsRawString,
  marketingParagraphFragmentString,
].join('\n\n')

export type GetMarketingParagraphsData = {
  pricingPageTitle: MarketingParagraphFragment
  pricingPageSubtitle: MarketingParagraphFragment
}

export type GetMarketingParagraphs = Operation<GetMarketingParagraphsData>

export const GetMarketingParagraphs = (): GetMarketingParagraphs => ({
  query: getMarketingParagraphsString,
  variables: undefined,
})
