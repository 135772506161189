import React, { useState } from 'react'
// @src imports
import { Button } from 'src/chrome'
import {
  CustomCardFragment,
  DetailedSendableCardFragment,
  MinimalSendableCardFragment,
} from 'src/graphql'

import styles from './cardFlip.module.scss'

type CardFlipProps = {
  card:
    | MinimalSendableCardFragment
    | DetailedSendableCardFragment
    | CustomCardFragment
  customCardSize?: string
  flipped?: boolean
  hideButton?: boolean
}

const rotateY = {
  transform: 'rotateY(180deg)',
}

const rotateX = {
  transform: 'rotateX(180deg)',
}

const CardFlip: React.FC<CardFlipProps> = props => {
  // tslint:disable-next-line: cyclomatic-complexity score 28
  const { card, customCardSize, flipped, hideButton } = props
  const [isFlipped, toggleFlipped] = useState<boolean>(!!flipped)

  const handleFlip = () => toggleFlipped(f => !f)

  const isLandscape = card.isHorizontal
  const cardOrientationStyles = isLandscape ? styles.landscape : styles.portrait
  const cardFrontImage =
    card.__typename === 'Card'
      ? card.frontPreviewUrl
      : card.frontImage.mediumThumb
  const cardInsideImage =
    card.__typename === 'SendableCard' &&
    'insideRightImage' in card &&
    card.insideRightImage &&
    card.insideRightImage.mediumThumb
  return (
    <div>
      <div
        className={
          !customCardSize
            ? `${styles.flipContainer} ${cardOrientationStyles}`
            : `${styles.flipContainer} ${customCardSize} ${cardOrientationStyles}`
        }
      >
        <div
          className={styles.flipper}
          style={
            (isFlipped || flipped) && !isLandscape
              ? rotateY
              : (isFlipped || flipped) && isLandscape
              ? rotateX
              : {}
          }
        >
          <div
            className={
              !customCardSize
                ? styles.front
                : `${styles.front} ${customCardSize}`
            }
            style={{
              backgroundImage: `url(${cardFrontImage})`,
              backgroundSize: 'contain',
            }}
          />
          {(isFlipped || flipped) && cardInsideImage && (
            <div
              className={
                customCardSize
                  ? `${styles.back} ${customCardSize}`
                  : styles.back
              }
              style={{ backgroundImage: `url(${cardInsideImage})` }}
            />
          )}
        </div>
      </div>
      {cardInsideImage && !hideButton && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: 10,
          }}
        >
          <Button
            id={isFlipped ? 'reasons_see_front_btn' : 'reasons_see_inside_btn'}
            title={isFlipped || flipped ? 'See Front' : 'See Inside'}
            onClick={handleFlip}
          />
        </div>
      )}
    </div>
  )
}

export default CardFlip
