import { Operation } from '../../../__generated__/globalTypes'

import {
  CollectionFragment,
  collectionFragmentString,
} from '../../fragments/__generated__/CollectionFragment'

import {
  MinimalSendableCardFragment,
  minimalSendableCardFragmentString,
} from '../../fragments/__generated__/MinimalSendableCardFragment'

import {
  CustomCardFragment,
  customCardFragmentString,
} from '../../fragments/__generated__/CustomCardFragment'

import {
  CardCategoryFragment,
  cardCategoryFragmentString,
} from '../../fragments/__generated__/CardCategoryFragment'

import { sendableCardImageFragmentString } from '../../fragments/__generated__/SendableCardImageFragment'

import getCatalogRawString from '../GetCatalog.graphql'

const getCatalogString = [
  getCatalogRawString,
  collectionFragmentString,
  minimalSendableCardFragmentString,
  customCardFragmentString,
  cardCategoryFragmentString,
  sendableCardImageFragmentString,
].join('\n\n')

export type GetCatalogData = {
  collections: CollectionFragment[]
  favoriteCards: MinimalSendableCardFragment[]
  customCards: CustomCardFragment[]
  legacyPicturePlusCards: MinimalSendableCardFragment[]
  featuredCards: MinimalSendableCardFragment[]
  cardCategories: CardCategoryFragment[]
}

export type GetCatalog = Operation<GetCatalogData>

export const GetCatalog = (): GetCatalog => ({
  query: getCatalogString,
  variables: undefined,
})
