import React from 'react'
import { SVGProps } from './types'

export const LinkedIn: React.FC<SVGProps> = ({ size, color, onClick }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M10.708 20H9.341c-.064-.012-.126-.029-.19-.038-.341-.046-.686-.072-1.024-.139-2.364-.472-4.34-1.62-5.86-3.493C.438 14.078-.278 11.486.097 8.613.331 6.8 1.023 5.157 2.193 3.75 4.59.87 7.675-.348 11.39.085c1.665.195 3.178.831 4.522 1.834 2.131 1.59 3.455 3.693 3.941 6.314.065.35.105.705.156 1.058v1.407c-.013.055-.03.11-.038.168-.045.328-.069.66-.133.984-.441 2.236-1.5 4.128-3.208 5.64a9.795 9.795 0 0 1-5.117 2.394c-.268.04-.536.077-.805.116Zm2.383-4.75c.081.004.139.009.196.009.592 0 1.185-.005 1.778.004.162.003.209-.045.208-.207-.006-1.205.006-2.41-.008-3.615a10.316 10.316 0 0 0-.104-1.36c-.093-.642-.334-1.218-.891-1.608-.468-.328-1.006-.414-1.56-.415-.867-.003-1.586.304-2.075 1.055-.013.019-.03.034-.084.092v-.956H8.483v6.998h2.15v-.218c0-1.042-.002-2.085.002-3.127a7.22 7.22 0 0 1 .04-.72c.091-.875.671-1.32 1.536-1.187.397.062.655.303.751.677.078.298.119.614.123.923.015 1.087.006 2.175.006 3.263v.393ZM4.934 8.244v7.004h2.143V8.244H4.934Zm1.063-.981a1.276 1.276 0 0 0 1.271-1.265 1.281 1.281 0 0 0-1.27-1.267 1.28 1.28 0 0 0-1.27 1.268 1.27 1.27 0 0 0 1.268 1.264h.001Z"
        fill={color}
      />
    </svg>
  )
}
