import { Flex, LoadingSpinner, Text } from '@sendoutcards/quantum-design-ui'
import React from 'react'
import { openApp } from 'src/helpers/appHelpers'

export const PricingPage: React.FC = () => {
  openApp('/pricing')

  return (
    <Flex
      width="100vw"
      height="100vh"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      overflow="hidden"
      backgroundColor="#fcfcfc"
    >
      <LoadingSpinner size="xLarge" />
      <Text type="subtitle" content="Loading..." outset={{ top: 'x3' }} />
    </Flex>
  )
}
