import React from 'react'
import { SVGProps } from './types'

export const PromptingsLogoFull: React.FC<SVGProps> = ({
  size,
  color,
  onClick,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 1925.3 393"
      width={size}
      onClick={onClick}
    >
      <g>
        <g>
          <g>
            <path
              fill={color}
              d="M555.4,123.7c9.4,0,17.5,1.3,24.3,4c6.8,2.7,12.4,6.4,16.9,11.1c4.4,4.7,7.7,10.3,9.9,16.7c2.2,6.4,3.3,13.5,3.3,21.2     c0,13.3-2,26.1-6,38.5c-4,12.4-10.2,23.4-18.6,33c-8.4,9.6-18.9,17.3-31.7,23c-12.8,5.8-27.9,8.6-45.4,8.6     c-2.1,0-4.4-0.1-6.9-0.3c-2.5-0.2-4.7-0.5-6.6-0.9l-12.4,51.9h-26.8l47.2-196.8c8.1-3.3,16.9-5.8,26.4-7.5     C538.6,124.6,547.3,123.7,555.4,123.7z M500.1,256.3c1.9,0.4,4,0.6,6.2,0.7c2.2,0.1,4.4,0.1,6.5,0.1c10.9,0,20.7-2.1,29.4-6.3     c8.6-4.2,16-9.9,22-17.1c6-7.2,10.7-15.5,14-24.8c3.3-9.3,4.9-19.1,4.9-29.2c0-4.2-0.5-8.4-1.4-12.4c-1-4-2.7-7.6-5.3-10.7     c-2.6-3.1-6.1-5.5-10.5-7.3c-4.4-1.8-10.1-2.7-17-2.7c-4.8,0-9.2,0.4-13.3,1.3c-4,0.9-7.4,1.7-10.1,2.4L500.1,256.3z"
            />
            <path
              fill={color}
              d="M708.5,124.3c2.3,0,4.9,0.1,7.8,0.3c2.9,0.2,5.7,0.5,8.5,1c2.8,0.5,5.3,1,7.6,1.4c2.3,0.5,4,1,5.2,1.6l-7.8,23.3     c-3.8-1.5-8.3-2.7-13.3-3.5c-5-0.8-9.8-1.2-14.4-1.2c-4,0-8,0.3-12,0.9c-3.9,0.6-7.3,1.3-10.2,2.3l-30.5,126.8h-26.8l34.6-144     c7.7-2.7,15.9-4.8,24.8-6.5C690.8,125.1,699.7,124.3,708.5,124.3z"
            />
            <path
              fill={color}
              d="M781.9,281c-15.8,0-28.1-4.7-37-14c-8.9-9.3-13.4-22.1-13.4-38.5c0-11.1,1.6-22.9,4.8-35.1c3.2-12.3,8.2-23.6,15.1-34     c6.9-10.4,15.8-18.9,26.8-25.6c10.9-6.7,24.2-10.1,39.8-10.1c15.7,0,28.1,4.7,37,14c8.9,9.3,13.4,22.1,13.4,38.5     c0,11.1-1.6,22.9-4.8,35.1c-3.2,12.3-8.2,23.6-15.1,34s-15.8,18.9-26.8,25.6C810.7,277.7,797.5,281,781.9,281z M786.5,258.3     c8.6,0,16.4-2.5,23.2-7.5c6.8-5,12.6-11.4,17.3-19.2c4.7-7.8,8.3-16.4,10.8-25.8c2.5-9.4,3.7-18.5,3.7-27.4     c0-9.8-2.1-17.6-6.3-23.3c-4.2-5.8-11.5-8.6-21.9-8.6c-8.6,0-16.4,2.5-23.2,7.5c-6.8,5-12.6,11.4-17.3,19.2     c-4.7,7.8-8.3,16.4-10.8,25.8c-2.5,9.4-3.7,18.5-3.7,27.4c0,9.8,2.1,17.6,6.3,23.3C768.9,255.4,776.2,258.3,786.5,258.3z"
            />
            <path
              fill={color}
              d="M1055,124.3c8.4,0,15.6,1.3,21.5,3.9c5.9,2.6,10.6,6.1,14.1,10.5c3.6,4.4,6.1,9.6,7.6,15.4c1.5,5.9,2.3,12.1,2.3,18.6     c0,4.2-0.3,8.8-1,13.7c-0.7,4.9-1.6,9.7-2.7,14.3l-18.4,76.6h-26.8l17-70.9c1-4.4,2.1-9.4,3.3-14.8c1.2-5.5,1.9-10.9,1.9-16.3     c0-3.6-0.4-7.2-1.3-10.7c-0.9-3.5-2.3-6.4-4.3-8.9c-2-2.5-4.9-4.5-8.5-6c-3.7-1.5-8.2-2.3-13.5-2.3c-6.1,0-12.1,1.2-17.9,3.6     c-5.8,2.4-10.6,4.9-14.4,7.3c0.4,2.7,0.7,5.3,1,7.9c0.3,2.6,0.4,5.1,0.4,7.6c0,4.4-0.3,8.9-1,13.4c-0.7,4.5-1.5,9-2.4,13.4     l-18.4,76.6h-26.8l17-70.9c1-4.4,2-9.4,3.2-15c1.2-5.6,1.7-11.1,1.7-16.7c0-3.6-0.4-7.2-1.2-10.5c-0.8-3.4-2.1-6.3-3.9-8.8     c-1.8-2.5-4.3-4.5-7.3-5.9c-3.1-1.4-7-2.2-11.8-2.2c-4.8,0-9.5,0.3-14,0.9c-4.5,0.6-8.3,1.3-11.4,2.3l-30.5,126.8h-26.8l34.6-144     c8.6-2.7,18.1-4.8,28.4-6.5c10.3-1.6,19.4-2.4,27.5-2.4s14.9,1.4,20.5,4.2c5.6,2.8,10,6.5,13.3,11.1c1.9-1.3,4.6-2.9,7.9-4.6     c3.4-1.7,7.2-3.4,11.5-4.9c4.3-1.5,9-2.9,14.1-4C1044.4,124.9,1049.6,124.3,1055,124.3z"
            />
            <path
              fill={color}
              d="M1204.1,123.7c9.4,0,17.5,1.3,24.3,4c6.8,2.7,12.4,6.4,16.9,11.1c4.4,4.7,7.7,10.3,9.9,16.7c2.2,6.4,3.3,13.5,3.3,21.2     c0,13.3-2,26.1-6,38.5c-4,12.4-10.2,23.4-18.6,33c-8.4,9.6-18.9,17.3-31.7,23c-12.8,5.8-27.9,8.6-45.4,8.6     c-2.1,0-4.4-0.1-6.9-0.3c-2.5-0.2-4.7-0.5-6.6-0.9l-12.4,51.9h-26.8l47.2-196.8c8.1-3.3,16.9-5.8,26.4-7.5     C1187.2,124.6,1196,123.7,1204.1,123.7z M1148.8,256.3c1.9,0.4,4,0.6,6.2,0.7c2.2,0.1,4.4,0.1,6.5,0.1c10.9,0,20.7-2.1,29.4-6.3     c8.6-4.2,16-9.9,22-17.1c6-7.2,10.7-15.5,14-24.8c3.3-9.3,4.9-19.1,4.9-29.2c0-4.2-0.5-8.4-1.4-12.4c-1-4-2.7-7.6-5.3-10.7     c-2.6-3.1-6.1-5.5-10.5-7.3c-4.4-1.8-10.1-2.7-17-2.7c-4.8,0-9.2,0.4-13.3,1.3c-4,0.9-7.4,1.7-10.1,2.4L1148.8,256.3z"
            />
            <path
              fill={color}
              d="M1321.9,281.3c-14.4,0-25-3.2-31.7-9.5c-6.7-6.3-10.1-15.6-10.1-27.7c0-7.9,1.3-18,4-30.2L1315,85.7l27.9-4.6l-11.2,46.4     h51l-5.5,22.5h-51l-15.8,66.3c-2.3,9-3.5,16.9-3.5,23.6c0,6.3,1.7,10.9,5.2,13.7c3.5,2.8,9.2,4.2,17.3,4.2c5.6,0,11-0.9,16.4-2.7     c5.4-1.8,9.5-3.5,12.4-5l2,22.8c-2.9,1.7-7.8,3.6-14.7,5.6C1338.6,280.3,1330.7,281.3,1321.9,281.3z"
            />
            <path
              fill={color}
              d="M1407.6,277.3h-26.8l36-149.8h26.8L1407.6,277.3z M1438.7,100.4c-4.4,0-8.1-1.3-11.1-4c-3-2.7-4.5-6.4-4.5-11.2     c0-6.1,1.9-11,5.8-14.5c3.8-3.6,8.2-5.3,13-5.3c4.4,0,8.2,1.4,11.2,4.2c3.1,2.8,4.6,6.7,4.6,11.7c0,5.8-2,10.4-6,14     C1447.6,98.6,1443.3,100.4,1438.7,100.4z"
            />
            <path
              fill={color}
              d="M1476.7,277.3h-26.8l34.6-144c8.8-2.7,18.4-4.8,28.7-6.5c10.3-1.6,19.4-2.4,27.5-2.4c8.6,0,16.1,1.2,22.3,3.6     c6.2,2.4,11.3,5.7,15.1,9.8c3.8,4.1,6.7,9.1,8.5,15c1.8,5.9,2.7,12.2,2.7,19.2c0,4.4-0.4,9.1-1.2,14c-0.8,4.9-1.7,9.8-2.9,14.8     l-18.4,76.6h-26.8l17-70.9c1.3-5.4,2.6-10.8,3.9-16.4c1.2-5.6,1.9-10.8,1.9-15.8c0-7.7-2.1-14.1-6.3-19.2     c-4.2-5.1-12.1-7.6-23.6-7.6c-4.8,0-9.5,0.3-14.1,0.9c-4.6,0.6-8.5,1.3-11.5,2.3L1476.7,277.3z"
            />
            <path
              fill={color}
              d="M1640.4,308.4c7.9,0,14.8-1,20.7-2.9c6-1.9,11.1-4.7,15.4-8.4c4.3-3.7,7.8-8,10.5-13c2.7-5,4.8-10.7,6.3-17l1.2-5.5     c-3.8,2.1-9,4.2-15.6,6.3c-6.5,2.1-13.3,3.2-20.2,3.2c-15.6,0-27.5-3.9-35.9-11.7c-8.4-7.8-12.5-18.7-12.5-32.7     c0-12.9,2.1-25.4,6.3-37.7c4.2-12.3,10.7-23.2,19.3-32.8c8.6-9.6,19.4-17.3,32.3-23.2c12.9-5.9,27.9-8.8,45.2-8.8     c2.7,0,5.7,0.2,9.1,0.6c3.4,0.4,6.8,0.9,10.4,1.6c3.6,0.7,7,1.6,10.4,2.7c3.4,1.2,6.3,2.5,8.8,4l-31.4,131.1     c-2.5,10.4-5.6,19.7-9.2,27.9c-3.7,8.3-8.6,15.3-15,21c-6.3,5.8-14.5,10.2-24.5,13.4c-10,3.2-22.5,4.8-37.5,4.8     c-8.3,0-16.4-1.1-24.5-3.3c-8.1-2.2-14.9-4.9-20.5-8.2l10.1-22.5c4.4,3.3,10.3,5.9,17.6,7.9     C1624.7,307.4,1632.4,308.4,1640.4,308.4z M1664.6,248.8c7.7,0,14.7-1.4,21.2-4.2c6.4-2.8,11.4-5.4,14.8-7.9l21-87.6     c-1.9-0.4-4.1-0.8-6.6-1.2c-2.5-0.4-4.9-0.6-7.2-0.6c-10.4,0-19.9,2-28.5,5.9c-8.6,3.9-16.1,9.2-22.3,15.8     c-6.2,6.6-11.1,14.3-14.5,23c-3.5,8.7-5.2,17.8-5.2,27.2c0,10.4,2.3,17.9,6.8,22.5C1648.5,246.5,1655.4,248.8,1664.6,248.8z"
            />
            <path
              fill={color}
              d="M1798.6,258.6c4,0,8.1-0.4,12.2-1.2c4.1-0.8,7.8-2,10.9-3.7c3.2-1.7,5.8-4.1,7.8-7.1c2-3,3-6.7,3-11.1     c0-2.9-0.5-5.4-1.4-7.6c-1-2.2-2.5-4.3-4.6-6.2c-2.1-1.9-4.8-3.8-7.9-5.6c-3.2-1.8-7-3.9-11.4-6.2c-4.4-2.1-8.5-4.2-12.1-6.3     c-3.7-2.1-6.9-4.6-9.7-7.5c-2.8-2.9-4.9-6.3-6.5-10.2c-1.5-3.9-2.3-8.7-2.3-14.3c0-14.4,5.2-26,15.6-34.7     c10.4-8.7,25.4-13.1,45.2-13.1c9.2,0,17.3,0.9,24.3,2.7c7,1.8,11.9,3.5,14.5,5l-10.1,21.9c-2.7-1.5-7-3.2-13-4.9     c-6-1.7-12.6-2.6-19.9-2.6c-3.7,0-7.3,0.4-10.9,1.2c-3.7,0.8-7,2.1-9.9,3.9c-3,1.8-5.4,4.2-7.2,7.2c-1.8,3-2.7,6.6-2.7,10.8     c0,5.2,2,9.5,6,12.8c4,3.4,10.1,6.9,18.2,10.5c5,2.3,9.5,4.7,13.4,7.1c3.9,2.4,7.3,5.1,10.1,8.1c2.8,3,4.9,6.5,6.3,10.5     c1.4,4,2.2,8.8,2.2,14.4c0,8.1-1.5,15.2-4.6,21.3c-3.1,6.1-7.3,11.2-12.8,15.3c-5.5,4-12,7.1-19.4,9.1c-7.5,2-15.7,3-24.5,3     c-6.5,0-12.4-0.4-17.7-1.3c-5.3-0.9-9.9-1.9-13.8-3c-3.9-1.2-7.2-2.3-9.8-3.6c-2.6-1.2-4.6-2.3-5.9-3l10.4-21.9     c1.3,0.8,3.2,1.7,5.6,2.9c2.4,1.2,5.3,2.3,8.6,3.5c3.4,1.2,7.1,2.1,11.1,2.9C1790,258.2,1794.2,258.6,1798.6,258.6z"
            />
          </g>
          <path
            fill={color}
            d="M249.9,72.4c-45.8,19.2-56.1,72.4-67.8,115.3c-16.8,1.6-31.4,6-50.1,15.1l-0.4,0.5c12.8-2,34-1,45.1,4.2l0,0    c-7.1,28.8-12.9,59.4-28.3,83.9c-17.4,29.9-62.1,33.1-83.2,5.4c-20.5-25.6-5.8-70.1,24.5-81.2c-33.7,10.4-52.4,56.7-33,87.1    c20.5,33.5,73.5,40.2,103.8,16.5c29.1-24.9,33.7-65.3,41.5-100.3c0.7-3.2,1.4-6.4,2.1-9.6l0,0c45.9-2,100.7,1.7,132.4-39.3    C384.4,108.1,317.4,38.1,249.9,72.4z M301,168.5c-27.6,15.2-61.1,15-92,18.7c5-21.7,11.1-43.4,21-62.9    c13.8-27.4,52.2-45.1,80-29.6C338.8,112.2,327.2,154.2,301,168.5z"
          />
        </g>
      </g>
    </svg>
  )
}
