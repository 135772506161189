import { useWindowWidth } from '@react-hook/window-size'
import { Flex, Image, Spacer, Text } from '@sendoutcards/quantum-design-ui'
import React from 'react'

import { Autoplay, Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
/*tslint:disable:no-import-side-effect*/
import 'swiper/swiper-bundle.css'

export type StoryGreetingCardType = {
  frontImage: string
  isHorizontal: boolean
}

export type StoryCardType = {
  title: string
  description: string
  position: 'center' | 'top' | 'bottom'
}

type CardStoriesRibbonProps = {
  stories: (StoryGreetingCardType | StoryCardType)[]
}

export const CardStoriesRibbon: React.FC<CardStoriesRibbonProps> = ({
  stories,
}) => {
  const windowWidth = useWindowWidth()
  const isMobile = windowWidth <= 700

  const isStoryGreetingCardType = (
    story: any,
  ): story is StoryGreetingCardType => {
    return 'frontImage' in story && 'isHorizontal' in story
  }

  const MAX_RIBBON_WIDTH = 1748
  const HORIZONTAL_CARD_WIDTH = 300
  const VERTICAL_CARD_WIDTH = 200
  const GAP = 32

  const ribbonWidth = stories.reduce((acc, curr) => {
    if (isStoryGreetingCardType(curr)) {
      return (
        acc + (curr.isHorizontal ? HORIZONTAL_CARD_WIDTH : VERTICAL_CARD_WIDTH)
      )
    }
    return acc + HORIZONTAL_CARD_WIDTH
  }, 0)

  const carouselWidth =
    (windowWidth >= MAX_RIBBON_WIDTH ? MAX_RIBBON_WIDTH : windowWidth) * 2

  const difference = carouselWidth - ribbonWidth
  const multiplier =
    difference <= 0 ? 2 : Math.ceil(carouselWidth / ribbonWidth)

  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      maxWidth={`${MAX_RIBBON_WIDTH}px`}
      overflow="visible"
    >
      <Swiper
        className="slider"
        modules={[Autoplay, Navigation]}
        slidesPerView={'auto'}
        grabCursor={true}
        speed={500}
        loop={true}
        autoplay={{
          delay: 5000,
          disableOnInteraction: true,
          pauseOnMouseEnter: true,
        }}
        centeredSlides={isMobile ? true : false}
        navigation={false}
        spaceBetween={GAP}
        style={{
          display: 'flex',
          justifyContent: 'center',
          paddingTop: '50px',
          width: '100%',
        }}
      >
        {new Array(multiplier)
          .fill(stories)
          .flat()
          .map((story, index) => {
            if (isStoryGreetingCardType(story)) {
              const cardWidth = story.isHorizontal
                ? `${HORIZONTAL_CARD_WIDTH}px`
                : `${VERTICAL_CARD_WIDTH}px`
              const cardHeight = story.isHorizontal
                ? `${VERTICAL_CARD_WIDTH}px`
                : `${HORIZONTAL_CARD_WIDTH}px`
              return (
                <SwiperSlide
                  key={index}
                  style={{ width: cardWidth, maxWidth: cardWidth }}
                >
                  <Flex>
                    <Image
                      isActive={true}
                      width={cardWidth}
                      height={cardHeight}
                      image={{ url: story.frontImage }}
                      borderRadius="none"
                    />
                  </Flex>
                </SwiperSlide>
              )
            } else {
              const storyCardPositionStyles =
                story.position === 'top'
                  ? { marginBottom: 'auto', bottom: 40 }
                  : story.position === 'bottom'
                  ? { marginTop: 'auto', top: 25 }
                  : { marginTop: 'auto', marginBottom: 'auto' }
              return (
                <SwiperSlide key={index}>
                  <Flex
                    position="relative"
                    flexDirection="column"
                    backgroundColor="foreground"
                    borderRadius="default"
                    inset={{ horizontal: 'x3', vertical: 'x5' }}
                    boxShadow="light"
                    style={storyCardPositionStyles}
                    width="300px"
                  >
                    <Text
                      type="body"
                      weight="bold"
                      color="primaryHeading"
                      content={story.title}
                    />
                    <Spacer space="x1" />
                    <Text type="caption" content={story.description} />
                  </Flex>
                </SwiperSlide>
              )
            }
          })}
      </Swiper>
    </Flex>
  )
}
