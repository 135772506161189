import pink from '@material-ui/core/colors/pink'
import grey from '@material-ui/core/colors/grey'
import blue from '@material-ui/core/colors/blue'

import { createMuiTheme } from '@material-ui/core/styles'

const white = '#fff'
// This replaces the textColor value on the palette
// and then update the keys for each component that depends on it.
// More on Colors: https://material-ui.com/customization/color/

const muiTheme = createMuiTheme({
  typography: {
    fontFamily: 'Source Sans Pro, sans-serif',
    fontSize: 16,
  },
  palette: {
    primary: {
      main: pink[200],
      contrastText: white,
    },
    secondary: {
      main: blue.A200,
      contrastText: white,
    },

    text: {
      primary: grey[800],
      secondary: grey[700],
      disabled: grey[300],
    },
  },
  overrides: {
    MuiTextField: {
      root: {
        fontFamily: 'Source Sans Pro, sans-serif',
        lineHeight: 1.5,
        marginTop: 8,
        marginBottom: 8,
      },
    },
    MuiInputLabel: {
      root: {
        font: 'inherit',
      },
    },
    MuiInput: {
      root: {
        font: 'inherit',
        paddingTop: 4,
        paddingBottom: 4,
      },
      input: {
        font: 'inherit',
      },
    },
    MuiButton: {
      root: {
        height: 30,
      },
      contained: {
        fontWeight: 600,
      },
    },
    MuiAppBar: {
      root: {
        height: 65,
      },
    },
    MuiTabs: {
      root: {
        backgroundColor: 'transparent',
        textColor: grey[400],
        selectedTextColor: pink[200],
      },
      indicator: {
        backgroundColor: pink[200],
      },
    },
  },
})

export default muiTheme
