import React from 'react'

import styles from './bottomGradient.module.scss'

interface BottomGradientProps {}

const BottomGradient: React.FC<BottomGradientProps> = props => {
  return (
    <div className={styles.bottomGradient}>
      <svg
        id={styles.welcomeGradient}
        viewBox="0 0 100 100"
        preserveAspectRatio="none"
      >
        <defs>
          <linearGradient
            x1="0"
            y1="0"
            x2="1"
            y2="1"
            id="linearGradientSj78eeriu1"
          >
            <stop offset="0%" stopColor="#ff3874" />
            <stop offset="100%" stopColor="#da60fa" />
          </linearGradient>
        </defs>
        <path
          d="M0,80L100,68L100,100L0,100z"
          fill="url('#linearGradientSj78eeriu1')"
          style={{ opacity: 0.85 }}
        />
      </svg>
    </div>
  )
}

export default BottomGradient
