import React from 'react'
import { IconSizeType } from './PersonalTouch'

export const VirtualAssistance: React.FC<IconSizeType> = ({
  width = 40,
  height = 40,
}) => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width={width}
      height={height}
      viewBox="0 0 40 40"
      enable-background="new 0 0 40 40"
      xmlSpace="preserve"
    >
      <g>
        <path
          shape-rendering="crispEdges"
          fill="#FFFFFF"
          d="M8,0h24c4.418,0,8,3.582,8,8v24c0,4.418-3.582,8-8,8H8c-4.418,0-8-3.582-8-8
		V8C0,3.582,3.582,0,8,0z"
        />
        <path
          fill="#E08F24"
          d="M13.547,18.623c0.145-0.01,0.248-0.145,0.238-0.279c-0.238-2.474,0.279-4.441,1.532-5.828
		c1.335-1.47,3.292-1.998,4.565-1.998c0.259,0,6.387,0.083,6.387,7.07c0,1.491-0.456,2.619-1.367,3.344
		c-0.994,0.797-2.36,1.004-3.478,1.014c0.31-0.228,0.497-0.518,0.497-0.849c0-0.704-0.839-1.263-1.905-1.263
		c-1.066,0-1.905,0.559-1.905,1.263c0,0.559,0.528,1.014,1.273,1.19c0.008,0.001,0.026,0.004,0.052,0.009
		c0.205,0.038,0.948,0.177,1.895,0.177c1.211,0,2.733-0.217,3.872-1.139c1.035-0.828,1.553-2.091,1.553-3.747
		C26.787,11.584,22.263,10,19.872,10c-1.377,0-3.499,0.569-4.948,2.164c-1.356,1.501-1.926,3.592-1.667,6.222
		c0.01,0.145,0.145,0.248,0.279,0.228L13.547,18.623z M18.64,21.097c0-0.352,0.569-0.745,1.387-0.745
		c0.818,0,1.387,0.393,1.387,0.745c0,0.352-0.569,0.745-1.387,0.745C19.209,21.843,18.64,21.449,18.64,21.097z M20.193,12.992
		c2.402,0,4.358,1.957,4.358,4.348c0,0.89-0.269,1.739-0.776,2.474c-0.083,0.114-0.052,0.28,0.062,0.362
		c0.041,0.031,0.093,0.041,0.145,0.041c0.083,0,0.166-0.041,0.217-0.114c0.569-0.818,0.87-1.78,0.87-2.774
		c0-2.681-2.184-4.865-4.876-4.865c-2.691,0-4.876,2.195-4.876,4.876c0,0.994,0.3,1.946,0.87,2.774
		c0.083,0.114,0.238,0.145,0.362,0.062c0.114-0.083,0.145-0.238,0.062-0.362c-0.507-0.735-0.776-1.594-0.776-2.474
		c0-2.402,1.957-4.348,4.348-4.348H20.193z M28.454,27.702l0.062,0.228h-0.01c0.207,0.694,0.166,1.242-0.093,1.615
		c-0.228,0.331-0.6,0.445-0.776,0.456H13.081c-0.642,0-1.118-0.186-1.418-0.549c-0.379-0.474-0.299-1.068-0.282-1.19
		c0.002-0.011,0.003-0.019,0.003-0.021c0.994-4.441,4.534-4.7,4.534-4.7h7.774h0.052C27.471,24.244,27.999,26.077,28.454,27.702z
		 M27.628,29.472c0.027-0.004,0.238-0.039,0.37-0.237h0.01c0.166-0.238,0.176-0.642,0.021-1.17l-0.062-0.228l-0.007-0.025
		c-0.443-1.535-0.902-3.125-4.278-3.763h-7.733c-0.001,0-0.002,0-0.003,0c-0.158,0.014-3.151,0.28-4.044,4.296
		c0,0-0.093,0.455,0.176,0.776c0.197,0.238,0.538,0.352,1.014,0.352h14.534C27.625,29.472,27.626,29.472,27.628,29.472z"
        />
        <defs>
          <filter
            id="Adobe_OpacityMaskFilter"
            filterUnits="userSpaceOnUse"
            x="11.063"
            y="9.7"
            width="17.875"
            height="20.6"
          >
            <feColorMatrix
              type="matrix"
              values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"
            />
          </filter>
        </defs>
        <mask
          maskUnits="userSpaceOnUse"
          x="11.063"
          y="9.7"
          width="17.875"
          height="20.6"
          id="path-2-outside-1_764_7739_1_"
        >
          <rect
            x="10.363"
            y="9"
            fill="#FFFFFF"
            filter="url(#Adobe_OpacityMaskFilter)"
            width="19"
            height="22"
          />
          <path
            d="M13.547,18.623c0.145-0.01,0.248-0.145,0.238-0.279c-0.238-2.474,0.279-4.441,1.532-5.828
			c1.335-1.47,3.292-1.998,4.565-1.998c0.259,0,6.387,0.083,6.387,7.07c0,1.491-0.456,2.619-1.367,3.344
			c-0.994,0.797-2.36,1.004-3.478,1.014c0.31-0.228,0.497-0.518,0.497-0.849c0-0.704-0.839-1.263-1.905-1.263
			c-1.066,0-1.905,0.559-1.905,1.263c0,0.559,0.528,1.014,1.273,1.19c0.008,0.001,0.026,0.004,0.052,0.009
			c0.205,0.038,0.948,0.177,1.895,0.177c1.211,0,2.733-0.217,3.872-1.139c1.035-0.828,1.553-2.091,1.553-3.747
			C26.787,11.584,22.263,10,19.872,10c-1.377,0-3.499,0.569-4.948,2.164c-1.356,1.501-1.926,3.592-1.667,6.222
			c0.01,0.145,0.145,0.248,0.279,0.228L13.547,18.623z M18.64,21.097c0-0.352,0.569-0.745,1.387-0.745
			c0.818,0,1.387,0.393,1.387,0.745c0,0.352-0.569,0.745-1.387,0.745C19.209,21.843,18.64,21.449,18.64,21.097z M20.193,12.992
			c2.402,0,4.358,1.957,4.358,4.348c0,0.89-0.269,1.739-0.776,2.474c-0.083,0.114-0.052,0.28,0.062,0.362
			c0.041,0.031,0.093,0.041,0.145,0.041c0.083,0,0.166-0.041,0.217-0.114c0.569-0.818,0.87-1.78,0.87-2.774
			c0-2.681-2.184-4.865-4.876-4.865c-2.691,0-4.876,2.195-4.876,4.876c0,0.994,0.3,1.946,0.87,2.774
			c0.083,0.114,0.238,0.145,0.362,0.062c0.114-0.083,0.145-0.238,0.062-0.362c-0.507-0.735-0.776-1.594-0.776-2.474
			c0-2.402,1.957-4.348,4.348-4.348H20.193z M28.454,27.702l0.062,0.228h-0.01c0.207,0.694,0.166,1.242-0.093,1.615
			c-0.228,0.331-0.6,0.445-0.776,0.456H13.081c-0.642,0-1.118-0.186-1.418-0.549c-0.379-0.474-0.299-1.068-0.282-1.19
			c0.002-0.011,0.003-0.019,0.003-0.021c0.994-4.441,4.534-4.7,4.534-4.7h7.774h0.052C27.471,24.244,27.999,26.077,28.454,27.702z
			 M27.628,29.472c0.027-0.004,0.238-0.039,0.37-0.237h0.01c0.166-0.238,0.176-0.642,0.021-1.17l-0.062-0.228l-0.007-0.025
			c-0.443-1.535-0.902-3.125-4.278-3.763h-7.733c-0.001,0-0.002,0-0.003,0c-0.158,0.014-3.151,0.28-4.044,4.296
			c0,0-0.093,0.455,0.176,0.776c0.197,0.238,0.538,0.352,1.014,0.352h14.534C27.625,29.472,27.626,29.472,27.628,29.472z"
          />
        </mask>
        <path
          mask="url(#path-2-outside-1_764_7739_1_)"
          fill="#E08F24"
          d="M13.785,18.344l0.299-0.023l-0.001-0.006L13.785,18.344z
		 M13.547,18.623l-0.212,0.212l0.097,0.097l0.137-0.01L13.547,18.623z M15.317,12.515l-0.222-0.202l-0.001,0.001L15.317,12.515z
		 M24.903,20.932l-0.187-0.235l-0.001,0.001L24.903,20.932z M21.425,21.946l-0.177-0.242l-0.751,0.55l0.931-0.009L21.425,21.946z
		 M19.385,22.288l-0.069,0.292l0.013,0.003l0.013,0.002L19.385,22.288z M19.437,22.297l0.055-0.295l0,0L19.437,22.297z
		 M25.203,21.335l-0.187-0.234l-0.001,0.001L25.203,21.335z M26.756,17.588l-0.3-0.002v0.002H26.756z M14.924,12.164l-0.222-0.202
		l-0.001,0.001L14.924,12.164z M13.257,18.385l0.299-0.021l-0.001-0.008L13.257,18.385z M13.537,18.613l0.212-0.212l-0.107-0.107
		l-0.15,0.023L13.537,18.613z M23.775,19.814l0.243,0.177l0.004-0.006L23.775,19.814z M23.837,20.176l0.18-0.24l-0.004-0.003
		L23.837,20.176z M24.199,20.104l0.244,0.174l0.002-0.003L24.199,20.104z M16.187,20.114l-0.247,0.17l0.005,0.006L16.187,20.114z
		 M16.549,20.176l0.167,0.25l0.01-0.007L16.549,20.176z M16.611,19.814l0.25-0.166l-0.003-0.004L16.611,19.814z M28.516,27.93v0.3
		h0.393l-0.103-0.379L28.516,27.93z M28.454,27.702l0.289-0.079l0-0.002L28.454,27.702z M28.506,27.93v-0.3h-0.403l0.115,0.386
		L28.506,27.93z M28.413,29.544l-0.246-0.171l-0.001,0.001L28.413,29.544z M27.636,30v0.3h0.009l0.009,0L27.636,30z M11.663,29.451
		l-0.234,0.188l0.003,0.004L11.663,29.451z M11.381,28.261l-0.297-0.04l0,0L11.381,28.261z M11.384,28.24l-0.293-0.065l-0.007,0.032
		v0.033H11.384z M15.918,23.54v-0.3h-0.011l-0.011,0.001L15.918,23.54z M23.744,23.54l0.056-0.295l-0.028-0.005h-0.028V23.54z
		 M27.999,29.234v-0.3h-0.16l-0.089,0.134L27.999,29.234z M27.628,29.472l0.048,0.296h0L27.628,29.472z M28.009,29.234v0.3h0.157
		l0.09-0.129L28.009,29.234z M28.03,28.064l-0.289,0.079l0.002,0.006L28.03,28.064z M27.967,27.836l0.289-0.079l-0.001-0.004
		L27.967,27.836z M27.96,27.811l-0.288,0.083h0L27.96,27.811z M23.682,24.048l0.056-0.295l-0.028-0.005h-0.028V24.048z
		 M15.949,24.048v-0.3h-0.014l-0.014,0.001L15.949,24.048z M15.946,24.048l-0.027-0.299l0,0L15.946,24.048z M11.901,28.344
		l-0.293-0.065l-0.001,0.005L11.901,28.344z M12.077,29.12l0.231-0.191l-0.002-0.002L12.077,29.12z M13.486,18.367
		c-0.001-0.016,0.004-0.024,0.007-0.028c0.004-0.005,0.015-0.014,0.033-0.015l0.043,0.599c0.323-0.023,0.538-0.314,0.516-0.602
		L13.486,18.367z M15.095,12.314c-1.326,1.468-1.852,3.529-1.608,6.058l0.597-0.057c-0.233-2.419,0.277-4.292,1.456-5.598
		L15.095,12.314z M19.882,10.218c-1.336,0-3.382,0.549-4.787,2.096l0.444,0.403c1.265-1.393,3.133-1.9,4.343-1.9V10.218z
		 M26.569,17.588c0-3.603-1.591-5.469-3.248-6.416c-1.632-0.933-3.291-0.954-3.439-0.954v0.6c0.11,0,1.645,0.02,3.142,0.875
		c1.472,0.841,2.945,2.51,2.945,5.895H26.569z M25.09,21.166c1.003-0.798,1.48-2.026,1.48-3.578h-0.6
		c0,1.429-0.434,2.457-1.253,3.109L25.09,21.166z M21.428,22.246c1.139-0.011,2.59-0.219,3.663-1.08l-0.375-0.468
		c-0.914,0.733-2.197,0.938-3.293,0.948L21.428,22.246z M21.622,21.097c0,0.206-0.113,0.415-0.374,0.607l0.355,0.484
		c0.36-0.264,0.619-0.634,0.619-1.091H21.622z M20.017,20.134c0.484,0,0.9,0.127,1.186,0.317c0.287,0.191,0.418,0.424,0.418,0.646
		h0.6c0-0.482-0.288-0.881-0.687-1.145c-0.4-0.265-0.936-0.418-1.518-0.418V20.134z M18.412,21.097c0-0.222,0.131-0.455,0.418-0.646
		c0.286-0.19,0.702-0.317,1.186-0.317v-0.6c-0.582,0-1.119,0.152-1.518,0.418c-0.399,0.265-0.687,0.663-0.687,1.145H18.412z
		 M19.454,21.996c-0.698-0.165-1.042-0.552-1.042-0.898h-0.6c0,0.771,0.711,1.295,1.504,1.482L19.454,21.996z M19.492,22.002
		c-0.021-0.004-0.048-0.009-0.064-0.011l-0.085,0.594c-0.002,0-0.002,0,0,0c0.002,0,0.004,0.001,0.008,0.001
		c0.008,0.001,0.018,0.003,0.031,0.006L19.492,22.002z M21.332,22.174c-0.919,0-1.64-0.135-1.84-0.172l-0.11,0.59
		c0.211,0.04,0.975,0.182,1.95,0.182V22.174z M25.014,21.102c-1.058,0.856-2.495,1.072-3.683,1.072v0.6
		c1.235,0,2.841-0.219,4.06-1.206L25.014,21.102z M26.456,17.588c0,1.591-0.495,2.757-1.44,3.513l0.375,0.468
		c1.125-0.9,1.665-2.261,1.665-3.982H26.456z M19.872,10.3c1.14,0,2.799,0.38,4.162,1.476c1.35,1.086,2.437,2.894,2.422,5.81
		l0.6,0.003c0.016-3.088-1.144-5.074-2.646-6.281C22.922,10.112,21.123,9.7,19.872,9.7V10.3z M15.146,12.365
		c1.379-1.517,3.412-2.065,4.726-2.065V9.7c-1.439,0-3.651,0.59-5.17,2.262L15.146,12.365z M13.556,18.356
		c-0.253-2.571,0.307-4.57,1.591-5.991l-0.445-0.402c-1.428,1.581-2.007,3.764-1.743,6.452L13.556,18.356z M13.491,18.316
		c0.023-0.003,0.039,0.004,0.047,0.011c0.009,0.007,0.017,0.02,0.019,0.037l-0.598,0.043c0.023,0.319,0.316,0.55,0.624,0.503
		L13.491,18.316z M13.759,18.411l-0.01-0.01l-0.424,0.424l0.01,0.01L13.759,18.411z M20.027,20.052
		c-0.455,0-0.855,0.109-1.151,0.281c-0.278,0.162-0.536,0.427-0.536,0.764h0.6c0-0.016,0.027-0.123,0.238-0.245
		c0.193-0.112,0.486-0.2,0.849-0.2V20.052z M21.714,21.097c0-0.337-0.257-0.602-0.536-0.764c-0.296-0.172-0.696-0.281-1.151-0.281
		v0.6c0.363,0,0.656,0.088,0.849,0.2c0.211,0.123,0.238,0.23,0.238,0.245H21.714z M20.027,22.143c0.455,0,0.855-0.109,1.151-0.281
		c0.279-0.162,0.536-0.427,0.536-0.764h-0.6c0,0.015-0.027,0.123-0.238,0.245c-0.193,0.112-0.486,0.2-0.849,0.2V22.143z
		 M18.34,21.097c0,0.336,0.257,0.602,0.536,0.764c0.296,0.172,0.697,0.281,1.151,0.281v-0.6c-0.363,0-0.657-0.088-0.849-0.2
		c-0.211-0.123-0.238-0.23-0.238-0.245H18.34z M24.851,17.34c0-2.557-2.091-4.648-4.658-4.648v0.6c2.236,0,4.058,1.823,4.058,4.048
		H24.851z M24.021,19.984c0.541-0.784,0.83-1.692,0.83-2.645h-0.6c0,0.828-0.25,1.618-0.723,2.304L24.021,19.984z M24.013,19.933
		c0.01,0.007,0.013,0.015,0.014,0.021c0.001,0.006,0.001,0.021-0.01,0.036l-0.485-0.353c-0.186,0.256-0.111,0.607,0.128,0.781
		L24.013,19.933z M23.982,19.917C23.975,19.917,23.976,19.917,23.982,19.917c0.007,0.002,0.02,0.007,0.035,0.019l-0.36,0.48
		c0.113,0.085,0.238,0.101,0.325,0.101V19.917z M23.955,19.929c0.002-0.003,0.006-0.006,0.009-0.008
		c0.004-0.002,0.009-0.004,0.018-0.004v0.6c0.181,0,0.354-0.089,0.461-0.239L23.955,19.929z M24.769,17.329
		c0,0.933-0.282,1.836-0.816,2.603l0.492,0.343c0.605-0.868,0.923-1.891,0.923-2.946H24.769z M20.193,12.764
		c2.526,0,4.576,2.05,4.576,4.565h0.6c0-2.847-2.319-5.165-5.176-5.165V12.764z M15.617,17.34c0-2.516,2.05-4.576,4.576-4.576v-0.6
		c-2.858,0-5.176,2.329-5.176,5.176H15.617z M16.434,19.944c-0.536-0.779-0.817-1.673-0.817-2.604h-0.6
		c0,1.056,0.319,2.067,0.922,2.944L16.434,19.944z M16.382,19.926c0.002-0.001,0.011-0.005,0.023-0.003
		c0.011,0.002,0.02,0.008,0.024,0.014l-0.485,0.353c0.177,0.243,0.513,0.308,0.771,0.135L16.382,19.926z M16.361,19.98
		c-0.001-0.002-0.005-0.011-0.003-0.023c0.002-0.011,0.008-0.02,0.014-0.024l0.353,0.485c0.243-0.177,0.308-0.513,0.135-0.771
		L16.361,19.98z M15.535,17.34c0,0.942,0.288,1.86,0.83,2.645l0.494-0.341c-0.473-0.686-0.723-1.485-0.723-2.304H15.535z
		 M20.182,12.692c-2.556,0-4.648,2.08-4.648,4.648h0.6c0-2.236,1.822-4.048,4.048-4.048V12.692z M20.193,12.692h-0.01v0.6h0.01
		V12.692z M28.806,27.851l-0.062-0.228l-0.579,0.158l0.062,0.228L28.806,27.851z M28.506,28.23h0.01v-0.6h-0.01V28.23z
		 M28.659,29.716c0.333-0.479,0.354-1.136,0.134-1.872l-0.575,0.172c0.194,0.651,0.133,1.092-0.052,1.358L28.659,29.716z
		 M27.654,30.299c0.243-0.014,0.713-0.159,1.006-0.585l-0.494-0.34c-0.163,0.236-0.438,0.319-0.547,0.326L27.654,30.299z
		 M13.081,30.3h14.555v-0.6H13.081V30.3z M11.432,29.643c0.375,0.453,0.951,0.657,1.649,0.657v-0.6c-0.585,0-0.962-0.168-1.187-0.44
		L11.432,29.643z M11.084,28.221c-0.017,0.123-0.12,0.837,0.345,1.418l0.468-0.375c-0.293-0.366-0.235-0.842-0.219-0.962
		L11.084,28.221z M11.084,28.24c0-0.008,0-0.014,0.001-0.018c0-0.003,0-0.006,0.001-0.008c0-0.003,0-0.004,0-0.004
		c0,0,0,0.001,0,0.003c0,0.002,0,0.004-0.001,0.007l0.595,0.08c0.001-0.005,0.002-0.012,0.002-0.017
		c0-0.003,0.001-0.008,0.001-0.013c0-0.003,0.001-0.016,0.001-0.031H11.084z M15.918,23.54c-0.022-0.299-0.022-0.299-0.022-0.299
		c0,0,0,0,0,0c0,0-0.001,0-0.001,0c-0.001,0-0.002,0-0.003,0c-0.003,0-0.006,0.001-0.011,0.001
		c-0.009,0.001-0.021,0.002-0.036,0.004c-0.03,0.004-0.073,0.009-0.127,0.018c-0.108,0.017-0.26,0.047-0.445,0.097
		c-0.369,0.1-0.871,0.281-1.405,0.609c-1.075,0.66-2.26,1.902-2.776,4.205l0.585,0.131c0.478-2.138,1.561-3.246,2.504-3.825
		c0.475-0.292,0.921-0.453,1.247-0.541c0.162-0.044,0.294-0.069,0.384-0.083c0.045-0.007,0.079-0.011,0.101-0.014
		c0.011-0.001,0.019-0.002,0.024-0.002c0.002,0,0.004,0,0.005,0c0,0,0,0,0,0s0,0,0,0c0,0,0,0,0,0
		C15.94,23.84,15.94,23.84,15.918,23.54z M23.692,23.24h-7.774v0.6h7.774V23.24z M23.744,23.24h-0.052v0.6h0.052V23.24z
		 M28.743,27.621c-0.226-0.807-0.486-1.736-1.191-2.543c-0.711-0.814-1.844-1.472-3.752-1.833l-0.111,0.59
		c1.819,0.343,2.813,0.953,3.412,1.638c0.604,0.692,0.836,1.492,1.065,2.31L28.743,27.621z M27.749,29.068
		c-0.059,0.088-0.157,0.106-0.169,0.108l0.097,0.592c0.043-0.007,0.365-0.058,0.571-0.367L27.749,29.068z M28.009,28.934h-0.01v0.6
		h0.01V28.934z M27.742,28.149c0.15,0.509,0.104,0.794,0.021,0.914l0.493,0.343c0.248-0.356,0.223-0.879,0.062-1.426L27.742,28.149z
		 M27.678,27.915l0.062,0.228l0.579-0.158l-0.062-0.228L27.678,27.915z M27.672,27.894l0.007,0.026l0.576-0.166l-0.007-0.026
		L27.672,27.894z M23.626,24.342c1.646,0.311,2.536,0.846,3.07,1.45c0.541,0.611,0.752,1.327,0.975,2.102l0.576-0.166
		c-0.219-0.76-0.459-1.606-1.102-2.333c-0.65-0.734-1.678-1.315-3.408-1.642L23.626,24.342z M15.949,24.348h7.733v-0.6h-7.733
		V24.348z M15.972,24.347c0.001,0,0.003,0,0.004,0l-0.055-0.597c0,0-0.001,0-0.002,0L15.972,24.347z M12.194,28.409
		c0.43-1.932,1.355-2.935,2.168-3.462c0.821-0.533,1.554-0.595,1.61-0.6l-0.053-0.598c-0.102,0.009-0.945,0.086-1.883,0.694
		c-0.946,0.613-1.964,1.751-2.428,3.835L12.194,28.409z M12.307,28.927c-0.078-0.093-0.111-0.217-0.119-0.336
		c-0.004-0.057-0.001-0.108,0.002-0.143c0.002-0.017,0.003-0.031,0.005-0.039c0.001-0.004,0.001-0.007,0.001-0.007c0,0,0-0.001,0,0
		c0,0,0,0,0,0.001c0,0,0,0,0,0.001c0,0,0,0,0,0s0,0,0,0c0,0,0,0-0.294-0.06c-0.294-0.06-0.294-0.06-0.294-0.06c0,0,0,0,0,0
		c0,0,0,0,0,0c0,0,0,0.001,0,0.001c0,0.001,0,0.002-0.001,0.003c0,0.002-0.001,0.005-0.001,0.007
		c-0.001,0.006-0.002,0.013-0.004,0.022c-0.003,0.018-0.006,0.043-0.009,0.073c-0.006,0.06-0.01,0.144-0.003,0.24
		c0.013,0.189,0.068,0.454,0.258,0.681L12.307,28.927z M13.092,29.172c-0.441,0-0.671-0.107-0.783-0.243l-0.462,0.382
		c0.281,0.34,0.734,0.461,1.246,0.461V29.172z M27.626,29.172H13.092v0.6h14.534V29.172z M27.58,29.175c0,0-0.002,0-0.003,0
		c0,0-0.005,0.001-0.011,0.002c-0.002,0-0.011,0.002-0.024,0.006c-0.004,0.001-0.022,0.006-0.043,0.016
		c-0.009,0.004-0.034,0.016-0.061,0.038c-0.016,0.013-0.1,0.08-0.112,0.207c-0.015,0.156,0.087,0.247,0.127,0.274
		c0.041,0.028,0.078,0.039,0.089,0.042c0.028,0.008,0.051,0.01,0.055,0.01c0.014,0.001,0.026,0.001,0.027,0.001v-0.6
		c0.001,0,0.013,0,0.027,0.001c0.004,0,0.027,0.002,0.055,0.01c0.011,0.003,0.049,0.014,0.089,0.042
		c0.04,0.028,0.142,0.118,0.127,0.274c-0.012,0.127-0.096,0.194-0.112,0.207c-0.027,0.021-0.052,0.033-0.061,0.038
		c-0.021,0.01-0.038,0.015-0.043,0.016c-0.012,0.003-0.022,0.005-0.023,0.006c-0.007,0.001-0.015,0.003-0.009,0.002L27.58,29.175z"
        />
      </g>
    </svg>
  )
}
