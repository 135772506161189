import React from 'react'
import { IconSizeType } from './PersonalTouch'

export const BuildYourOwnCard: React.FC<IconSizeType> = ({
  width = 56,
  height = 56,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_1106_842)">
        <rect
          x={12}
          y={12}
          width={40}
          height={40}
          rx={4}
          fill="white"
          shapeRendering="crispEdges"
        />
        <g clipPath="url(#clip0_1106_842)">
          <path
            d="M39.9973 23.131H38.1739V20.4079C38.1779 20.1879 38.002 20.004 37.7821 20C37.7541 20 37.7261 20 37.6981 20.008L23.9227 22.7631C23.7347 22.7991 23.6028 22.971 23.6028 23.1629V43.6001C23.6028 43.8201 23.7827 44 24.0026 44H39.9973C40.2172 44 40.3972 43.8201 40.3972 43.6001V23.5308C40.3972 23.3109 40.2172 23.131 39.9973 23.131ZM37.3742 20.8957V23.131H26.1779L37.3742 20.8957ZM39.5975 43.2003H24.4025V23.9307H39.5975V43.2003Z"
            fill="#5D3088"
          />
          <path
            d="M33.1996 32.9957C33.1996 32.3839 33.1996 31.7761 33.1996 31.1643V30.3326V29.5009C33.1996 28.8891 33.1996 28.2813 33.1996 27.6695C33.1996 27.1296 32.8917 26.8177 32.3559 26.8177C30.5805 26.8177 28.8011 26.8177 27.0257 26.8177C26.4858 26.8177 26.174 27.1256 26.174 27.6655C26.174 29.4409 26.174 31.2203 26.174 32.9957C26.174 33.5355 26.4818 33.8434 27.0217 33.8434C28.0013 33.8434 28.977 33.8434 29.9567 33.8434C30.7564 33.8434 31.5522 33.8434 32.3519 33.8434C32.8997 33.8434 33.1996 33.5435 33.1996 32.9957ZM32.3319 32.5718C32.3319 32.7038 32.3399 32.8397 32.3399 32.9717H27.0457V31.7681L27.4135 31.988C28.0493 32.3639 28.6691 32.3039 29.2049 31.8081C29.4609 31.5762 29.7128 31.3402 29.9647 31.1083C30.2166 30.8764 30.4725 30.6405 30.7244 30.4086C31.0483 30.1127 31.3442 30.0927 31.7801 30.3526C32.2399 30.6245 32.4039 30.9444 32.3479 31.4642C32.3079 31.8321 32.3159 32.192 32.3279 32.5758L32.3319 32.5718ZM27.0377 28.5092C27.0377 28.2853 27.0297 28.0613 27.0297 27.8374V27.6775H32.3199V29.6848L32.2199 29.6128C31.5642 29.145 30.7884 29.189 30.1966 29.7248C29.8087 30.0767 29.4209 30.4325 29.037 30.7924L28.6731 31.1283C28.3532 31.4242 28.1653 31.4442 27.7894 31.2123C27.7694 31.2003 27.7494 31.1843 27.7294 31.1723C27.6774 31.1363 27.6255 31.1003 27.5655 31.0803C27.1336 30.9244 26.9817 30.6485 27.0177 30.0927C27.0537 29.5648 27.0457 29.029 27.0377 28.5132V28.5092Z"
            fill="#5D3088"
          />
          <path
            d="M36.6624 40.3172L35.9586 38.5698C35.5508 37.5541 35.1389 36.5385 34.731 35.5228C34.6991 35.4388 34.5951 35.1909 34.3072 35.1909C34.0153 35.1909 33.9153 35.4428 33.8793 35.5228C33.5155 36.4305 33.1476 37.3382 32.7797 38.2499L31.948 40.3172C31.832 40.6051 31.912 40.845 32.1599 40.949C32.2159 40.973 32.2679 40.981 32.3198 40.981C32.4958 40.981 32.6557 40.857 32.7437 40.6371L32.7877 40.5251C32.9276 40.1813 33.0676 39.8374 33.1996 39.4895C33.2315 39.4015 33.2555 39.3775 33.3635 39.3815C33.9393 39.3895 34.5551 39.3935 35.1749 39.3815C35.3308 39.3735 35.3788 39.4095 35.4268 39.5495C35.5508 39.8973 35.6947 40.2412 35.8347 40.5731L35.8867 40.6971C35.9786 40.917 36.1666 41.017 36.3905 40.965C36.5304 40.933 36.7024 40.829 36.7224 40.5291V40.5091L36.7064 40.4532C36.6944 40.4132 36.6824 40.3652 36.6624 40.3172ZM35.003 38.5058H33.6074L34.3032 36.7744L35.003 38.5058Z"
            fill="#5D3088"
          />
          <path
            d="M37.3663 28.6531C36.5505 28.6531 35.7388 28.6531 34.9231 28.6531C34.8671 28.6531 34.6751 28.6611 34.6032 28.777C34.5712 28.829 34.4632 29.013 34.4872 29.1929C34.5112 29.3848 34.6791 29.5088 34.9151 29.5088C35.211 29.5088 35.5069 29.5088 35.8068 29.5088H36.1507H36.5065C36.6265 29.5088 36.7425 29.5088 36.8624 29.5088C37.0304 29.5088 37.1943 29.5088 37.3623 29.5088C37.5142 29.5088 37.6382 29.4608 37.7221 29.3728C37.7981 29.2969 37.8381 29.1889 37.8341 29.0689C37.8301 28.813 37.6502 28.6531 37.3703 28.6531H37.3663Z"
            fill="#5D3088"
          />
          <path
            d="M29.069 36.5905C28.7851 36.5905 28.4972 36.5905 28.2133 36.5905H27.8574H27.5175C27.2336 36.5905 26.9497 36.5905 26.6698 36.5905C26.3779 36.5905 26.182 36.7544 26.174 37.0023C26.17 37.1183 26.21 37.2222 26.2859 37.3022C26.3779 37.3942 26.5139 37.4462 26.6698 37.4502C27.0817 37.4502 27.4975 37.4502 27.9094 37.4502C28.2973 37.4502 28.6811 37.4502 29.069 37.4502C29.3609 37.4502 29.5489 37.2782 29.5489 37.0183C29.5489 36.7584 29.3649 36.5945 29.069 36.5905Z"
            fill="#5D3088"
          />
          <path
            d="M34.959 27.6735C35.2349 27.6735 35.5068 27.6735 35.7828 27.6735H36.1466H36.5105C36.6345 27.6735 36.7544 27.6735 36.8784 27.6735C37.0303 27.6735 37.1823 27.6735 37.3342 27.6735C37.5022 27.6735 37.6341 27.6255 37.7221 27.5375C37.7941 27.4615 37.8341 27.3576 37.8301 27.2376C37.8261 26.9777 37.6381 26.8177 37.3422 26.8177C36.5505 26.8177 35.7588 26.8177 34.967 26.8177C34.6591 26.8177 34.4592 26.9857 34.4552 27.2456C34.4552 27.3616 34.4912 27.4615 34.5672 27.5375C34.6591 27.6295 34.7911 27.6775 34.959 27.6775V27.6735Z"
            fill="#5D3088"
          />
          <path
            d="M29.053 38.4259C28.7771 38.4259 28.5012 38.4259 28.2253 38.4259H27.8654H27.5095C27.2336 38.4259 26.9537 38.4259 26.6778 38.4259C26.5098 38.4259 26.3739 38.4779 26.2859 38.5699C26.2099 38.6458 26.174 38.7458 26.174 38.8578C26.174 39.1097 26.3739 39.2816 26.6658 39.2816C27.0657 39.2816 27.4655 39.2816 27.8654 39.2816C28.2653 39.2816 28.6651 39.2816 29.065 39.2816C29.3649 39.2816 29.5488 39.1217 29.5528 38.8618C29.5528 38.7418 29.5168 38.6378 29.4449 38.5619C29.3569 38.4739 29.2289 38.4259 29.057 38.4259H29.053Z"
            fill="#5D3088"
          />
          <path
            d="M28.3932 30.1766H28.4012C29.009 30.1726 29.5048 29.6688 29.5009 29.061C29.5009 28.4412 29.017 27.9534 28.3972 27.9534C27.7814 27.9534 27.2896 28.4332 27.2816 29.049C27.2776 29.3409 27.3976 29.6288 27.6095 29.8447C27.8214 30.0567 28.1053 30.1766 28.3972 30.1766H28.3932ZM28.1453 29.041C28.1573 28.9091 28.2653 28.8131 28.3972 28.8131C28.4052 28.8131 28.4092 28.8131 28.4172 28.8131C28.4812 28.8171 28.5412 28.8451 28.5812 28.8931C28.6251 28.941 28.6451 29.009 28.6411 29.081C28.6371 29.149 28.6051 29.2129 28.5532 29.2569C28.5012 29.3009 28.4412 29.3209 28.3772 29.3129C28.2413 29.3009 28.1333 29.173 28.1453 29.041Z"
            fill="#5D3088"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_1106_842"
          x={0}
          y={0}
          width={64}
          height={64}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation={6} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1106_842"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1106_842"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_1106_842">
          <rect
            width={16.7944}
            height={24}
            fill="white"
            transform="translate(23.6028 20)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
