import React from 'react'
import { SVGProps } from './types'

export const PaperPlaneWithHeart: React.FC<SVGProps> = ({
  size = 24,
  onClick,
  color = '#1C1C1C',
  opacity,
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 66 65"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <g opacity={opacity}>
        <path
          d="M3.36697 4.73538L3.57038 8.27424L16.0764 0.682218L3.36697 4.73538Z"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.16893 3.52308L9.46493 2.79064"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.4837 0.923975L1.53149 1.40829L3.36698 4.73571L7.16889 3.52317"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.4152 0.964726L16.0764 0.682487L9.46487 2.79065"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.86023 7.0537L7.53066 8.68404L16.0764 0.682307"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.5014 8.55618L3.95572 9.61279"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.04528 10.2358L2.49932 10.6088"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.09681 38.8623L7.48553 54.3158L65 26.629L8.09681 38.8623Z"
          stroke={color}
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M40.2967 25.4639C40.0236 26.0075 39.7114 26.5615 39.3577 27.1208C37.81 29.5685 36.4964 31.4856 35.3987 32.9916"
          stroke={color}
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.9844 24.1786C13.7076 26.8292 15.6454 29.5787 19.2923 31.8313C21.6099 33.2619 23.5296 34.3544 25.1189 35.2024"
          stroke={color}
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M40.2967 25.4637C44.6744 16.7706 39.1938 10.2469 34.0851 10.6319C29.1923 10.9986 27.3949 13.8131 26.5469 17.2362C24.3073 14.5388 21.4772 12.809 16.9226 14.6611C13.8064 15.9252 11.8295 19.9232 12.9896 24.1787"
          stroke={color}
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M25.1189 35.2026L35.3987 32.9916"
          stroke={color}
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.9844 24.1788L1.53149 23.6378L8.09684 38.8624L25.1189 35.2026"
          stroke={color}
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M40.2967 25.4639L65 26.6292L35.3987 32.9916"
          stroke={color}
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.9448 49.9823L24.5102 57.7676L64.9999 26.629"
          stroke={color}
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.752 56.3553L8.59363 60.2909"
          stroke={color}
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.37715 62.6112L1.84882 64.0002"
          stroke={color}
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  )
}
