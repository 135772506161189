import React from 'react'
import { IconSizeType } from './PersonalTouch'

export const Calendar: React.FC<IconSizeType> = ({
  width = 104,
  height = 104,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 104 104"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_425_16895)">
        <rect
          x={32}
          y={32}
          width={40}
          height={40}
          rx={8}
          fill="white"
          shapeRendering="crispEdges"
        />
        <path
          d="M48 45.75C47.59 45.75 47.25 45.41 47.25 45V42C47.25 41.59 47.59 41.25 48 41.25C48.41 41.25 48.75 41.59 48.75 42V45C48.75 45.41 48.41 45.75 48 45.75ZM56 45.75C55.59 45.75 55.25 45.41 55.25 45V42C55.25 41.59 55.59 41.25 56 41.25C56.41 41.25 56.75 41.59 56.75 42V45C56.75 45.41 56.41 45.75 56 45.75ZM48.5 54.5C48.37 54.5 48.24 54.47 48.12 54.42C47.99 54.37 47.89 54.3 47.79 54.21C47.61 54.02 47.5 53.76 47.5 53.5C47.5 53.24 47.61 52.98 47.79 52.79C47.89 52.7 48 52.63 48.12 52.58C48.3635 52.48 48.6365 52.48 48.88 52.58C49 52.63 49.11 52.7 49.21 52.79C49.25 52.84 49.3 52.89 49.33 52.94C49.37 53 49.4 53.06 49.42 53.12C49.45 53.18 49.47 53.24 49.48 53.3C49.49 53.37 49.5 53.44 49.5 53.5C49.5 53.76 49.39 54.02 49.21 54.21C49.11 54.3 49 54.37 48.88 54.42C48.76 54.47 48.63 54.5 48.5 54.5ZM52 54.5C51.87 54.5 51.74 54.47 51.62 54.42C51.49 54.37 51.39 54.3 51.29 54.21C51.11 54.02 51 53.76 51 53.5C51 53.44 51.01 53.37 51.02 53.3C51.03 53.24 51.05 53.18 51.08 53.12C51.1 53.06 51.13 53 51.17 52.94L51.29 52.79C51.66 52.42 52.33 52.42 52.71 52.79L52.83 52.94C52.87 53 52.9 53.06 52.92 53.12C52.95 53.18 52.97 53.24 52.98 53.3C52.99 53.37 53 53.44 53 53.5C53 53.76 52.89 54.02 52.71 54.21C52.52 54.39 52.27 54.5 52 54.5ZM48.5 58C48.37 58 48.24 57.97 48.12 57.92C48 57.87 47.89 57.8 47.79 57.71C47.61 57.52 47.5 57.26 47.5 57C47.5 56.94 47.51 56.87 47.52 56.81C47.53 56.74 47.55 56.68 47.58 56.62C47.6 56.56 47.63 56.5 47.67 56.44C47.7 56.39 47.75 56.34 47.79 56.29C47.89 56.2 48 56.13 48.12 56.08C48.3635 55.98 48.6365 55.98 48.88 56.08C49 56.13 49.11 56.2 49.21 56.29C49.25 56.34 49.3 56.39 49.33 56.44C49.37 56.5 49.4 56.56 49.42 56.62C49.45 56.68 49.47 56.74 49.48 56.81C49.49 56.87 49.5 56.94 49.5 57C49.5 57.26 49.39 57.52 49.21 57.71C49.11 57.8 49 57.87 48.88 57.92C48.76 57.97 48.63 58 48.5 58ZM60.5 49.84H43.5C43.09 49.84 42.75 49.5 42.75 49.09C42.75 48.68 43.09 48.34 43.5 48.34H60.5C60.91 48.34 61.25 48.68 61.25 49.09C61.25 49.5 60.91 49.84 60.5 49.84Z"
          fill="#E68C96"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M47.75 62.75H51.75H55.75C59.4 62.75 61.5 60.65 61.5 57V52V48.5C61.5 44.85 59.4 42.75 55.75 42.75H47.75C44.1 42.75 42 44.85 42 48.5V52V57C42 60.65 44.1 62.75 47.75 62.75ZM43.5 52V57C43.5 59.86 44.89 61.25 47.75 61.25H51.75H55.75C58.61 61.25 60 59.86 60 57V52V48.5C60 45.64 58.61 44.25 55.75 44.25H47.75C44.89 44.25 43.5 45.64 43.5 48.5V52Z"
          fill="#E68C96"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_425_16895"
          x={0}
          y={0}
          width={104}
          height={104}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation={16} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_425_16895"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_425_16895"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  )
}
