import { Operation } from '../../../__generated__/globalTypes'

import {
  FilterFragment,
  filterFragmentString,
} from '../../fragments/__generated__/FilterFragment'

import getFiltersRawString from '../GetFilters.graphql'

const getFiltersString = [getFiltersRawString, filterFragmentString].join(
  '\n\n',
)

export type GetFiltersData = {
  filters: FilterFragment[]
}

export type GetFilters = Operation<GetFiltersData>

export const GetFilters = (): GetFilters => ({
  query: getFiltersString,
  variables: undefined,
})
