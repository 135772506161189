import React from 'react'
import { Text } from '@sendoutcards/quantum-design-ui'

import { Icon } from 'src/chrome'
import { CreditCardLogo } from 'src/payments/components'
import { CreditCardBrand } from 'src/payments/components/CardLogo/CreditCardLogo'

import styles from './paymentTab.module.scss'

type PaymentTabProps = {
  paymentType: 'card' | 'points' | 'bank'
  brand?: CreditCardBrand
  last4?: string
}

const PaymentTab: React.FC<PaymentTabProps> = props => {
  const { brand, last4, paymentType } = props
  return (
    <div className={styles.paymentTab}>
      {((): React.ReactElement => {
        switch (paymentType) {
          case 'card':
            return (
              <div className={`${styles.paymentTabContents} ${styles.card}`}>
                <Text type="body">
                  {last4 ? `XXXX XXXX XXXX ${last4}` : 'Credit Card'}
                </Text>
                <CreditCardLogo brand={brand} />
              </div>
            )
          case 'points':
            return (
              <div className={styles.paymentTabContents}>
                <Icon
                  icon={'MONEY'}
                  viewBox={'0 0 32 32'}
                  size={28}
                  color={'#F666B0'}
                />
                <Text type="body">{`Points & Expense & Credits`}</Text>
              </div>
            )
          case 'bank':
            return <div />
        }
      })()}
    </div>
  )
}

export default PaymentTab
