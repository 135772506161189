import React from 'react'
import { SVGProps } from './types'

export const Cards20: React.FC<SVGProps> = ({
  size = 24,
  color = '#fff',
  onClick,
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 70 84"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick && (() => onClick())}
    >
      <path
        d="M68.61 1.25H19.5701V64.18H68.61V1.25Z"
        fill="white"
        stroke="#231F20"
        strokeWidth={2.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M62.61 7.25H13.5701V70.18H62.61V7.25Z"
        fill="white"
        stroke="#231F20"
        strokeWidth={2.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M56.6001 13.2598H7.56006V76.1898H56.6001V13.2598Z"
        fill="white"
        stroke="#231F20"
        strokeWidth={2.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M50.6001 19.2598H1.56006V82.1898H50.6001V19.2598Z"
        fill="white"
        stroke="#231F20"
        strokeWidth={2.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.05 45.8798L13.67 43.9698C15.19 41.8998 16.66 40.7798 19.41 40.7798C22.69 40.7798 24.71 42.6698 24.71 45.5498C24.71 48.1198 23.39 49.4198 20.66 51.5298L18.1499 53.4698H24.86V56.3998H13.46V53.7198L18.59 49.5198C20.5 47.9398 21.25 47.0998 21.25 45.8198C21.25 44.5398 20.3899 43.8198 19.1799 43.8198C17.9699 43.8198 17.18 44.4798 16.03 45.8898L16.05 45.8798Z"
        fill={color}
      />
      <path
        d="M34.12 56.66C30.01 56.66 27.3 53.21 27.3 48.72C27.3 44.23 30.0501 40.73 34.1701 40.73C38.2901 40.73 40.99 44.18 40.99 48.67C40.99 53.16 38.2401 56.66 34.1301 56.66H34.12ZM34.12 43.79C32.07 43.79 30.78 45.84 30.78 48.67C30.78 51.5 32.1201 53.6 34.1701 53.6C36.2201 53.6 37.5101 51.53 37.5101 48.72C37.5101 45.91 36.17 43.79 34.12 43.79Z"
        fill={color}
      />
    </svg>
  )
}
