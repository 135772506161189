import React from 'react'
import { SVGProps } from './types'

export const PaperPlane: React.FC<SVGProps> = ({
  size = 24,
  color = '#323232',
  onClick,
}) => {
  return (
    <svg
      width={`${size}px`}
      height="auto"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M7.11045 10.8151C6.52045 10.8151 5.68545 10.4001 5.02545 8.41505L4.66543 7.33505L3.58543 6.97505C1.60543 6.31505 1.19043 5.48005 1.19043 4.89004C1.19043 4.30504 1.60543 3.46504 3.58543 2.80004L7.83045 1.38504C8.89045 1.03004 9.77545 1.13504 10.3204 1.67504C10.8654 2.21504 10.9704 3.10504 10.6154 4.16504L9.20045 8.41005C8.53545 10.4001 7.70045 10.8151 7.11045 10.8151ZM3.82043 3.51504C2.43043 3.98004 1.93543 4.53004 1.93543 4.89004C1.93543 5.25005 2.43043 5.80005 3.82043 6.26005L5.08045 6.68005C5.19045 6.71505 5.28045 6.80505 5.31545 6.91505L5.73545 8.17505C6.19545 9.56505 6.75045 10.0601 7.11045 10.0601C7.47045 10.0601 8.02045 9.56505 8.48545 8.17505L9.90045 3.93004C10.1554 3.16004 10.1104 2.53004 9.78545 2.20504C9.46045 1.88004 8.83045 1.84004 8.06545 2.09504L3.82043 3.51504Z"
        fill={color}
      />
      <path
        d="M5.0554 7.2C4.96039 7.2 4.86539 7.165 4.79039 7.09C4.64539 6.945 4.64539 6.705 4.79039 6.56L6.5804 4.765C6.7254 4.62 6.9654 4.62 7.1104 4.765C7.2554 4.91 7.2554 5.15 7.1104 5.295L5.3204 7.09C5.2504 7.165 5.1504 7.2 5.0554 7.2Z"
        fill={color}
      />
    </svg>
  )
}
