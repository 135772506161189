import { ReactElement, useCallback, useEffect } from 'react'
import debounce from 'lodash/debounce'

import useEffectDeepCompare from 'src/hooks/useEffectDeepCompare'

type PersistStateProps<T> = {
  name: string
  data: Object
  onMount: (data: T) => void
}

const PersistState = <T extends object>(
  props: PersistStateProps<T>,
): ReactElement | null => {
  const { name, data, onMount } = props

  useEffect(() => {
    const data = window.localStorage.getItem(name)
    data && onMount(JSON.parse(data))
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const persist = useCallback(
    debounce((key: string, value: Object) => {
      window.localStorage.setItem(key, JSON.stringify(value))
    }, 500),
    [],
  )

  useEffectDeepCompare(() => {
    persist(name, data)
  }, [name, data]) // Deep Compare

  return null
}

export default PersistState
