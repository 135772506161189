import {
  Maybe,
  ValidateEmailVerificationCodeFailureType,
  If,
  AccountInput,
  VerticalID,
  Operation,
} from '../../../__generated__/globalTypes'

import {
  AccountFragment,
  accountFragmentString,
} from '../../fragments/__generated__/AccountFragment'

import { creditCardFragmentString } from '../../fragments/__generated__/CreditCardFragment'

import { sponsorFragmentString } from '../../fragments/__generated__/SponsorFragment'

import { storefrontString } from '../../fragments/__generated__/Storefront'

import { addressFragmentString } from '../../fragments/__generated__/AddressFragment'

import { stripeSourceFragmentString } from '../../fragments/__generated__/StripeSourceFragment'

import createAccountRawString from '../CreateAccount.graphql'

const createAccountString = [
  createAccountRawString,
  accountFragmentString,
  creditCardFragmentString,
  sponsorFragmentString,
  storefrontString,
  addressFragmentString,
  stripeSourceFragmentString,
].join('\n\n')

export type CreateAccountData = {
  createAccount: {
    validation: Maybe<
      | If<
          'ValidateEmailVerificationCodeFailure',
          {
            __typename: 'ValidateEmailVerificationCodeFailure'
            failureType: ValidateEmailVerificationCodeFailureType
            message: string
            success: boolean
          }
        >
      | If<
          'ValidateEmailVerificationCodeSuccess',
          {
            __typename: 'ValidateEmailVerificationCodeSuccess'
            success: boolean
            message: string
          }
        >
    >
    account: Maybe<AccountFragment>
  }
}

export type CreateAccountVariables = {
  account: AccountInput
  verificationCode: string
  verticalId?: Maybe<VerticalID>
  recaptchaToken?: Maybe<string>
}

export type CreateAccount = Operation<CreateAccountData, CreateAccountVariables>

export const CreateAccount = ({
  account,
  verificationCode,
  verticalId,
  recaptchaToken,
}: CreateAccountVariables): CreateAccount => ({
  query: createAccountString,
  variables: {
    account: AccountInput(account),
    verificationCode,
    verticalId,
    recaptchaToken,
  },
})
