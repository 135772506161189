import { Maybe } from '../../../__generated__/globalTypes'

import subscriptionFeaturesRawString from '../SubscriptionFeatures.graphql'

export const subscriptionFeaturesString = subscriptionFeaturesRawString

export type SubscriptionFeatures = {
  groupSends: Maybe<number>
  scheduledSends: Maybe<number>
  loyaltyRewards: Maybe<number>
  systemSends: Maybe<number>
  heartfeltSends: Maybe<number>
}

export const isSubscriptionFeatures = (
  fragment: any,
): fragment is SubscriptionFeatures =>
  fragment &&
  (fragment.groupSends === null || typeof fragment.groupSends === 'number') &&
  (fragment.scheduledSends === null ||
    typeof fragment.scheduledSends === 'number') &&
  (fragment.loyaltyRewards === null ||
    typeof fragment.loyaltyRewards === 'number') &&
  (fragment.systemSends === null || typeof fragment.systemSends === 'number') &&
  (fragment.heartfeltSends === null ||
    typeof fragment.heartfeltSends === 'number')
