import React from 'react'

const styles = {
  ldsDualRing: {
    display: 'inline-block',
    width: '46px',
    height: '46px',
    '&:after': {
      content: '" "',
      display: 'block',
      width: '46px',
      height: '46px',
      margin: '1px',
      borderRadius: '50%',
      animation: 'ldsDualRing 1.2s linear infinite',
    },
  },
  pink: {
    '&:after': {
      border: `5px solid #ff5689`,
      borderColor: `#ff5689 transparent #ff5689 transparent`,
    },
  },
  green: {
    '&:after': {
      border: `5px solid rgb(85, 249, 168)`,
      borderColor: `rgb(85, 249, 168) transparent rgb(85, 249, 168) transparent`,
    },
  },
  mainText: {
    '&:after': {
      border: `5px solid rgba(0, 0, 0, 0.8)`,
      borderColor: `rgba(0, 0, 0, 0.8) transparent rgba(0, 0, 0, 0.8) transparent`,
    },
  },
  lightText: {
    '&:after': {
      border: `5px solid rgba(0, 0, 0, 0.5)`,
      borderColor: `rgba(0, 0, 0, 0.5) transparent rgba(0, 0, 0, 0.5) transparent`,
    },
  },
  white: {
    '&:after': {
      border: '5px solid white',
      borderColor: 'white transparent white transparent',
    },
  },
  '@keyframes ldsDualRing': {
    from: {
      transform: 'rotate(0deg)',
    },
    to: {
      transform: 'rotate(360deg)',
    },
  },
}

const SimpleLoadingSpinner: React.FC<{}> = () => (
  <div className={`${styles.ldsDualRing} ${styles.pink}`} />
)

export default SimpleLoadingSpinner
