import { Maybe, CurrencyType } from '../../../__generated__/globalTypes'

import {
  LargeMinimalSendableCardFragment,
  isLargeMinimalSendableCardFragment,
} from './LargeMinimalSendableCardFragment'

import {
  SendableCardImageFragment,
  isSendableCardImageFragment,
} from './SendableCardImageFragment'

import {
  SendableCardWithTemplateFragment,
  isSendableCardWithTemplateFragment,
} from './SendableCardWithTemplateFragment'

import { PanelFragment, isPanelFragment } from './PanelFragment'

import detailedSendableCardFragmentRawString from '../DetailedSendableCardFragment.graphql'

export const detailedSendableCardFragmentString = detailedSendableCardFragmentRawString

export type DetailedSendableCardFragment = LargeMinimalSendableCardFragment & {
  description: string
  insideRightImage: Maybe<SendableCardImageFragment>
  variationColor: string
  variations: SendableCardWithTemplateFragment[]
  cardTemplate: Maybe<{
    backPanelLocation: number
    panels: PanelFragment[]
  }>
  cost: {
    total: {
      currency: CurrencyType
      asString: string
      amount: number
    }[]
  }
}

export const isDetailedSendableCardFragment = (
  fragment: any,
): fragment is DetailedSendableCardFragment =>
  (isLargeMinimalSendableCardFragment(fragment) as boolean) &&
  typeof fragment.description === 'string' &&
  (fragment.insideRightImage === null ||
    (isSendableCardImageFragment(fragment.insideRightImage) as boolean)) &&
  typeof fragment.variationColor === 'string' &&
  Array.isArray(fragment.variations) &&
  fragment.variations
    .slice(0, 5)
    .reduce(
      (accum: any, next: any) =>
        accum && (isSendableCardWithTemplateFragment(next) as boolean),
      true,
    ) &&
  (fragment.cardTemplate === null ||
    (fragment.cardTemplate &&
      typeof fragment.cardTemplate.backPanelLocation === 'number' &&
      Array.isArray(fragment.cardTemplate.panels) &&
      fragment.cardTemplate.panels
        .slice(0, 5)
        .reduce(
          (accum: any, next: any) =>
            accum && (isPanelFragment(next) as boolean),
          true,
        ))) &&
  fragment.cost &&
  Array.isArray(fragment.cost.total) &&
  fragment.cost.total
    .slice(0, 5)
    .reduce(
      (accum: any, next: any) =>
        accum &&
        next &&
        (next.currency === 'POINT' ||
          next.currency === 'EXPENSE' ||
          next.currency === 'FREE' ||
          next.currency === 'USD' ||
          next.currency === 'CREDIT' ||
          next.currency === 'CARDTOKEN') &&
        typeof next.asString === 'string' &&
        typeof next.amount === 'number',
      true,
    )
