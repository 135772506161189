import {
  CreateSocProContactInput,
  Operation,
} from '../../../__generated__/globalTypes'

import {
  SocProContactFragment,
  socProContactFragmentString,
} from '../../fragments/__generated__/SocProContactFragment'

import createSocProContactRawString from '../CreateSocProContact.graphql'

const createSocProContactString = [
  createSocProContactRawString,
  socProContactFragmentString,
].join('\n\n')

export type CreateSocProContactData = {
  createSocProContact: {
    socProContact: SocProContactFragment
  }
}

export type CreateSocProContactVariables = {
  socProContact: CreateSocProContactInput
}

export type CreateSocProContact = Operation<
  CreateSocProContactData,
  CreateSocProContactVariables
>

export const CreateSocProContact = ({
  socProContact,
}: CreateSocProContactVariables): CreateSocProContact => ({
  query: createSocProContactString,
  variables: {
    socProContact: CreateSocProContactInput(socProContact),
  },
})
