import React, { useCallback, useEffect, useRef, useState } from 'react'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'

import Paper from '@material-ui/core/Paper'

import { Icon } from 'src/chrome'
import SearchTags from './SearchTags'

import styles from './search.module.scss'

type Filter = {
  name: string
  value: string
}

interface SearchProps {
  activeCategory?: any
  buttonClick?: any
  categories?: any
  className?: string
  clearSearch?: any
  filters?: any
  handleCategory?: any
  handleFilterByIsPremium?: (isPremium?: boolean) => void
  hint?: string
  id?: string
  inputClassName?: string
  isFiltered?: boolean
  onClear?: () => void
  onSearch: (search: string) => void
  search?: string
  style?: React.CSSProperties
  tagsClassName?: string
  value?: string
}

const Search: React.FC<SearchProps> = props => {
  const {
    activeCategory,
    value,
    search,
    isFiltered,
    buttonClick,
    categories,
    className,
    filters,
    handleCategory,
    hint,
    id,
    inputClassName,
    style,
    tagsClassName,
    handleFilterByIsPremium,
    onSearch,
    onClear,
    clearSearch,
  } = props

  const inputRef = useRef<HTMLInputElement>(null)

  const [searchValue, setSearchValue] = useState<string>(value ?? '')
  const [showTooltip, setShowTooltip] = useState<boolean>(false)
  const [filter, setFilter] = useState<Filter>({
    name: 'Filter Search',
    value: '',
  })

  const handleSearch = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchValue(event.currentTarget.value)
      setShowTooltip(!!event.currentTarget.value)
    },
    [],
  )

  const handleEnter = useCallback(
    (keyEvent: React.KeyboardEvent<HTMLInputElement>) => {
      if (keyEvent.key === 'Enter') {
        onSearch(searchValue)
        inputRef.current && inputRef.current.focus()
      }
    },
    [onSearch, searchValue],
  )

  const resetSearch = useCallback(() => {
    if (clearSearch) {
      clearSearch()
    } else if (onClear) {
      onClear()
      setSearchValue('')
    } else {
      setSearchValue('')
      onSearch('')
    }
  }, [onSearch, onClear, clearSearch])

  const handleChange = useCallback((value: any) => {
    setFilter(value)
  }, [])

  const handleBlur = useCallback(() => {
    setShowTooltip(false)
  }, [])

  useEffect(() => {
    if (search) {
      setSearchValue(search)
      setShowTooltip(!!search)
    }
    if (!isFiltered) {
      setFilter(previousFilter => ({
        ...previousFilter,
        value: '',
      }))
    }
  }, [search, isFiltered])

  useEffect(() => {
    setSearchValue(value ?? '')
    setShowTooltip(!!value)
    inputRef.current && inputRef.current.focus()
  }, [value])

  useEffect(() => {
    const resolveFilter = (value: any) => {
      filters.forEach((filter: any, index: number) => {
        if (value === index) {
          handleFilterByIsPremium && handleFilterByIsPremium(filter.value)
        }
      })
    }

    filter.value !== '' && resolveFilter(filter)
  }, [handleFilterByIsPremium, filters, filter])

  return (
    <div className={`${styles.searchField} ${className}`} style={style}>
      <div
        className={
          inputClassName ? `${styles.search} ${inputClassName}` : styles.search
        }
      >
        <Icon icon={'SEARCH'} size={18} color={'#ff5689'} />
        <div className={styles.selectedTag}>
          {activeCategory ? activeCategory.description : 'Search'}:
        </div>
        <input
          id={id}
          ref={inputRef}
          type="text"
          value={searchValue}
          onChange={handleSearch}
          onKeyPress={handleEnter}
          onBlur={handleBlur}
          className={styles.searchInput}
          placeholder={hint}
        />
        {(activeCategory || value) && (
          <div onClick={resetSearch} style={{ cursor: 'pointer' }}>
            <Icon icon={'CLOSECIRCLE'} size={18} color={'#ff5689'} />
          </div>
        )}
        {showTooltip && (
          <Paper
            style={{
              position: 'absolute',
              bottom: '-97%',
              left: 83,
              zIndex: 30,
            }}
          >
            <ListItem>
              <ListItemText primary={'Press Enter key to search'} />
            </ListItem>
          </Paper>
        )}
        {filters && (
          <Select
            value={filter.value}
            onChange={handleChange}
            className={styles.filterMenu}
            disableUnderline={true}
            // labelStyle={{
            //   height: 45,
            //   lineHeight: '45px',
            // }}
            classes={{
              icon: styles.searchSelectIcon,
            }}
          >
            {filters.map((filter: any, index: number) => {
              return (
                <MenuItem
                  key={index}
                  value={index}
                  style={
                    index === 0
                      ? {
                          display: 'none',
                          fontWeight: 500,
                        }
                      : {}
                  }
                >
                  {filter.name}
                </MenuItem>
              )
            })}
          </Select>
        )}
      </div>
      {categories && (
        <SearchTags
          className={tagsClassName ?? ''}
          categories={categories}
          selectedTag={activeCategory}
          onClick={handleCategory}
          buttonClick={buttonClick}
        />
      )}
    </div>
  )
}

export default Search
