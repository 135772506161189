import LogRocket from 'logrocket'

import { AccountFragment } from 'src/graphql'
import { Store } from 'src/redux/store'
import Result from 'src/utils/Result'
import { APP_URL } from 'src/app/api'

export function userAuthenticated() {
  return false
}

export const setLogrocketUser = (
  accountResult: Result<AccountFragment | null>,
) => {
  const account = accountResult.value
  // Check for a username so we don't set sparse accounts
  if (account?.username) {
    LogRocket.identify(account.id, {
      name: `${account.firstName} ${account.lastName}`,
      username: account.username,
      email: account.email,
    })
  }
}

export function noop() {}

export const openApp = (
  path: string = '',
  partialQuery: { [key: string]: string } = {},
) => {
  const state = Store.getState().storefront
  const sponsorSlug =
    state.storefront?.user.storefront?.slug ?? state.sponsorSlug
  const sponsorGenealogyId = state.storefront?.user.genealogyId
  const query = sponsorSlug
    ? {
        ...partialQuery,
        sponsor: sponsorGenealogyId ? `${sponsorGenealogyId}` : sponsorSlug,
      }
    : partialQuery
  const keyValues = Object.keys(query)
    // tslint:disable-next-line: no-array-mutation
    .sort()
    .map(key => (query[key] ? `${key}=${query[key]}` : key))
    .join('&')
  const queryString = keyValues ? '?' + keyValues : ''
  window.location.href =
    APP_URL + (path.startsWith('/') ? path : `/${path}`) + queryString
}
