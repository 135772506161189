import { Operation } from '../../../__generated__/globalTypes'

import commitPurchaseString from '../CommitPurchase.graphql'

export type CommitPurchaseData = {
  commitPurchase: {
    paid: boolean
  }
}

export type CommitPurchaseVariables = {
  purchaseIntentId: string
}

export type CommitPurchase = Operation<
  CommitPurchaseData,
  CommitPurchaseVariables
>

export const CommitPurchase = ({
  purchaseIntentId,
}: CommitPurchaseVariables): CommitPurchase => ({
  query: commitPurchaseString,
  variables: {
    purchaseIntentId,
  },
})
