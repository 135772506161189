import { BentoCardData } from 'src/marketing/components/BentoGrid/BentoCard'
import { coverToCoverExpandData } from './expands/coverToCover'
import { handWritingFontExpandData } from './expands/handwritingFont'

export const CustomizationOptionsBentoCards = (isMobile: boolean) => {
  const cardData = [
    {
      id: 10,
      pill: 'Add-on',
      title: 'Personal digital signature & handwriting font',
      description:
        'Add your own unique and personal touch to all of your cards with your own handwriting or signature.',
      icon: 'hand_written',
      details: handWritingFontExpandData,
      isFullWidth: true,
      grid: {
        colStart: '1',
        colEnd: '2',
        rowStart: '3',
        rowEnd: '10',
      },
    },
    {
      id: 11,
      title: 'Customize every detail, cover to cover',
      description:
        'Create a custom keepsake with each card you send. Curate a beautiful custom card with your favorite photos and colors. ',
      icon: 'contact_card',
      details: coverToCoverExpandData,
      isFullWidth: true,
      grid: {
        colStart: '2',
        colEnd: '3',
        rowStart: '3',
        rowEnd: '10',
      },
    },
  ] as BentoCardData[]
  return cardData
}
