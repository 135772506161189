import { FC } from 'react'
import { SVGProps } from './types'

export const MultiTouchCard: FC<SVGProps> = ({ size, color, onClick }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 64 90"
      width={size}
      height={size}
      onClick={onClick}
    >
      <path
        fill={color}
        d="M61.99 11.742h-6.838V1.53A1.504 1.504 0 0053.682 0c-.104 0-.21 0-.314.03L1.71 10.362c-.705.135-1.2.78-1.2 1.5V88.5c0 .825.675 1.5 1.5 1.5h59.98c.825 0 1.5-.675 1.5-1.5V13.24c0-.825-.675-1.5-1.5-1.5zm-9.837-8.383v8.383H10.167l41.986-8.383zm8.337 83.642H3.51v-72.26h56.98V87z"
      />
      <path
        fill={color}
        d="M44.55 36.873a4.5 4.5 0 00-.644-.045c-.21-1.65-.855-3.584-3.344-3.748a3.528 3.528 0 00-2.354.66c-.975.704-1.47 1.709-1.515 3.073H26.377v-.21c-.03-1.004-.42-1.874-1.14-2.579-.6-.585-1.35-.84-1.994-.99h-.9l-.225.046c-1.814.314-3.089 1.874-3.044 3.763-2.699.075-4.813 2.204-4.813 4.889-.015 7.722 0 15.43 0 23.152 0 .705.165 1.424.465 2.054.884 1.86 2.474 2.834 4.633 2.834h10.002v-2.744h-9.987c-1.064 0-1.74-.39-2.13-1.214a2.587 2.587 0 01-.239-.975V46.77H46.47a5.22 5.22 0 011.41-2.804H17.02V41.82c.015-.674.27-1.259.72-1.649.36-.315.825-.495 1.35-.54v1.035c0 1.08.524 1.604 1.589 1.604h4.108c1.095 0 1.605-.524 1.605-1.634v-.99h10.316v1.035c0 1.095.495 1.59 1.59 1.59h4.063c1.05 0 1.575-.54 1.575-1.575v-1.065c1.08.06 1.8.63 2.054 1.62.06.255.075.525.075.84v1.319l.03 6.013h2.714V41.97c0-2.699-1.694-4.678-4.288-5.068l.03-.03zm-20.932 2.594h-1.785v-2.714c0-.6.345-.96.885-.96.555 0 .9.375.9.96v2.714zm17.544 0h-1.694v-.554c0-.84-.016-1.68.014-2.505 0-.33.39-.57.75-.6.405 0 .765.21.87.585.015.075.045.165.045.24v2.834h.015z"
      />
      <path
        fill={color}
        d="M56.965 64.82c-.183-1.615-1.133-3.152-2.616-4.218a7.76 7.76 0 00-2.182-1.081c.35-.183.683-.411.983-.67.85-.776 1.316-1.797 1.316-2.877 0-2.239-2.016-4.066-4.499-4.066-1.2 0-2.332.427-3.182 1.218a4.166 4.166 0 00-1.05 1.492c.033-.197.033-.395.033-.593 0-2.771-2.482-5.024-5.532-5.024-3.032 0-5.515 2.238-5.531 4.978 0 1.34.55 2.604 1.6 3.563.4.365.849.67 1.332.898a10.22 10.22 0 00-3.216 1.583c-.05.03-.066.076-.1.122-.733.502-3.149 2.497-3.315 6.775-.017.67 0 1.355.1 2.056-.05.076-.05.182-.034.274.367 1.172 1.25 1.75 2.683 1.75h13.413c1.366 0 2.333-.73 2.566-1.872h4.882c.183 0 .367 0 .55-.03 1.033-.138 1.816-.93 1.833-1.843v-.198c0-.73.016-1.477-.067-2.223l.033-.015zm-9.814 3.333v.381H33.688v-.411c0-.624-.016-1.279.017-1.903.166-2.512 1.983-4.644 4.632-5.42 1.766-.518 3.666-.366 5.282.441 1.616.807 2.8 2.147 3.299 3.776.25.807.233 1.66.233 2.558v.578zm-8.947-12.347c-.534-.487-.833-1.127-.833-1.796 0-1.416 1.283-2.558 2.865-2.558.767 0 1.483.274 2.017.76.533.488.833 1.128.833 1.813 0 1.416-1.283 2.573-2.866 2.573a2.99 2.99 0 01-2.033-.777l.017-.015zm13.83 6.318c1.366.67 2.232 1.904 2.315 3.304.017.35.017.7 0 1.081v.152h-4.532c0-1.78-.516-3.319-1.516-4.72 1.2-.44 2.55-.38 3.716.183h.016zm-3.883-6.15c0-.899.816-1.63 1.816-1.63s1.816.731 1.816 1.63c0 .426-.183.822-.516 1.126-.35.32-.8.487-1.3.487-1 0-1.816-.73-1.816-1.614zm-.35 3.547c-.466.137-.9.304-1.316.518a9.852 9.852 0 00-3.516-1.69 5.666 5.666 0 001.183-.823 4.917 4.917 0 001.333-1.948c0 .121-.017.258-.017.38 0 1.508.933 2.847 2.333 3.548v.015z"
      />
    </svg>
  )
}
