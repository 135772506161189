import { SendableCardRoute } from 'src/catalog/routes/SendableCardRoute'
import { selectedCatalogCard } from 'src/redux/actions/catalog'
import Action from 'src/redux/action'

export const selectedSendableCardReducer = <
  T extends {
    subroute: SendableCardRoute | undefined
  }
>(
  route: T,
  action: Action,
): T =>
  action.type === selectedCatalogCard.type && action.dataType === 'SendableCard'
    ? { ...route, subroute: SendableCardRoute(action.id) }
    : route
