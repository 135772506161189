import fontFragmentRawString from '../FontFragment.graphql'

export const fontFragmentString = fontFragmentRawString

export type FontFragment = {
  id: string
  displayName: string
  fontName: string
  ttfFile: string
  isSig: boolean
}

export const isFontFragment = (fragment: any): fragment is FontFragment =>
  fragment &&
  typeof fragment.id === 'string' &&
  typeof fragment.displayName === 'string' &&
  typeof fragment.fontName === 'string' &&
  typeof fragment.ttfFile === 'string' &&
  typeof fragment.isSig === 'boolean'
