import React from 'react'
import { IconSizeType } from './PersonalTouch'

export const CustomBrand: React.FC<IconSizeType> = ({
  width = 40,
  height = 40,
}) => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width={width}
      height={height}
      viewBox="0 0 40 40"
      enable-background="new 0 0 40 40"
      xmlSpace="preserve"
    >
      <g>
        <path
          shape-rendering="crispEdges"
          fill="#FFFFFF"
          d="M4,0h32c2.209,0,4,1.791,4,4v32c0,2.209-1.791,4-4,4H4c-2.209,0-4-1.791-4-4
		V4C0,1.791,1.791,0,4,0z"
        />
        <g>
          <defs>
            <rect id="SVGID_1_" x="9.997" y="10" width="20.007" height="20" />
          </defs>
          <clipPath id="SVGID_2_">
            <use xlinkHref="#SVGID_1_" overflow="visible" />
          </clipPath>
          <g clip-path="url(#SVGID_2_)">
            <path
              fill="#FCD34D"
              d="M22.765,19.48h-1.581v1.052h0.519c0,0.213,0.013,0.408-0.003,0.6c-0.022,0.271-0.254,0.464-0.528,0.464
				c-0.007,0-0.014,0-0.022,0c-0.278-0.01-0.507-0.23-0.508-0.509c-0.005-0.725-0.004-1.45,0-2.175
				c0.001-0.253,0.193-0.466,0.435-0.506c0.033-0.005,0.064-0.008,0.096-0.008c0.23,0,0.429,0.137,0.507,0.358
				c0.019,0.054,0.03,0.111,0.046,0.17h1.025c0.073-0.769-0.669-1.555-1.478-1.583c-0.022-0.001-0.043-0.001-0.065-0.001
				c-0.922,0-1.619,0.686-1.63,1.622c-0.005,0.465-0.005,0.931,0,1.397c0.003,0.324-0.007,0.65,0.035,0.97
				c0.096,0.727,0.759,1.306,1.483,1.323c0.015,0,0.029,0,0.043,0c0.785,0,1.47-0.513,1.58-1.258
				c0.068-0.462,0.035-0.939,0.045-1.41C22.768,19.816,22.765,19.647,22.765,19.48L22.765,19.48z"
            />
            <path
              fill="#FCD34D"
              d="M14.373,17.345h-1.035v5.298h2.094v-1.05h-1.059V17.345z"
            />
            <path
              fill="#FCD34D"
              d="M25.046,17.34c-0.018,0-0.035,0-0.052,0.001c-0.789,0.023-1.47,0.704-1.494,1.507
				c-0.011,0.383-0.002,0.766-0.002,1.149c0,0,0,0,0,0c0,0.389-0.01,0.778,0.002,1.166c0.025,0.786,0.713,1.465,1.495,1.489
				c0.018,0,0.035,0.001,0.053,0.001c0.842,0,1.563-0.596,1.603-1.404c0.041-0.828,0.042-1.662,0.001-2.49
				C26.611,17.937,25.897,17.341,25.046,17.34L25.046,17.34z M24.542,21.03c-0.006-0.689-0.006-1.379,0-2.068
				c0.003-0.333,0.234-0.565,0.541-0.565c0.003,0,0.007,0,0.01,0c0.312,0.005,0.528,0.242,0.53,0.584c0.002,0.342,0,0.683,0,1.025
				c0,0.342,0.001,0.672,0,1.007c-0.002,0.342-0.22,0.579-0.531,0.583c-0.003,0-0.006,0-0.009,0
				C24.777,21.596,24.545,21.363,24.542,21.03z"
            />
            <path
              fill="#FCD34D"
              d="M23.711,15.226h-7.418v1.022h7.418V15.226z"
            />
            <path
              fill="#FCD34D"
              d="M21.581,13.082h-3.164v1.035h3.164V13.082z"
            />
            <path fill="#FCD34D" d="M16.295,24.77h7.419v-1.023h-7.419V24.77z" />
            <path
              fill="#FCD34D"
              d="M29.994,19.223c-0.014-0.096-0.027-0.192-0.041-0.294c-0.031-0.226-0.062-0.46-0.106-0.699
				c-0.48-2.593-1.802-4.713-3.931-6.301c-1.374-1.026-2.903-1.646-4.544-1.842C20.892,10.029,20.414,10,19.953,10
				c-2.955,0-5.49,1.171-7.534,3.48c-1.286,1.452-2.068,3.179-2.325,5.132c-0.368,2.795,0.31,5.326,2.015,7.524
				c1.473,1.897,3.425,3.124,5.802,3.645c0.285,0.062,0.558,0.096,0.822,0.129c0.115,0.014,0.223,0.028,0.33,0.044
				c0.008,0.002,0.016,0.004,0.024,0.006c0.023,0.006,0.045,0.011,0.068,0.016L19.258,30h1.448l0.068-0.01
				c0.098-0.014,0.196-0.027,0.294-0.041c0.224-0.031,0.456-0.062,0.691-0.104c1.909-0.339,3.6-1.18,5.025-2.5
				c1.655-1.534,2.694-3.45,3.086-5.696c0.038-0.215,0.064-0.425,0.09-0.628c0.011-0.087,0.022-0.174,0.034-0.261l0.009-0.064
				v-1.407L29.994,19.223L29.994,19.223z M29.058,20.633c-0.039,0.285-0.069,0.571-0.118,0.854
				c-0.355,2.034-1.283,3.762-2.798,5.165c-1.293,1.197-2.811,1.954-4.548,2.262c-0.317,0.056-0.638,0.094-0.956,0.14h-1.274
				c-0.051-0.012-0.101-0.027-0.153-0.034c-0.366-0.054-0.738-0.084-1.099-0.163c-2.146-0.47-3.911-1.566-5.257-3.301
				c-1.562-2.012-2.157-4.303-1.825-6.821c0.23-1.747,0.927-3.308,2.095-4.628c1.868-2.11,4.154-3.162,6.826-3.162
				c0.426,0,0.862,0.027,1.307,0.08c1.506,0.181,2.875,0.754,4.091,1.661c1.929,1.439,3.128,3.343,3.566,5.715
				c0.059,0.317,0.095,0.639,0.141,0.958L29.058,20.633L29.058,20.633z"
            />
            <path fill="#FCD34D" d="M18.42,26.909h3.164v-1.035H18.42V26.909z" />
            <path
              fill="#FCD34D"
              d="M17.23,22.653c0.843,0,1.563-0.597,1.603-1.405c0.041-0.828,0.042-1.662,0.001-2.49
				c-0.04-0.822-0.755-1.418-1.606-1.418c-0.018,0-0.036,0-0.054,0.001c-0.789,0.023-1.469,0.705-1.493,1.508
				c-0.011,0.383-0.002,0.766-0.002,1.149c0,0,0,0,0.001,0c0,0.389-0.01,0.778,0.002,1.166c0.025,0.786,0.714,1.465,1.496,1.487
				C17.196,22.653,17.213,22.653,17.23,22.653L17.23,22.653z M17.805,20.002c0,0.336,0.001,0.672,0,1.007
				c-0.002,0.343-0.216,0.58-0.527,0.587c-0.004,0-0.009,0-0.013,0c-0.311,0-0.539-0.236-0.541-0.58
				c-0.004-0.678-0.004-1.355,0-2.033c0.002-0.348,0.228-0.587,0.541-0.587c0.002,0,0.004,0,0.007,0
				c0.318,0.003,0.533,0.243,0.534,0.598C17.806,19.33,17.805,19.666,17.805,20.002L17.805,20.002z"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}
