import get from 'lodash/get'
import zip from 'lodash/zip'
import { Location } from 'history'
// @src imports
import { StateWithoutRoute as State } from 'src/redux/reducers'
import { Omit, Task } from 'src/app/types'
import { Action } from 'src/redux/action'
import { compact } from 'src/helpers'

import { Subroute } from './Subroute'

type Variables = {
  [name: string]: string | undefined
}

type SubrouteOf<T> = 'subroute' extends keyof T
  ? T extends { subroute?: infer U }
    ? U
    : undefined
  : undefined

type SubrouteMetaOf<T> = SubrouteOf<T> extends undefined
  ? undefined
  : SubrouteMeta<SubrouteOf<T>>

type WithoutSubroute<T> = T extends { subroute: any } ? Omit<T, 'subroute'> : T

type WithOptionalSubroute<T> = T extends { subroute: infer U }
  ? Omit<T, 'subroute'> & { subroute?: U }
  : T

type SubrouteIsRequired<T> = T extends { subroute: infer U }
  ? U extends undefined
    ? undefined
    : true
  : undefined

type FromPath<T> = (path: string, location: Location) => T | undefined
type ToPath<T> = (route: T) => string
type ToPathComponents<T> = (route: T) => string[]
type Tasks<T> = (route: T, state: State) => Task[]
type Reducer<T> = (route: T, action: Action, state: State) => T | undefined
type GetAccessLevel<T> = (route: T) => AccessLevel
type Predicate<T> = (route: T) => boolean
export interface SubrouteMeta<T> {
  fromPath: FromPath<T>
  toPath: ToPath<T>
  tasks: Tasks<T>
  reducer: Reducer<T>
  accessLevel: GetAccessLevel<T>
  hideSidebar: Predicate<T>
  hideTopBar: Predicate<T>
  doNotResetWindowScroll: Predicate<T>
}

export interface RouteMeta<P extends string, T, A extends any[]>
  extends SubrouteMeta<RouteType<P, T>> {
  (...args: A): RouteType<P, T>
  path: P
  subroute: SubrouteMetaOf<T>
  subrouteIsRequired: SubrouteIsRequired<T>
}

type RouteType<P extends string, T> = { path: P } & T

export type Route<T> = T extends RouteMeta<infer P, infer U, any>
  ? RouteType<P, U>
  : Subroute<T>

type IncrementalFromPath<T> = (
  path: string,
  location: Location,
) => [WithOptionalSubroute<T>, string] | undefined

type AreSame<A, B> = A extends B ? (B extends A ? true : false) : false
type IsPartial<A> = AreSame<A, Partial<A>>
type DefaultArgs<T> = IsPartial<T> extends true ? [] : [T]

type RequiredFromPathOptions<T> = {
  fromPath: (variables: Variables) => WithOptionalSubroute<T> | undefined
}

type FromPathOptions<T> = IsPartial<WithoutSubroute<T>> extends true
  ? Partial<RequiredFromPathOptions<T>>
  : RequiredFromPathOptions<T>

type SubrouteOptions<T> = SubrouteMetaOf<T> extends undefined
  ? { subroute?: undefined }
  : { subroute: SubrouteMetaOf<T> }

type SubrouteIsRequiredOptions<T> = AreSame<
  SubrouteIsRequired<T>,
  true
> extends true
  ? { subrouteIsRequired: true }
  : { subrouteIsRequired?: undefined }

type RouteOptions<P extends string, T, A extends any[]> = FromPathOptions<T> & {
  path: P
  additionalPatterns?: string[]
  init?: (...args: A) => T
  tasks?: Tasks<T>
  reducer?: Reducer<T>
  accessLevel?: AccessLevel
  hideSidebar?: boolean
  hideTopBar?: boolean
  doNotResetWindowScroll?: boolean
} & SubrouteOptions<T> &
  SubrouteIsRequiredOptions<T>

const subrouteOf = <T>(route: T): SubrouteOf<T> | undefined =>
  get(route, 'subroute')

const composedFromPath = <P extends string, T>(
  pattern: P,
  fromPath: IncrementalFromPath<T>,
  subroute?: SubrouteMetaOf<T>,
  subrouteIsRequired?: boolean,
): FromPath<RouteType<P, T>> => (path, location) => {
  const result = fromPath(path, location)
  if (!result) {
    return undefined
  }
  const [partialRouteWithoutPath, remainingPath] = result
  const partialRoute = { ...partialRouteWithoutPath, path: pattern }
  if (subroute) {
    const possibleSubroute = subroute.fromPath(remainingPath, location)
    if (possibleSubroute) {
      return { ...partialRoute, subroute: possibleSubroute } as RouteType<P, T>
    }
  }
  return subrouteIsRequired && !get(partialRoute, 'subroute')
    ? undefined
    : (partialRoute as RouteType<P, T>)
}

const components = (path: string) =>
  path.split('/').filter(component => component !== '')

const pathFromComponents = (components: string[]) => `/${components.join('/')}`

const remainingPath = (path: string[], pattern: string[]) =>
  pathFromComponents(path.slice(pattern.length))

const variables = (path: string[], pattern: string[]): Variables | undefined =>
  zip(path, pattern).reduce<Variables | undefined>(
    (variables, [path, pattern]) =>
      variables && pattern
        ? pattern.startsWith(':')
          ? { ...variables, [pattern.slice(1)]: path }
          : pattern === path
          ? variables
          : undefined
        : variables,
    {},
  )

const variablesAndRemainingPath = (
  path: string[],
  pattern: string[],
): [Variables | undefined, string] => [
  variables(path, pattern),
  remainingPath(path, pattern),
]

const fromPathWithPattern = <T>(
  pattern: string,
  init: (variables: Variables) => WithOptionalSubroute<T> | undefined,
): IncrementalFromPath<T> => (path: string, location: Location) => {
  const [pathPattern] = pattern.split('?')
  const [variables, remainingPath] = variablesAndRemainingPath(
    components(path),
    components(pathPattern),
  )
  const result = variables && init(variables)
  return result ? [result, remainingPath] : undefined
}

const fromPathWithPatterns = <T>(
  patterns: string[],
  init: (variables: Variables) => WithOptionalSubroute<T> | undefined,
): IncrementalFromPath<T> => (path: string, location: Location) => {
  if (patterns.length === 0) {
    return undefined
  }
  const result = fromPathWithPattern(patterns[0], init)(path, location)
  return result
    ? result
    : fromPathWithPatterns(patterns.slice(1), init)(path, location)
}

const toPathComponentsWithPattern = <T>(
  pattern: string,
): ToPathComponents<T> => route =>
  compact(
    ...components(pattern).map(component =>
      component.startsWith(':') ? get(route, component.slice(1)) : component,
    ),
  )

const composedPath = <T>(
  pathComponents: (route: T) => string[],
  subrouteMeta?: SubrouteMetaOf<T>,
): ToPath<T> => route =>
  pathFromComponents([
    ...pathComponents(route),
    ...components(
      ifBoth(route, subrouteMeta, (meta, subroute) => meta.toPath(subroute)) ??
        '',
    ),
  ])

const composedTasks = <T>(
  tasks: Tasks<T>,
  subrouteMeta?: SubrouteMetaOf<T>,
): Tasks<T> => (route, state) => [
  ...(ifBoth(route, subrouteMeta, (meta, subroute) =>
    meta.tasks(subroute, state),
  ) ?? []),
  ...tasks(route, state),
]

const composedReducer = <P extends string, T>(
  path: P,
  reducer: Reducer<T>,
  subrouteMeta?: SubrouteMetaOf<T>,
  subrouteIsRequired?: boolean,
): Reducer<RouteType<P, T>> => (route, action, state) => {
  const newRoute = reducer(route, action, state)
  if (newRoute === undefined) {
    return undefined
  }
  const subroute = subrouteOf(newRoute)
  if (subrouteMeta && subroute) {
    const newSubroute = subrouteMeta.reducer(
      subroute as SubrouteOf<T>,
      action,
      state,
    )
    if (newSubroute !== subroute) {
      return newSubroute === undefined && subrouteIsRequired
        ? undefined
        : Object.assign({}, newRoute, { subroute: newSubroute }, { path })
    }
  }
  return get(newRoute, 'path') === path
    ? (newRoute as RouteType<P, T>)
    : Object.assign({}, newRoute, { path })
}

export enum AccessLevel {
  PUBLIC = 0,
  LOGGED_IN = 1,
  PRIVATE = 2,
}

const composedAccessLevel = <T>(
  accessLevel: AccessLevel,
  subrouteMeta?: SubrouteMetaOf<T>,
): GetAccessLevel<T> => route =>
  Math.max(
    accessLevel,
    ifBoth(route, subrouteMeta, (meta, subroute) =>
      meta.accessLevel(subroute),
    ) ?? 0,
  )

type Predicates = {
  [K in keyof SubrouteMeta<any>]: SubrouteMeta<any>[K] extends Predicate<any>
    ? K
    : never
}[keyof SubrouteMeta<any>]

const ifBoth = <T, U>(
  route: T,
  subrouteMeta: SubrouteMetaOf<T> | undefined,
  withSubroute: (
    subrouteMeta: SubrouteMeta<SubrouteOf<T>>,
    subroute: SubrouteOf<T>,
  ) => U,
) => {
  const subroute = subrouteOf(route)
  return subrouteMeta && subroute
    ? withSubroute(subrouteMeta as SubrouteMeta<SubrouteOf<T>>, subroute)
    : undefined
}

const composedPredicate = <T>(
  value: boolean | undefined,
  predicate: Predicates,
  subrouteMeta?: SubrouteMetaOf<T>,
): Predicate<T> => route =>
  value ||
  ifBoth(route, subrouteMeta, (meta, subroute) => meta[predicate](subroute)) ||
  false

/**
 * Creates a Route definition
 * @param path
 * REQUIRED: A pattern like `'/contacts/:id'`
 * @param additionalPatterns
 * An array of additional patterns like `'/contacts/:id'` that will be matched
 * @param init
 * A initializer that returns the route body
 * @param fromPath
 * An initializer that returns the route body given matching path variables
 * @param subroute
 * The Subroute definition if the route body contains a subroute
 * @param subrouteIsRequired
 * Must be marked true if `subroute` is not optional
 * @param tasks
 * A method that returns the tasks for this route
 * @param reducer
 * A reducer for this route
 * @param accessLevel
 * The access level for this route
 * @param hideSidebar
 * Whether to hide the sidebar when viewing this route.
 * @param hideTopBar
 * Whether to hide the top bar when viewing this route.
 * @param doNotResetWindowScroll
 * Mark `true` if the window scroll should not reset when navigating to or from this route.
 */
export const Route = <
  P extends string,
  T extends {},
  A extends any[] = DefaultArgs<T>
>({
  path,
  additionalPatterns,
  init,
  fromPath,
  subroute,
  subrouteIsRequired,
  tasks,
  reducer,
  accessLevel,
  hideSidebar,
  hideTopBar,
  doNotResetWindowScroll,
}: RouteOptions<P, T, A>): RouteMeta<P, T, A> =>
  Object.assign(
    (...args: A) =>
      Object.assign({}, init?.(...args) ?? args[0] ?? {}, { path }),
    {
      path,
      fromPath: composedFromPath(
        path,
        fromPathWithPatterns(
          [path, ...(additionalPatterns ?? [])],
          fromPath || (() => ({} as WithOptionalSubroute<T>)),
        ),
        subroute,
        subrouteIsRequired,
      ),
      toPath: composedPath(toPathComponentsWithPattern(path), subroute),
      subroute: subroute as SubrouteMetaOf<T>,
      subrouteIsRequired: subrouteIsRequired as SubrouteIsRequired<T>,
      tasks: composedTasks(tasks ?? (() => []), subroute),
      reducer: composedReducer(
        path,
        reducer ? reducer : r => r,
        subroute,
        subrouteIsRequired,
      ),
      accessLevel: composedAccessLevel(
        accessLevel ?? AccessLevel.PUBLIC,
        subroute,
      ),
      hideSidebar: composedPredicate(hideSidebar, 'hideSidebar', subroute),
      hideTopBar: composedPredicate(hideTopBar, 'hideTopBar', subroute),
      doNotResetWindowScroll: composedPredicate(
        doNotResetWindowScroll,
        'doNotResetWindowScroll',
        subroute,
      ),
    },
  )
