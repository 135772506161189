import React from 'react'
import { SVGProps } from './types'

export const PaperPlaneFlying: React.FC<SVGProps> = ({
  size = 24,
  color = '#323232',
  onClick,
  opacity,
}) => {
  const originalWidth = 51
  const originalHeight = 35
  const ratio = originalWidth / originalHeight
  const scaledWidth = size
  const scaledHeight = size / ratio
  return (
    <svg
      width={scaledWidth}
      height={scaledHeight}
      viewBox={`0 0 ${originalWidth} ${originalHeight}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <g opacity={opacity}>
        <path
          d="M7.63224 14.9343L8.27373 26.0946L47.7132 2.15213L7.63224 14.9343Z"
          stroke={color}
          strokeWidth={2.5}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.622 11.1106L26.8627 8.80077"
          stroke={color}
          strokeWidth={2.5}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M33.2292 2.91355L1.84375 4.44092L7.63218 14.9344L19.622 11.1104"
          stroke={color}
          strokeWidth={2.5}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M29.8597 3.04206L47.7132 2.15199L26.8628 8.80036"
          stroke={color}
          strokeWidth={2.5}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.4951 22.245L20.763 27.3865L47.7132 2.152"
          stroke={color}
          strokeWidth={2.5}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.3634 26.9835L9.48886 30.3157"
          stroke={color}
          strokeWidth={2.5}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.61787 32.2796L4.89614 33.4558"
          stroke={color}
          strokeWidth={2.5}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  )
}
