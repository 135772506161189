import gradients from './gradients'
import colors from './colors'
import { CSSProperties } from 'react'

const buttonColors: ButtonColors = {
  orange: {
    backgroundImage: gradients.orange,
    color: colors.white,
  },
  pink: {
    backgroundImage: gradients.pink,
    boxShadow: '0 1px 15px 0 rgba(239, 146, 251, 0.3)',
    color: colors.white,
  },
  blue: {
    backgroundImage: gradients.blue,
    boxShadow: '0 2px 9px -4px rgb(23, 130, 234)',
    color: colors.white,
  },
  facebook: {
    backgroundImage: gradients.facebook,
    boxShadow: '0 3px 10px rgba(48, 58, 157, 0.2)',
    color: colors.white,
  },
}

export interface ButtonColors {
  orange: CSSProperties
  pink: CSSProperties
  blue: CSSProperties
  facebook: CSSProperties
}

export type ButtonColorChoices = keyof ButtonColors

export default buttonColors
