import { useWindowWidth } from '@react-hook/window-size'
import {
  Button,
  Flex,
  Grid,
  GridItem,
  Image,
  Spacer,
  Text,
} from '@sendoutcards/quantum-design-ui'
import React from 'react'
import { EventBlock } from '../components/EventBlock'
import { EventBlockDetailType } from './types'

export type TrainingPageProps = {
  heroImage: string
  description: string
  eventBlockDetails: EventBlockDetailType[]
  previousTrainingUrl: string
}

export const TrainingPage: React.FC<TrainingPageProps> = ({
  heroImage,
  description,
  eventBlockDetails,
  previousTrainingUrl,
}) => {
  const width = useWindowWidth()
  const defaultEventBlockUrl = 'https://www.facebook.com/SendOutCards'

  return (
    <Flex
      inset={width < 992 ? { vertical: 'x4' } : { vertical: 'x6' }}
      backgroundColor="foreground"
      justifyContent="center"
      flexDirection="column"
    >
      <Flex
        inset={width < 992 ? { top: 'x4' } : { top: 'x8' }}
        alignContent="center"
        justifyContent="center"
      >
        <Image
          isActive={true}
          height={
            width < 550
              ? '120px'
              : width < 1200
              ? '220px'
              : width < 1600
              ? '420px'
              : '600px'
          }
          width="80%"
          image={{
            url: heroImage,
          }}
        />
      </Flex>
      <Text
        inset={{
          xSmall: 'x3',
          large: 'x8',
        }}
        alignment="center"
        type={{
          xSmall: 'caption',
          medium: 'body',
          large: 'largeBody',
        }}
        content={description}
      />
      <Flex
        inset="x8"
        backgroundColor="background"
        alignContent="center"
        justifyContent="center"
      >
        <Button
          size={width < 550 ? 'small' : width < 1200 ? 'medium' : 'large'}
          onClick={() => window.open(previousTrainingUrl)}
          outlined={true}
          title="View all previous trainings"
        />
      </Flex>
      <Spacer space="x4" />
      <Flex width="100%" justifyContent="center">
        <Grid maxWidth={2100} gap={16} columnSize={336} rowSize={220}>
          {eventBlockDetails.map((eventBlock, index) => (
            <GridItem key={index}>
              <EventBlock
                alignment={width < 690 ? 'center' : 'left'}
                button={{
                  title: eventBlock.button?.title
                    ? eventBlock.button.title
                    : 'SendOutCards FaceBook page',
                  onClick: () => {
                    eventBlock.button?.onClick
                      ? eventBlock.button.onClick()
                      : window.open(defaultEventBlockUrl)
                  },
                  size: width < 350 ? 'medium' : 'large',
                }}
                date={eventBlock.date}
                description={eventBlock.description}
              />
            </GridItem>
          ))}
        </Grid>
      </Flex>
    </Flex>
  )
}
