import {
  Button,
  Div,
  Flex,
  Input,
  LoadingSpinner,
  Spacer,
  Text,
} from '@sendoutcards/quantum-design-ui'
import React, { useCallback, useContext, useEffect } from 'react'
import useDebounce from 'src/hooks/useDebounce'
import {
  ValidateEmail,
  ValidateEmailData,
  ValidateUsername,
  ValidateUsernameData,
} from 'src/graphql'
import { APP_URL, performOperation } from 'src/app/api'
import { PromoContext } from '../context/promoContext'

type CreateAccountProps = {
  isMobile: boolean
  isCreatingAccount: boolean
  selectedPlan?: string
}

export interface CreateAccountFormState {
  email: string
  username: string
  password: string
}

const CreateAccountForm: React.FC<CreateAccountProps> = props => {
  const { isCreatingAccount, selectedPlan } = props

  const {
    state: { create },
    dispatch,
  } = useContext(PromoContext)

  const {
    isCheckingUsername,
    isCheckingEmail,
    form,
    isUsernameTaken,
    emailValidation,
  } = create

  const handleChange = (name: keyof CreateAccountFormState, value: string) => {
    const sanitizedValue =
      name === 'username'
        ? value.replace(/[^a-z0-9_\-@.+]|\s+/gim, '')
        : name === 'email'
        ? value
            .split(' ')
            .join('')
            .toLowerCase()
        : value
    dispatch({
      type: 'SET_CREATE_FORM',
      payload: { ...create.form, [name]: sanitizedValue },
    })
  }

  const debouncedUsername = useDebounce(form.username, 500)
  const debouncedEmail = useDebounce(form.email, 500)

  const setIsCheckingUsername = React.useCallback(
    (isChecking: boolean) => {
      dispatch({
        type: 'SET_IS_CHECKING_USERNAME',
        payload: { isCheckingUsername: isChecking },
      })
    },
    [dispatch],
  )

  const setIsUsernameTaken = useCallback(
    (isTaken: boolean) => {
      dispatch({
        type: 'SET_IS_USERNAME_TAKEN',
        payload: {
          isUsernameTaken: isTaken,
        },
      })
    },
    [dispatch],
  )

  const setIsCheckingEmail = React.useCallback(
    (isChecking: boolean) => {
      dispatch({
        type: 'SET_IS_CHECKING_EMAIL',
        payload: { isCheckingEmail: isChecking },
      })
    },
    [dispatch],
  )

  const setEmailValidation = useCallback(
    (isTaken: boolean, errorMsg?: string) => {
      dispatch({
        type: 'SET_EMAIL_VALIDATION',
        payload: {
          emailValidation: {
            isEmailTaken: isTaken,
            errorMsg,
          },
        },
      })
    },
    [dispatch],
  )

  useEffect(() => {
    if (debouncedUsername) {
      setIsCheckingUsername(true)
      performOperation<ValidateUsernameData>(
        ValidateUsername({ username: debouncedUsername }),
        result => {
          setIsCheckingUsername(false)
          if (result.value) {
            setIsUsernameTaken(!result.value.validateUsername.valid)
          }
        },
      )
    }
  }, [debouncedUsername, setIsCheckingUsername, setIsUsernameTaken])

  useEffect(() => {
    if (debouncedEmail) {
      setIsCheckingEmail(true)

      const rfc5322EmailRegex = /([!#-'*+/-9=?A-Z^-~-]+(\.[!#-'*+/-9=?A-Z^-~-]+)*|"(!#-[^-~ \t]|(\\[\t -~]))+")@([!#-'*+/-9=?A-Z^-~-]+(\.[!#-'*+/-9=?A-Z^-~-]+)+|\[[\t -Z^-~]*])/
      const isFormatValid = rfc5322EmailRegex.test(debouncedEmail)
      if (!isFormatValid) {
        setEmailValidation(
          true,
          'Email format is invalid. Please enter a valid email address to continue.',
        )
        setIsCheckingEmail(false)
        return
      }

      performOperation<ValidateEmailData>(
        ValidateEmail({ email: debouncedEmail }),
        result => {
          setIsCheckingEmail(false)
          if (result.value) {
            setEmailValidation(
              !result.value.validateEmail.isValid,
              result.value.validateEmail.errorMsg
                ? result.value.validateEmail.errorMsg
                : undefined,
            )
          }
        },
      )
    }
  }, [debouncedEmail, setIsCheckingEmail, setEmailValidation])

  return (
    <>
      <Flex width="100%" outset={{ vertical: 'x1_5' }} flexDirection="column">
        <Text type="footnote" content="Already have an account?" />
        <Button
          title="Log In"
          onClick={() => {
            if (selectedPlan) {
              window.open(
                `${APP_URL}/dashboard/pricing?selected=${selectedPlan}`,
              )
              return
            }

            window.open(`${APP_URL}/dashboard/pricing?goToPricing=true`)
          }}
        />
      </Flex>

      {isCreatingAccount && (
        <Div overflow="hidden" outset={{ top: 'x6', bottom: 'x3' }}>
          <LoadingSpinner size="xLarge" />
        </Div>
      )}
      {!isCreatingAccount && (
        <Div overflow="hidden">
          <Spacer space="x1" />
          <Text type="footnote" content="Or Create Your Account" />
          <Text
            type="body"
            weight="bold"
            content="Step 1: Create Your Account"
            color="primaryHeading"
            style={{ textDecoration: 'underline' }}
          />
          <Spacer space={'x1'} />
          <Flex width="100%" flexDirection="column">
            <Input
              onChange={email => handleChange('email', email)}
              type="email"
              name="email"
              value={form.email}
              placeholder="Email Address"
              focusColor="primaryBrand"
              message={
                emailValidation.errorMsg
                  ? {
                      type: 'danger',
                      content: emailValidation.errorMsg,
                      icon: 'information',
                      onClick: () => {},
                    }
                  : undefined
              }
              isFullWidth={true}
              isLoading={isCheckingEmail}
            />
            <Spacer space={'x1_5'} />
            <Input
              onChange={username => handleChange('username', username)}
              type="text"
              name="username"
              value={form.username}
              placeholder="@username"
              focusColor="primaryBrand"
              message={
                isUsernameTaken
                  ? {
                      type: 'danger',
                      content: 'This username is taken',
                      icon: 'information',
                      onClick: () => {},
                    }
                  : undefined
              }
              isFullWidth={true}
              isLoading={isCheckingUsername}
            />
            <Text
              type="footnote"
              content={'Usernames are case sensitive'}
              outset={{ horizontal: 'x1' }}
            />
            <Spacer space={'x1_5'} />
            <Input
              onChange={password => handleChange('password', password)}
              type="password"
              name="password"
              value={form.password}
              placeholder="Password"
              focusColor="primaryBrand"
              isFullWidth={true}
            />
          </Flex>
        </Div>
      )}
    </>
  )
}

export default CreateAccountForm
