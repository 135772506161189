import { VideoObject } from 'src/marketing/components/VideoRibbon/VideoRibbon'

export const VIDEOS: VideoObject[] = [
  {
    id: 1,
    title: 'Hair Salon Specialist',
    src: '//fast.wistia.net/embed/iframe/dxvphtuckf',
    duration: '4:55',
    poster:
      'https://www.sendoutcards.com/soc-media/images/Screen_Shot_2018-04-04_at_10.41.04_PM.original.png',
  },
  {
    id: 2,
    title: 'Manufacturer Rep',
    src: '//fast.wistia.net/embed/iframe/7fv48sjsxs',
    duration: '2:41',
    poster:
      'https://www.sendoutcards.com/soc-media/images/Screen_Shot_2018-04-04_at_11.11.11_PM.original.png',
  },
  {
    id: 3,
    title: 'Author Speaker',
    src: '//fast.wistia.net/embed/iframe/dpjlkj9kko',
    duration: '3:50',
    poster:
      'https://www.sendoutcards.com/soc-media/images/Screen_Shot_2018-04-04_at_11.12.33_PM.original.png',
  },
  {
    id: 4,
    title: 'Car Sales',
    src: '//fast.wistia.net/embed/iframe/cym5hz5a5z',
    duration: '4:24',
    poster:
      'https://www.sendoutcards.com/soc-media/images/Screen_Shot_2018-04-04_at_11.15.17_PM.original.png',
  },
  {
    id: 5,
    title: 'Dental Hygienist',
    src: '//fast.wistia.net/embed/iframe/d6hn1ao0yt',
    duration: '3:27',
    poster:
      'https://www.sendoutcards.com/soc-media/images/Screen_Shot_2018-04-04_at_11.18.28_PM.original.png',
  },
  {
    id: 6,
    title: 'Franchisor',
    src: '//fast.wistia.net/embed/iframe/f4tzm9jwoi',
    duration: '1:57',
    poster:
      'https://www.sendoutcards.com/soc-media/images/Screen_Shot_2018-04-04_at_11.09.19_PM.original.png',
  },
]
