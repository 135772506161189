import React, { useState } from 'react'
import {
  DirectionType,
  Div,
  Flex,
  Tooltip,
} from '@sendoutcards/quantum-design-ui'
import { Display } from '@sendoutcards/quantum-design-ui/dist/src/helpers/hoc-types/cssValueTypes'
import { ColorModeType } from '@sendoutcards/quantum-design-ui/dist/src/types/global'

interface HoverTooltipProps {
  title?: string
  shouldDisplayTooltip?: boolean
  tooltipDirection?: DirectionType
  tooltipChildren?: React.ReactNode
  isFullWidth?: boolean
  background?: ColorModeType
}

const HoverTooltip: React.FC<HoverTooltipProps> = props => {
  const [isHoveringOn, setIsHoveringOn] = useState(false)
  const {
    title = '',
    shouldDisplayTooltip = true,
    tooltipChildren,
    tooltipDirection = 'top',
    isFullWidth = false,
    background = 'light',
    children,
  } = props

  const tooltipVariants = {
    hoverOn: {
      opacity: 1,
    },
    hoverOff: {
      opacity: 0,
    },
  }

  return (
    <Div
      onHoverStart={() => {
        setIsHoveringOn(true)
      }}
      onHoverEnd={() => {
        setIsHoveringOn(false)
      }}
      position="relative"
      display={isFullWidth ? ('block' as Display) : ('inline-block' as Display)}
    >
      {children}
      {shouldDisplayTooltip && isHoveringOn && (
        <Flex
          variants={tooltipVariants}
          animate={isHoveringOn ? 'hoverOn' : 'hoverOff'}
          initial={'hoverOff'}
          transition={{ ease: 'easeIn', duration: 0.2 }}
          top={tooltipDirection === 'bottom' ? '-100%' : '135%'}
          left="50%"
          transform="translate(-50%)"
          position="absolute"
          zIndex={10000}
          width={isFullWidth ? 'max-content' : 'auto'}
        >
          <Tooltip
            title={title}
            direction={tooltipDirection}
            background={background}
          >
            {tooltipChildren}
          </Tooltip>
        </Flex>
      )}
    </Div>
  )
}

export default HoverTooltip
