import { ContentMediaType } from 'src/home_page/components/ContentPop/ContentPop'
import { BentoContentExpandType } from 'src/marketing/components/BentoGrid/BentoCard'
import { CardTypeImageDiffData } from 'src/marketing/mockData/CardTypeImageDiffData'

const fontContentStream: ContentMediaType[] = [
  {
    content: {
      type: 'content',
      title: 'Personal digital signature & handwriting font',
      description:
        'Nothing makes your message more personal than your own handwriting to say it in and your signature as the sign-off. ',
    },
    width: '100%',
    height: 'auto',
  },
  {
    content: {
      type: 'content',
      title: '',
      description:
        'Add this feature to enhance your message and add a personal touch to your card. ',
    },
    width: '100%',
    height: 'auto',
  },
]
export const handWritingFontExpandData: BentoContentExpandType = {
  bannerImages: [
    'https://soc-website-public.s3.us-west-2.amazonaws.com/static/soc-website/images/popups/Sig-Font-main-image.png',
  ],
  contentStream: fontContentStream,
  type: 'content',
  imageDiff: CardTypeImageDiffData[4],
}
