import React from 'react'
import { Icon } from 'src/design_system/atoms/icons/components/Icon'

type RatingProps = {
  rating: number
}

export const Rating: React.FC<RatingProps> = ({ rating }) => {
  return (
    <div style={{ display: 'flex' }}>
      {[...Array(5)].map((star, index) => {
        return index < rating ? (
          <Icon key={index} name="star" color="#FFB951" size={16} />
        ) : (
          <Icon key={index} name="star" color="#D1D5DB" size={16} />
        )
      })}
    </div>
  )
}
