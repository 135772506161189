import {
  SendDelayType,
  Maybe,
  SendDelayDelayType,
  SendDelayTimeType,
} from '../../../__generated__/globalTypes'

import sendDelayFragmentRawString from '../SendDelayFragment.graphql'

export const sendDelayFragmentString = sendDelayFragmentRawString

export type SendDelayFragment = {
  type: SendDelayType
  delayNumber: Maybe<number>
  delayType: SendDelayDelayType
  timeType: SendDelayTimeType
  specificDate: string
}

export const isSendDelayFragment = (
  fragment: any,
): fragment is SendDelayFragment =>
  fragment &&
  (fragment.type === 'IMM' ||
    fragment.type === 'BIR' ||
    fragment.type === 'ANN' ||
    fragment.type === 'SPE') &&
  (fragment.delayNumber === null || typeof fragment.delayNumber === 'number') &&
  (fragment.delayType === 'DAY' ||
    fragment.delayType === 'WEE' ||
    fragment.delayType === 'MON') &&
  (fragment.timeType === 'BEF' || fragment.timeType === 'AFT') &&
  typeof fragment.specificDate === 'string'
