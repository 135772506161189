import { ActionCreator } from '@sendoutcards/core'
import { SponsorFragment } from 'src/graphql'

export const loadedStorefront = ActionCreator(
  'LOADED_STOREFRONT',
  (storefront: { user: SponsorFragment }) => ({ storefront }),
)

export const clearStorefront = ActionCreator('CLEAR_STOREFRONT')

export const loadedStorefrontError = ActionCreator(
  'LOADED_STOREFRONT_ERROR',
  (error: Error) => ({ error }),
)
