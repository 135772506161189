import { Operation } from '../../../__generated__/globalTypes'

import validateUsernameString from '../ValidateUsername.graphql'

export type ValidateUsernameData = {
  validateUsername: {
    valid: boolean
  }
}

export type ValidateUsernameVariables = {
  username: string
}

export type ValidateUsername = Operation<
  ValidateUsernameData,
  ValidateUsernameVariables
>

export const ValidateUsername = ({
  username,
}: ValidateUsernameVariables): ValidateUsername => ({
  query: validateUsernameString,
  variables: {
    username,
  },
})
