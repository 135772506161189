import React from 'react'
import Carousel from 'nuka-carousel'
import { BoundActionCreator } from '@sendoutcards/core'
import { Set } from 'immutable'
// @src imports
import connect from 'src/redux/connect'
import { PaginatedList } from 'src/utils/PaginatedList'
import { clearTourId, setTourId } from 'src/redux/actions/helpmenu'
import { ProgressModal, Search, Transition } from 'src/chrome'
import { Selection, setBodyOverflowHidden } from 'src/helpers'
import { performOperation } from 'src/app/api'
import { Future } from 'src/utils/Future'
import { PhotoDropCardsRoute } from 'src/catalog/routes/PhotoDropCardsRoute'
import { StandardCardsRoute } from 'src/catalog/routes/StandardCardsRoute'
import { LegacyPicturePlusCardsRoute } from 'src/catalog/routes/LegacyPicturePlusCardsRoute'
import {
  CardCategoryFragment,
  CollectionFragment,
  CreateSparseUser,
  CreateSparseUserData,
  CustomCardFragment,
  DetailedSendableCardFragment,
  InviteFragment,
  MinimalSendableCardFragment,
} from 'src/graphql'

import { IconTypeOrIconString } from 'src/app/types'
import { StorefrontState } from 'src/redux/reducers/storefront'
import { CatalogState } from 'src/redux/reducers/catalog'
import { CatalogRoute } from 'src/catalog/routes/CatalogRoute'
import {
  dismissedCatalogCard,
  dismissedCatalogSubroute,
  selectedCardCategory,
  selectedCardCollection,
  selectedCatalogCard,
  selectedPhotoDropCards,
  selectedStandardCards,
  setIsBulkOrderFromQueryParam,
  updatedCatalogSearch,
} from 'src/redux/actions/catalog'
// relative imports
import {
  getCardCatalog,
  getPaginatedCards,
  getSelectedCategory,
  getSelectedCollection,
  getSelectedSendableCard,
  shouldShowCardPreview,
} from '../../redux/selectors/catalog'
import { CardCatalog, isMinimalSendableCardFragment } from '../types'
import {
  BlankCardModal,
  CardPreview,
  CatalogRibbon,
  Collection,
  Reason,
} from '../components'

import styles from './catalog.module.scss'
import { openApp } from 'src/helpers/appHelpers'
import {
  Div,
  QuantumThemeWrapper,
  Spacer,
  Text,
} from '@sendoutcards/quantum-design-ui'
import { AppRoute } from 'src/app/routes/AppRoute'

export type FilterButton = {
  __typename: 'FilterButton'
  name: string
  isPremium?: boolean
  id: string
  icon: {
    icon: IconTypeOrIconString
    color: string
    viewBox: string
    size: number
  }
  uniqueHandler: boolean
}

const filterButtons: FilterButton[] = [
  {
    __typename: 'FilterButton',
    name: 'Photo Drop',
    isPremium: true,
    id: 'photo_drop_filter_btn',
    icon: {
      icon: 'PHOTODROP',
      color: '#6f8394',
      viewBox: '0 0 19 32',
      size: 60,
    },
    uniqueHandler: false,
  },
  {
    __typename: 'FilterButton',
    name: 'Catalog',
    id: 'catalog_filter_btn',
    isPremium: false,
    icon: {
      icon: 'CATALOGCARD',
      color: '#6f8394',
      viewBox: '0 0 19 32',
      size: 60,
    },
    uniqueHandler: false,
  },
  {
    __typename: 'FilterButton',
    name: 'Build Your Own',
    id: 'create_your_own_filter_btn',
    isPremium: undefined,
    icon: {
      icon: 'CAMERA1',
      color: '#6f8394',
      viewBox: '0 0 1224 1024',
      size: 45,
    },
    uniqueHandler: true,
  },
]

type CatalogActions = {
  dismissedCatalogSubroute: BoundActionCreator<typeof dismissedCatalogSubroute>
  updatedCatalogSearch: BoundActionCreator<typeof updatedCatalogSearch>
  selectedCatalogCard: BoundActionCreator<typeof selectedCatalogCard>
  dismissedCatalogCard: BoundActionCreator<typeof dismissedCatalogCard>
  selectedCardCategory: BoundActionCreator<typeof selectedCardCategory>
  selectedCardCollection: BoundActionCreator<typeof selectedCardCollection>
  selectedPhotoDropCards: BoundActionCreator<typeof selectedPhotoDropCards>
  selectedStandardCards: BoundActionCreator<typeof selectedStandardCards>
  setIsBulkOrderFromQueryParam: BoundActionCreator<
    typeof setIsBulkOrderFromQueryParam
  >
}

type CardCatalogProps = CatalogActions &
  CatalogState & {
    route: CatalogRoute
    cardCatalog: Future<CardCatalog>
    paginatedCards:
      | PaginatedList<MinimalSendableCardFragment>
      | PaginatedList<CustomCardFragment>
      | undefined
    selectedCategory: CardCategoryFragment | undefined
    selectedCollection: CollectionFragment | undefined
    selectedSendableCard: DetailedSendableCardFragment | undefined
    storefront: StorefrontState
    hideSideScroll?: boolean
    showCardPreview: boolean
    loadingCardForPreview: boolean
    setTourId: BoundActionCreator<typeof setTourId>
    clearTourId: BoundActionCreator<typeof clearTourId>
    isMobile: boolean
    invite?: InviteFragment
    isCreatingInvite: boolean
    isBulk?: boolean
  }

type State = {
  blankCard: boolean
  creating?: boolean
  deletedCards: number
  selectedCustomCards: Set<string>
  isRedirecting: boolean
}

const paginatedCardsTitle = (
  cards:
    | PaginatedList<MinimalSendableCardFragment>
    | PaginatedList<CustomCardFragment>,
): string => {
  if (cards.elements.length === 0) {
    return ''
  }
  const firstCard = cards.elements[0]
  return isMinimalSendableCardFragment(firstCard) ? firstCard.title : ''
}

class Catalogs extends React.Component<CardCatalogProps, State> {
  state: State = {
    blankCard: false,
    selectedCustomCards: Set(),
    deletedCards: 0,
    isRedirecting: false,
  }

  get categoryType(): 'myCards' | 'legacyPicturePlusCards' | undefined {
    const subroute = this.props.route.subroute
    if (!subroute) {
      return undefined
    }
    return subroute.path === LegacyPicturePlusCardsRoute.path
      ? 'legacyPicturePlusCards'
      : undefined
  }

  get sponsorId(): string | undefined {
    return (
      this.props.storefront.storefront?.user.id ??
      this.props.storefront.sponsorId
    )
  }

  get isLoadingSponsor(): boolean {
    return this.props.storefront.loading
  }

  selectCustomCard = (id: string) => {
    this.props.selectedCatalogCard('Card', id)
  }

  handleCollection = (id: string) => {
    this.props.selectedCardCollection(id)
  }

  handleCardPreview = (id: string, typename?: string) => {
    this.props.selectedCatalogCard(
      typename === 'Card' ? typename : 'SendableCard',
      id,
    )
    setBodyOverflowHidden(true)
  }

  handleClose = () => {
    this.props.dismissedCatalogCard()
    setBodyOverflowHidden(false)
  }

  handleCategory = (category: CardCategoryFragment) => {
    this.props.selectedCardCategory(category.id)
  }

  handleSearchAll = () => {
    this.props.dismissedCatalogSubroute()
  }

  handleFilterByIsPremium = (isPremium?: boolean) => {
    if (isPremium === true) {
      this.props.selectedPhotoDropCards()
    } else if (isPremium === false) {
      this.props.selectedStandardCards()
    } else {
      this.props.dismissedCatalogSubroute()
    }
  }

  handleBlankCard = () => {
    this.setState({ blankCard: !this.state.blankCard })
  }

  setFilterLabel = (): { title: string; description: string } | undefined => {
    const { search, subroute } = this.props.route
    if (!subroute) {
      return {
        title: 'Search Cards',
        description: `Search results for '${search}'.`,
      }
    }
    switch (subroute.path) {
      case PhotoDropCardsRoute.path:
        return {
          title: 'Photo Drop Cards',
          description:
            'Put your own image in these cards and add the perfect personal touch.',
        }
      case StandardCardsRoute.path:
        return {
          title: 'Catalog Cards',
          description:
            'Browse our card catalog for quality designs to send to your loved ones.',
        }
      default:
        return undefined
    }
  }

  selectMyCard = (cardId: string) =>
    this.setState({
      selectedCustomCards: Selection.toggle(
        this.state.selectedCustomCards,
        cardId,
      ),
    })

  clearDeletedCount = () => {
    this.setState({
      deletedCards: 0,
      selectedCustomCards: Set(),
    })
  }

  // Close blank card modal after card is selected
  UNSAFE_componentWillReceiveProps() {
    if (this.state.blankCard) {
      this.setState({
        blankCard: false,
      })
    }
  }

  createSparseAndRedirect(inviteId: string) {
    performOperation<CreateSparseUserData>(
      CreateSparseUser({ inviteId }),
      result => {
        if (result.value) {
          openApp('', {
            access_token: result.value.createSparseUser.account.token,
            postcardPromo: '',
          })
        } else {
          openApp('', { verify: '', postcardPromo: '' })
        }
      },
    )
  }

  componentDidMount() {
    const urlParam = new URL(window.location.href)
    const hasBulkOrderQueryParam = urlParam.searchParams.get('bulkSendEnabled')
    if (hasBulkOrderQueryParam) {
      this.props.setIsBulkOrderFromQueryParam(true)
    }
    if (window.Appcues) {
      this.props.setTourId('-L83V80t7XTfP4WxnT-4')
      window.Appcues.start()
    }
  }

  componentWillUnmount() {
    this.props.clearTourId()
  }

  componentDidUpdate() {
    const { postcardPromo, promotion } = this.props.route
    const { invite, isCreatingInvite } = this.props

    if (postcardPromo) {
      if (!isCreatingInvite && invite && !this.state.isRedirecting) {
        this.setState({ isRedirecting: true })
        this.createSparseAndRedirect(invite.id)
      } else if (
        !isCreatingInvite &&
        !this.isLoadingSponsor &&
        this.sponsorId &&
        !this.state.isRedirecting
      ) {
        this.setState({ isRedirecting: true })
        if (promotion)
          openApp('', {
            verify: '',
            postcardPromo: '',
            promotion: '',
          })
        else {
          openApp('', {
            verify: '',
            postcardPromo: '',
          })
        }
      }
    }
  }

  // tslint:disable-next-line: cyclomatic-complexity 28
  render() {
    const {
      cardCatalog,
      dismissedCatalogSubroute,
      loadingCardForPreview,
      paginatedCards,
      route,
      selectedCategory,
      selectedCollection,
      selectedSendableCard,
      showCardPreview,
      updatedCatalogSearch,
      isMobile,
      isBulk,
    } = this.props
    const { blankCard, creating, deletedCards } = this.state
    const isPostcardPromo = route.postcardPromo ?? false

    if (isPostcardPromo) {
      return (
        <Transition
          message="Loading..."
          messageStyle={{ color: 'black', fontWeight: 500 }}
        />
      )
    }

    return (
      <QuantumThemeWrapper>
        <Div width="100%">
          {isMobile && (
            <>
              <Spacer space="x4" />
              <Text alignment="center" type="subtitle">
                Card Catalog
              </Text>
            </>
          )}
          <div
            className={styles.catalogWrapper}
            id={'tour-catalogWrapper'}
            style={{ marginTop: isMobile ? '60px' : undefined }}
          >
            {cardCatalog.match(
              // tslint:disable-next-line: cyclomatic-complexity score 30
              cardCatalog =>
                (!selectedCollection && !paginatedCards && (
                  <div>
                    <div
                      className={styles.searchHeader}
                      style={{ marginTop: isMobile ? '0px' : undefined }}
                    >
                      {!isMobile &&
                        cardCatalog.collections.filter(
                          collection => collection.reasonToSend,
                        ).length && (
                          <Carousel
                            initialSlideHeight={500}
                            dragging={true}
                            easing={'easeCubicIn'}
                          >
                            {cardCatalog.collections
                              .filter(collection => collection.reasonToSend)
                              .map(reason => {
                                return (
                                  <Reason
                                    key={reason.id}
                                    id={reason.id}
                                    title={reason.title}
                                    description={reason.description}
                                    textColor={reason.textColor}
                                    backgroundStartColor={
                                      reason.backgroundStartColor
                                    }
                                    backgroundEndColor={
                                      reason.backgroundEndColor
                                    }
                                    card={reason.cards[0]}
                                    reasonToSend={true}
                                    onClick={this.handleCollection}
                                    cardPreview={this.handleCardPreview}
                                  />
                                )
                              })}
                          </Carousel>
                        )}
                      <Search
                        id={'search_bar'}
                        categories={cardCatalog.categories}
                        handleCategory={this.handleCategory}
                        hint={'What are you looking for?'}
                        search={route.search}
                        onSearch={updatedCatalogSearch}
                        clearSearch={dismissedCatalogSubroute}
                        style={{ marginBottom: 60 }}
                        className={styles.catalogSearchBar}
                        inputClassName={styles.catalogSearchField}
                        tagsClassName={styles.catalogSearchTags}
                        buttonClick={this.handleBlankCard}
                      />
                    </div>
                    <CatalogRibbon
                      title={'Filter By Card Type'}
                      items={filterButtons}
                      handleFilter={this.handleFilterByIsPremium}
                      handleBlankCard={this.handleBlankCard}
                    />
                    <CatalogRibbon
                      type={'collections'}
                      title={'Card Collections'}
                      items={cardCatalog.collections.filter(
                        collection => !collection.reasonToSend,
                      )}
                      tiles={true}
                      onClick={this.handleCollection}
                    />
                    <CatalogRibbon
                      type={'featured'}
                      title={'Featured'}
                      hideScrollBar={showCardPreview}
                      items={cardCatalog.featuredCards}
                      onClick={this.handleCardPreview}
                    />
                    {cardCatalog.categories
                      .filter(category => category.cards.length > 0)
                      .map(category => {
                        return (
                          <CatalogRibbon
                            type={category.id}
                            key={category.id}
                            hideScrollBar={showCardPreview}
                            title={category.description}
                            items={category.cards}
                            onClick={this.handleCardPreview}
                            handleCategory={() => this.handleCategory(category)}
                            isCategory={true}
                          />
                        )
                      })}
                  </div>
                )) ||
                (paginatedCards && (
                  <div>
                    <Reason
                      title={
                        selectedCategory?.description ??
                        paginatedCardsTitle(paginatedCards)
                      }
                      info={
                        !selectedCategory ? this.setFilterLabel() : undefined
                      }
                      textColor="#fff"
                      backgroundStartColor="#e687ff"
                      backgroundEndColor="#ff5689"
                      card={paginatedCards.elements[0]}
                      cardPreview={this.handleCardPreview}
                      onClose={dismissedCatalogSubroute}
                    />
                    {!route.subroute ||
                      (route.subroute.path !==
                        LegacyPicturePlusCardsRoute.path && (
                        <Search
                          categories={cardCatalog.categories}
                          activeCategory={selectedCategory}
                          handleCategory={this.handleCategory}
                          search={route.search}
                          buttonClick={this.handleBlankCard}
                          onSearch={updatedCatalogSearch}
                          clearSearch={dismissedCatalogSubroute}
                          className={styles.catalogSearchBar}
                          inputClassName={styles.catalogSearchField}
                          tagsClassName={styles.catalogSearchTags}
                        />
                      ))}
                    <CatalogRibbon
                      type={'search_results'}
                      title={selectedCategory && selectedCategory.description}
                      items={paginatedCards.elements}
                      emptySearch={
                        route.search !== '' &&
                        !paginatedCards.hasMore &&
                        paginatedCards.elements.length <= 0
                      }
                      search={route.search}
                      category={
                        selectedCategory && selectedCategory.description
                      }
                      grid={true}
                      onClick={this.handleCardPreview}
                      onClose={dismissedCatalogSubroute}
                      handleSearchAll={this.handleSearchAll}
                      handleCheckbox={
                        this.categoryType ? this.selectMyCard : undefined
                      }
                      selectedSet={this.state.selectedCustomCards}
                      loadOnScroll={!!this.categoryType}
                      hasMore={paginatedCards.hasMore}
                    />
                  </div>
                )) ||
                (selectedCollection && (
                  <Collection
                    cards={cardCatalog.collectionCards[selectedCollection.id]}
                    collection={selectedCollection}
                    onClick={this.handleCardPreview}
                    onClose={dismissedCatalogSubroute}
                  />
                )),
              error => (
                <div>{error.stack || error.message}</div>
              ),
              () =>
                !showCardPreview ? (
                  <Transition message={'Gorgeous cards are on their way!'} />
                ) : null,
            )}
            {creating && (
              <Transition
                message={'Crafting your perfect card...'}
                messageStyle={{ color: 'black', fontWeight: 500 }}
              />
            )}
            {showCardPreview && (
              <CardPreview
                loadingCard={loadingCardForPreview}
                sendableCard={selectedSendableCard}
                onClose={this.handleClose}
                editCard={() => {}}
                isBulk={isBulk}
              />
            )}
            {blankCard && (
              <BlankCardModal
                onClick={this.handleCardPreview}
                onClose={this.handleBlankCard}
                style={{ width: 750 }}
              />
            )}
          </div>
          <ProgressModal
            size={100}
            open={deletedCards > 0}
            currentCount={deletedCards}
            totalCount={this.state.selectedCustomCards.size}
            errorTitle={'Some cards could not be deleted'}
            message={'cards deleted successfully'}
            onClose={this.clearDeletedCount}
          />
        </Div>
      </QuantumThemeWrapper>
    )
  }
}

export default connect(
  state => {
    const { cardCatalog, storefront } = state
    return {
      ...cardCatalog,
      cardCatalog: getCardCatalog(state),
      paginatedCards: getPaginatedCards(state),
      selectedCategory: getSelectedCategory(state),
      selectedCollection: getSelectedCollection(state),
      selectedSendableCard: getSelectedSendableCard(state),
      setIsBulkOrderFromQueryParam: setIsBulkOrderFromQueryParam(false),
      storefront,
      showCardPreview: shouldShowCardPreview(state),
      isMobile: state.window.isMobile,
      invite: state.invites.invite,
      isCreatingInvite: state.invites.isCreating,
      isBulk:
        (state.route.subroute &&
          state.route.subroute.path === AppRoute.path &&
          state.route.subroute.subroute &&
          state.route.subroute.subroute.path === CatalogRoute.path &&
          state.route.subroute.subroute.bulk) ||
        state.cardCatalog.isBulkOrderFromQueryParam,
    }
  },
  {
    dismissedCatalogSubroute,
    setTourId,
    clearTourId,
    updatedCatalogSearch,
    selectedCatalogCard,
    dismissedCatalogCard,
    selectedCardCategory,
    selectedCardCollection,
    selectedPhotoDropCards,
    selectedStandardCards,
    setIsBulkOrderFromQueryParam,
  },
)(Catalogs)
