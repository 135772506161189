import React from 'react'
import { SVGProps } from './types'

export const Star: React.FC<SVGProps> = ({ size, color, onClick }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 17 16"
      width={size}
      height={size}
      onClick={onClick}
    >
      <path
        d="M9.65411 2.34001L10.8274 4.68668C10.9874 5.01334 11.4141 5.32668 11.7741 5.38668L13.9008 5.74001C15.2608 5.96668 15.5808 6.95334 14.6008 7.92667L12.9474 9.58001C12.6674 9.86001 12.5141 10.4 12.6008 10.7867L13.0741 12.8333C13.4474 14.4533 12.5874 15.08 11.1541 14.2333L9.16078 13.0533C8.80078 12.84 8.20744 12.84 7.84078 13.0533L5.84744 14.2333C4.42078 15.08 3.55411 14.4467 3.92744 12.8333L4.40078 10.7867C4.48744 10.4 4.33411 9.86001 4.05411 9.58001L2.40077 7.92667C1.42744 6.95334 1.74077 5.96668 3.10078 5.74001L5.22744 5.38668C5.58078 5.32668 6.00744 5.01334 6.16744 4.68668L7.34078 2.34001C7.98078 1.06668 9.02078 1.06668 9.65411 2.34001Z"
        fill={color}
      />
    </svg>
  )
}
