import {
  Maybe,
  CardType,
  CardPaperType,
} from '../../../__generated__/globalTypes'

import {
  SendableCardWithTemplateFragment,
  isSendableCardWithTemplateFragment,
} from './SendableCardWithTemplateFragment'

import { PanelFragment, isPanelFragment } from './PanelFragment'

import {
  ItemizedCostFragment,
  isItemizedCostFragment,
} from './ItemizedCostFragment'

import cardFragmentRawString from '../CardFragment.graphql'

export const cardFragmentString = cardFragmentRawString

export type CardFragment = {
  __typename: 'Card'
  id: string
  sendableCard: Maybe<
    SendableCardWithTemplateFragment & {
      variations: SendableCardWithTemplateFragment[]
    }
  >
  type: CardType
  paperType: CardPaperType
  isHorizontal: boolean
  backPanelLocation: number
  tinyThumb: string
  frontPreviewUrl: string
  insidePreviewUrl: Maybe<string>
  flapPreviewUrl: Maybe<string>
  backPreviewUrl: string
  showInPicturePlus: boolean
  panels: PanelFragment[]
  cost: ItemizedCostFragment
}

export const isCardFragment = (fragment: any): fragment is CardFragment =>
  fragment &&
  fragment.__typename === 'Card' &&
  typeof fragment.id === 'string' &&
  (fragment.sendableCard === null ||
    ((isSendableCardWithTemplateFragment(fragment.sendableCard) as boolean) &&
      Array.isArray(fragment.sendableCard.variations) &&
      fragment.sendableCard.variations
        .slice(0, 5)
        .reduce(
          (accum: any, next: any) =>
            accum && (isSendableCardWithTemplateFragment(next) as boolean),
          true,
        ))) &&
  (fragment.type === 'POSTCARD' ||
    fragment.type === 'FLATCARD' ||
    fragment.type === 'TWO_PANEL' ||
    fragment.type === 'THREE_PANEL' ||
    fragment.type === 'TWO_PANEL_BIG') &&
  (fragment.paperType === 'STD' ||
    fragment.paperType === 'PRE' ||
    fragment.paperType === 'HVY') &&
  typeof fragment.isHorizontal === 'boolean' &&
  typeof fragment.backPanelLocation === 'number' &&
  typeof fragment.tinyThumb === 'string' &&
  typeof fragment.frontPreviewUrl === 'string' &&
  (fragment.insidePreviewUrl === null ||
    typeof fragment.insidePreviewUrl === 'string') &&
  (fragment.flapPreviewUrl === null ||
    typeof fragment.flapPreviewUrl === 'string') &&
  typeof fragment.backPreviewUrl === 'string' &&
  typeof fragment.showInPicturePlus === 'boolean' &&
  Array.isArray(fragment.panels) &&
  fragment.panels
    .slice(0, 5)
    .reduce(
      (accum: any, next: any) => accum && (isPanelFragment(next) as boolean),
      true,
    ) &&
  (isItemizedCostFragment(fragment.cost) as boolean)
