import { Set } from 'immutable'
class Selection {
  static toggle = <T>(set: Set<T>, item: T) =>
    set.contains(item) ? set.delete(item) : set.add(item)
  static dict(dict: any, key: any, value: any) {
    if (dict[key]) {
      const { key, ...newDict } = dict
      return newDict
    } else {
      return {
        ...dict,
        [key]: value,
      }
    }
  }
  static isChecked(obj: any, key: any) {
    if (obj.size >= 0) {
      // this is a set not a dict
      return obj.contains(key)
    } else {
      return !!obj[key]
    }
  }
  static selectAllSet = <T>(selectAll: boolean, items: T[]): Set<T> =>
    selectAll ? Set() : Set(items)
  static selectAllDict = (allSelected: boolean, items: any, key: string) =>
    !allSelected
      ? items.reduce(
          (selected: any, item: any) => ({ ...selected, [item[key]]: item }),
          {},
        )
      : {}
  static allSelected = (selectedCount: number, itemsCount: number) =>
    selectedCount === itemsCount
}

export default Selection
