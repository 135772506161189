import React from 'react'

import { VIDEOS } from 'src/marketing/components/VideoRibbon/videos'
import { VideoTile } from 'src/marketing/components'

import styles from './videoRibbon.module.scss'

export interface VideoObject {
  id: number
  title: string
  src: string
  duration: string
  poster: string
}

interface VideoRibbonProps {
  hideTitle?: boolean
  onPlay: (video: VideoObject) => void
}

const VideoRibbon: React.FC<VideoRibbonProps> = props => {
  const { onPlay, hideTitle } = props
  return (
    <div className={styles.videoRibbon}>
      {!hideTitle && (
        <div className={styles.ribbonTitleBar}>
          <h4 className={styles.black}>Get Inspired</h4>
          <div className={styles.chip}>{VIDEOS.length} Videos</div>
        </div>
      )}
      <div className={styles.ribbonInner}>
        {VIDEOS &&
          VIDEOS.map(video => {
            return <VideoTile key={video.id} video={video} onPlay={onPlay} />
          })}
      </div>
    </div>
  )
}

export default VideoRibbon
