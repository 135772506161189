import { Price, isPrice } from './Price'

import { LabeledPrice, isLabeledPrice } from './LabeledPrice'

import itemizedCostFragmentRawString from '../ItemizedCostFragment.graphql'

export const itemizedCostFragmentString = itemizedCostFragmentRawString

export type ItemizedCostFragment = {
  total: Price[]
  entries: LabeledPrice[]
}

export const isItemizedCostFragment = (
  fragment: any,
): fragment is ItemizedCostFragment =>
  fragment &&
  Array.isArray(fragment.total) &&
  fragment.total
    .slice(0, 5)
    .reduce(
      (accum: any, next: any) => accum && (isPrice(next) as boolean),
      true,
    ) &&
  Array.isArray(fragment.entries) &&
  fragment.entries
    .slice(0, 5)
    .reduce(
      (accum: any, next: any) => accum && (isLabeledPrice(next) as boolean),
      true,
    )
