import { BentoCardData } from 'src/marketing/components/BentoGrid/BentoCard'
import { PersonalTouchExpandData } from './expands/PersonalTouch'

export const CardOptionsBentoCards = (isMobile: boolean) => {
  const cardData = [
    {
      id: 4,
      title: 'Paper Types',
      description: 'We offer 3 beautiful paper types (pearl, satin, standard)',
      icon: 'paper_plane_2',
      isFullWidth: true,
      grid: {
        colStart: '1',
        colEnd: '6',
        rowStart: '2',
        rowEnd: '4',
      },
    },
    {
      id: 5,
      title: 'Card Types',
      description:
        'We offer Flat, 2 Panel, 3 Panel, Postcard and Big Card (11x14)',
      icon: 'paper_plane_2',
      isFullWidth: true,
      grid: {
        colStart: '6',
        colEnd: '11',
        rowStart: '2',
        rowEnd: '4',
      },
    },
    {
      id: 6,
      title: 'Add your personal touch',
      description:
        'Choose the level of customization you add to your card. A little, or a lot, the sky is the limit. ',
      icon: 'personal_touch',
      details: PersonalTouchExpandData,
      isFullWidth: true,
      grid: {
        colStart: '1',
        colEnd: '11',
        rowStart: '4',
        rowEnd: '6',
      },
    },
  ] as BentoCardData[]
  return cardData
}
