import React from 'react'
import { SVGProps } from './types'

export const Cards15: React.FC<SVGProps> = ({
  size = 24,
  color = '#000',
  onClick,
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 71 84"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick && (() => onClick())}
    >
      <path
        d="M68.9201 1.25H19.8801V64.18H68.9201V1.25Z"
        fill="white"
        stroke="#231F20"
        strokeWidth={2.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M62.9101 7.25H13.8701V70.18H62.9101V7.25Z"
        fill="white"
        stroke="#231F20"
        strokeWidth={2.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M56.9101 13.2598H7.87012V76.1898H56.9101V13.2598Z"
        fill="white"
        stroke="#231F20"
        strokeWidth={2.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M50.9001 19.2598H1.86011V82.1898H50.9001V19.2598Z"
        fill="white"
        stroke="#231F20"
        strokeWidth={2.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.66 40.8799H21.97V56.3899H18.63V44.3099L15.95 44.9699L15.25 42.1999L19.67 40.8799H19.66Z"
        fill={color}
      />
      <path
        d="M26.4099 40.9897H35.8699V43.9597H29.3099L29.1299 46.6197C29.7499 46.4697 30.2999 46.3597 31.1499 46.3597C34.1899 46.3597 36.5399 47.8097 36.5399 51.3097C36.5399 54.5897 34.2299 56.6597 30.6899 56.6597C28.2299 56.6597 26.4199 55.7797 24.9299 54.3497L26.9799 51.9097C28.1499 52.9697 29.2899 53.5797 30.6499 53.5797C32.2299 53.5797 33.2199 52.8097 33.2199 51.4197C33.2199 50.0297 32.1399 49.3097 30.4899 49.3097C29.5199 49.3097 28.6399 49.5697 27.9199 49.8797L25.9599 48.5797L26.3999 40.9897H26.4099Z"
        fill={color}
      />
    </svg>
  )
}
