const lowShadow = '0 4px 5px 0 #d4d4d4'
const lowLightShadow = '0 4px 8px 0 rgba(0,0,0,.16)'
const lowTopDarkShadow = '0 0px 3px 0 #3e3e3e'
const lowTopLightShadow = '0 0px 3px 0 #d4d4d4'
const roundNotificationShadow = '0 10px 20px 0 rgba(0,0,0,.20)'
const buttonShadows = {
  pink: '0 1px 15px 0 rgba(239, 146, 251, 0.3)',
  blue: '0 2px 9px -4px rgb(23, 130, 234)',
  facebook: '0 3px 10px rgba(48, 58, 157, 0.2)',
}

type ButtonShadows = {
  pink: string
  blue: string
  facebook: string
}

export interface Shadows {
  lowShadow: string
  lowLightShadow: string
  lowTopDarkShadow: string
  lowTopLightShadow: string
  roundNotificationShadow: string
  buttonShadows: ButtonShadows
}

const shadows: Shadows = {
  lowShadow,
  lowLightShadow,
  lowTopDarkShadow,
  lowTopLightShadow,
  roundNotificationShadow,
  buttonShadows,
}

export default shadows
