import { BentoCardData } from 'src/marketing/components/BentoGrid/BentoCard'
import { gourmetFoodsExpandData } from './expands/gourmetFoods'
import { seasonalGiftsExpandData } from './expands/seasonalGifts'
import { giftCardsExpandData } from './expands/giftCards'

export const GiftBentoCards = (isMobile: boolean) => {
  const cardData = [
    {
      id: 7,
      title: 'Gourmet foods',
      description:
        'Choose from an array of delectable and perfectly packaged gifts. ',
      icon: 'candy',
      isFullWidth: true,
      details: gourmetFoodsExpandData,
      grid: {
        colStart: '3',
        colEnd: '4',
        rowStart: '4',
        rowEnd: '6',
      },
    },
    {
      id: 8,
      title: 'Premium gifts',
      description:
        'Create an experience and make memories with our curated high-end gifts.',
      icon: 'gift_2',
      isFullWidth: true,
      details: seasonalGiftsExpandData,
      grid: {
        colStart: '4',
        colEnd: '5',
        rowStart: '4',
        rowEnd: '6',
      },
    },
    {
      id: 9,
      title: 'Gift cards',
      description:
        'Not sure what to get them? We have you covered with gift cards for anyone!',
      icon: 'gift_card',
      isFullWidth: true,
      details: giftCardsExpandData,
      grid: {
        colStart: '5',
        colEnd: '6',
        rowStart: '4',
        rowEnd: '6',
      },
    },
  ] as BentoCardData[]
  return cardData
}
