import { Icon } from '@sendoutcards/quantum-design-ui'
import { motion, useMotionValue } from 'framer-motion'
import React, { useEffect, useState } from 'react'

type OverlayProps = {
  isOpen: boolean
  onClick: () => void
}

const OverlayStyle = {
  overlay: {
    zIndex: 120,
    position: 'fixed' as const,
    background: 'rgba(0, 0, 0, 0.8)',
    willChange: 'opacity',
    top: 0,
    bottom: 0,
    left: 0,
    width: '100%',
  },
}

const Overlay: React.FC<OverlayProps> = ({ isOpen, onClick }) => (
  <motion.div
    initial={false}
    animate={{ opacity: isOpen ? 1 : 0 }}
    transition={{ duration: 0.2 }}
    style={{ pointerEvents: isOpen ? 'auto' : 'none' }}
    css={OverlayStyle.overlay}
    onClick={onClick}
  >
    <motion.div
      layout="position"
      style={{
        position: 'fixed',
        top: '1rem',
        right: '1.5rem',
        zIndex: 122,
        cursor: 'pointer',
      }}
      onClick={onClick}
    >
      <Icon name="close" size="small" primaryColor="accent" />
    </motion.div>
  </motion.div>
)

type ExpandableItemProps = {
  closedState: React.ReactNode
  openState: React.ReactNode
  id?: string
  modalOptions?: {
    maxWidth: string
    maxHeight: string
    margin: string
    width?: string
  }
  isExpanded?: boolean
  handleOnClose?: () => void
  handleOnAnimationComplete?: (id?: string) => void
}

const openSpring = { type: 'spring', stiffness: 200, damping: 30 }
const closeSpring = { type: 'spring', stiffness: 300, damping: 35 }

export const ExpandableItem: React.FC<ExpandableItemProps> = ({
  closedState,
  openState,
  modalOptions,
  isExpanded,
  id,
  handleOnClose,
  handleOnAnimationComplete,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const isExternalOpen = isExpanded ? isExpanded : false
  const zIndex = useMotionValue(isOpen ? 2 : 0)

  const onLayoutAnimationComplete = () => {
    if (!isOpen) zIndex.set(0)
    handleOnAnimationComplete?.(id)
  }

  const styles = {
    fullHeight: {
      height: '100%',
    },
    wrapper: {
      display: 'block',
      width: '100%',
      height: '100%',
    },
    card: {
      width: '100%',
      height: '100%',
      position: isOpen ? ('fixed' as const) : ('relative' as const),
      marginLeft: '50%',
    },
    openState: {
      position: 'fixed' as const,
      top: '10vh',
      width: 'initial',
      height: 'initial',
      left: '50%',
      marginLeft: '0',
    },
    modalOptions: modalOptions,
  }

  const onClose = () => {
    handleOnClose?.()
    setIsOpen(false)
  }

  useEffect(() => {
    if (isExternalOpen) {
      setIsOpen(true)
      zIndex.set(121)
    } else {
      setIsOpen(false)
    }
  }, [isExternalOpen, zIndex])

  return (
    <div css={styles.wrapper}>
      <Overlay onClick={onClose} isOpen={isOpen} />
      <motion.div css={styles.fullHeight}>
        <motion.div
          layout={true}
          transition={isOpen ? openSpring : closeSpring}
          css={{ ...styles.card, ...(isOpen ? styles.openState : {}) }}
          onLayoutAnimationComplete={onLayoutAnimationComplete}
          style={{ zIndex }}
          onClick={onClose}
        >
          <motion.div
            css={{
              ...styles.fullHeight,
              ...styles.modalOptions,
              overflow: 'auto',
            }}
            onClick={e => {
              e.stopPropagation()
            }}
            style={{ translateX: '-50%' }}
          >
            <motion.div
              onClick={() => {
                if (isExpanded === undefined) {
                  setIsOpen(true)
                  zIndex.set(121)
                }
              }}
              css={{
                ...styles.fullHeight,
                opacity: isOpen ? '0' : '1',
                position: isOpen ? 'absolute' : 'relative',
              }}
            >
              {closedState}
            </motion.div>
            {isOpen && <motion.div layout="position">{openState}</motion.div>}
          </motion.div>
        </motion.div>
      </motion.div>
    </div>
  )
}
