import { Reducer } from 'redux'
import combineSectionReducers from 'combine-section-reducers'
import { StateType } from '@sendoutcards/core'

import Action from 'src/redux/action'
import { windowReducer } from './window'
// reducers
import route from './routes'
import cardCatalog from './catalog'
import helpmenu from './helpmenu'
import identifier from './identifier'
import invites from './invites'
import storefront from './storefront'
import normalizedData from './normalizeddata'
import promo from './promo'
import user from './user'

const reducers = {
  cardCatalog,
  helpmenu,
  identifier,
  invites,
  storefront,
  window: windowReducer,
  normalizedData,
  promo,
  user,
}

export type StateWithoutRoute = {
  [K in keyof typeof reducers]: ReturnType<typeof reducers[K]>
}

const combinedReducer: Reducer<
  StateWithoutRoute & { route: ReturnType<typeof route> },
  Action
> = combineSectionReducers({ route, ...reducers })

export interface State extends StateType<typeof combinedReducer> {}

export const appReducer = combinedReducer
