import { ContentMediaType } from 'src/home_page/components/ContentPop/ContentPop'
import { BentoContentExpandType } from 'src/marketing/components/BentoGrid/BentoCard'
import { CardTypeImageDiffData } from 'src/marketing/mockData/CardTypeImageDiffData'

const pTContentStream: ContentMediaType[] = [
  {
    content: {
      type: 'content',
      title: 'Add your personal touch',
      description:
        'Whether you are customizing a card for an invitation, branding for your business, or just to make something aesthetically pleasing to your recipient - we have you covered!',
    },
    width: '100%',
    height: 'auto',
  },
  {
    content: {
      type: 'content',
      title: '',
      description:
        'Our system allows you to completely take over the creative control and make your card uniquely yours. ',
    },
    width: '100%',
    height: 'auto',
  },
]
export const PersonalTouchExpandData: BentoContentExpandType = {
  bannerImages: [
    'https://soc-website-public.s3.us-west-2.amazonaws.com/static/soc-website/images/popups/Personal-touch-main-image.png',
  ],
  imageDiff: CardTypeImageDiffData[3],
  contentStream: pTContentStream,
  type: 'content',
}
