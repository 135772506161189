import React from 'react'
import { SVGProps } from './types'

export const X: React.FC<SVGProps> = ({
  size = 24,
  color = '#E7515D',
  onClick,
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M2.45711 1.2675C2.06658 0.876978 1.43342 0.876978 1.04289 1.2675C0.652369 1.65803 0.652369 2.29119 1.04289 2.68172L7.33586 8.97468L1.04289 15.2677C0.652369 15.6582 0.652369 16.2913 1.04289 16.6819C1.43342 17.0724 2.06658 17.0724 2.45711 16.6819L8.75008 10.3889L15.0429 16.6817C15.4334 17.0722 16.0666 17.0722 16.4571 16.6817C16.8476 16.2912 16.8476 15.658 16.4571 15.2675L10.1643 8.97468L16.4571 2.68187C16.8476 2.29134 16.8476 1.65818 16.4571 1.26765C16.0666 0.877129 15.4334 0.877129 15.0429 1.26765L8.75008 7.56047L2.45711 1.2675Z"
        fill={color}
      />
    </svg>
  )
}
