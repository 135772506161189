import React from 'react'
import { IconSizeType } from './PersonalTouch'

export const CustomCard: React.FC<IconSizeType> = ({
  width = 105,
  height = 104,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 105 104"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_764_7543)">
        <rect
          x={32.5}
          y={32}
          width={40}
          height={40}
          rx={8}
          fill="white"
          shapeRendering="crispEdges"
        />
        <g clipPath="url(#clip0_764_7543)">
          <path
            d="M61.2037 45.3136C61.2022 43.8099 60.1822 42.5056 58.727 42.1132C58.5783 42.073 58.4286 42.0376 58.2794 42H47.4574C47.4151 42.0136 47.3739 42.0313 47.3311 42.0402C45.9876 42.3063 45.0954 43.0857 44.6723 44.3896C44.5445 44.7825 44.7354 45.1607 45.0939 45.2708C45.4622 45.384 45.8186 45.1863 45.9485 44.796C46.2688 43.8376 46.9727 43.3299 47.9875 43.3299C51.2426 43.3294 54.4983 43.3289 57.7534 43.332C57.9408 43.332 58.1307 43.3544 58.3143 43.391C59.0542 43.5391 59.8743 44.231 59.8707 45.4759C59.8582 49.8241 59.8665 54.1728 59.8639 58.521C59.8639 58.7026 59.8582 58.8878 59.8201 59.0641C59.6135 60.0257 58.8355 60.6643 57.8338 60.6664C54.5265 60.6737 51.2186 60.6711 47.9113 60.6649C47.6937 60.6649 47.4657 60.632 47.2607 60.5616C46.1493 60.1812 45.7403 59.2332 45.8916 58.0233H46.5208C46.5234 58.0848 46.5229 58.1302 46.5281 58.1751C46.5704 58.5414 46.8537 58.7975 47.2106 58.7939C47.5638 58.7908 47.8596 58.5231 47.8711 58.1553C47.8868 57.6481 47.8857 57.1394 47.8695 56.6323C47.8586 56.2848 47.5852 56.0266 47.2424 55.9953C46.9455 55.9681 46.6523 56.181 46.5553 56.493C46.5386 56.5467 46.5208 56.6 46.502 56.6594C46.452 56.6594 46.4134 56.6594 46.3747 56.6594C45.7497 56.6589 45.1246 56.6563 44.4996 56.6584C44.1198 56.6594 43.8365 56.9151 43.7968 57.2819C43.7619 57.6106 44.0039 57.9184 44.3592 57.9951C44.4135 58.0066 44.4683 58.0144 44.5299 58.0254C44.5299 58.2539 44.5288 58.4683 44.5299 58.6822C44.5419 60.5094 46.0273 61.9948 47.8528 61.9963C51.193 61.999 54.5327 61.999 57.8729 61.9963C59.7016 61.9948 61.1985 60.5177 61.2017 58.689C61.2095 54.2297 61.2074 49.7709 61.2022 45.3115L61.2037 45.3136Z"
            fill="#E68C96"
          />
          <path
            d="M55.7035 50.3527C55.7015 48.9581 54.5823 47.83 53.2096 47.8389C51.8171 47.8483 50.7021 48.9622 50.7026 50.3443C50.7026 51.7233 51.8306 52.8497 53.2112 52.8513C54.5781 52.8524 55.7056 51.7223 55.7035 50.3527ZM52.0654 50.3574C52.0675 49.7292 52.5793 49.2221 53.2096 49.2241C53.8425 49.2262 54.3402 49.7297 54.3397 50.3673C54.3392 51.0028 53.8383 51.499 53.1997 51.4974C52.5673 51.4958 52.0633 50.9897 52.0654 50.3574Z"
            fill="#E68C96"
          />
          <path
            d="M44.5303 47.4162C44.5303 47.4308 44.5303 47.5012 44.5303 47.5722C44.5303 48.3856 44.5303 49.1984 44.5303 50.0118C44.5303 50.4298 44.8215 50.7433 45.2049 50.7402C45.5926 50.7365 45.8696 50.4282 45.8691 50.003C45.8681 49.1896 45.8686 48.3767 45.8681 47.5633C45.8681 47.4944 45.8681 47.4256 45.8681 47.3478H46.5109C46.5281 47.4063 46.5401 47.4501 46.5531 47.4934C46.6507 47.8169 46.9481 48.0339 47.2502 48.0021C47.6018 47.9645 47.8648 47.6969 47.8726 47.3395C47.883 46.8454 47.8851 46.3502 47.8731 45.8562C47.8648 45.5055 47.5909 45.229 47.2637 45.2055C46.9016 45.18 46.6032 45.4106 46.5364 45.7706C46.5239 45.8384 46.5223 45.9083 46.514 45.9918C46.4326 45.9918 46.3622 45.9918 46.2917 45.9918C45.6933 45.9918 45.0943 45.9876 44.4959 45.9918C44.2048 45.9939 43.9825 46.1217 43.8599 46.3941C43.7404 46.6596 43.7769 46.9205 43.983 47.112C44.1218 47.2409 44.3221 47.304 44.5308 47.4156L44.5303 47.4162Z"
            fill="#E68C96"
          />
          <path
            d="M46.2115 52.668H46.5178C46.5214 52.7358 46.523 52.7744 46.5261 52.8125C46.5585 53.1986 46.8559 53.4814 47.2159 53.4694C47.5842 53.4569 47.8696 53.1668 47.8748 52.7817C47.8816 52.2615 47.8827 51.7408 47.8748 51.2207C47.8675 50.772 47.4919 50.4725 47.0578 50.556C46.7333 50.618 46.5517 50.8727 46.5152 51.337C45.8646 51.337 45.228 51.3349 44.591 51.3381C44.2065 51.3396 43.9138 51.6474 43.9341 52.0314C43.9539 52.4024 44.1845 52.5949 44.5299 52.6836C44.5299 53.568 44.5299 54.4456 44.5299 55.3237C44.5299 55.7724 44.7955 56.0687 45.1988 56.0692C45.6026 56.0692 45.8693 55.7718 45.8687 55.3263C45.8677 54.5134 45.8677 53.7 45.8687 52.8871C45.8687 52.8182 45.875 52.7488 45.8792 52.669H46.2115V52.668Z"
            fill="#E68C96"
          />
          <path
            d="M50.001 54.8669C49.7124 55.1706 49.6978 55.5827 49.9843 55.8556C50.2728 56.1306 50.6949 56.0873 50.9845 55.7758C51.7822 54.9186 52.76 54.6149 53.9026 54.8747C54.4833 55.0067 54.9633 55.3172 55.3718 55.7476C55.5842 55.9709 55.8591 56.0231 56.12 55.9052C56.3819 55.7867 56.5139 55.5791 56.564 55.2692C56.4899 55.1231 56.445 54.9493 56.337 54.8361C55.2904 53.7379 54.0085 53.2865 52.5064 53.5104C51.5125 53.6585 50.6897 54.1406 50.0005 54.8664L50.001 54.8669Z"
            fill="#E68C96"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_764_7543"
          x={0.5}
          y={0}
          width={104}
          height={104}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation={16} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_764_7543"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_764_7543"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_764_7543">
          <rect
            width={17.4168}
            height={20}
            fill="white"
            transform="translate(43.7915 42)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
