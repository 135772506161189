import React, { useRef, useState } from 'react'
import { Div } from '@sendoutcards/quantum-design-ui'
import { Icon } from 'src/design_system/atoms/icons/components/Icon'

type VideoSource = {
  videoSrc: string
}

type IframeSource = {
  iframeSrc: string
  iframeTitle: string
}

export type VideoPlayerProps = {
  playbackOption: VideoSource | IframeSource
  thumbnailUrl: string
  width?: string
  height?: string
  btnColor: string
}

export const VideoPlayer: React.FC<VideoPlayerProps> = ({
  width,
  height,
  playbackOption,
  thumbnailUrl,
  btnColor,
}) => {
  const [isPlaying, setIsPlaying] = useState(false)
  const videoRef = useRef<HTMLVideoElement>(null)

  const handlePlay = (
    e: React.MouseEvent<HTMLDivElement | HTMLImageElement>,
  ) => {
    e.preventDefault()
    e.stopPropagation()
    if (videoRef.current) {
      videoRef.current
        .play()
        .then(() => setIsPlaying(true))
        .catch(error => console.error('Error playing video', error))
    }
  }

  const handlePause = () => {
    if (videoRef.current) {
      videoRef.current.pause()
      setIsPlaying(false)
    }
  }

  const handleVideoClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    if (videoRef.current) {
      if (videoRef.current.paused) {
        handlePlay(e as any)
      } else {
        handlePause()
      }
    }
  }

  const handleThumbnailClick = (e: React.MouseEvent<HTMLImageElement>) => {
    e.stopPropagation()
    if ('videoSrc' in playbackOption) {
      handlePlay(e)
    }
    setIsPlaying(true)
  }
  const playbackSrc =
    'videoSrc' in playbackOption ? (
      <video
        ref={videoRef}
        width="100%"
        height="100%"
        controls={true}
        style={{ borderRadius: 'inherit' }}
        preload="true"
      >
        <source src={playbackOption.videoSrc} type="video/mp4" />
      </video>
    ) : 'iframeSrc' in playbackOption && isPlaying ? (
      <iframe
        title={playbackOption.iframeTitle}
        src={`${playbackOption.iframeSrc}`}
        width="100%"
        height="100%"
        frameBorder="0"
        allow="fullscreen"
        allowFullScreen={true}
        style={{ borderRadius: 'inherit' }}
      />
    ) : null

  return (
    <div
      onClick={handleVideoClick}
      style={{
        position: 'relative',
        width: width,
        height: height,
        borderRadius: '30px',
      }}
    >
      {playbackSrc}
      {!isPlaying && (
        <Div width="100%" height="100%" position="absolute" top="0px">
          <img
            src={thumbnailUrl}
            alt="Video Thumbnail"
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              cursor: 'pointer',
              borderRadius: '30px',
            }}
            onClick={handleThumbnailClick}
          />
          <div
            onClick={handleThumbnailClick}
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%) rotate(-3deg)',
              cursor: 'pointer',
            }}
          >
            <Icon name="play_button" size={200} color={btnColor} />
          </div>
        </Div>
      )}
    </div>
  )
}
