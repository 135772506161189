import React from 'react'

import ReactCompareImage from 'react-compare-image'

export type DiffImageType = { image: string; alt: string }

export type ImageDiffSliderProps = {
  leftImage: DiffImageType
  rightImage: DiffImageType
}

export const ImageDiffSlider: React.FC<ImageDiffSliderProps> = ({
  leftImage,
  rightImage,
}) => {
  return (
    <ReactCompareImage
      leftImage={leftImage.image}
      leftImageAlt={leftImage.alt}
      rightImage={rightImage.image}
      rightImageAlt={rightImage.alt}
    />
  )
}
