import { Operation } from '../../../__generated__/globalTypes'

import {
  PlanFragment,
  planFragmentString,
} from '../../fragments/__generated__/PlanFragment'

import { amountString } from '../../fragments/__generated__/Amount'

import getPlansRawString from '../GetPlans.graphql'

const getPlansString = [
  getPlansRawString,
  planFragmentString,
  amountString,
].join('\n\n')

export type GetPlansData = {
  plans: PlanFragment[]
}

export type GetPlans = Operation<GetPlansData>

export const GetPlans = (): GetPlans => ({
  query: getPlansString,
  variables: undefined,
})
