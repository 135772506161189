import { Route } from 'src/routes/Route'
import {
  dismissedCatalogCard,
  loadedSendableCard,
} from 'src/redux/actions/catalog'
import Future from 'src/utils/Future'
import { compact } from 'src/helpers'
import { Task } from 'src/app/types'
import { GetSendableCard } from 'src/graphql'

const getSendableCardTask = (id: string): Task =>
  Task(GetSendableCard({ id }), result =>
    loadedSendableCard(id, result.select('sendableCard')),
  )

export const SendableCardRoute = Route({
  path: '/cards/:cardId',
  init: (cardId: string) => ({ cardId }),
  fromPath: ({ cardId }) => (cardId ? { cardId } : undefined),
  doNotResetWindowScroll: true,
  reducer: (route, action) =>
    action.type === dismissedCatalogCard.type ? undefined : route,
  tasks: (route, { cardCatalog: { sendableCards } }) =>
    compact(
      (sendableCards.get(route.cardId) ?? Future()).isUnresolved &&
        getSendableCardTask(route.cardId),
    ),
})
export type SendableCardRoute = Route<typeof SendableCardRoute>
