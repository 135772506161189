import { REHYDRATE } from 'redux-persist'

import { Action } from 'src/redux/action'
import { BaseRoute } from 'src/routes/BaseRoute'
import { openRoute } from 'src/redux/actions/routes'
import { StateWithoutRoute } from 'src/redux/reducers'

export default (
  route: BaseRoute = BaseRoute(),
  action: Action,
  state: StateWithoutRoute,
): BaseRoute => {
  switch (action.type) {
    case openRoute.type:
      return action.route
    case REHYDRATE:
      return route
    default:
      return BaseRoute.reducer(route, action, state) || BaseRoute()
  }
}
