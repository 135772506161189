import { Route, RouteMeta } from './Route'

export type RouteType = {
  path: string
  subroute?: RouteType
}

export const findRoute = <T extends RouteMeta<any, any, any>>(
  route: RouteType,
  subroute: T,
): Route<T> | undefined =>
  route.path === subroute.path
    ? ((route as unknown) as Route<T>)
    : route.subroute && findRoute(route.subroute, subroute)
