import deepmerge from 'deepmerge'
import mapValues from 'lodash/mapValues'
import { NormalizedData } from 'src/graphql'

const MAX_DEPTH = 32

export const gatherNormalizedData = (
  value: any,
  depth: number = 0,
): Partial<NormalizedData> => {
  if (depth > MAX_DEPTH) {
    throw Error(
      'Reached max recursion depth when gathering the normalized data',
    )
  } else if (typeof value != 'object' || value == null) {
    return {}
  } else if (Array.isArray(value)) {
    return deepmerge.all(
      value.map(v => gatherNormalizedData(v, depth + 1)),
      deepmergeOptions,
    )
  } else {
    return deepmerge.all(
      [
        isNormalizable(value)
          ? {
              [value.__typename]: {
                [value.id]: normalizeObject(value, depth + 1),
              },
            }
          : {},
        ...Object.values(value).map(v => gatherNormalizedData(v, depth + 1)),
      ],
      deepmergeOptions,
    )
  }
}

const normalizeObject = <T extends object>(object: T, depth: number) =>
  mapValues(object, value => normalizeValue(value, depth))

const normalizeValue = (value: any, depth: number): any => {
  if (depth > MAX_DEPTH) {
    console.warn('Reached max recursion depth value normalizing value')
    return undefined
  } else if (typeof value != 'object' || value == null) {
    return value
  } else if (Array.isArray(value)) {
    return value.map(v => normalizeValue(v, depth + 1))
  } else if (isNormalizable(value)) {
    return normalize(value)
  } else {
    return normalizeObject(value, depth + 1)
  }
}

export const deepmergeOptions: deepmerge.Options = {
  arrayMerge: (destination, source) => source,
}

const denormalizeObject = (
  object: any,
  data: NormalizedData,
  depth: number,
): any => mapValues(object, value => denormalizeValue(value, data, depth))

const denormalizeValue = (
  value: any,
  data: NormalizedData,
  depth: number,
): any => {
  if (depth > MAX_DEPTH) {
    console.warn('Reached max recursion depth value denormalizing value')
    return undefined
  } else if (typeof value != 'object' || value == null) {
    return value
  } else if (Array.isArray(value)) {
    return value.map(v => denormalizeValue(v, data, depth + 1))
  } else if (isNormalized(value)) {
    return denormalize(data, depth + 1)(value)
  } else {
    return denormalizeObject(value, data, depth + 1)
  }
}

type Normalizable<K extends keyof NormalizedData> = {
  __typename: K
  id: string
}

const isNormalizable = (value: any): value is Normalizable<any> =>
  value && typeof value.__typename == 'string' && typeof value.id == 'string'

type KeyOf<T> = T extends Normalizable<infer K> ? K : never

export type Normalized<T extends Normalizable<any>> = {
  __typename: KeyOf<T>
  id: string
  isNormalized: true
}

const isNormalized = (value: any): value is Normalized<any> =>
  value &&
  typeof value.__typename == 'string' &&
  typeof value.id == 'string' &&
  value.isNormalized === true

export const normalize = <T extends Normalizable<any>>(
  value: T,
): Normalized<T> => ({
  __typename: value.__typename,
  id: value.id,
  isNormalized: true,
})

export const denormalize: <T extends Normalizable<any>>(
  data: NormalizedData,
  depth?: number,
) => (normalized: Normalized<T>) => T = (data, depth = 0) => normalized =>
  denormalizeObject(data[normalized.__typename][normalized.id], data, depth)
