import React from 'react'
import { SVGProps } from './types'

export const Cards30: React.FC<SVGProps> = ({
  size = 24,
  color = '#000',
  onClick,
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 70 84"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick && (() => onClick())}
    >
      <path
        d="M68.4699 1.25H19.4299V64.18H68.4699V1.25Z"
        fill="white"
        stroke="#231F20"
        strokeWidth={2.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M62.4699 7.25H13.4299V70.18H62.4699V7.25Z"
        fill="white"
        stroke="#231F20"
        strokeWidth={2.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M56.4599 13.2598H7.41992V76.1898H56.4599V13.2598Z"
        fill="white"
        stroke="#231F20"
        strokeWidth={2.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M50.4599 19.2598H1.41992V82.1898H50.4599V19.2598Z"
        fill="white"
        stroke="#231F20"
        strokeWidth={2.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.9999 43.9V41H23.4099V43.55L19.5199 47.27C21.6299 47.62 23.6599 48.72 23.6599 51.6C23.6599 54.48 21.5499 56.66 18.1199 56.66C15.3499 56.66 13.4299 55.56 12.1099 54L14.4399 51.78C15.4999 52.97 16.5999 53.63 18.1599 53.63C19.4399 53.63 20.3399 52.9 20.3399 51.74C20.3399 50.46 19.2599 49.76 17.3299 49.76H15.9199L15.3899 47.6L19.0599 43.9H13.0099H12.9999Z"
        fill={color}
      />
      <path
        d="M32.9299 56.66C28.8099 56.66 26.1099 53.21 26.1099 48.72C26.1099 44.23 28.8599 40.73 32.9799 40.73C37.0999 40.73 39.7999 44.18 39.7999 48.67C39.7999 53.16 37.0499 56.66 32.9399 56.66H32.9299ZM32.9299 43.79C30.8799 43.79 29.5899 45.84 29.5899 48.67C29.5899 51.5 30.9299 53.6 32.9799 53.6C35.0299 53.6 36.3199 51.53 36.3199 48.72C36.3199 45.91 34.9799 43.79 32.9299 43.79Z"
        fill={color}
      />
    </svg>
  )
}
