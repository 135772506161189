import { DynamicDiffImageType } from '../components/DynamicImageDiff/DynamicImageDiff'

export const CardTypeImageDiffData: DynamicDiffImageType[] = [
  {
    // [0] Catalog Card
    leftImage: {
      image:
        'https://soc-website-public.s3.us-west-2.amazonaws.com/static/soc-website/images/popups/Catalog-cards-swipe-image01.png',
      alt: 'pink_card',
    },
    rightImage: {
      image:
        'https://soc-website-public.s3.us-west-2.amazonaws.com/static/soc-website/images/popups/Catalog-cards-swipe-image02.png',
      alt: 'blue_card',
    },
  },
  {
    // [1] Photo Drop Card
    leftImage: {
      image:
        'https://soc-website-public.s3.us-west-2.amazonaws.com/static/soc-website/images/popups/Photo-drop-swipe-image02.png',
      alt: 'photo_drop_final',
    },
    rightImage: {
      image:
        'https://soc-website-public.s3.us-west-2.amazonaws.com/static/soc-website/images/popups/Photo-drop-swipe-image01.png',
      alt: 'photo_drop_blank',
    },
  },
  {
    // [2] Build Your Own Card
    leftImage: {
      image:
        'https://soc-website-public.s3.us-west-2.amazonaws.com/static/soc-website/images/popups/BYO-swipe-image02.png',
      alt: 'byo_final',
    },
    rightImage: {
      image:
        'https://soc-website-public.s3.us-west-2.amazonaws.com/static/soc-website/images/popups/BYO-swipe-image01.png',
      alt: 'byo_empty',
    },
  },
  {
    // [3] Personal Touch
    leftImage: {
      image:
        'https://soc-website-public.s3.us-west-2.amazonaws.com/static/soc-website/images/popups/Personal-touch-swipe-image02.png',
      alt: 'personal_touch_final',
    },
    rightImage: {
      image:
        'https://soc-website-public.s3.us-west-2.amazonaws.com/static/soc-website/images/popups/Personal-touch-swipe-image01.png',
      alt: 'personal_touch_empty',
    },
  },
  {
    // [4] Personal Digital Signature & Handwriting
    leftImage: {
      image:
        'https://soc-website-public.s3.us-west-2.amazonaws.com/static/soc-website/images/popups/Sig-Font-swipe-image02.png',
      alt: 'personal_digital_sig_final',
    },
    rightImage: {
      image:
        'https://soc-website-public.s3.us-west-2.amazonaws.com/static/soc-website/images/popups/Sig-Font-swipe-image01.png',
      alt: 'personal_digital_sig_empty',
    },
  },
  {
    // [4] Personal Digital Signature & Handwriting
    leftImage: {
      image:
        'https://soc-website-public.s3.us-west-2.amazonaws.com/static/soc-website/images/popups/Personal-touch-swipe-image02.png',
      alt: 'personal_digital_sig_final',
    },
    rightImage: {
      image:
        'https://soc-website-public.s3.us-west-2.amazonaws.com/static/soc-website/images/popups/Personal-touch-swipe-image01.png',
      alt: 'personal_digital_sig_empty',
    },
  },
]
