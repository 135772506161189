import React from 'react'
import Button from '@material-ui/core/Button'

import { Dialog } from 'src/chrome'
import { VideoObject } from 'src/marketing/components/VideoRibbon/VideoRibbon'

import styles from './videoModal.module.scss'
import { DialogActions } from '@material-ui/core'
import DialogTitle from '@material-ui/core/DialogTitle'

interface VideoModalProps {
  video: VideoObject
  onClose: () => void
}

const VideoModal: React.FC<VideoModalProps> = props => {
  const { onClose, video } = props

  return (
    <div className={styles.player}>
      <Button variant={'contained'}>Dialog</Button>
      <Dialog open={true} onClose={onClose}>
        <DialogTitle>{video.title}</DialogTitle>
        <div>
          <iframe title={'embed'} src={video.src} width="640" height="360" />
        </div>
        <DialogActions>
          <Button key="Done" color="primary" onClick={() => onClose()}>
            Done
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default VideoModal
