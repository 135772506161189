import React from 'react'
import { ImageContentType } from '../ContentPop'
import { Flex, Image } from '@sendoutcards/quantum-design-ui'

type ImageContentProps = Omit<ImageContentType, 'type'> & {
  width: string
  height: string
}

export const ImageContent: React.FC<ImageContentProps> = ({
  width,
  height,
  image,
}) => {
  return (
    <Flex width={width} height={height}>
      <Image image={{ url: image }} isActive={true} width={width} height={height} />
    </Flex>
  )
}
