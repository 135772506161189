import React from 'react'

import { Icon } from 'src/chrome'

import { VideoObject } from '../VideoRibbon/VideoRibbon'

import styles from './videoTile.module.scss'

interface VideoTileProps {
  onPlay: (video: VideoObject) => void
  style?: React.CSSProperties
  video: VideoObject
}

const VideoTile: React.FC<VideoTileProps> = props => {
  const { video, onPlay, style } = props

  return (
    <div className={styles.videoTile} onClick={() => onPlay(video)}>
      <div className={styles.videoThumbnail} style={style}>
        <div className={styles.thumbnailInner}>
          <div
            className={styles.thumbnail}
            style={{ backgroundImage: `url(${video.poster})` }}
          />
          <div className={styles.duration}>{video.duration}</div>
          <div className={styles.play}>
            <Icon
              style={{ marginLeft: 4 }}
              icon={'PLAY'}
              viewBox={'0 0 24 24'}
              color={'#FF5689'}
              size={28}
            />
          </div>
        </div>
      </div>
      <h4>{video.title}</h4>
    </div>
  )
}

export default VideoTile
