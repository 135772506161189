import { BentoCardData } from 'src/marketing/components/BentoGrid/BentoCard'

export const SystemBentoGridCards = (
  isMobile: boolean,
  isSystemSectionMobileBreakpoint: boolean,
) => {
  const cardData = [
    {
      id: 12,
      title: 'Multiple card campaigns',
      description:
        'Select the amount of contacts you want us to deliver and we take care of the rest.',
      icon: 'multi_card_campaign',
      isFullWidth: true,
      grid: {
        colStart: '1',
        colEnd: '2',
        rowStart: '2',
        rowEnd: '3',
      },
    },
    {
      id: 13,
      title: 'Create groups to organize your contacts',
      description:
        'Create endless groups of contacts to keep your card sending organized. ',
      icon: 'multi_card_campaign',
      isFullWidth: true,
      grid: {
        colStart: '2',
        colEnd: '3',
        rowStart: '2',
        rowEnd: '3',
      },
    },
  ] as BentoCardData[]
  return cardData
}

export const CustomComponentSystemBentoCards = (
  isMobile: boolean,
  isSystemSectionMobileBreakpoint: boolean,
) => {
  const cardData = [
    {
      id: 14,
      title: 'Birthday reminder',
      description:
        'Be the person who always remembers to celebrate upcoming birthdays. ',
      icon: 'multi_card_campaign',
      isFullWidth: true,
      grid: {
        colStart: '2',
        colEnd: '3',
        rowStart: '1',
        rowEnd: '2',
      },
    },
    {
      id: 15,
      title: 'Anniversary reminder',
      description:
        'Work, personal, or relationship anniversary reminders to celebrate others. ',
      icon: 'multi_card_campaign',
      isFullWidth: true,
      grid: {
        colStart: '2',
        colEnd: '3',
        rowStart: '2',
        rowEnd: '3',
      },
    },
  ] as BentoCardData[]
  return cardData
}

export const SystemBentoCards: BentoCardData[] = [
  ...SystemBentoGridCards(false, false),
  ...CustomComponentSystemBentoCards(false, false),
]
