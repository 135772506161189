import React from 'react'
import { EffectCards, Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Image } from '@sendoutcards/quantum-design-ui'

/*tslint:disable:no-import-side-effect*/
import 'swiper/swiper-bundle.css'
/*tslint:disable:no-import-side-effect*/
import './styles.css'

export type CardThumbnailType = {
  image: string
  isHorizontal: boolean
}

type CardStackCarouselProps = {
  initialSlide: number
  cardThumbnails: CardThumbnailType[]
}

export const CardStackCarousel: React.FC<CardStackCarouselProps> = ({
  initialSlide,
  cardThumbnails,
}) => {
  return (
    <div className="cardStackSwiper">
      <Swiper
        effect={'cards'}
        navigation={true}
        modules={[EffectCards, Navigation]}
        grabCursor={true}
        className="cardStackSwiper"
        autoplay={{
          delay: 5000,
          disableOnInteraction: true,
          pauseOnMouseEnter: true,
        }}
        initialSlide={initialSlide}
      >
        {cardThumbnails.map((thumbnail: CardThumbnailType, index: number) => {
          return (
            <SwiperSlide key={index}>
              <Image
                image={{ url: thumbnail.image }}
                isActive={true}
                width="100%"
                height="100%"
                borderRadius="none"
              />
            </SwiperSlide>
          )
        })}
      </Swiper>
    </div>
  )
}
