import { FC } from 'react'
import { SVGProps } from './types'

export const ScheduleCard: FC<SVGProps> = ({ size, color, onClick }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 64 90"
      width={size}
      height={size}
      onClick={onClick}
    >
      <path
        fill={color}
        d="M48.854 51.928c-.15-.105-.15-.135-.15-.255v-9.567c0-2.729-1.709-4.753-4.333-5.143a4.66 4.66 0 00-.645-.045c-.21-1.664-.87-3.629-3.389-3.794-.885-.06-1.694.165-2.384.675-.99.72-1.485 1.74-1.53 3.104H25.972v-.21a3.66 3.66 0 00-1.155-2.609c-.614-.6-1.364-.84-2.024-1.005h-.915l-.224.045c-1.845.315-3.12 1.905-3.09 3.824-2.729.075-4.873 2.234-4.873 4.949a6111.18 6111.18 0 000 23.452c0 .704.165 1.454.465 2.084.885 1.89 2.52 2.879 4.693 2.879h12.896c.315-.015.51.075.765.345 2.549 2.624 5.548 3.944 8.952 3.944.645 0 1.29-.045 1.964-.135 5.578-.78 10.017-5.383 10.587-10.932.48-4.723-1.26-8.622-5.174-11.576l.015-.03zm-27.5-12.506v-2.564c0-.6.344-.974.884-.974.555 0 .915.374.915.974v2.744h-1.814v-.18h.015zm17.889-.39c0-.854 0-1.694.015-2.549 0-.33.404-.584.764-.6.405 0 .78.225.885.585.015.075.045.165.045.24v2.864h-1.71v-.555.015zm2.369 13.361h.06c2.534 0 4.948 1.005 6.793 2.85 1.859 1.858 2.879 4.288 2.864 6.867-.03 5.338-4.364 9.687-9.687 9.687h-.015c-5.338 0-9.672-4.364-9.687-9.702 0-5.308 4.334-9.657 9.687-9.702h-.015zM16.48 46.965H45.9v3.374c-5.383-1.68-9.911-.525-13.48 3.434-3.554 3.943-4.244 8.562-2.07 13.72H18.895c-1.08 0-1.754-.39-2.159-1.23a2.397 2.397 0 01-.24-.99c-.015-5.158 0-10.316 0-15.474v-2.85l-.015.016zm3.704-4.559h4.154c1.11 0 1.634-.524 1.634-1.65v-1.004h10.451v1.05c0 1.11.51 1.604 1.62 1.604h4.109c1.05 0 1.59-.54 1.604-1.604v-1.08c1.095.06 1.83.63 2.084 1.635.06.255.075.54.075.854v1.905h-29.45V41.94c.015-.674.27-1.274.72-1.664.36-.315.84-.51 1.364-.54v1.05c0 1.095.525 1.62 1.62 1.634l.015-.015z"
      />
      <path
        fill={color}
        d="M40.847 64.314c.825.015 1.68.03 2.534.03.855 0 1.68 0 2.534-.03.3 0 .585-.135.81-.36.255-.255.39-.63.375-1.02a1.337 1.337 0 00-1.23-1.319c-.314-.03-.614-.03-.93-.03h-2.518v-4.588c0-.885-.54-1.5-1.35-1.515-.375 0-.72.12-.99.375-.285.27-.434.66-.45 1.095v5.938c0 .81.51 1.424 1.215 1.439v-.015z"
      />
      <path
        fill={color}
        d="M61.99 11.742h-6.838V1.53A1.504 1.504 0 0053.682 0c-.104 0-.21 0-.314.03L1.71 10.362c-.705.135-1.2.78-1.2 1.5V88.5c0 .825.675 1.5 1.5 1.5h59.98c.825 0 1.5-.675 1.5-1.5V13.24c0-.825-.675-1.5-1.5-1.5zm-9.837-8.383v8.383H10.167l41.986-8.383zm8.337 83.642H3.51v-72.26h56.98V87z"
      />
    </svg>
  )
}
