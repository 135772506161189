import { Maybe, Operation } from '../../../__generated__/globalTypes'

import validateEmailString from '../ValidateEmail.graphql'

export type ValidateEmailData = {
  validateEmail: {
    isValid: boolean
    errorMsg: Maybe<string>
  }
}

export type ValidateEmailVariables = {
  email: string
}

export type ValidateEmail = Operation<ValidateEmailData, ValidateEmailVariables>

export const ValidateEmail = ({
  email,
}: ValidateEmailVariables): ValidateEmail => ({
  query: validateEmailString,
  variables: {
    email,
  },
})
