const green = 'rgb(85, 249, 168)'
const brightGreen = '#1DE9B6'
const pink = '#ff5689'
const pink2 = '#e841b3'
const pink2Opacity = 'rgba(232, 65, 179, .5)'
const purple = '#e687ff'
const blue = '#02c3e2'
const purple2 = '#CC396E'
const purple3 = '#A06AFF'

export interface Colors {
  pink: string
  purple: string
  purple2: string
  purple3: string
  green: string
  success: string
  mainText: string
  secondaryText: string
  lightText: string
  white: string
  primary: string
  secondary: string
  orange: string
  blue: string
  pink2: string
  pink2Opacity: string
  selected: string
  brightGreen: string
  selectedFont: string
  faintGrey: string
  lightGrey: string
  almostWhite: string
  heading1: string
  heading2: string
}

export type ColorChoices = keyof Colors

const colors: Colors = {
  pink,
  purple,
  purple2,
  purple3,
  green,
  success: green,
  mainText: 'rgba(0, 0, 0, 0.8)',
  secondaryText: 'rgba(111, 131, 148, 1)',
  lightText: 'rgba(0, 0, 0, 0.5)',
  white: '#fff',
  primary: pink,
  secondary: purple,
  orange: '#dd2476',
  blue,
  pink2,
  pink2Opacity,
  selected: green,
  brightGreen,
  selectedFont: '#fff',
  faintGrey: '#f0f0f0',
  lightGrey: 'rgb(220, 220, 220)',
  almostWhite: 'rgb(245, 245, 245)',
  heading1: '#323232',
  heading2: '#656D79',
}

export default colors
