import { Maybe, Operation } from '../../../__generated__/globalTypes'

import {
  ImageFragment,
  imageFragmentString,
} from '../../fragments/__generated__/ImageFragment'

import getImagesRawString from '../GetImages.graphql'

const getImagesString = [getImagesRawString, imageFragmentString].join('\n\n')

export type GetImagesData = {
  images: ImageFragment[]
}

export type GetImagesVariables = {
  offset?: Maybe<number>
  limit?: Maybe<number>
}

export type GetImages = Operation<GetImagesData, GetImagesVariables>

export const GetImages = ({
  offset,
  limit,
}: GetImagesVariables = {}): GetImages => ({
  query: getImagesString,
  variables: {
    offset,
    limit,
  },
})
