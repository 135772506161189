const dollarsAndCents = (amount: number) => {
  const amountStr = amount.toString()
  const paddedAmount =
    amountStr.length < 3 ? amountStr.padStart(3, '0') : amountStr
  return {
    dollars: paddedAmount.slice(0, -2),
    cents: paddedAmount.slice(-2),
  }
}

export default dollarsAndCents
