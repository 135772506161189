import { CurrencyType } from '../../../__generated__/globalTypes'

import priceRawString from '../PriceFragment.graphql'

export const priceString = priceRawString

export type Price = {
  asString: string
  currency: CurrencyType
  amount: number
}

export const isPrice = (fragment: any): fragment is Price =>
  fragment &&
  typeof fragment.asString === 'string' &&
  (fragment.currency === 'POINT' ||
    fragment.currency === 'EXPENSE' ||
    fragment.currency === 'FREE' ||
    fragment.currency === 'USD' ||
    fragment.currency === 'CREDIT' ||
    fragment.currency === 'CARDTOKEN') &&
  typeof fragment.amount === 'number'
