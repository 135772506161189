import React from 'react'
import { SVGProps } from './types'

export const EmptyGift: React.FC<SVGProps> = ({ size, color, onClick }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 66 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick && (() => onClick())}
    >
      <g clipPath="url(#clip0_767_14295)">
        <path
          d="M5.43893 22.2861C5.16218 22.2861 4.88543 22.1673 4.68851 21.9673C4.4916 21.7674 4.37451 21.4864 4.37451 21.2054C4.37451 20.9244 4.4916 20.638 4.68851 20.4381C4.73641 20.3894 4.78963 20.3462 4.84818 20.303C4.90672 20.2652 4.97059 20.2327 5.03445 20.2057C5.09832 20.1787 5.1675 20.1571 5.23137 20.1409C5.36974 20.1139 5.51344 20.1139 5.64649 20.1409C5.71568 20.1571 5.78487 20.1787 5.84873 20.2057C5.9126 20.2327 5.97646 20.2652 6.02968 20.303C6.08822 20.3462 6.14677 20.3894 6.19467 20.4381C6.39158 20.638 6.50335 20.919 6.50335 21.2054C6.50335 21.4918 6.39158 21.7674 6.19467 21.9673C5.99775 22.1673 5.721 22.2861 5.43893 22.2861Z"
          fill={color}
        />
        <path
          d="M48.6236 64H16.4715C14.8522 64 13.5337 62.6626 13.5337 61.0172V35.7414C13.5337 35.1443 14.01 34.6606 14.5981 34.6606H50.4969C51.085 34.6606 51.5613 35.1443 51.5613 35.7414V61.0172C51.5613 62.6612 50.2441 64 48.6236 64ZM15.6625 36.8221V61.0172C15.6625 61.4697 16.0258 61.8385 16.4715 61.8385H48.6249C49.0706 61.8385 49.4338 61.4697 49.4338 61.0172V36.8221H15.6625Z"
          fill={color}
        />
        <path
          d="M59.9357 35.5252C59.4195 35.5252 58.9112 35.3861 58.4548 35.1145L41.7262 25.1555L24.9989 15.1979C23.6018 14.3657 23.1321 12.5352 23.9504 11.1167L26.5715 6.57899C26.968 5.89137 27.6054 5.40234 28.3637 5.20105C29.1221 4.99976 29.9138 5.11054 30.591 5.51446L64.047 25.4311C64.7243 25.8337 65.2059 26.4808 65.4042 27.2508C65.6024 28.0208 65.492 28.8246 65.0955 29.5123L62.4743 34.05C62.0779 34.7376 61.4405 35.2267 60.6821 35.428C60.4347 35.4928 60.1845 35.5252 59.9357 35.5252ZM42.8026 23.2913L59.5312 33.2503C59.7188 33.361 59.937 33.3908 60.1446 33.3367C60.3535 33.2813 60.5291 33.1476 60.6382 32.9585L63.2594 28.4207C63.3685 28.2316 63.3991 28.01 63.3445 27.7979C63.29 27.5859 63.1569 27.4075 62.9706 27.2968L29.5133 7.37874C29.327 7.26796 29.1102 7.23824 28.8999 7.29228C28.6911 7.34766 28.5154 7.48276 28.4063 7.67189L25.7852 12.2096C25.5603 12.6001 25.6894 13.1039 26.0739 13.3336L42.8012 23.2913H42.8026Z"
          fill={color}
        />
        <path
          d="M58.1329 20.6137C54.3968 20.6137 47.2399 17.7552 46.3844 17.408C46.0531 17.2742 45.8109 16.9797 45.7378 16.6258C45.6646 16.2719 45.771 15.9031 46.0212 15.6464C46.5773 15.0736 51.5495 10.04 55.078 9.57938C58.8527 9.0863 62.7112 10.9857 63.8661 13.901C64.3837 15.2073 64.2946 16.5745 63.6147 17.7511C62.7418 19.2628 61.0068 20.2909 58.8527 20.5718C58.6305 20.6002 58.3897 20.6137 58.1316 20.6137H58.1329ZM48.7368 16.0044C51.6879 17.1013 56.5562 18.6914 58.584 18.4266C60.0568 18.2347 61.2517 17.5728 61.7799 16.6569C62.1285 16.053 62.1657 15.3978 61.893 14.7061C61.1093 12.7284 58.1129 11.3626 55.3508 11.7233C53.4814 11.9665 50.5622 14.3252 48.7368 16.0044Z"
          fill={color}
        />
        <path
          d="M46.7795 17.485C46.5387 17.485 46.3018 17.4026 46.1102 17.2445C45.3452 16.6163 38.5968 11.0086 37.3022 7.74075C36.4919 5.6968 36.5278 3.6569 37.4006 2.14522C38.0805 0.968567 39.2115 0.221506 40.5872 0.0418336C43.6554 -0.35804 47.1733 2.13441 48.593 5.71841C49.9209 9.0687 48.0222 15.9341 47.8013 16.7055C47.7015 17.0527 47.4381 17.3269 47.0975 17.435C46.9924 17.4687 46.8859 17.485 46.7795 17.485ZM40.8587 2.18575C40.1309 2.28031 39.5854 2.63426 39.2368 3.23812C38.7085 4.15404 38.7232 5.53604 39.2767 6.93425C40.0391 8.86066 43.7845 12.3974 46.1675 14.4751C46.7196 12.038 47.321 8.30003 46.6172 6.52492C45.578 3.90142 42.9436 1.91016 40.8573 2.18575H40.8587Z"
          fill={color}
        />
        <path
          d="M33.6127 35.7427H31.4839V62.9206H33.6127V35.7427Z"
          fill={color}
        />
        <path
          d="M45.8596 15.8556L41.3452 23.6744L43.1817 24.7676L47.6961 16.9487L45.8596 15.8556Z"
          fill={color}
        />
        <path
          d="M14.0554 22.6834L11.9425 22.4213C11.9664 22.2065 12.4947 17.0379 7.68082 15.2911C1.09208 12.9 2.52239 7.89886 2.53702 7.84888L4.57538 8.47165C4.4889 8.78777 3.85557 11.6085 8.39664 13.2566C13.5444 15.125 14.3494 20.2409 14.0554 22.6847V22.6834Z"
          fill={color}
        />
        <path
          d="M29.5585 29.5772L27.4457 29.3151C27.4696 29.0989 27.9992 23.9317 23.184 22.1849C16.5939 19.7938 18.0256 14.7927 18.0402 14.7427L20.0786 15.3655C19.9921 15.6816 19.3588 18.5023 23.8998 20.1504C29.049 22.0174 29.8526 27.1333 29.5585 29.5785V29.5772Z"
          fill={color}
        />
        <path
          d="M16.735 33.8474C16.6246 33.6624 13.917 29.253 9.06058 30.8754C2.41462 33.0963 0.518627 28.2573 0.5 28.2073L2.49046 27.44L1.49523 27.8237L2.48514 27.4279C2.60755 27.7318 3.81433 30.3526 8.39399 28.822C13.5883 27.0874 17.3151 30.6241 18.5605 32.737L16.7337 33.8474H16.735Z"
          fill={color}
        />
        <path
          d="M19.8352 27.9601C19.766 27.9601 19.6968 27.9547 19.6276 27.9385C19.5584 27.9277 19.4946 27.9061 19.4307 27.8791C19.3615 27.852 19.303 27.8196 19.2444 27.7818C19.1859 27.7386 19.1327 27.6953 19.0848 27.6467C18.8825 27.4414 18.7708 27.1658 18.7708 26.8794C18.7708 26.8091 18.7761 26.7389 18.792 26.6686C18.808 26.5984 18.824 26.5335 18.8506 26.4687C18.8772 26.4039 18.9144 26.339 18.9517 26.2796C18.989 26.2201 19.0315 26.1661 19.0848 26.1175C19.3296 25.8689 19.6968 25.75 20.0427 25.8203C20.1119 25.8365 20.1758 25.8581 20.245 25.8851C20.3088 25.9121 20.3674 25.9445 20.4259 25.9824C20.4845 26.0202 20.5377 26.0688 20.5909 26.1175C20.6388 26.1661 20.6814 26.2201 20.7186 26.2796C20.7612 26.339 20.7931 26.4039 20.8198 26.4687C20.8464 26.5335 20.8677 26.5984 20.8783 26.6686C20.8943 26.7389 20.8996 26.8091 20.8996 26.8794C20.8996 27.1658 20.7878 27.4414 20.5909 27.6467C20.5377 27.6953 20.4845 27.7386 20.4259 27.7818C20.3674 27.8196 20.3088 27.852 20.245 27.8791C20.1758 27.9061 20.1119 27.9277 20.0427 27.9385C19.9735 27.9547 19.9044 27.9601 19.8352 27.9601Z"
          fill={color}
        />
        <path
          d="M16.4716 12.7865C16.4025 12.7865 16.3333 12.7811 16.2641 12.7649C16.1949 12.7487 16.131 12.7271 16.0672 12.7054C16.0033 12.673 15.9394 12.6406 15.8809 12.6028C15.8223 12.5649 15.7691 12.5163 15.7212 12.4677C15.6733 12.419 15.6254 12.365 15.5882 12.3056C15.5509 12.2461 15.5137 12.1813 15.4924 12.1164C15.4604 12.0516 15.4445 11.9867 15.4285 11.9165C15.4125 11.8463 15.4072 11.776 15.4072 11.7058C15.4072 11.4194 15.5243 11.1384 15.7212 10.9384C15.7691 10.8898 15.8223 10.8466 15.8809 10.8033C15.9394 10.7655 16.0033 10.7331 16.0672 10.7061C16.131 10.6791 16.1949 10.6574 16.2641 10.6466C16.4025 10.6142 16.5462 10.6142 16.6792 10.6466C16.7484 10.6574 16.8176 10.6791 16.8814 10.7061C16.9453 10.7331 17.0039 10.7655 17.0624 10.8033C17.1209 10.8466 17.1742 10.8898 17.2274 10.9384C17.2753 10.9925 17.3179 11.0465 17.3551 11.1059C17.3977 11.1654 17.4296 11.2248 17.4562 11.2897C17.4828 11.3545 17.5041 11.4248 17.5148 11.495C17.5307 11.5653 17.5361 11.6355 17.5361 11.7058C17.5361 11.9867 17.4243 12.2677 17.2274 12.4677C17.1742 12.5163 17.1209 12.5649 17.0624 12.6028C17.0039 12.6406 16.9453 12.673 16.8814 12.7054C16.8176 12.7271 16.7484 12.7487 16.6792 12.7649C16.61 12.7811 16.5408 12.7865 16.4716 12.7865Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_767_14295">
          <rect
            width={65}
            height={64}
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
