import { Operation } from '../../../__generated__/globalTypes'

import {
  MarketingURLFragment,
  marketingUrlFragmentString,
} from '../../fragments/__generated__/MarketingURLFragment'

import getMarketingUrlRawString from '../GetMarketingURL.graphql'

const getMarketingUrlString = [
  getMarketingUrlRawString,
  marketingUrlFragmentString,
].join('\n\n')

export type GetMarketingURLData = {
  marketingUrl: MarketingURLFragment
}

export type GetMarketingURLVariables = {
  title: string
}

export type GetMarketingURL = Operation<
  GetMarketingURLData,
  GetMarketingURLVariables
>

export const GetMarketingURL = ({
  title,
}: GetMarketingURLVariables): GetMarketingURL => ({
  query: getMarketingUrlString,
  variables: {
    title,
  },
})
