// useGradientBorderStyle.ts
export type GradientSettingsType = {
  gradient: string
  backgroundColor: string
  radius: number
  thickness: number
  duration: number
  shouldAnimate?: boolean
}

export const useGradientBorderStyle = (
  gradientSettings: GradientSettingsType,
) => {
  const {
    gradient,
    backgroundColor,
    radius,
    thickness,
    duration,
    shouldAnimate = true,
  } = gradientSettings

  const borderStyles = {
    background: backgroundColor,
    position: 'relative',
    borderRadius: 0,
  }

  const gradientBorderOverlay = {
    content: '""',
    position: 'absolute',
    top: `calc(-1 * ${thickness}px)`,
    left: `calc(-1 * ${thickness}px)`,
    height: `calc(100% + ${thickness}px * 2)`,
    width: `calc(100% + ${thickness}px * 2)`,
    background: gradient,
    borderRadius: radius,
    zIndex: 0,
    animation: shouldAnimate
      ? `animateBorder ${duration}s ease infinite alternate`
      : '',
    backgroundSize: '300% 300%',
  }

  // Include the animation styles only if animate is true
  const cssString = shouldAnimate
    ? `
      @keyframes animateBorder {
        0% {
          background-position: 0% 50%;
        }
        25% {
          background-position: 50% 75%;
        }
        50% {
          background-position: 100% 50%;
        }
        100% {
          background-position: 0% 50%;
        }
      }
    `
    : ''

  // Inject the CSS into the head of the document
  if (cssString) {
    const styleElement = document.createElement('style')
    styleElement.setAttribute('type', 'text/css')
    // styleElement.type = 'text/css'
    styleElement.appendChild(document.createTextNode(cssString))
    document.head.appendChild(styleElement)
  }

  return {
    borderStyles,
    gradientBorderOverlay,
    shouldAnimateBorder: shouldAnimate,
  }
}
