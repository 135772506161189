import { useWindowWidth } from '@react-hook/window-size'
import {
  Anchor,
  Button,
  Div,
  DropDownMenu,
  Flex,
  Icon,
  LinkColumnType,
} from '@sendoutcards/quantum-design-ui'
import React, { useState } from 'react'
import { openApp } from 'src/helpers/appHelpers'
import { connect } from 'src/redux/connect'
import { openPlans } from 'src/redux/actions/routeAliases'
import { BaseRoute } from 'src/routes/BaseRoute'
import { PricingRoute } from 'src/pricing_page/routes/PricingRoute'
import { findRoute } from 'src/routes/utilities'

type AccountSectionProps = {
  route: BaseRoute
  openPlans: () => void
}

const AccountSection: React.FC<AccountSectionProps> = props => {
  const { route, openPlans } = props
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const width = useWindowWidth()
  const isMobile = width < 600

  const onJoin = () => {
    const isPricingRoute = !!findRoute(route, PricingRoute)
    if (isPricingRoute) {
      openApp('', {
        join: '',
        promo: '1',
        planSelected: '',
        goToPricing: '',
      })
    } else {
      openPlans()
    }
  }

  const mobileDropdownLinks: LinkColumnType = {
    title: 'My Account',
    links: [
      {
        title: 'Login',
        href: '#',
        onClick: () => openApp('/dashboard/account', { client: 'soc' }),
      },
      { title: 'Join Now', href: '#', onClick: onJoin },
    ],
  }

  return isMobile ? (
    <Flex position="relative">
      <Icon name="user" size="xSmall" primaryColor="primaryBodyText" />
      <Icon
        name={'chevron'}
        orientation={isDropdownOpen ? 'up' : 'down'}
        size={'small'}
        onClick={() => {
          setIsDropdownOpen(!isDropdownOpen)
        }}
        primaryColor={'primaryBodyText'}
      />
      <DropDownMenu
        right={-24}
        top={48}
        isOpen={isDropdownOpen}
        linkColumn={mobileDropdownLinks}
      />
    </Flex>
  ) : (
    <Div width="200px">
      <Flex columnGap="x2">
        <Button
          size="xSmall"
          type="shadow"
          hover={true}
          onClick={() => openApp('/dashboard/account', { client: 'soc' })}
        >
          Login
        </Button>
        <Anchor style={{ textDecoration: 'none' }} onClick={onJoin}>
          <Button hover={true} outlined={true} size="xSmall">
            Join Now
          </Button>
        </Anchor>
      </Flex>
    </Div>
  )
}

export default connect(
  state => {
    return {
      route: state.route,
    }
  },
  { openPlans },
)(AccountSection)
