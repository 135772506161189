import { Maybe, Operation } from '../../../__generated__/globalTypes'

import {
  InviteFragment,
  inviteFragmentString,
} from '../../fragments/__generated__/InviteFragment'

import createInviteRawString from '../CreateInvite.graphql'

const createInviteString = [createInviteRawString, inviteFragmentString].join(
  '\n\n',
)

export type CreateInviteData = {
  createInvite: {
    invite: InviteFragment
  }
}

export type CreateInviteVariables = {
  sponsorId: string
  socProContactId?: Maybe<string>
  prospectId?: Maybe<string>
  utmTerm?: Maybe<string>
  utmCampaign?: Maybe<string>
  utmContent?: Maybe<string>
  utmMedium?: Maybe<string>
  utmSource?: Maybe<string>
}

export type CreateInvite = Operation<CreateInviteData, CreateInviteVariables>

export const CreateInvite = ({
  sponsorId,
  socProContactId,
  prospectId,
  utmTerm,
  utmCampaign,
  utmContent,
  utmMedium,
  utmSource,
}: CreateInviteVariables): CreateInvite => ({
  query: createInviteString,
  variables: {
    sponsorId,
    socProContactId,
    prospectId,
    utmTerm,
    utmCampaign,
    utmContent,
    utmMedium,
    utmSource,
  },
})
