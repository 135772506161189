import creditCardFragmentRawString from '../CreditCardFragment.graphql'

export const creditCardFragmentString = creditCardFragmentRawString

export type CreditCardFragment = {
  id: string
  brand: string
  last4: string
  expMonth: number
  expYear: number
  object: string
}

export const isCreditCardFragment = (
  fragment: any,
): fragment is CreditCardFragment =>
  fragment &&
  typeof fragment.id === 'string' &&
  typeof fragment.brand === 'string' &&
  typeof fragment.last4 === 'string' &&
  typeof fragment.expMonth === 'number' &&
  typeof fragment.expYear === 'number' &&
  typeof fragment.object === 'string'
