import marketingParagraphFragmentRawString from '../MarketingParagraphFragment.graphql'

export const marketingParagraphFragmentString = marketingParagraphFragmentRawString

export type MarketingParagraphFragment = {
  __typename: 'MarketingParagraph'
  title: string
  content: string
}

export const isMarketingParagraphFragment = (
  fragment: any,
): fragment is MarketingParagraphFragment =>
  fragment &&
  fragment.__typename === 'MarketingParagraph' &&
  typeof fragment.title === 'string' &&
  typeof fragment.content === 'string'
