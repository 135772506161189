import { PlanFragment } from 'src/graphql'

export interface Plan extends PlanFragment {
  billingInterval?: string
}

class Detail {
  detail: string
  innerDetails: Detail[] | undefined
  constructor(detail: string, innerDetails: Detail[] | undefined) {
    this.detail = detail
    this.innerDetails = innerDetails
  }

  static fromObj(obj: Detail): Detail {
    return new Detail(obj.detail, obj.innerDetails?.map(Detail.fromObj))
  }
}

class Upsale {
  title: string
  subtitle?: string
  isFeatured?: boolean

  constructor(title: string, subtitle?: string, isFeatured?: boolean) {
    this.title = title
    this.subtitle = subtitle
    this.isFeatured = isFeatured
  }

  static fromObj(obj: Upsale): Upsale {
    return new Upsale(obj.title, obj.subtitle, obj.isFeatured)
  }
}

export class PlanDescription {
  plan: string
  secondaryTitle?: string
  description?: string
  details?: Detail[]
  disclaimer?: string
  isFeatured?: boolean
  order?: number
  purchaseButtonText?: string
  isPromotional?: boolean
  upsale?: Upsale
  shouldIgnore?: boolean

  constructor(
    plan: string,
    secondaryTitle?: string,
    description?: string,
    details?: Detail[],
    disclaimer?: string,
    isFeatured?: boolean,
    order?: number,
    purchaseButtonText?: string,
    isPromotional?: boolean,
    upsale?: Upsale,
    shouldIgnore?: boolean,
  ) {
    this.plan = plan
    this.secondaryTitle = secondaryTitle
    this.description = description
    this.details = details
    this.disclaimer = disclaimer
    this.isFeatured = isFeatured
    this.order = order
    this.purchaseButtonText = purchaseButtonText
    this.isPromotional = isPromotional
    this.upsale = upsale
    this.shouldIgnore = shouldIgnore
  }

  static fromObj(obj: PlanDescription): PlanDescription {
    return new PlanDescription(
      obj.plan,
      obj.secondaryTitle,
      obj.description,
      obj.details?.map(Detail.fromObj),
      obj.disclaimer,
      obj.isFeatured,
      obj.order,
      obj.purchaseButtonText,
      obj.isPromotional,
      obj.upsale,
      obj.shouldIgnore,
    )
  }
}

export class PlansDescription {
  id: string
  description: PlanDescription

  constructor(id: string, description: PlanDescription) {
    this.id = id
    this.description = description
  }

  static fromPlanFragment(fragment: PlanFragment): PlansDescription {
    try {
      const descriptionObj = JSON.parse(fragment.description ?? '')
      return new PlansDescription(
        fragment.id,
        PlanDescription.fromObj(descriptionObj),
      )
    } catch (e) {
      return new PlansDescription(
        fragment.id,
        new PlanDescription(fragment.title, fragment.subtitle ?? undefined),
      )
    }
  }
}
