import React from 'react'
// @src imports
import { Button, Icon } from 'src/chrome'
import { CustomCardFragment, MinimalSendableCardFragment } from 'src/graphql'
// relative imports
import CardFlip from '../CardFlip/CardFlip'

import styles from './reason.module.scss'

interface ReasonProps {
  backgroundStartColor: string
  backgroundEndColor: string
  card?: MinimalSendableCardFragment | CustomCardFragment
  cardPreview?: (id: string, typename?: string) => void
  description?: string
  id?: string
  info?: {
    title: string
    description: string
  }
  onClick?: (id: string) => void
  onClose?: () => void
  reasonToSend?: boolean
  title: string
  textColor?: string
}

const Reason: React.FC<ReasonProps> = props => {
  const {
    backgroundEndColor,
    backgroundStartColor,
    card,
    cardPreview,
    description,
    id,
    info,
    onClick,
    onClose,
    reasonToSend,
    textColor,
    title,
  } = props
  return (
    <div
      className={styles.reasonToSend}
      style={{
        backgroundImage: `linear-gradient(115deg, ${backgroundStartColor}, ${backgroundEndColor})`,
      }}
    >
      {!reasonToSend && (
        <div
          className={styles.closeCollection}
          onClick={() => {
            onClose && onClose()
          }}
        >
          <Icon icon={'ARROWLEFT'} color={textColor} size={18} />
          <span style={{ color: textColor }}>Back</span>
        </div>
      )}
      <div className={styles.reasonBackground}>
        <div className={styles.featureContent}>
          {card && (
            <div
              id={`reasons_card_preview_${id}`}
              className={styles.feature}
              onClick={() => {
                cardPreview && cardPreview(card.id, card.__typename)
              }}
            >
              <CardFlip card={card} />
            </div>
          )}
          <div className={styles.reason}>
            {info ? (
              <div>
                <h2 style={textColor ? { color: textColor } : {}}>
                  {info.title}
                </h2>
                <p style={textColor ? { color: textColor } : {}}>
                  {info.description}
                </p>
              </div>
            ) : (
              <h2 style={textColor ? { color: textColor } : {}}>{title}</h2>
            )}
            {reasonToSend && (
              <p style={textColor ? { color: textColor } : {}}>{description}</p>
            )}
            {reasonToSend && (
              <Button
                titleStyle={{
                  color: '#fff',
                }}
                style={
                  {
                    border: `1px solid ${textColor ? textColor : '#FFF'}`,
                    background: 'transparent',
                  } as React.CSSProperties
                }
                innerStyle={
                  {
                    background: 'transparent',
                  } as React.CSSProperties
                }
                id={`reasons_${id}_btn`}
                title={'More Cards'}
                onClick={() => {
                  id && onClick && onClick(id)
                }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Reason
