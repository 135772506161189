import borders, { Borders } from './borders'
import colors, { Colors } from './colors'
import gradients, { Gradients } from './gradients'
import shadows, { Shadows } from './shadows'
import buttonColors, { ButtonColors } from './buttonColors'

export interface Theme {
  borders: Borders
  colors: Colors
  gradients: Gradients
  shadows: Shadows
  buttonColors: ButtonColors
}

const theme: Theme = {
  borders,
  colors,
  gradients,
  shadows,
  buttonColors,
}

export default theme
