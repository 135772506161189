import styled from '@emotion/styled'

export const FlexColumn = styled.div({
  display: 'flex',
  flexDirection: 'column',
})

export const FlexRow = styled.div({ display: 'flex', flexDirection: 'row' })

// RoundedFooter
export const FairUse = styled.a({
  color: '#ff5689',
  display: 'inline-block',
})

export const Span = styled.span({ color: 'rgba(0,0,0,.65)', fontSize: 13 })

// MenuItem
export const MenuItemLink = styled.a({
  width: '100%',
  height: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
})

// Disclaimer
export const P = styled.p({
  fontWeight: 300,
})

export const H6 = styled.h6({
  marginBottom: 20,
  fontWeight: 500,
})

export const Content = styled.div({
  width: '80%',
  margin: '0 auto',
  padding: '50px 0',
})

export const Section = styled.section({
  background: '#fff',
  width: '100%',
})
