import { Operation } from '../../../__generated__/globalTypes'

import {
  FontFragment,
  fontFragmentString,
} from '../../fragments/__generated__/FontFragment'

import getFontsRawString from '../GetFonts.graphql'

const getFontsString = [getFontsRawString, fontFragmentString].join('\n\n')

export type GetFontsData = {
  fonts: FontFragment[]
}

export type GetFonts = Operation<GetFontsData>

export const GetFonts = (): GetFonts => ({
  query: getFontsString,
  variables: undefined,
})
