import { FC } from 'react'
import { SVGProps } from './types'

export const GroupSendCard: FC<SVGProps> = ({ size, color, onClick }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 64 90"
      width={size}
      height={size}
      onClick={onClick}
    >
      <path
        fill={color}
        d="M54.088 58.166c-.225-2.264-1.455-4.423-3.374-5.923a9.17 9.17 0 00-4.754-1.934 5.498 5.498 0 003.044-1.545 5.516 5.516 0 001.635-3.914c0-3.043-2.504-5.533-5.578-5.533a5.572 5.572 0 00-3.959 1.65 5.459 5.459 0 00-1.62 3.883c0 2.775 2.085 5.069 4.784 5.459-.09 0-.18 0-.27.03-1.38.195-2.64.63-3.764 1.304a12.293 12.293 0 00-6.777-3.029c1.214-.3 2.339-.9 3.253-1.814a6.827 6.827 0 002.04-4.889c0-3.823-3.134-6.957-6.988-6.957-3.839 0-6.973 3.089-7.003 6.898 0 1.859.705 3.613 2.01 4.933.944.96 2.129 1.59 3.419 1.89-2.475.36-4.874 1.454-6.793 3.313l-.105-.06c-2.444-1.334-5.338-1.47-7.918-.39a9.071 9.071 0 00-5.233 5.938c-.09.315-.15.63-.225.945l-.09.435v3.674l.015.195c.45 1.244 1.395 1.829 2.834 1.814 1.68-.03 3.36-.03 5.039-.015h2.04c.419 1.575 1.409 2.28 3.163 2.28h18.369c1.754 0 2.909-1.095 3.014-2.805h7.182c.225 0 .45 0 .675-.045 1.14-.165 1.995-1.11 2.01-2.204v-.27c.015-1.08.03-2.19-.075-3.284v-.03zm-2.43 2.49v.794h-7.347c.09-3.014-.69-5.593-2.354-7.872 1.934-1.05 4.243-1.05 6.208 0 2.054 1.094 3.358 3.119 3.479 5.428.03.555.03 1.11.014 1.65zm-9.866 2.428v.705c0 .375-.075.45-.465.45H22.673c-.42 0-.45-.12-.465-.405v-.75c-.015-.96-.03-1.964.03-2.924.24-4.003 2.88-7.392 6.733-8.622a9.705 9.705 0 013.044-.48c1.605 0 3.194.39 4.633 1.185 2.37 1.275 4.064 3.419 4.799 6.013.36 1.29.345 2.594.33 3.989v.855l.015-.016zm-20.019-9.132c-1.544 2.265-2.234 4.889-2.084 8.008h-7.348v-.09c0-.345.016-.705.016-1.05 0-.885.014-1.71.195-2.504.434-1.934 1.754-3.539 3.628-4.394 1.83-.824 3.854-.81 5.593.03zm20.244-9.087c0-1.649 1.364-2.998 3.044-3.014 1.68 0 3.044 1.35 3.059 3 0 .78-.3 1.529-.87 2.099a3.061 3.061 0 01-2.19.914c-1.679 0-3.058-1.349-3.058-2.998h.015zm-13.45.136a4.359 4.359 0 01-1.305-3.104c0-2.43 2.024-4.409 4.498-4.409 1.2 0 2.324.465 3.164 1.305.84.84 1.32 1.949 1.305 3.118 0 2.43-2.025 4.424-4.499 4.424-1.2 0-2.34-.48-3.194-1.334h.03z"
      />
      <path
        fill={color}
        d="M18.924 50.744c3.074 0 5.579-2.475 5.579-5.519 0-1.454-.57-2.834-1.62-3.898a5.538 5.538 0 00-3.944-1.65c-3.074 0-5.578 2.474-5.578 5.518 0 3.044 2.49 5.534 5.563 5.549zm-3.044-5.549c0-1.649 1.38-2.999 3.06-2.999 1.679 0 3.043 1.35 3.043 3.014 0 .81-.315 1.56-.884 2.115-.57.57-1.335.914-2.175.87-.824 0-1.604-.33-2.189-.915a2.95 2.95 0 01-.87-2.1l.015.015z"
      />
      <path
        fill={color}
        d="M61.99 11.742h-6.838V1.53A1.504 1.504 0 0053.682 0c-.104 0-.21 0-.314.03L1.71 10.362c-.705.135-1.2.78-1.2 1.5V88.5c0 .825.675 1.5 1.5 1.5h59.98c.825 0 1.5-.675 1.5-1.5V13.24c0-.825-.675-1.5-1.5-1.5zm-9.837-8.383v8.383H10.167l41.986-8.383zm8.337 83.642H3.51v-72.26h56.98V87z"
      />
    </svg>
  )
}
