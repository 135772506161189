import { Route } from 'src/routes/Route'
import { SendableCardRoute } from 'src/catalog/routes/SendableCardRoute'
import { selectedSendableCardReducer } from './reducers'

export const CardCategoryRoute = Route({
  path: '/categories/:cardCategoryId',
  init: (cardCategoryId: string, subroute?: SendableCardRoute) => ({
    cardCategoryId,
    subroute,
  }),
  fromPath: ({ cardCategoryId }) =>
    cardCategoryId ? { cardCategoryId } : undefined,
  subroute: SendableCardRoute,
  reducer: selectedSendableCardReducer,
})
export type CardCategoryRoute = Route<typeof CardCategoryRoute>
