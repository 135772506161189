import Action from '../action'
import { openRoute } from '../actions/routes'

type State = {
  promo?: string
}

export default (state: State = {}, action: Action): State => {
  switch (action.type) {
    case openRoute.type:
      return { ...state, promo: action.promo }
    default:
      return state
  }
}
