import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import Action from 'src/redux/action'
import { openRoute } from 'src/redux/actions/routes'
import { SponsorFragment } from 'src/graphql'

import {
  clearStorefront,
  loadedStorefront,
  loadedStorefrontError,
} from '../actions/storefront'

type State = {
  loading: boolean
  sponsorSlug?: string
  sponsorId?: string
  storefront?: {
    user: SponsorFragment
  }
  error?: Error
  showSponsorBar: boolean
}

const initialState: State = {
  loading: true,
  showSponsorBar: true,
}

const reducer = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case openRoute.type:
      if (action.sponsorId && action.sponsorId !== state.sponsorId) {
        return {
          ...state,
          loading: true,
          sponsorId: action.sponsorId,
          sponsorSlug: undefined,
        }
      }
      if (action.slug && action.slug !== state.sponsorSlug) {
        return {
          ...state,
          loading: true,
          sponsorId: undefined,
          sponsorSlug: action.slug,
        }
      }
      return state
    case loadedStorefront.type:
      return {
        ...state,
        loading: false,
        sponsorId: undefined,
        sponsorSlug: undefined,
        storefront: action.storefront,
        showSponsorBar: true,
      }
    case loadedStorefrontError.type:
      return {
        ...state,
        loading: false,
        error: action.error,
      }
    case clearStorefront.type:
      return {
        ...state,
        showSponsorBar: false,
      }
    default:
      return state
  }
}

const stateReconciler = (persistedState: State, currentState: State): State => {
  return {
    ...currentState,
    storefront: currentState.storefront || persistedState.storefront,
  }
}

const persistedReducer = persistReducer(
  {
    key: 'storefront',
    storage,
    stateReconciler,
  },
  reducer,
)

export type StorefrontState = ReturnType<typeof persistedReducer>

export default persistedReducer
