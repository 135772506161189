import { Route } from 'src/routes/Route'
import { SendableCardRoute } from './SendableCardRoute'
import { selectedSendableCardReducer } from './reducers'

export const StandardCardsRoute = Route({
  path: '/favorites',
  init: (subroute?: SendableCardRoute) => ({ subroute }),
  subroute: SendableCardRoute,
  reducer: (route, action) => selectedSendableCardReducer(route, action),
})
export type StandardCardsRoute = Route<typeof StandardCardsRoute>
