import React from 'react'

type buttonProps = {
  title: string
  outlineColor?: string
  outlineWidth?: string
  backgroundColor?: string
  textColor?: string
  textWeight?: number
  textSize?: string
  padding?: string
  border?: string
  borderRadius?: string
  shouldDisplayBoxShadow?: boolean
  boxShadow?: string
  width?: string
  height?: string
  onClick?: () => void
}

/**
 * We may want to use rem units when customizing the button.
 *
 * Standard rem to px is 0.25rem = 4px.
 */
export const Button: React.FC<buttonProps> = ({
  title,
  outlineColor,
  outlineWidth,
  backgroundColor = 'hsl(323,100%,51%)',
  textColor = '#FFF',
  textWeight = 600,
  textSize = '1rem',
  padding = '0.75rem 1rem',
  border = 'none',
  borderRadius = '0.5rem',
  shouldDisplayBoxShadow = false,
  boxShadow = '0px 0.25rem 1.5rem rgba(0, 0, 0, 0.25)',
  width = 'max-content',
  height = 'min-content',
  onClick,
}) => {
  const style = {
    cursor: 'pointer',
    backgroundColor,
    padding,
    border,
    borderRadius,
    width,
    height,
    outlineColor,
    outlineWidth,
    outlineStyle: outlineColor ? 'solid' : undefined,
    outlineOffset: `-${outlineWidth}`,
    boxShadow: shouldDisplayBoxShadow ? boxShadow : undefined,
    color: textColor,
    fontSize: textSize,
    fontWeight: textWeight,
  }
  return (
    <button css={style} onClick={onClick}>
      {title}
    </button>
  )
}
