import React from 'react'
import { SVGProps } from './types'

export const SOCLogo: React.FC<SVGProps> = ({
  size = 24,
  color = '#000',
  onClick,
}) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 681.6 63.7"
      width={size}
      onClick={onClick}
    >
      <g>
        <g>
          <path
            fill={color}
            d="M62.2,4.7c-3-2.2-14.6,1.5-19.5,3.2C32.6,11.6,21.4,16.8,13,22.2c-4.2,3-4.4,3.5-4,4l0.2,0.2h0.2
          c0.2,0,0.7,0.2,4.2-2.2C28.8,16.3,53.3,6.7,59,9.4c3.2,1.5,1.7,7.4-0.2,17.3c-1.5,7.2-3.5,15.8-4.4,26.2c-0.5,6.7,0,6.7,0.5,6.9
          H55c0.5,0,1,0,2.5-6.2C58.7,45.9,65.9,7.6,62.2,4.7z"
          />
          <path
            fill={color}
            d="M29.1,49.1L29.1,49.1c3,0,4.7-2.2,6.4-4c0.5-0.5,1-1,1.5-1.5c0.7-0.7,1.5-1.5,2.5-2.2
          c4.9-4.2,13.3-11.4,19.3-27.7c0.2-0.5,0.2-1-0.2-1.2c-0.5-0.5-1.2,0-2,0.5v0.2c-11.9,20.5-20.3,27.2-23,29.4L33,43
          c-0.5,0.5-1,1-1.5,1.5c-1.2,1.2-2.2,2.7-4,2.5c-3-0.2-7.2-4.2-12.6-12.1c0,0-1.5-1.5-2.2-0.7c-0.7,0.5-0.2,1.5,1.2,3.2
          C20.5,45.4,25.1,49.1,29.1,49.1z"
          />
          <path
            fill={color}
            d="M49.3,57.5c-16.3-3.7-24.5-2-29.9-1c-4.4,1-6.7,1.5-9.9-1.7c-2.5-2-2.2-4.7-2-4.9c0-1.2,0-1.7-0.5-2
          c-0.2-0.2-0.5,0-0.7,0c-0.5,0.2-0.7,1.5-0.7,2c0,0.2,0,4.7,3.7,7.4l0,0c4.4,2.5,5.7,2.5,10.4,2c4.7-0.2,13.1-1,32.6,0.5
          c0.5,0,1-0.2,1-0.7C53.3,59,53.1,58.8,49.3,57.5z"
          />
        </g>
        <g>
          <path
            fill={color}
            d="M100.7,56.3c-6.9,0-12.1-2.2-17.3-6.9l2-2.5c4.7,4.4,9.1,6.4,15.6,6.4c6.4,0,10.9-3.7,10.9-8.6l0,0
          c0-4.4-2.5-7.2-12.1-9.1c-10.4-2.2-14.6-5.7-14.6-12.1v-0.2c0-6.4,5.9-11.4,13.8-11.4c6.2,0,10.4,1.7,14.8,5.2l-2,2.5
          c-4-3.5-8.2-4.9-12.8-4.9c-6.4,0-10.6,3.7-10.6,8.2V23c0,4.4,2.2,7.4,12.6,9.4c9.9,2,14.1,5.7,14.1,11.9l0,0
          C115.1,51.4,109.1,56.3,100.7,56.3z"
          />
          <path
            fill={color}
            d="M128.9,55.6V12.3h30.6v3h-27.4v17h24.7v3h-24.7v17.3H160v3H128.9z"
          />
          <path
            fill={color}
            d="M206.7,55.6l-30.4-38.3v38.3h-3V12.3h3l29.9,37.5V12.3h3v43.2H206.7z"
          />
          <path
            fill={color}
            d="M239.5,55.6h-14.3V12.3h14.3c13.6,0,23,9.4,23,21.5l0,0C262.5,46.2,253.1,55.6,239.5,55.6z M259,34.1
          c0-10.1-7.7-18.5-19.5-18.5h-11.1v37.3h11.1C251.4,52.6,259,44.4,259,34.1L259,34.1z"
          />
          <path
            fill={color}
            d="M296.1,56.3c-13.1,0-21.7-10.4-21.7-22.2l0,0c0-11.9,8.6-22.2,21.7-22.2c13.1,0,21.7,10.4,21.7,22.2l0,0
          C318.1,45.7,309.2,56.3,296.1,56.3z M314.6,34.1c0-10.6-7.7-19.5-18.5-19.5c-10.6,0-18.3,8.6-18.3,19.3l0,0
          c0,10.6,7.7,19.5,18.5,19.5C307,53.3,314.6,44.7,314.6,34.1L314.6,34.1z"
          />
          <path
            fill={color}
            d="M349,56.3c-10.4,0-17.8-6.4-17.8-18.5V12.3h3.2v24.9c0,10.1,5.4,16.1,14.6,16.1c8.6,0,14.3-5.2,14.3-15.8
          V12.3h3.2v24.9C366.7,49.6,359.6,56.3,349,56.3z"
          />
          <path
            fill={color}
            d="M397.1,15.3v40.3h-3.2V15.3h-15.1v-3h33.3v3H397.1z"
          />
          <path
            fill={color}
            d="M441.3,56.3c-12.3,0-21.5-9.9-21.5-22.2l0,0c0-12.1,9.1-22.2,21.5-22.2c7.7,0,12.3,2.7,16.8,6.9l-2.2,2.2
          c-3.7-3.7-8.2-6.4-14.6-6.4c-10.4,0-18.3,8.4-18.3,19.3l0,0c0,10.9,7.9,19.5,18.3,19.5c6.2,0,10.4-2.5,14.8-6.7l2.2,2
          C453.9,53.3,449,56.3,441.3,56.3z"
          />
          <path
            fill={color}
            d="M506.5,55.6l-5.4-12.1h-25.4l-5.4,12.1H467l20-43.5h3l20,43.5H506.5z M488.3,15.5l-11.4,24.9h22.7L488.3,15.5
          z"
          />
          <path
            fill={color}
            d="M553.5,55.6l-13.8-17.8h-14.1v17.8h-3.2V12.3h18c9.4,0,15.3,4.9,15.3,12.3l0,0c0,7.2-5.4,11.4-12.6,12.3
          l14.3,18.5H553.5z M552.7,24.7c0-5.7-4.4-9.4-12.3-9.4h-14.8v19.5h14.6C547.3,34.8,552.7,31.1,552.7,24.7L552.7,24.7z"
          />
          <path
            fill={color}
            d="M584.3,55.6H570V12.3h14.3c13.6,0,23,9.4,23,21.5l0,0C607.3,46.2,597.9,55.6,584.3,55.6z M604.1,34.1
          c0-10.1-7.7-18.5-19.5-18.5h-11.4v37.3h11.1C596.4,52.6,604.1,44.4,604.1,34.1L604.1,34.1z"
          />
          <path
            fill={color}
            d="M635.5,56.3c-6.9,0-12.1-2.2-17.3-6.9l2-2.5c4.7,4.4,9.1,6.4,15.6,6.4c6.4,0,10.9-3.7,10.9-8.6l0,0
          c0-4.4-2.5-7.2-12.1-9.1c-10.4-2.2-14.6-5.7-14.6-12.1v-0.2c0-6.4,5.9-11.4,13.8-11.4c6.2,0,10.4,1.7,14.8,5.2l-2,2.5
          c-4-3.5-8.2-4.9-12.8-4.9c-6.4,0-10.6,3.7-10.6,8.2V23c0,4.4,2.2,7.4,12.6,9.4c9.9,2,14.1,5.7,14.1,11.9l0,0
          C649.8,51.4,643.6,56.3,635.5,56.3z"
          />
          <path
            fill={color}
            d="M667.1,27.2c-4.9,0-8.9-4-8.9-8.9s4-8.9,8.9-8.9s8.9,4,8.9,8.9S672,27.2,667.1,27.2z M667.1,10.1
          c-4.7,0-8.2,3.7-8.2,8.2s3.5,8.2,8.2,8.2s8.2-3.7,8.2-8.2S671.8,10.1,667.1,10.1z M668.6,18.8l3,3.7H670l-3-3.7h-2.7v3.7h-1v-8.9
          h4c2,0,3.2,1,3.2,2.7C671,17.5,670,18.5,668.6,18.8z M667.6,14.6h-3V18h2.7c1.2,0,2.5-0.5,2.5-1.7C670,15.1,669.1,14.6,667.6,14.6
          z"
          />
        </g>
      </g>
    </svg>
  )
}
