import React from 'react'

import {
  DiffImageType,
  ImageDiffSlider,
} from 'src/design_system/components/ImageDiffSlider/ImageDiffSlider'

export type DynamicDiffImageType = {
  leftImage: DiffImageType
  rightImage: DiffImageType
}

type DynamicImageDiffProps = {
  images: DynamicDiffImageType[]
  activeImageIndex: number
}

export const DynamicImageDiff: React.FC<DynamicImageDiffProps> = ({
  images,
  activeImageIndex,
}) => {
  return <ImageDiffSlider {...images[activeImageIndex]} />
}
