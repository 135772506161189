import React from 'react'
import { SVGProps } from './types'

export const Cards25: React.FC<SVGProps> = ({
  size = 24,
  color = '#000',
  onClick,
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 70 84"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick && (() => onClick())}
    >
      <path
        d="M68.32 1.25H19.28V64.18H68.32V1.25Z"
        fill="white"
        stroke="#231F20"
        strokeWidth={2.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M62.31 7.25H13.27V70.18H62.31V7.25Z"
        fill="white"
        stroke="#231F20"
        strokeWidth={2.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M56.31 13.2598H7.27002V76.1898H56.31V13.2598Z"
        fill="white"
        stroke="#231F20"
        strokeWidth={2.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M50.3 19.2598H1.26001V82.1898H50.3V19.2598Z"
        fill="white"
        stroke="#231F20"
        strokeWidth={2.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.7599 45.8798L13.3799 43.9698C14.8999 41.8998 16.3699 40.7798 19.1199 40.7798C22.3999 40.7798 24.4199 42.6698 24.4199 45.5498C24.4199 48.1198 23.0999 49.4198 20.3699 51.5298L17.8599 53.4698H24.5699V56.3998H13.1699V53.7198L18.2999 49.5198C20.2099 47.9398 20.9599 47.0998 20.9599 45.8198C20.9599 44.5398 20.0999 43.8198 18.8899 43.8198C17.6799 43.8198 16.8899 44.4798 15.7399 45.8898L15.7599 45.8798Z"
        fill={color}
      />
      <path
        d="M28.23 40.9897H37.69V43.9597H31.13L30.95 46.6197C31.57 46.4697 32.12 46.3597 32.97 46.3597C36.01 46.3597 38.36 47.8097 38.36 51.3097C38.36 54.5897 36.05 56.6597 32.51 56.6597C30.05 56.6597 28.24 55.7797 26.75 54.3497L28.8 51.9097C29.97 52.9697 31.11 53.5797 32.47 53.5797C34.05 53.5797 35.04 52.8097 35.04 51.4197C35.04 50.0297 33.96 49.3097 32.31 49.3097C31.34 49.3097 30.46 49.5697 29.73 49.8797L27.77 48.5797L28.21 40.9897H28.23Z"
        fill={color}
      />
    </svg>
  )
}
