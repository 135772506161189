import { BaseRoute } from 'src/routes/BaseRoute'
import { ActionCreator } from '@sendoutcards/core'

export const openRoute = ActionCreator(
  'OPEN_ROUTE',
  (
    route: BaseRoute,
    slug?: string,
    sponsorId?: string,
    token?: string,
    promo?: string,
    bulk?: string,
    identifier?: string,
  ) => ({
    route,
    slug,
    sponsorId,
    token,
    promo,
    bulk,
    identifier,
  }),
)
