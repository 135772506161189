import { clearTourId, setTourId } from 'src/redux/actions/helpmenu'
import Action from 'src/redux/action'

type State = {
  tourId?: string
}

const initialState: State = {}

export default (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case setTourId.type:
      return {
        ...state,
        tourId: action.tourId,
      }
    case clearTourId.type:
      return {
        ...state,
        tourId: undefined,
      }
    default:
      return state
  }
}
