import { Maybe } from '../../../__generated__/globalTypes'

import {
  SendableCardImageFragment,
  isSendableCardImageFragment,
} from './SendableCardImageFragment'

import sendableCardFragmentRawString from '../SendableCardFragment.graphql'

export const sendableCardFragmentString = sendableCardFragmentRawString

export type SendableCardFragment = {
  id: string
  title: string
  description: string
  variationColor: string
  isFavorite: boolean
  isHorizontal: boolean
  isPremium: boolean
  frontImage: SendableCardImageFragment
  insideRightImage: Maybe<SendableCardImageFragment>
  cost: {
    total: {
      asString: string
    }[]
  }
}

export const isSendableCardFragment = (
  fragment: any,
): fragment is SendableCardFragment =>
  fragment &&
  typeof fragment.id === 'string' &&
  typeof fragment.title === 'string' &&
  typeof fragment.description === 'string' &&
  typeof fragment.variationColor === 'string' &&
  typeof fragment.isFavorite === 'boolean' &&
  typeof fragment.isHorizontal === 'boolean' &&
  typeof fragment.isPremium === 'boolean' &&
  (isSendableCardImageFragment(fragment.frontImage) as boolean) &&
  (fragment.insideRightImage === null ||
    (isSendableCardImageFragment(fragment.insideRightImage) as boolean)) &&
  fragment.cost &&
  Array.isArray(fragment.cost.total) &&
  fragment.cost.total
    .slice(0, 5)
    .reduce(
      (accum: any, next: any) =>
        accum && next && typeof next.asString === 'string',
      true,
    )
