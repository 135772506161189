import { Route } from 'src/routes/Route'
import { SendableCardRoute } from './SendableCardRoute'
import { selectedSendableCardReducer } from './reducers'

export const LegacyPicturePlusCardsRoute = Route({
  path: '/legacy-picture-plus-cards',
  init: (subroute?: SendableCardRoute) => ({ subroute }),
  subroute: SendableCardRoute,
  reducer: (route, action) => selectedSendableCardReducer(route, action),
})
export type LegacyPicturePlusCardsRoute = Route<
  typeof LegacyPicturePlusCardsRoute
>
