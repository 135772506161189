import React from 'react'
import { SVGProps } from './types'

export const Card: React.FC<SVGProps> = ({
  size = 24,
  color = '#3A3A3C',
  onClick,
}) => {
  const originalWidth = 114
  const originalHeight = 161
  const ratio = originalWidth / originalHeight
  const scaledWidth = size
  const scaledHeight = size / ratio
  return (
    <svg
      width={scaledWidth}
      height={scaledHeight}
      viewBox={`0 0 ${originalWidth} ${originalHeight}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M63.3332 80.4954C56.3507 80.4954 50.6665 75.3345 50.6665 68.9948C50.6665 62.655 56.3507 57.4941 63.3332 57.4941C70.3157 57.4941 75.9998 62.655 75.9998 68.9948C75.9998 75.3345 70.3157 80.4954 63.3332 80.4954ZM63.3332 63.2445C59.8419 63.2445 56.9998 65.8249 56.9998 68.9948C56.9998 72.1646 59.8419 74.7451 63.3332 74.7451C66.8244 74.7451 69.6665 72.1646 69.6665 68.9948C69.6665 65.8249 66.8244 63.2445 63.3332 63.2445Z"
        fill={color}
      />
      <path
        d="M66.5005 114.997C65.693 114.997 64.8776 114.717 64.2601 114.156L41.1672 93.1891L24.4076 108.406C23.1726 109.527 21.1617 109.527 19.9267 108.406C18.6917 107.285 18.6917 105.466 19.9267 104.338L38.9267 87.0866C40.1617 85.9653 42.1726 85.9653 43.4076 87.0866L68.7409 110.088C69.9759 111.209 69.9759 113.028 68.7409 114.156C68.1234 114.717 67.3159 114.997 66.5005 114.997Z"
        fill={color}
      />
      <path
        d="M91.8333 114.997C91.0258 114.997 90.2104 114.717 89.5929 114.156L72.8333 98.9396L62.4071 108.406C61.1721 109.527 59.1612 109.527 57.9262 108.406C56.6913 107.285 56.6913 105.466 57.9262 104.338L70.5929 92.8371C71.8279 91.7158 73.8388 91.7158 75.0738 92.8371L94.0737 110.088C95.3088 111.209 95.3088 113.028 94.0737 114.156C93.4562 114.717 92.6487 114.997 91.8333 114.997Z"
        fill={color}
      />
      <path
        d="M91.8338 126.498H22.1672C20.4176 126.498 19.0005 125.211 19.0005 123.623V43.1183C19.0005 41.5298 20.4176 40.2432 22.1672 40.2432H91.8338C93.5834 40.2432 95.0005 41.5298 95.0005 43.1183V123.623C95.0005 125.211 93.5834 126.498 91.8338 126.498ZM25.3338 120.748H88.6671V45.9935H25.3338V120.748Z"
        fill={color}
      />
      <path
        d="M79.1665 143.749H34.8332C33.0836 143.749 31.6665 142.462 31.6665 140.874C31.6665 139.285 33.0836 137.999 34.8332 137.999H79.1665C80.9161 137.999 82.3332 139.285 82.3332 140.874C82.3332 142.462 80.9161 143.749 79.1665 143.749Z"
        fill={color}
      />
      <path
        d="M110.833 161H3.16667C1.41708 161 0 159.713 0 158.125V26.112C0 24.5234 1.41708 23.2368 3.16667 23.2368H110.833C112.583 23.2368 114 24.5234 114 26.112V158.125C114 159.713 112.583 161 110.833 161ZM6.33333 155.25H107.667V28.9871H6.33333V155.25Z"
        fill={color}
      />
      <path
        d="M3.16718 28.987C1.78968 28.987 0.530932 28.1676 0.127182 26.9097C-0.355734 25.3859 0.610099 23.7902 2.28843 23.3517L90.9551 0.113279C91.913 -0.138297 92.9422 0.0342122 93.7339 0.580492C94.5255 1.12677 95.0005 1.97494 95.0005 2.88062V25.8747C95.0005 27.4632 93.5834 28.7498 91.8339 28.7498C90.0843 28.7498 88.6672 27.4632 88.6672 25.8747V6.69739L4.04593 28.872C3.75302 28.9511 3.4601 28.987 3.16718 28.987Z"
        fill={color}
      />
    </svg>
  )
}
