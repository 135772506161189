import React from 'react'
import { SVGProps } from './types'

export const DoubleCheck: React.FC<SVGProps> = ({
  size = 24,
  color = '#3BB07B',
  onClick,
}) => {
  const originalWidth = 26
  const originalHeight = 15
  const ratio = originalWidth / originalHeight
  const scaledWidth = size
  const scaledHeight = size / ratio
  return (
    <svg
      width={scaledWidth}
      height={scaledHeight}
      viewBox={`0 0 ${originalWidth} ${originalHeight}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.3713 10.7675L19.4445 3.69428L20.4571 2.68172C20.8476 2.29119 20.8476 1.65803 20.4571 1.2675C20.0666 0.876978 19.4334 0.876978 19.0429 1.2675L14.7019 5.60849L10.9571 9.35329L12.3713 10.7675ZM10.25 12.8888L11.6642 11.4746L10.25 10.0604L8.83579 11.4746L10.25 12.8888ZM8.12868 12.1817L7.75 12.5604L2.45711 7.2675C2.06658 6.87698 1.43342 6.87698 1.04289 7.2675C0.652369 7.65803 0.652369 8.29119 1.04289 8.68172L7.04289 14.6817C7.43342 15.0722 8.06658 15.0722 8.45711 14.6817L9.54289 13.5959L8.12868 12.1817Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.4571 1.2675C25.8476 1.65803 25.8476 2.29119 25.4571 2.68172L13.4571 14.6817C13.0666 15.0722 12.4334 15.0722 12.0429 14.6817L6.04289 8.68172C5.65237 8.29119 5.65237 7.65803 6.04289 7.2675C6.43342 6.87698 7.06658 6.87698 7.45711 7.2675L12.75 12.5604L24.0429 1.2675C24.4334 0.876978 25.0666 0.876978 25.4571 1.2675Z"
        fill={color}
      />
    </svg>
  )
}
