import React from 'react'
import { IconSizeType } from './PersonalTouch'

export const ContactCard: React.FC<IconSizeType> = ({
  width = 40,
  height = 40,
}) => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width={width}
      height={height}
      viewBox="0 0 40 40"
      enable-background="new 0 0 40 40"
      xmlSpace="preserve"
    >
      <g>
        <path
          shape-rendering="crispEdges"
          fill="#FFFFFF"
          d="M8,0h24c4.418,0,8,3.582,8,8v24c0,4.418-3.582,8-8,8H8c-4.418,0-8-3.582-8-8
		V8C0,3.582,3.582,0,8,0z"
        />
        <g>
          <defs>
            <rect id="SVGID_1_" x="11.292" y="10" width="17.417" height="20" />
          </defs>
          <clipPath id="SVGID_2_">
            <use xlinkHref="#SVGID_1_" overflow="visible" />
          </clipPath>
          <g clip-path="url(#SVGID_2_)">
            <path
              fill="#E68C96"
              d="M28.704,13.314c-0.001-1.504-1.021-2.808-2.477-3.2c-0.149-0.04-0.298-0.076-0.448-0.113H14.957
				c-0.042,0.014-0.083,0.031-0.126,0.04c-1.344,0.266-2.236,1.046-2.659,2.349c-0.128,0.393,0.063,0.771,0.422,0.881
				c0.368,0.113,0.725-0.085,0.855-0.475c0.32-0.958,1.024-1.466,2.039-1.466c3.255,0,6.511-0.001,9.766,0.002
				c0.187,0,0.377,0.022,0.561,0.059c0.74,0.148,1.56,0.84,1.556,2.085c-0.013,4.348-0.004,8.697-0.007,13.045
				c0,0.182-0.006,0.367-0.044,0.543c-0.207,0.962-0.985,1.6-1.986,1.602c-3.307,0.007-6.615,0.005-9.923-0.001
				c-0.218,0-0.446-0.033-0.651-0.103c-1.111-0.38-1.52-1.328-1.369-2.538h0.629c0.003,0.062,0.002,0.107,0.007,0.152
				c0.042,0.366,0.326,0.622,0.683,0.619c0.353-0.003,0.649-0.271,0.66-0.639c0.016-0.507,0.015-1.016-0.002-1.523
				c-0.011-0.347-0.284-0.606-0.627-0.637c-0.297-0.027-0.59,0.186-0.687,0.498c-0.017,0.054-0.035,0.107-0.053,0.166
				c-0.05,0-0.089,0-0.127,0c-0.625,0-1.25-0.003-1.875-0.001c-0.38,0.001-0.663,0.257-0.703,0.623
				c-0.035,0.329,0.207,0.637,0.562,0.713c0.054,0.012,0.109,0.019,0.171,0.03c0,0.229-0.001,0.443,0,0.657
				c0.012,1.827,1.497,3.313,3.323,3.314c3.34,0.003,6.68,0.003,10.02,0c1.829-0.001,3.326-1.479,3.329-3.307
				c0.008-4.459,0.006-8.918,0.001-13.377L28.704,13.314z"
            />
            <path
              fill="#E68C96"
              d="M23.203,18.353c-0.002-1.395-1.121-2.523-2.494-2.514c-1.392,0.009-2.507,1.123-2.507,2.505
				c0,1.379,1.128,2.505,2.509,2.507C22.078,20.852,23.206,19.722,23.203,18.353z M19.565,18.357
				c0.002-0.628,0.514-1.135,1.144-1.133c0.633,0.002,1.131,0.506,1.13,1.143c0,0.636-0.501,1.132-1.14,1.13
				C20.067,19.496,19.563,18.99,19.565,18.357z"
            />
            <path
              fill="#E68C96"
              d="M12.03,15.416c0,0.015,0,0.085,0,0.156c0,0.813,0,1.626,0,2.44c0,0.418,0.291,0.732,0.675,0.728
				c0.388-0.004,0.665-0.312,0.664-0.737c-0.001-0.813,0-1.626-0.001-2.44c0-0.069,0-0.138,0-0.215h0.643
				c0.017,0.058,0.029,0.102,0.042,0.146c0.098,0.323,0.395,0.541,0.697,0.509c0.352-0.038,0.615-0.305,0.622-0.663
				c0.01-0.494,0.013-0.989,0-1.483c-0.008-0.351-0.282-0.627-0.609-0.651c-0.362-0.026-0.66,0.205-0.727,0.565
				c-0.013,0.068-0.014,0.138-0.022,0.221c-0.081,0-0.152,0-0.222,0c-0.598,0-1.197-0.004-1.796,0
				c-0.291,0.002-0.513,0.13-0.636,0.402c-0.12,0.265-0.083,0.526,0.123,0.718C11.622,15.241,11.822,15.304,12.03,15.416
				L12.03,15.416z"
            />
            <path
              fill="#E68C96"
              d="M13.711,20.668h0.306c0.004,0.068,0.005,0.106,0.008,0.145c0.032,0.386,0.33,0.669,0.69,0.657
				c0.368-0.012,0.654-0.303,0.659-0.688c0.007-0.52,0.008-1.041,0-1.561c-0.007-0.449-0.383-0.748-0.817-0.665
				c-0.325,0.062-0.506,0.317-0.543,0.781c-0.651,0-1.287-0.002-1.924,0.001c-0.384,0.001-0.677,0.309-0.657,0.693
				c0.02,0.371,0.25,0.563,0.596,0.652c0,0.884,0,1.762,0,2.64c0,0.449,0.266,0.745,0.669,0.745c0.404,0,0.671-0.297,0.67-0.743
				c-0.001-0.813-0.001-1.626,0-2.439c0-0.069,0.006-0.138,0.011-0.218L13.711,20.668L13.711,20.668z"
            />
            <path
              fill="#E68C96"
              d="M17.501,22.867c-0.289,0.304-0.303,0.716-0.017,0.989c0.289,0.275,0.711,0.232,1-0.08
				c0.798-0.857,1.775-1.161,2.918-0.901c0.581,0.132,1.061,0.443,1.469,0.873c0.212,0.223,0.487,0.276,0.748,0.158
				c0.262-0.118,0.394-0.326,0.444-0.636c-0.074-0.146-0.119-0.32-0.227-0.433c-1.047-1.098-2.329-1.55-3.831-1.326
				C19.013,21.659,18.19,22.141,17.501,22.867L17.501,22.867z"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}
