import { Button, Flex, Text } from '@sendoutcards/quantum-design-ui'
import { SizeType } from '@sendoutcards/quantum-design-ui/dist/src/types/global'
import React from 'react'
import { EventDateType } from '../containers/types'

type EventBlockProps = {
  date: EventDateType
  button: {
    size: SizeType
    title: string
    onClick?: () => void
  }
  description: string
  alignment?: 'left' | 'center' | 'right'
}

export const EventBlock: React.FC<EventBlockProps> = ({
  date,
  button,
  description,
  alignment,
}) => {
  const alignItems =
    alignment === 'left'
      ? 'flex-start'
      : alignment === 'right'
      ? 'flex-end'
      : 'center'
  return (
    <Flex
      alignItems={alignItems}
      outset="x2"
      flexDirection="column"
      justifyContent="center"
      width="100%"
    >
      <Text
        weight="extraBold"
        type={{
          xSmall: 'body',
          medium: 'largeBody',
          large: 'subtitle',
        }}
        content={`${date.dayOfWeek}, ${date.month} ${date.day} at ${date.time}`}
      />
      <Text
        weight="extraBold"
        type={{
          xSmall: 'caption',
          medium: 'body',
          large: 'largeBody',
        }}
        content={description}
      />
      <Flex
        outset={{
          vertical: {
            xSmall: 'x1',
            small: 'x2',
          },
        }}
        justifyContent="flex-start"
      >
        <Button
          onClick={() => {
            button.onClick && button.onClick()
          }}
          size={button.size}
          title={button.title}
        />
      </Flex>
    </Flex>
  )
}
