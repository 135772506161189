import {
  Button,
  Flex,
  PinInput,
  Text,
  VStack,
} from '@sendoutcards/quantum-design-ui'
import React from 'react'

export type ConfirmEmailVerificationProps = {
  email: string
  onRequestAgain: () => void
  onSubmit: (code: string) => Promise<void>
}

export const ConfirmEmailVerification = (
  props: ConfirmEmailVerificationProps,
) => {
  const CODE_LENGTH = 6
  const { email, onRequestAgain, onSubmit } = props
  const [verificationCode, setVerificationCode] = React.useState('')

  return (
    <form
      onSubmit={event => {
        event.preventDefault()
        onSubmit(verificationCode)
      }}
      autoComplete="on"
    >
      <Flex justifyContent="center" flexDirection="column" alignItems="center">
        <Text type="body">
          Please enter the six-digit code emailed to
          <Text weight="bold" type="body">
            {email}
          </Text>
        </Text>
        <Flex outset="x4">
          <PinInput
            length={CODE_LENGTH}
            hasFocus={true}
            onChange={setVerificationCode}
            onComplete={onSubmit}
            message={undefined}
          />
        </Flex>
        <VStack gap="x2">
          <Button
            onClick="submitForm"
            title="Proceed"
            fullWidth={true}
            disabled={verificationCode.length < CODE_LENGTH}
          />
          <Button
            onClick={() => onRequestAgain()}
            fullWidth={true}
            outlined={true}
            title="Request Another Code"
          />
        </VStack>
      </Flex>
    </form>
  )
}
