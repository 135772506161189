export type Maybe<T> = T | null
export type If<T, V> = { __typename: T } & V
export type Operation<Data, Variables = undefined> = {
  query: string
  variables: Variables
}
export type ById<T> = { [id: string]: T | undefined }

export enum Currency {
  SOC = 'SOC',
  USD = 'USD',
  UNAVAILABLE = 'UNAVAILABLE',
}

export enum CardType {
  POSTCARD = 'POSTCARD',
  FLATCARD = 'FLATCARD',
  TWO_PANEL = 'TWO_PANEL',
  THREE_PANEL = 'THREE_PANEL',
  TWO_PANEL_BIG = 'TWO_PANEL_BIG',
}

export enum CardPaperType {
  STD = 'STD',
  PRE = 'PRE',
  HVY = 'HVY',
}

export enum CurrencyType {
  POINT = 'POINT',
  EXPENSE = 'EXPENSE',
  FREE = 'FREE',
  USD = 'USD',
  CREDIT = 'CREDIT',
  CARDTOKEN = 'CARDTOKEN',
}

export enum NotificationType {
  ANNIVERSARY = 'ANNIVERSARY',
  BIRTHDAY = 'BIRTHDAY',
  CAMPAIGN = 'CAMPAIGN',
  REJECTED_CARDS = 'REJECTED_CARDS',
  HELD_PRODUCTION_INFOS = 'HELD_PRODUCTION_INFOS',
}

export enum TextAlignment {
  LEFT = 'LEFT',
  CENTER = 'CENTER',
  RIGHT = 'RIGHT',
}

export enum PlansPlanIconChoices {
  A_10_CARD = 'A_10_CARD',
  A_15_CARD = 'A_15_CARD',
  A_20_CARD = 'A_20_CARD',
  A_25_CARD = 'A_25_CARD',
  BROCHURE = 'BROCHURE',
  CANCEL = 'CANCEL',
  CARD = 'CARD',
  CARD_ERROR = 'CARD_ERROR',
  CHECK = 'CHECK',
  CONTACT = 'CONTACT',
  DANGER = 'DANGER',
  DATABASE = 'DATABASE',
  DOUBLE_CHECK = 'DOUBLE_CHECK',
  EDIT = 'EDIT',
  EMPTY_GIFT = 'EMPTY_GIFT',
  GIFT = 'GIFT',
  HEARTFELT = 'HEARTFELT',
  INFO = 'INFO',
  MULTIPLE_CARDS = 'MULTIPLE_CARDS',
  PACKAGE = 'PACKAGE',
  PAPER_PLANE = 'PAPER_PLANE',
  PAPER_PLANE_FLYING = 'PAPER_PLANE_FLYING',
  PAPER_PLANE_WITH_HEART = 'PAPER_PLANE_WITH_HEART',
  REPLACE = 'REPLACE',
  SEARCH = 'SEARCH',
  SHOPPING_CART = 'SHOPPING_CART',
  UPLOAD = 'UPLOAD',
  USER = 'USER',
  V_CHECK = 'V_CHECK',
  X = 'X',
  SMILE_CARD = 'SMILE_CARD',
  SMILE_CARD_W_HEARTS = 'SMILE_CARD_W_HEARTS',
  SMILE_CARD_W_HEART = 'SMILE_CARD_W_HEART',
  SOLOPRENEUR = 'SOLOPRENEUR',
  ENTREPRENEUR = 'ENTREPRENEUR',
  BUSINESSPRENEUR = 'BUSINESSPRENEUR',
  CARD_PACK = 'CARD_PACK',
  TIME_BLOCK = 'TIME_BLOCK',
  EXP_CALENDAR = 'EXP_CALENDAR',
  CHECK_MARK = 'CHECK_MARK',
  TICKET = 'TICKET',
  AUTOMATED_CARD = 'AUTOMATED_CARD',
  CAMPAIGN_CARD = 'CAMPAIGN_CARD',
  GROUP_SEND_CARD = 'GROUP_SEND_CARD',
  SCHEDULE_CARD = 'SCHEDULE_CARD',
  MULTI_TOUCH_CARD = 'MULTI_TOUCH_CARD',
  CATALOG_CARD = 'CATALOG_CARD',
  PHOTO_DROP_CARD = 'PHOTO_DROP_CARD',
  ENHANCED_STORAGE = 'ENHANCED_STORAGE',
  PREMIUM_STORAGE = 'PREMIUM_STORAGE',
  PERSONAL_SIGNATURE = 'PERSONAL_SIGNATURE',
  PERSONAL_HAND_WRITING = 'PERSONAL_HAND_WRITING',
  ESSENTIAL_PACKS = 'ESSENTIAL_PACKS',
}

export enum SendDelayType {
  IMM = 'IMM',
  BIR = 'BIR',
  ANN = 'ANN',
  SPE = 'SPE',
}

export enum SendDelayDelayType {
  DAY = 'DAY',
  WEE = 'WEE',
  MON = 'MON',
}

export enum SendDelayTimeType {
  BEF = 'BEF',
  AFT = 'AFT',
}

export type AccountInput = {
  username?: Maybe<string>
  password?: Maybe<string>
  email?: Maybe<string>
  firstName?: Maybe<string>
  lastName?: Maybe<string>
  shippingAddress?: Maybe<AddressInput>
  phoneNumber?: Maybe<string>
  stripeToken?: Maybe<string>
  plan?: Maybe<string>
  keepBundlePlan?: Maybe<boolean>
  keepStreamMarketing?: Maybe<boolean>
  planAddon?: Maybe<string>
  expenseReload?: Maybe<ExpenseReloadInput>
  profileImage?: Maybe<string>
  sponsor?: Maybe<string>
  sponsorGenealogyId?: Maybe<string>
  sponsorSlug?: Maybe<string>
  isBetterWayPromo?: Maybe<boolean>
  settings?: Maybe<AccountSettingsInput>
  storefront?: Maybe<UpdateStorefrontInput>
}

export const AccountInput = ({
  username,
  password,
  email,
  firstName,
  lastName,
  shippingAddress,
  phoneNumber,
  stripeToken,
  plan,
  keepBundlePlan,
  keepStreamMarketing,
  planAddon,
  expenseReload,
  profileImage,
  sponsor,
  sponsorGenealogyId,
  sponsorSlug,
  isBetterWayPromo,
  settings,
  storefront,
}: AccountInput = {}): AccountInput => ({
  username,
  password,
  email,
  firstName,
  lastName,
  shippingAddress: shippingAddress && AddressInput(shippingAddress),
  phoneNumber,
  stripeToken,
  plan,
  keepBundlePlan,
  keepStreamMarketing,
  planAddon,
  expenseReload: expenseReload && ExpenseReloadInput(expenseReload),
  profileImage,
  sponsor,
  sponsorGenealogyId,
  sponsorSlug,
  isBetterWayPromo,
  settings: settings && AccountSettingsInput(settings),
  storefront: storefront && UpdateStorefrontInput(storefront),
})

export type AddressInput = {
  firstName: string
  lastName: string
  company: string
  address1: string
  address2: string
  city: string
  state: string
  postalCode: string
  country: string
}

export const AddressInput = ({
  firstName,
  lastName,
  company,
  address1,
  address2,
  city,
  state,
  postalCode,
  country,
}: AddressInput): AddressInput => ({
  firstName,
  lastName,
  company,
  address1,
  address2,
  city,
  state,
  postalCode,
  country,
})

export type ExpenseReloadInput = {
  purchaseAmount: number
  threshold: number
  isEnabled: boolean
}

export const ExpenseReloadInput = ({
  purchaseAmount,
  threshold,
  isEnabled,
}: ExpenseReloadInput): ExpenseReloadInput => ({
  purchaseAmount,
  threshold,
  isEnabled,
})

export type AccountSettingsInput = {
  defaultBackPanelId?: Maybe<string>
  receivesNotificationEmails?: Maybe<boolean>
  receivesPromoEmails?: Maybe<boolean>
  receivesReceiptEmails?: Maybe<boolean>
  receivesReminderEmails?: Maybe<boolean>
  printRecipientCompany?: Maybe<boolean>
  remindersAdvanceNoticeDays?: Maybe<number>
  remindersAdvanceNoticeDaysInternational?: Maybe<number>
}

export const AccountSettingsInput = ({
  defaultBackPanelId,
  receivesNotificationEmails,
  receivesPromoEmails,
  receivesReceiptEmails,
  receivesReminderEmails,
  printRecipientCompany,
  remindersAdvanceNoticeDays,
  remindersAdvanceNoticeDaysInternational,
}: AccountSettingsInput = {}): AccountSettingsInput => ({
  defaultBackPanelId,
  receivesNotificationEmails,
  receivesPromoEmails,
  receivesReceiptEmails,
  receivesReminderEmails,
  printRecipientCompany,
  remindersAdvanceNoticeDays,
  remindersAdvanceNoticeDaysInternational,
})

export type UpdateStorefrontInput = {
  slug?: Maybe<string>
  biography?: Maybe<string>
}

export const UpdateStorefrontInput = ({
  slug,
  biography,
}: UpdateStorefrontInput = {}): UpdateStorefrontInput => ({
  slug,
  biography,
})

export enum VerticalID {
  SENDOUTCARDS = 'SENDOUTCARDS',
  BLUE_42 = 'BLUE_42',
  BELLA_CADEAUX = 'BELLA_CADEAUX',
  STREAM_VA = 'STREAM_VA',
  STREAM_MARKETING = 'STREAM_MARKETING',
  PROMPTING_U = 'PROMPTING_U',
  GREENER_STILL = 'GREENER_STILL',
  BYDESIGN = 'BYDESIGN',
}

export enum ValidateEmailVerificationCodeFailureType {
  NOT_FOUND = 'NOT_FOUND',
  TOO_MANY_ATTEMPTS = 'TOO_MANY_ATTEMPTS',
  INCORRECT_CODE = 'INCORRECT_CODE',
  DENIED = 'DENIED',
}

export enum SubscriptionStatus {
  ACTIVE = 'ACTIVE',
  CANCELED = 'CANCELED',
  PAST_DUE = 'PAST_DUE',
  TRIALING = 'TRIALING',
  UNPAID = 'UNPAID',
}

export type ItemObject = {
  itemCode: string
  quantity?: Maybe<number>
}

export const ItemObject = ({ itemCode, quantity }: ItemObject): ItemObject => ({
  itemCode,
  quantity,
})

export type CreateSocProContactInput = {
  contactId?: Maybe<string>
  metadataBlob?: Maybe<string>
}

export const CreateSocProContactInput = ({
  contactId,
  metadataBlob,
}: CreateSocProContactInput = {}): CreateSocProContactInput => ({
  contactId,
  metadataBlob,
})

export enum RequestEmailVerificationFailureType {
  INVALID_EMAIL = 'INVALID_EMAIL',
  TOO_MANY_ATTEMPTS = 'TOO_MANY_ATTEMPTS',
}

export type SubscriptionInput = {
  id?: Maybe<string>
  plan?: Maybe<string>
  cancelAtPeriodEnd?: Maybe<boolean>
}

export const SubscriptionInput = ({
  id,
  plan,
  cancelAtPeriodEnd,
}: SubscriptionInput = {}): SubscriptionInput => ({
  id,
  plan,
  cancelAtPeriodEnd,
})

export enum LayoutCategory {
  BASIC_LAYOUTS = 'BASIC_LAYOUTS',
  BACK_PANELS = 'BACK_PANELS',
}

export enum LayoutDimensions {
  VERTICAL = 'VERTICAL',
  HORIZONTAL = 'HORIZONTAL',
  POSTCARD = 'POSTCARD',
}

export enum Owner {
  USER = 'USER',
  SYSTEM = 'SYSTEM',
}

export enum MarketingPricingGridRowItemIconChoices {
  A_10_CARD = 'A_10_CARD',
  A_15_CARD = 'A_15_CARD',
  A_20_CARD = 'A_20_CARD',
  A_25_CARD = 'A_25_CARD',
  BROCHURE = 'BROCHURE',
  CANCEL = 'CANCEL',
  CARD = 'CARD',
  CARD_ERROR = 'CARD_ERROR',
  CHECK = 'CHECK',
  CONTACT = 'CONTACT',
  DANGER = 'DANGER',
  DATABASE = 'DATABASE',
  DOUBLE_CHECK = 'DOUBLE_CHECK',
  EDIT = 'EDIT',
  EMPTY_GIFT = 'EMPTY_GIFT',
  GIFT = 'GIFT',
  HEARTFELT = 'HEARTFELT',
  INFO = 'INFO',
  MULTIPLE_CARDS = 'MULTIPLE_CARDS',
  PACKAGE = 'PACKAGE',
  PAPER_PLANE = 'PAPER_PLANE',
  PAPER_PLANE_FLYING = 'PAPER_PLANE_FLYING',
  PAPER_PLANE_WITH_HEART = 'PAPER_PLANE_WITH_HEART',
  REPLACE = 'REPLACE',
  SEARCH = 'SEARCH',
  SHOPPING_CART = 'SHOPPING_CART',
  UPLOAD = 'UPLOAD',
  USER = 'USER',
  V_CHECK = 'V_CHECK',
  X = 'X',
  SMILE_CARD = 'SMILE_CARD',
  SMILE_CARD_W_HEARTS = 'SMILE_CARD_W_HEARTS',
  SMILE_CARD_W_HEART = 'SMILE_CARD_W_HEART',
  SOLOPRENEUR = 'SOLOPRENEUR',
  ENTREPRENEUR = 'ENTREPRENEUR',
  BUSINESSPRENEUR = 'BUSINESSPRENEUR',
  CARD_PACK = 'CARD_PACK',
  TIME_BLOCK = 'TIME_BLOCK',
  EXP_CALENDAR = 'EXP_CALENDAR',
  CHECK_MARK = 'CHECK_MARK',
  TICKET = 'TICKET',
  AUTOMATED_CARD = 'AUTOMATED_CARD',
  CAMPAIGN_CARD = 'CAMPAIGN_CARD',
  GROUP_SEND_CARD = 'GROUP_SEND_CARD',
  SCHEDULE_CARD = 'SCHEDULE_CARD',
  MULTI_TOUCH_CARD = 'MULTI_TOUCH_CARD',
  CATALOG_CARD = 'CATALOG_CARD',
  PHOTO_DROP_CARD = 'PHOTO_DROP_CARD',
  ENHANCED_STORAGE = 'ENHANCED_STORAGE',
  PREMIUM_STORAGE = 'PREMIUM_STORAGE',
  PERSONAL_SIGNATURE = 'PERSONAL_SIGNATURE',
  PERSONAL_HAND_WRITING = 'PERSONAL_HAND_WRITING',
  ESSENTIAL_PACKS = 'ESSENTIAL_PACKS',
}

export type NormalizedMarketingURL = {
  __typename: 'MarketingURL'
  id: string
  title: string
  url: string
}

export type NormalizedCreditCard = {
  __typename: 'CreditCard'
  id: string
  brand: string
  last4: string
  expMonth: number
  expYear: number
  object: string
}

export type NormalizedPlan = {
  color: Maybe<string>
  __typename: 'Plan'
  isExpenseReloadRequired: boolean
  stripeId: string
  price: {
    __typename: 'Amount'
    amount: number
    currency: Currency
    description: string
  }
  bulkCardCost: number
  backgroundStartColor: string
  giftDiscount: number
  backgroundEndColor: string
  isAddon: boolean
  subtitle: Maybe<string>
  title: string
  icon: PlansPlanIconChoices
  id: string
  description: Maybe<string>
  cardCost: number
}

export type NormalizedCardCategory = {
  __typename: 'CardCategory'
  id: string
  description: string
  cards: {
    __typename: 'SendableCard'
    id: string
  }[]
}

export type NormalizedCard = {
  insidePreviewUrl?: Maybe<string>
  __typename: 'Card'
  paperType?: CardPaperType
  cost?: {
    total: {
      asString: string
      currency: CurrencyType
      amount: number
    }[]
    entries: {
      label: string
      asString: string
      currency: CurrencyType
      amount: number
    }[]
  }
  backPreviewUrl?: string
  isHorizontal: boolean
  panels?: {
    location: number
    isFullBleed: boolean
    backgroundColor: Maybe<{
      red: number
      green: number
      blue: number
    }>
    name: string
    width: number
    height: number
    previewUrl: string
    elements: {
      width: number
      height: number
      locked: boolean
      text: Maybe<{
        paragraphs: {
          textFragments: {
            text: string
            textColor: {
              red: number
              green: number
              blue: number
            }
            font: {
              id: string
              fontName: string
            }
            fontSize: number
            isReplacement: boolean
          }[]
          alignment: TextAlignment
        }[]
      }>
      x: number
      y: number
      z: number
      image: Maybe<{
        image: Maybe<{
          id: string
          url: string
          smallThumb: string
          width: number
          height: number
        }>
        position: {
          x: number
          y: number
        }
        scale: number
        filter: Maybe<{
          name: string
        }>
      }>
      rotation: number
    }[]
  }[]
  frontPreviewUrl: string
  backPanelLocation?: number
  sendableCard?: Maybe<{
    variations: {
      isFavorite: boolean
      cardTemplate: Maybe<{
        backPanelLocation: number
        panels: {
          location: number
          isFullBleed: boolean
          backgroundColor: Maybe<{
            red: number
            green: number
            blue: number
          }>
          name: string
          width: number
          height: number
          previewUrl: string
          elements: {
            width: number
            height: number
            locked: boolean
            text: Maybe<{
              paragraphs: {
                textFragments: {
                  text: string
                  textColor: {
                    red: number
                    green: number
                    blue: number
                  }
                  font: {
                    id: string
                    fontName: string
                  }
                  fontSize: number
                  isReplacement: boolean
                }[]
                alignment: TextAlignment
              }[]
            }>
            x: number
            y: number
            z: number
            image: Maybe<{
              image: Maybe<{
                id: string
                url: string
                smallThumb: string
                width: number
                height: number
              }>
              position: {
                x: number
                y: number
              }
              scale: number
              filter: Maybe<{
                name: string
              }>
            }>
            rotation: number
          }[]
        }[]
      }>
      isPremium: boolean
      cost: {
        total: {
          asString: string
        }[]
      }
      isHorizontal: boolean
      variationColor: string
      frontImage: {
        id: string
        url: string
        width: number
        height: number
        smallThumb: string
        mediumThumb: string
      }
      title: string
      id: string
      insideRightImage: Maybe<{
        id: string
        url: string
        width: number
        height: number
        smallThumb: string
        mediumThumb: string
      }>
      description: string
    }[]
    isFavorite: boolean
    cardTemplate: Maybe<{
      backPanelLocation: number
      panels: {
        location: number
        isFullBleed: boolean
        backgroundColor: Maybe<{
          red: number
          green: number
          blue: number
        }>
        name: string
        width: number
        height: number
        previewUrl: string
        elements: {
          width: number
          height: number
          locked: boolean
          text: Maybe<{
            paragraphs: {
              textFragments: {
                text: string
                textColor: {
                  red: number
                  green: number
                  blue: number
                }
                font: {
                  id: string
                  fontName: string
                }
                fontSize: number
                isReplacement: boolean
              }[]
              alignment: TextAlignment
            }[]
          }>
          x: number
          y: number
          z: number
          image: Maybe<{
            image: Maybe<{
              id: string
              url: string
              smallThumb: string
              width: number
              height: number
            }>
            position: {
              x: number
              y: number
            }
            scale: number
            filter: Maybe<{
              name: string
            }>
          }>
          rotation: number
        }[]
      }[]
    }>
    isPremium: boolean
    cost: {
      total: {
        asString: string
      }[]
    }
    isHorizontal: boolean
    variationColor: string
    frontImage: {
      id: string
      url: string
      width: number
      height: number
      smallThumb: string
      mediumThumb: string
    }
    title: string
    id: string
    insideRightImage: Maybe<{
      id: string
      url: string
      width: number
      height: number
      smallThumb: string
      mediumThumb: string
    }>
    description: string
  }>
  showInPicturePlus?: boolean
  tinyThumb?: string
  type?: CardType
  id: string
  flapPreviewUrl?: Maybe<string>
}

export type NormalizedSendableCard = {
  variations?: {
    isFavorite: boolean
    cardTemplate: Maybe<{
      backPanelLocation: number
      panels: {
        location: number
        isFullBleed: boolean
        backgroundColor: Maybe<{
          red: number
          green: number
          blue: number
        }>
        name: string
        width: number
        height: number
        previewUrl: string
        elements: {
          width: number
          height: number
          locked: boolean
          text: Maybe<{
            paragraphs: {
              textFragments: {
                text: string
                textColor: {
                  red: number
                  green: number
                  blue: number
                }
                font: {
                  id: string
                  fontName: string
                }
                fontSize: number
                isReplacement: boolean
              }[]
              alignment: TextAlignment
            }[]
          }>
          x: number
          y: number
          z: number
          image: Maybe<{
            image: Maybe<{
              id: string
              url: string
              smallThumb: string
              width: number
              height: number
            }>
            position: {
              x: number
              y: number
            }
            scale: number
            filter: Maybe<{
              name: string
            }>
          }>
          rotation: number
        }[]
      }[]
    }>
    isPremium: boolean
    cost: {
      total: {
        asString: string
      }[]
    }
    isHorizontal: boolean
    variationColor: string
    frontImage: {
      id: string
      url: string
      width: number
      height: number
      smallThumb: string
      mediumThumb: string
    }
    title: string
    id: string
    insideRightImage: Maybe<{
      id: string
      url: string
      width: number
      height: number
      smallThumb: string
      mediumThumb: string
    }>
    description: string
  }[]
  __typename: 'SendableCard'
  isFavorite: boolean
  cardTemplate?: Maybe<{
    backPanelLocation: number
    panels: {
      location: number
      isFullBleed: boolean
      backgroundColor: Maybe<{
        red: number
        green: number
        blue: number
      }>
      name: string
      width: number
      height: number
      previewUrl: string
      elements: {
        width: number
        height: number
        locked: boolean
        text: Maybe<{
          paragraphs: {
            textFragments: {
              text: string
              textColor: {
                red: number
                green: number
                blue: number
              }
              font: {
                id: string
                fontName: string
              }
              fontSize: number
              isReplacement: boolean
            }[]
            alignment: TextAlignment
          }[]
        }>
        x: number
        y: number
        z: number
        image: Maybe<{
          image: Maybe<{
            id: string
            url: string
            smallThumb: string
            width: number
            height: number
          }>
          position: {
            x: number
            y: number
          }
          scale: number
          filter: Maybe<{
            name: string
          }>
        }>
        rotation: number
      }[]
    }[]
  }>
  isPremium: boolean
  cost?: {
    total: {
      currency: CurrencyType
      asString: string
      amount: number
    }[]
  }
  isHorizontal: boolean
  variationColor: string
  frontImage: {
    id: string
    url: string
    width: number
    height: number
    smallThumb: string
    mediumThumb: string
  }
  title: string
  id: string
  insideRightImage?: Maybe<{
    id: string
    url: string
    width: number
    height: number
    smallThumb: string
    mediumThumb: string
  }>
  description?: string
}

export type NormalizedSocProContact = {
  __typename: 'SocProContact'
  id: string
  contactId: string
  metadataBlob: string
  createdAt: string
  updatedAt: string
}

export type NormalizedMarketingMultiParagraph = {
  __typename: 'MarketingMultiParagraph'
  id: string
  title: string
  content: string
  createdAt: string
  updatedAt: string
}

export type NormalizedCollection = {
  backgroundImageUrl: Maybe<string>
  cards: {
    __typename: 'SendableCard'
    id: string
  }[]
  __typename: 'Collection'
  reasonToSend: boolean
  backgroundStartColor: string
  textColor: string
  backgroundEndColor: string
  title: string
  id: string
  description: string
}

export type NormalizedData = {
  MarketingURL: ById<NormalizedMarketingURL>
  CreditCard: ById<NormalizedCreditCard>
  Plan: ById<NormalizedPlan>
  CardCategory: ById<NormalizedCardCategory>
  Card: ById<NormalizedCard>
  SendableCard: ById<NormalizedSendableCard>
  SocProContact: ById<NormalizedSocProContact>
  MarketingMultiParagraph: ById<NormalizedMarketingMultiParagraph>
  Collection: ById<NormalizedCollection>
}

export const NormalizedData = (): NormalizedData => ({
  MarketingURL: {},
  CreditCard: {},
  Plan: {},
  CardCategory: {},
  Card: {},
  SendableCard: {},
  SocProContact: {},
  MarketingMultiParagraph: {},
  Collection: {},
})
