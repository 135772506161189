import { Route } from './Route'
import { Subroute } from './Subroute'
import { AppRoute } from 'src/app/routes/AppRoute'
// marketing routes to remove
import { FunnelPageRoute } from 'src/marketing/storefront/routes/FunnelPageRoute'

export const BaseSubroute = Subroute(AppRoute, FunnelPageRoute)
export type BaseSubroute = Subroute<typeof BaseSubroute>

export const BaseRoute = Route({
  path: '/',
  init: (subroute?: BaseSubroute): { subroute?: BaseSubroute } => ({
    subroute,
  }),
  subroute: BaseSubroute,
})
// Declared as an interface to avoid self-reference issues
export interface BaseRoute {
  path: '/'
  subroute?: BaseSubroute
}

export const isFunnelPageRoute = (
  route: BaseSubroute,
): route is FunnelPageRoute => route.path === FunnelPageRoute.path
