import { If } from '../../../__generated__/globalTypes'

import { CreditCardFragment, isCreditCardFragment } from './CreditCardFragment'

import stripeSourceFragmentRawString from '../StripeSourceFragment.graphql'

export const stripeSourceFragmentString = stripeSourceFragmentRawString

export type StripeSourceFragment = {
  __typename: 'CreditCard' | 'BankAccount'
} & (If<'CreditCard', CreditCardFragment> | If<'BankAccount', {}>)

export const isStripeSourceFragment = (
  fragment: any,
): fragment is StripeSourceFragment =>
  fragment &&
  (fragment.__typename === 'CreditCard' ||
    fragment.__typename === 'BankAccount') &&
  ((fragment.__typename === 'CreditCard' &&
    (isCreditCardFragment(fragment) as boolean)) ||
    fragment.__typename === 'BankAccount')
