import React, { FC, useContext, useState } from 'react'
import {
  Button,
  Div,
  Input,
  LoadingSpinner,
  Spacer,
  Text,
  VStack,
} from '@sendoutcards/quantum-design-ui'
import {
  EditCard,
  ExpenseReloadForm,
  PaymentTab,
} from 'src/payments/components'
import {
  AccountFragment,
  AccountInput,
  UpdateAccount,
  UpdateAccountData,
} from 'src/graphql'
import { creditCardBrand } from 'src/payments/components/CardLogo/CreditCardLogo'
import JoinAddressForm from './JoinAddressForm'
import { performOperation } from 'src/app/api'
import { PaymentFormState, PromoContext } from '../context/promoContext'

export type UpsaleResponseData = {
  title: string
  subTitle: string
  isLoading: boolean
  loadedStatus: 'success' | 'error' | undefined
}

type InnerPaymentSheetProps = {
  planId: string
  onAccountUpdated: (updatedAccount: AccountFragment) => void
}
const InnerPaymentSheet: FC<InnerPaymentSheetProps> = props => {
  const { planId, onAccountUpdated } = props

  const { state, dispatch } = useContext(PromoContext)
  const {
    account,
    payment: {
      form,
      isUpdatingAccount,
      expenseReload,
      shouldHaveExpenseBucket,
    },
  } = state

  const [errorMessage, setErrorMessage] = useState<string | undefined>()

  const hasShippingAddress =
    account?.shippingAddress && account?.shippingAddress.address1
  const hasFirstLastName = !!account?.firstName && !!account?.lastName
  const stripeSource = account?.stripeSource
  const [isAddingCard, setIsAddingCard] = useState(false)
  const [isFirstNameValid, setIsFirstNameValid] = useState(!!form.firstName)
  const [isLastNameValid, setIsLastNameValid] = useState(!!form.lastName)

  const [addCCardError, setAddCCardError] = useState<string | undefined>(
    undefined,
  )

  const validateNameFields = (
    value: string,
    setFieldValidation: (value: React.SetStateAction<boolean>) => void,
    setName: (value: string) => void,
  ) => {
    setName(value)
    value ? setFieldValidation(true) : setFieldValidation(false)
  }

  // Adding credit card to account
  const submitCard = async (token?: stripe.Token) => {
    setIsAddingCard(true)
    const accountInfo: AccountInput = { stripeToken: token ? token.id : null }

    performOperation<UpdateAccountData>(
      UpdateAccount({ account: accountInfo }),
      result => {
        if (result.value) {
          onAccountUpdated(result.value.updateAccount.account)
        } else if (result.error) {
          onError(result.error.message)
        }
        setIsAddingCard(false)
      },
    )
  }

  const onError = (error: string) => {
    try {
      const errorMessage = error.split(': ')[1]
      setAddCCardError(errorMessage)
    } catch {
      setAddCCardError('Failed to add card. Contact Support for assistance.')
    }
  }

  const handleChangeExpense = (name: string, value: number) => {
    dispatch({
      type: 'SET_EXPENSE_RELOAD',
      payload: {
        ...expenseReload,
        [name]: value,
      },
    })
  }

  const setForm = (payload: PaymentFormState) => {
    dispatch({ type: 'SET_PAYMENT_FORM', payload })
  }

  React.useEffect(() => {
    dispatch({ type: 'SET_SHOULD_HAVE_EXPENSE_BUCKET', payload: { planId } })
  }, [planId, dispatch])

  return (
    <VStack gap="x2">
      {isUpdatingAccount ? (
        <Div>
          <Spacer space="x5" />
          <LoadingSpinner size="xLarge" />
          <Spacer space="x4" />
          <Text type="largeBody" alignment="center">
            Please wait while we update your account.
          </Text>
        </Div>
      ) : errorMessage !== undefined ? (
        <Div>
          <Spacer space="x5" />
          <Text type="subtitle">An error occurred</Text>
          <Spacer space="x2_5" />
          <Text type="body">{errorMessage}</Text>
          <Spacer space="x2_5" />
          <Button
            type="secondary"
            title="BACK"
            fullWidth={true}
            onClick={() => setErrorMessage(undefined)}
          />
        </Div>
      ) : (
        <Div id="user-info-flow" overflowX="hidden">
          <Spacer space="x1" />
          {!hasFirstLastName && (
            <Div id="user-info-form">
              <Spacer space="x1" />
              <Text
                type="body"
                weight="bold"
                content="Step 2: Add Billing Information"
                color="primaryHeading"
                style={{ textDecoration: 'underline' }}
              />
              <Spacer space="x1" />
              <Text
                type="caption"
                content="First Name"
                color={isFirstNameValid ? 'primaryBody' : 'danger'}
              />
              <Input
                type="text"
                placeholder="First Name"
                value={form.firstName}
                onChange={value =>
                  validateNameFields(
                    value,
                    setIsFirstNameValid,
                    (value: string) => setForm({ ...form, firstName: value }),
                  )
                }
                isFullWidth={true}
              />
              <Spacer space="x2" />
              <Text
                type="caption"
                content="Last Name"
                color={isLastNameValid ? 'primaryBody' : 'danger'}
              />
              <Input
                type="text"
                placeholder="Last Name"
                value={form.lastName}
                onChange={value =>
                  validateNameFields(
                    value,
                    setIsLastNameValid,
                    (value: string) => setForm({ ...form, lastName: value }),
                  )
                }
                isFullWidth={true}
              />
              <Spacer space="x2" />
            </Div>
          )}
          {!hasShippingAddress && (
            <>
              <Text type="body">Billing Address</Text>
              <JoinAddressForm
                shippingAddress={form.shippingAddress}
                onChange={address =>
                  setForm({ ...form, shippingAddress: address })
                }
              />
              <Spacer space="x2" />
            </>
          )}
          {shouldHaveExpenseBucket && (
            <>
              <Spacer space="x2" />
              <Text type="body" color="primaryHeading">
                This plan requires an Expense Bucket:
              </Text>
              <Spacer space="x1_5" />
              <ExpenseReloadForm
                expenseReload={expenseReload}
                onChange={handleChangeExpense}
              />
              <Text
                type="footnote"
                inset="x2"
              >{`*We will charge $${expenseReload.purchaseAmount} of expense to your card today`}</Text>
            </>
          )}
          {!stripeSource || stripeSource?.__typename !== 'CreditCard' ? (
            <Div>
              <Text type="body" content="Attach your credit card to checkout" />
              <EditCard
                onSuccess={submitCard}
                onError={onError}
                isSubmitButtonVisible={true}
                isAddingCard={isAddingCard}
              />
              {addCCardError !== undefined && (
                <Text type="caption" color="danger">
                  {addCCardError}
                </Text>
              )}
            </Div>
          ) : (
            <>
              <Div>
                <PaymentTab
                  paymentType={'card'}
                  brand={creditCardBrand(stripeSource.brand)}
                />
                <Spacer space="x1_5" />
                <Div display="flex" alignItems="center">
                  <Text type="body">Credit Card Information</Text>
                </Div>
                <Spacer space="x2" />
                <Div
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  css={{
                    '@media (max-width: 1200px)': {
                      display: 'block',
                    },
                  }}
                >
                  <Div>
                    <Text type="caption">
                      Card Number: **** **** **** {stripeSource.last4}
                    </Text>
                    <Text type="caption">
                      Exp: {stripeSource.expMonth}/{stripeSource.expYear}
                    </Text>
                  </Div>
                  <Button
                    fullWidth={false}
                    type="secondary"
                    title={isAddingCard ? 'Removing Card...' : 'Remove Card'}
                    disabled={isAddingCard}
                    onClick={() => submitCard()}
                  />
                </Div>
              </Div>
              <Spacer space="x1_5" />
            </>
          )}
        </Div>
      )}
    </VStack>
  )
}

export default InnerPaymentSheet
