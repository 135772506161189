import axios from 'axios'
import { Location } from 'history'
import { BaseRoute, BaseSubroute } from 'src/routes/BaseRoute'
import { AppRoute } from 'src/app/routes/AppRoute'
import { PricingRoute } from 'src/pricing_page/routes/PricingRoute'
import { CatalogRoute } from 'src/catalog/routes/CatalogRoute'
import { PromoRoute } from 'src/promo/routes/PromoRoute'
import Action from 'src/redux/action'
import Result from 'src/utils/Result'
import { openRoute } from 'src/redux/actions/routes'
import { performOperation } from '../app/api'
import {
  CreateInvite,
  CreateInviteData,
  CreateSocProContact,
  CreateSocProContactData,
} from 'src/graphql'
// marketing routes to remove
import { FunnelPageRoute } from 'src/marketing/storefront/routes/FunnelPageRoute'
import { BusinessRoute } from 'src/marketing/routes/BusinessRoute'
import { Store } from 'src/redux/store'
import { createdInvite, creatingInvite } from 'src/redux/actions/invites'
import { TrainingRoute } from 'src/training/routes/TrainingRoute'
import { AudereRoute } from 'src/marketing/routes/AudereRoute'
// Types

type QueryParamType =
  | 'access_token'
  | 'utm_term'
  | 'utm_campaign'
  | 'utm_medium'
  | 'utm_source'
  | 'utm_content'
  | 'utm_contact'
  | 'utm_distributor'
  | 'utm_stream'
  | 'promo'
  | 'sponsor'
  | 'promotion'
  | 'bulk'
  | 'identifier'

export type InviteData = {
  utmTerm: string
  utmCampaign: string
  utmContent: string
  utmMedium: string
  utmSource: string
  sponsorId: string
  utmStream: string
}

/* /join/?sponsor_pk={pk} is the old direct join link that we need to honor */
const legacyActionForLocation = (
  location: Location,
):
  | { subroute?: BaseSubroute; slug?: string; sponsorId?: string }
  | undefined => {
  if (
    location.pathname.startsWith('/join') &&
    location.search.startsWith('?sponsor_pk=')
  ) {
    const sponsorId = location.search.split('?sponsor_pk=')[1]
    return { subroute: FunnelPageRoute(sponsorId, 'sponsorId'), sponsorId }
  } else {
    return undefined
  }
}

const slugActionForPath = (
  pathname: string,
  promotion?: true,
):
  | { subroute?: BaseSubroute; slug?: string; sponsorId?: string }
  | undefined => {
  const slugRegex = /\/(u\/)?(?<slug>[-\w\d]+)(?<destination>\/send|\/business|\/pricing|\/promo|\/training-schedule|)/
  const match = slugRegex.exec(pathname)
  if (match !== null && match.groups !== undefined) {
    const slug = match.groups.slug
    const destination = match.groups.destination
    return {
      subroute: (() => {
        switch (destination) {
          case '/audere':
            return AppRoute(AudereRoute())
          case '/send':
            return AppRoute(CatalogRoute(undefined, true, undefined, promotion))
          case '/business':
            return AppRoute(BusinessRoute())
          case '/pricing':
            return AppRoute(PricingRoute())
          case '/promo':
            return AppRoute(PromoRoute())
          case '/training-schedule':
            return AppRoute(TrainingRoute())
          default:
            return AppRoute(undefined)
        }
      })(),
      slug,
    }
  } else {
    return undefined
  }
}

const parseQueryParams = (
  location: Location<any>,
): { [key in QueryParamType]?: string } => {
  const params = new URLSearchParams(location.search)
  const result: { [key in QueryParamType]?: string } = Object.fromEntries(
    params,
  )
  return result
}

async function fetchUserData(id: string) {
  const userRequest = await axios.get(
    'https://promptingpro.mysecureoffice.com/remote/contacts/view.json',
    {
      params: {
        master_username: 'sendoutcards-api',
        master_password: 'CheerConfessionAuntUpright',
        id: id,
      },
    },
  )

  return userRequest.data.contact
}

async function createInvite(
  result: Result<CreateSocProContactData>,
  inviteData: InviteData,
) {
  if (!result.error && result.value) {
    const {
      createSocProContact: { socProContact },
    } = result.value
    performOperation<CreateInviteData>(
      CreateInvite({ socProContactId: socProContact.id, ...inviteData }),
      result => {
        if (result.value) {
          Store.dispatch(createdInvite(result.value.createInvite.invite))
        }
      },
    )
  }
}

async function createSocProContactAndInvite(
  inviteData: InviteData,
  contactId?: string,
) {
  Store.dispatch(creatingInvite())
  // axios call to VerbTech API
  const userData = await fetchUserData(contactId ? contactId : '')

  const replacer = <Value>(key: string, value: Value): Value | string =>
    value === null ? '' : value

  const sanitizedJsonString = JSON.stringify(userData, replacer)
  // graphql calls to create SocProContact, then create Invite
  performOperation<CreateSocProContactData>(
    CreateSocProContact({
      socProContact: {
        contactId: contactId,
        metadataBlob: sanitizedJsonString,
      },
    }),
    result => createInvite(result, inviteData),
  )
}

export default (location: Location<any>): Action => {
  const route = BaseRoute.fromPath(location.pathname, location)
  const queryParams: { [key in QueryParamType]?: string } = parseQueryParams(
    location,
  )
  const {
    access_token: token,
    utm_term: utmTerm,
    utm_campaign: utmCampaign,
    utm_content: utmContent,
    utm_medium: utmMedium,
    utm_source: utmSource,
    utm_contact: utmContact,
    utm_distributor: utmDistributor,
    utm_stream: utmStream,
    promo,
    sponsor,
    promotion,
    bulk,
    identifier,
  } = queryParams

  const inviteData: InviteData = {
    utmTerm: utmTerm ?? '',
    utmCampaign: utmCampaign ?? '',
    utmContent: utmContent ?? '',
    utmMedium: utmMedium ?? '',
    utmSource: utmSource ?? '',
    utmStream: utmStream ?? 'gspro',
    sponsorId: utmDistributor ?? '',
  }

  const hasInviteData = !!utmContact && !!utmDistributor

  if (hasInviteData && utmDistributor) {
    createSocProContactAndInvite(inviteData, utmContact)
    return openRoute(
      BaseRoute(AppRoute(CatalogRoute(undefined, true))),
      utmDistributor,
      undefined,
      token,
      undefined,
      bulk,
      identifier,
    )
  } else if (
    route &&
    (!route.subroute || route.subroute.path !== FunnelPageRoute.path)
  ) {
    return openRoute(route, sponsor, undefined, token, promo, bulk, identifier)
  } else {
    const isPromotion = promotion === undefined ? promotion : true
    const { subroute, slug, sponsorId } = legacyActionForLocation(location) ||
      slugActionForPath(location.pathname, isPromotion) || {
        subroute: undefined,
        slug: undefined,
        sponsorId: undefined,
      }

    return openRoute(
      BaseRoute(subroute),
      slug,
      sponsorId,
      token,
      promo,
      bulk,
      identifier,
    )
  }
}
