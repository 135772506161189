import React from 'react'
import { Dialog, Flex } from '@sendoutcards/quantum-design-ui'
import { RequestEmailVerification } from 'src/email_verification/VerifyEmail/RequestEmailVerification'
import {
  ConfirmEmailVerification,
  ConfirmEmailVerificationProps,
} from 'src/email_verification/VerifyEmail/ConfirmEmailVerification'
import {
  ValidationError,
  ValidationFailureEmailVerification,
} from 'src/email_verification/VerifyEmail/ValidationFailureEmailVerification'

export enum EmailValidationStep {
  Request,
  Confirm,
  ValidationError,
  Finish,
}

type VerifyClientProps = {
  onClose: () => void
  email: string
  confirm: Omit<
    ConfirmEmailVerificationProps,
    'email' | 'onRequestAgain' | 'onComplete'
  >
  initialStep?: EmailValidationStep
  validationFailure?: ValidationError
}

export const VerifyClient = (props: VerifyClientProps) => {
  const { onClose, email, confirm, initialStep, validationFailure } = props
  const [step, setStep] = React.useState<EmailValidationStep>(
    initialStep ?? EmailValidationStep.Request,
  )

  return (
    <Dialog isOpen={true} onClose={() => onClose()}>
      <Flex
        minWidth="320px"
        minHeight="274px"
        justifyContent="center"
        alignItems="center"
      >
        {step === EmailValidationStep.Request ? (
          <RequestEmailVerification
            email={email}
            onComplete={() => setStep(EmailValidationStep.Confirm)}
            onAlreadyRecieved={() => setStep(EmailValidationStep.Confirm)}
          />
        ) : step === EmailValidationStep.Confirm ? (
          <ConfirmEmailVerification
            {...confirm}
            onRequestAgain={() => setStep(EmailValidationStep.Request)}
            email={email}
          />
        ) : step === EmailValidationStep.ValidationError &&
          validationFailure ? (
          <ValidationFailureEmailVerification
            onRequestAgain={() => setStep(EmailValidationStep.Request)}
            onRetry={() => setStep(EmailValidationStep.Confirm)}
            validationFailure={validationFailure}
          />
        ) : (
          <></>
        )}
      </Flex>
    </Dialog>
  )
}
