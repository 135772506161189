import React from 'react'
import { SVGProps } from './types'

export const Cards10: React.FC<SVGProps> = ({
  size = 24,
  color = '#000',
  onClick,
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 70 84"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick && (() => onClick())}
    >
      <path
        d="M68.3 1.25H19.26V64.18H68.3V1.25Z"
        fill="white"
        stroke="#231F20"
        strokeWidth={2.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M62.3 7.25H13.26V70.18H62.3V7.25Z"
        fill="white"
        stroke="#231F20"
        strokeWidth={2.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M56.29 13.2598H7.25V76.1898H56.29V13.2598Z"
        fill="white"
        stroke="#231F20"
        strokeWidth={2.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M50.29 19.2598H1.25V82.1898H50.29V19.2598Z"
        fill="white"
        stroke="#231F20"
        strokeWidth={2.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.0399 40.8799H21.3499V56.3899H18.0099V44.3099L15.3299 44.9699L14.6299 42.1999L19.0499 40.8799H19.0399Z"
        fill={color}
      />
      <path
        d="M31.3898 56.66C27.2798 56.66 24.5698 53.21 24.5698 48.72C24.5698 44.23 27.3198 40.73 31.4398 40.73C35.5598 40.73 38.2598 44.18 38.2598 48.67C38.2598 53.16 35.5098 56.66 31.3998 56.66H31.3898ZM31.3898 43.79C29.3398 43.79 28.0498 45.84 28.0498 48.67C28.0498 51.5 29.3898 53.6 31.4398 53.6C33.4898 53.6 34.7798 51.53 34.7798 48.72C34.7798 45.91 33.4398 43.79 31.3898 43.79Z"
        fill={color}
      />
    </svg>
  )
}
