import uniqBy from 'lodash/uniqBy'

import { Result } from 'src/utils/Result'
import { update as updateArray } from 'src/helpers'

type PaginatedListContent<T> = {
  elements: T[]
  hasMore: boolean
  error?: Error
}

const map = <T, U>(
  { elements, hasMore, error }: PaginatedListContent<T>,
  transform: (value: T) => U,
): PaginatedListContent<U> => ({
  elements: elements.map(transform),
  hasMore,
  error,
})

const appendResult = <T>(
  paginatedList: PaginatedListContent<T>,
  result: Result<T[]>,
  limit: number,
): PaginatedListContent<T> => ({
  elements: uniqBy([...paginatedList.elements, ...(result.value ?? [])], 'id'),
  hasMore: !result.value || result.value.length === limit,
  error: result.error,
})

type ValueForKey<K, T> = K extends keyof PaginatedListContent<T>
  ? PaginatedListContent<T>[K]
  : K extends number
  ? T
  : never

const update = <T, K extends keyof PaginatedListContent<T> | number>(
  paginatedList: PaginatedListContent<T>,
  key: K,
  update: (value: ValueForKey<K, T>) => ValueForKey<K, T>,
): PaginatedListContent<T> =>
  typeof key == 'number'
    ? {
        ...paginatedList,
        elements: updateArray(
          paginatedList.elements,
          key as number,
          (update as unknown) as (value: T) => T,
        ),
      }
    : {
        ...paginatedList,
        [key]: update(
          paginatedList[key as keyof PaginatedListContent<T>] as ValueForKey<
            K,
            T
          >,
        ),
      }

export type PaginatedList<T> = PaginatedListContent<T> & {
  elements: T[]
  hasMore: boolean
  error?: Error
  shouldLoadMore: boolean
  map: <U>(transform: (value: T) => U) => PaginatedList<U>
  appendResult: (result: Result<T[]>, limit: number) => PaginatedList<T>
  update: <K extends keyof PaginatedListContent<T> | number>(
    key: K,
    update: (value: ValueForKey<K, T>) => ValueForKey<K, T>,
  ) => PaginatedList<T>
}

const createPaginatedList = <T>(
  content: PaginatedListContent<T>,
): PaginatedList<T> => ({
  ...content,
  get shouldLoadMore() {
    return content.hasMore && !content.error
  },
  map: transform => createPaginatedList(map(content, transform)),
  appendResult: (result, limit) =>
    createPaginatedList(appendResult(content, result, limit)),
  update: (key, updater) => createPaginatedList(update(content, key, updater)),
})

export const PaginatedList = <T>(
  elements?: T[],
  hasMore?: boolean,
  error?: Error,
): PaginatedList<T> =>
  createPaginatedList({
    elements: elements ?? [],
    hasMore: hasMore !== undefined ? hasMore : true,
    error,
  })
