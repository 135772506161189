import { Maybe } from '../../../__generated__/globalTypes'

import { SponsorFragment, isSponsorFragment } from './SponsorFragment'

import { Storefront, isStorefront } from './Storefront'

import { AddressFragment, isAddressFragment } from './AddressFragment'

import {
  StripeSourceFragment,
  isStripeSourceFragment,
} from './StripeSourceFragment'

import accountFragmentRawString from '../AccountFragment.graphql'

export const accountFragmentString = accountFragmentRawString

export type AccountFragment = {
  id: string
  username: string
  firstName: string
  lastName: string
  email: string
  profileImageUrl: Maybe<string>
  isStaff: boolean
  isDistributor: boolean
  isNewUser: boolean
  genealogyId: number
  stripeCustomerId: Maybe<string>
  groupSends: number
  loyaltyRewards: number
  scheduledSends: number
  systemSends: number
  heartfeltSends: number
  pointPrice: number
  token: string
  sponsor: Maybe<SponsorFragment>
  storefront: Maybe<Storefront>
  shippingAddress: Maybe<AddressFragment>
  stripeSource: Maybe<StripeSourceFragment>
}

export const isAccountFragment = (fragment: any): fragment is AccountFragment =>
  fragment &&
  typeof fragment.id === 'string' &&
  typeof fragment.username === 'string' &&
  typeof fragment.firstName === 'string' &&
  typeof fragment.lastName === 'string' &&
  typeof fragment.email === 'string' &&
  (fragment.profileImageUrl === null ||
    typeof fragment.profileImageUrl === 'string') &&
  typeof fragment.isStaff === 'boolean' &&
  typeof fragment.isDistributor === 'boolean' &&
  typeof fragment.isNewUser === 'boolean' &&
  typeof fragment.genealogyId === 'number' &&
  (fragment.stripeCustomerId === null ||
    typeof fragment.stripeCustomerId === 'string') &&
  typeof fragment.groupSends === 'number' &&
  typeof fragment.loyaltyRewards === 'number' &&
  typeof fragment.scheduledSends === 'number' &&
  typeof fragment.systemSends === 'number' &&
  typeof fragment.heartfeltSends === 'number' &&
  typeof fragment.pointPrice === 'number' &&
  typeof fragment.token === 'string' &&
  (fragment.sponsor === null ||
    (isSponsorFragment(fragment.sponsor) as boolean)) &&
  (fragment.storefront === null ||
    (isStorefront(fragment.storefront) as boolean)) &&
  (fragment.shippingAddress === null ||
    (isAddressFragment(fragment.shippingAddress) as boolean)) &&
  (fragment.stripeSource === null ||
    (isStripeSourceFragment(fragment.stripeSource) as boolean))
