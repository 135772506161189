import { Maybe } from '../../../__generated__/globalTypes'

import {
  CalendarDateFragment,
  isCalendarDateFragment,
} from './CalendarDateFragment'

import noteFragmentRawString from '../NoteFragment.graphql'

export const noteFragmentString = noteFragmentRawString

export type NoteFragment = {
  id: string
  note: string
  date: Maybe<CalendarDateFragment>
}

export const isNoteFragment = (fragment: any): fragment is NoteFragment =>
  fragment &&
  typeof fragment.id === 'string' &&
  typeof fragment.note === 'string' &&
  (fragment.date === null || (isCalendarDateFragment(fragment.date) as boolean))
