import React, { useState } from 'react'
import { Card, Flex, Icon, Text } from '@sendoutcards/quantum-design-ui'
import { Rating } from './Rating'
import { useWindowWidth } from '@react-hook/window-size'
import { CustomerTile } from './CustomerTile'
import { mockReviewsData } from '../mockReviewsData'

export const CustomerReview: React.FC = () => {
  const windowWidth = useWindowWidth()
  const isBreakPoint = windowWidth <= 1020
  const [activeReviewIndex, setActiveReviewIndex] = useState(0)
  const activeReview = mockReviewsData[activeReviewIndex]
  const Container = isBreakPoint ? Card : Flex
  const [seeMore, setSeeMore] = useState(false)

  const handleUserClick = (index: number) => {
    setActiveReviewIndex(index)
  }
  const truncatedReview =
    isBreakPoint && seeMore === false && activeReview.review.length > 100
      ? `${activeReview.review.slice(0, 137)}...`
      : seeMore === true
      ? activeReview.review
      : activeReview.review

  return (
    <Container
      outset={{ horizontal: windowWidth < 600 ? 'x1_5' : 'x4' }}
      inset="20px"
      flexDirection="row"
      width="100%"
      rowGap="x2"
      position="relative"
      backgroundColor={isBreakPoint ? 'foreground' : undefined}
    >
      <Flex width="100%" flexDirection="column">
        <Flex
          justifyContent="flex-start"
          alignContent="flex-start"
          flexDirection={'row'}
          width="100%"
          rowGap="x1_5"
        >
          {!isBreakPoint && (
            <Flex
              flexDirection={'column'}
              rowGap="x2"
              width="100%"
              height="100%"
              maxHeight="380px"
              maxWidth="290px"
              minWidth="290px"
              overflowY="auto"
              outset={{ right: isBreakPoint ? 'x0' : 'x2' }}
            >
              {mockReviewsData.map((review, index) => (
                <CustomerTile
                  key={index}
                  title={review.user.title}
                  description={review.user.description}
                  profileImage={review.user.profileImage}
                  onSelect={() => handleUserClick(index)}
                  isActive={activeReviewIndex === index}
                  isReviewBreakPoint={isBreakPoint}
                />
              ))}
            </Flex>
          )}
          <Flex
            width="100%"
            justifyContent={isBreakPoint ? 'space-evenly' : 'flex-start'}
          >
            <Flex
              flexDirection="column"
              width="100%"
              justifyContent={isBreakPoint ? 'space-evenly' : 'flex-start'}
            >
              <Flex
                inset={{ left: !isBreakPoint ? 'x2' : 'x0' }}
                flexDirection={isBreakPoint ? 'row' : 'column'}
                width="100%"
                justifyContent="space-between"
                rowGap="x_5"
              >
                <Text
                  type="body"
                  content={activeReview.title}
                  style={{
                    fontFamily: 'Montserrat',
                    fontSize: '1.125rem',
                    fontWeight: 700,
                    color: '#6B7280',
                  }}
                />
                <Flex>
                  <Rating rating={activeReview.rating} />
                </Flex>
              </Flex>
              <Text
                outset={isBreakPoint ? { vertical: 'x2' } : 'x2'}
                type="body"
                content={truncatedReview}
                style={{
                  fontFamily: 'Montserrat',
                  fontSize: '1rem',
                  color: '#6B7280',
                  fontStyle: 'italic',
                }}
              />
            </Flex>
          </Flex>
        </Flex>
        {isBreakPoint && (
          <>
            <Flex position="relative">
              <CustomerTile
                key={activeReviewIndex}
                title={activeReview.user.title}
                description={activeReview.user.description}
                profileImage={activeReview.user.profileImage}
                onSelect={() => {}}
                isActive={true}
                isReviewBreakPoint={isBreakPoint}
              />
              <Flex
                position="absolute"
                right="0px"
                onClick={() => setSeeMore(!seeMore)}
                cursor="pointer"
                bottom="72px"
              >
                <Text
                  style={{ fontWeight: 'bold' }}
                  type="caption"
                  content={!seeMore ? 'See More' : 'See Less'}
                />
              </Flex>
            </Flex>
            <Flex
              display={
                activeReviewIndex >= mockReviewsData.length - 1
                  ? 'none'
                  : 'flex'
              }
              position="absolute"
              cursor="pointer"
              right="-60px"
              top="90px"
            >
              <Icon
                name="rightChevron"
                onClick={() => {
                  if (activeReviewIndex < mockReviewsData.length - 1) {
                    setActiveReviewIndex(prev => prev + 1)
                  }
                }}
                size="large"
                primaryColor={'grey'}
              />
            </Flex>
            <Flex
              display={activeReviewIndex <= 0 ? 'none' : 'flex'}
              position="absolute"
              cursor="pointer"
              left="-60px"
              top="90px"
            >
              <Icon
                name="leftChevron"
                onClick={() => {
                  if (activeReviewIndex > 0) {
                    setActiveReviewIndex(prev => prev - 1)
                  }
                }}
                size="large"
                primaryColor={'grey'}
              />
            </Flex>
          </>
        )}
      </Flex>
    </Container>
  )
}
