import {
  RequestEmailVerificationFailureType,
  If,
  Operation,
} from '../../../__generated__/globalTypes'

import requestEmailVerificationCodeString from '../RequestEmailVerificationCode.graphql'

export type RequestEmailVerificationCodeData = {
  requestEmailVerificationCode: {
    __typename:
      | 'RequestEmailVerificationCodeFailure'
      | 'RequestEmailVerificationCodeSuccess'
  } & (
    | If<
        'RequestEmailVerificationCodeFailure',
        {
          failureType: RequestEmailVerificationFailureType
          message: string
          success: boolean
        }
      >
    | If<
        'RequestEmailVerificationCodeSuccess',
        {
          message: string
          success: boolean
        }
      >
  )
}

export type RequestEmailVerificationCodeVariables = {
  email: string
}

export type RequestEmailVerificationCode = Operation<
  RequestEmailVerificationCodeData,
  RequestEmailVerificationCodeVariables
>

export const RequestEmailVerificationCode = ({
  email,
}: RequestEmailVerificationCodeVariables): RequestEmailVerificationCode => ({
  query: requestEmailVerificationCodeString,
  variables: {
    email,
  },
})
