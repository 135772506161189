import { performOperation } from 'src/app/api'
import { useEffect, useState } from 'react'
import { GetCardCategories, GetCardCategoriesData } from 'src/graphql'

const useGetCardCategories = () => {
  const [categories, setCategories] = useState<
    GetCardCategoriesData | undefined
  >(undefined)

  useEffect(() => {
    performOperation<GetCardCategoriesData>(GetCardCategories(), result => {
      if (result.error || !result.value) {
        setCategories(undefined)
      } else {
        const pagesResponse: GetCardCategoriesData | undefined = result.value
        setCategories(pagesResponse)
      }
    })
  }, [])
  return categories
}

export default useGetCardCategories
