import React from 'react'
import { Helmet } from 'react-helmet'

const Favicon: React.FC = () => {
  const favicon = `<svg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/2000/svg'
  viewBox='0 0 16 16' enable-background='new 0 0 16 16' xml:space='preserve'>
  <style>path {fill: %23231f20;}@media (prefers-color-scheme: dark) {path {fill: white;}}</style>
<path d='M12.847,14.831c-0.021-0.507,0.007-1.001,0.052-1.506c0.184-2.035,0.521-4.162,0.914-6.05
 c0.255-1.225,0.538-2.488,0.645-3.723c0.021-0.247,0.014-0.503-0.022-0.768c-0.076-0.565-0.389-0.871-0.938-0.918
 c-0.335-0.029-0.713-0.006-1.134,0.07c-0.994,0.18-1.958,0.478-2.912,0.837c-2.128,0.801-4.188,1.75-6.18,2.847
 c-0.417,0.23-0.787,0.543-1.218,0.741c-0.012,0.006-0.027,0-0.032-0.012C2.019,6.342,2.02,6.333,2.025,6.326
 C2.047,6.294,2.075,6.261,2.11,6.23c0.42-0.381,0.851-0.702,1.344-1.017c2.287-1.463,4.71-2.653,7.269-3.57
 c0.718-0.257,1.449-0.465,2.195-0.624c0.476-0.102,1.067-0.204,1.53-0.053c0.319,0.104,0.352,0.681,0.358,0.953
 c0.012,0.551,0.004,1.034-0.023,1.449c-0.06,0.916-0.163,1.936-0.309,3.058c-0.279,2.147-0.623,4.282-0.98,6.417
 c-0.094,0.565-0.221,1.122-0.38,1.673c-0.042,0.146-0.099,0.294-0.17,0.445c-0.009,0.02-0.033,0.028-0.052,0.019
 c-0.009-0.004-0.016-0.011-0.019-0.02C12.857,14.926,12.849,14.883,12.847,14.831z'/>
<path d='M8.729,9.95c1.89-1.97,3.348-4.369,4.66-6.748c0.037-0.067,0.209-0.198,0.285-0.138
 c0.025,0.02,0.031,0.049,0.018,0.088c-0.831,2.507-2.147,4.814-4.073,6.628c-0.54,0.509-0.975,0.872-1.373,1.355
 c-0.376,0.457-0.763,1.026-1.372,1.114c-0.79,0.115-1.688-0.691-2.194-1.224c-0.522-0.55-1.01-1.129-1.463-1.738
 c-0.08-0.107-0.388-0.559-0.369-0.676c0.004-0.023,0.025-0.038,0.048-0.035C2.9,8.578,2.903,8.579,2.906,8.58
 c0.15,0.07,0.237,0.144,0.333,0.291c0.506,0.771,1.052,1.537,1.689,2.206c0.341,0.358,0.824,0.788,1.287,0.865
 c0.725,0.12,1.119-0.696,1.573-1.105C8.108,10.548,8.438,10.254,8.729,9.95z'/>
<path d='M8.37,14.863c-0.481-0.009-0.981-0.032-1.44-0.026c-0.872,0.01-1.743,0.052-2.612,0.124
 c-0.339,0.028-0.755,0.06-1.083-0.001c-0.393-0.074-0.807-0.281-1.12-0.479c-0.516-0.327-0.829-1.008-0.902-1.59
 c-0.032-0.258-0.046-0.414,0.046-0.632c0.009-0.022,0.034-0.032,0.055-0.023c0.01,0.004,0.018,0.011,0.022,0.021
 c0.028,0.059,0.04,0.132,0.036,0.203c-0.013,0.231-0.013,0.394,0.002,0.489c0.096,0.624,0.462,1.038,0.968,1.39
 c0.513,0.358,1.107,0.33,1.703,0.197c0.823-0.183,1.601-0.35,2.484-0.415c0.574-0.042,1.234-0.028,1.979,0.041
 c0.988,0.092,1.963,0.26,2.925,0.505c0.31,0.079,0.609,0.19,0.895,0.332c0.04,0.02,0.058,0.041,0.055,0.063
 c-0.005,0.034-0.025,0.05-0.059,0.047C11.006,14.994,9.681,14.889,8.37,14.863z'/>
</svg>`

  return (
    <Helmet>
      <link
        rel="icon"
        href={`data:image/svg+xml,${favicon}`}
        type="image/svg+xml"
      />
    </Helmet>
  )
}

export default Favicon
