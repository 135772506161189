import { Operation } from '../../../__generated__/globalTypes'

import {
  MinimalSendableCardFragment,
  minimalSendableCardFragmentString,
} from '../../fragments/__generated__/MinimalSendableCardFragment'

import { sendableCardImageFragmentString } from '../../fragments/__generated__/SendableCardImageFragment'

import getLegacyPicturePlusCardsRawString from '../GetLegacyPicturePlusCards.graphql'

const getLegacyPicturePlusCardsString = [
  getLegacyPicturePlusCardsRawString,
  minimalSendableCardFragmentString,
  sendableCardImageFragmentString,
].join('\n\n')

export type GetLegacyPicturePlusCardsData = {
  legacyPicturePlusCards: MinimalSendableCardFragment[]
}

export type GetLegacyPicturePlusCardsVariables = {
  offset: number
  limit: number
}

export type GetLegacyPicturePlusCards = Operation<
  GetLegacyPicturePlusCardsData,
  GetLegacyPicturePlusCardsVariables
>

export const GetLegacyPicturePlusCards = ({
  offset,
  limit,
}: GetLegacyPicturePlusCardsVariables): GetLegacyPicturePlusCards => ({
  query: getLegacyPicturePlusCardsString,
  variables: {
    offset,
    limit,
  },
})
