import { Maybe, Operation } from '../../../__generated__/globalTypes'

import createSparseUserString from '../CreateSparseUser.graphql'

export type CreateSparseUserData = {
  createSparseUser: {
    account: {
      token: string
    }
  }
}

export type CreateSparseUserVariables = {
  inviteId?: Maybe<string>
}

export type CreateSparseUser = Operation<
  CreateSparseUserData,
  CreateSparseUserVariables
>

export const CreateSparseUser = ({
  inviteId,
}: CreateSparseUserVariables = {}): CreateSparseUser => ({
  query: createSparseUserString,
  variables: {
    inviteId,
  },
})
