import { InviteFragment } from 'src/graphql/fragments/__generated__/InviteFragment'
import Action from '../action'
import { createdInvite, creatingInvite } from '../actions/invites'

type State = {
  invite?: InviteFragment
  isCreating: boolean
}

export default (
  state: State = { isCreating: false },
  action: Action,
): State => {
  switch (action.type) {
    case createdInvite.type:
      return { ...state, invite: action.invite, isCreating: false }
    case creatingInvite.type:
      return { ...state, isCreating: true }
    default:
      return state
  }
}
