import { createBrowserHistory } from 'history'

import { trackPath } from 'src/redux/middleware/segmentAnalytics'
import { Store } from 'src/redux/store'
import action from 'src/routes/actionForLocation'
import { BaseRoute } from 'src/routes/BaseRoute'
import { APP_URL } from 'src/app/api'
// import { openApp } from 'src/helpers/appHelpers'

// Direct usage of history is considered legacy behavior and should be strongly avoided.
const browserHistory = createBrowserHistory()

export const startListeningToBrowserHistory = () => {
  const href = window.location.href
  const appPath = href.split('/app')[1]
  if (
    appPath?.startsWith('/') &&
    appPath.length > 1 &&
    !appPath.includes('/catalog') &&
    !appPath.includes('/pricing') &&
    !appPath.includes('/business') &&
    !appPath.includes('/promo') &&
    !appPath.includes('/audere') &&
    !appPath.includes('/training-schedule')
  ) {
    window.location.href = APP_URL + appPath
  }
  // Initialize route
  Store.dispatch(action(browserHistory.location))

  // Track starting path
  trackPath(BaseRoute.toPath(Store.getState().route))

  // Listen to changes in browserHistory
  browserHistory.listen(location => {
    const state = Store.getState()
    const currentPath = BaseRoute.toPath(state.route)
    if (location.pathname !== currentPath) {
      Store.dispatch(action(location))
    }
  })

  const mutableState: { previousRoute: BaseRoute } = {
    previousRoute: Store.getState().route,
  }

  // Observe the route
  Store.subscribe(() => {
    const route = Store.getState().route

    const newPath = BaseRoute.toPath(route)
    if (newPath && browserHistory.location.pathname !== newPath) {
      // Reset the window scroll if neither the previous route or the current route objects
      if (
        !(
          BaseRoute.doNotResetWindowScroll(mutableState.previousRoute) ||
          BaseRoute.doNotResetWindowScroll(route)
        )
      ) {
        window.scrollTo(0, 0)
      }
      // Update browser history
      browserHistory.push(newPath)
      // Track new path
      if (window.Appcues) {
        window.Appcues.page()
      }
      trackPath(newPath)
    }
    mutableState.previousRoute = route
  })
}
