import { CurrencyType } from '../../../__generated__/globalTypes'

import labeledPriceRawString from '../LabeledPrice.graphql'

export const labeledPriceString = labeledPriceRawString

export type LabeledPrice = {
  label: string
  asString: string
  currency: CurrencyType
  amount: number
}

export const isLabeledPrice = (fragment: any): fragment is LabeledPrice =>
  fragment &&
  typeof fragment.label === 'string' &&
  typeof fragment.asString === 'string' &&
  (fragment.currency === 'POINT' ||
    fragment.currency === 'EXPENSE' ||
    fragment.currency === 'FREE' ||
    fragment.currency === 'USD' ||
    fragment.currency === 'CREDIT' ||
    fragment.currency === 'CARDTOKEN') &&
  typeof fragment.amount === 'number'
