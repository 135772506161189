import React from 'react'
import { SVGProps } from './types'

export const Search: React.FC<SVGProps> = ({
  size = 24,
  color = '#71B0DA',
  onClick,
}) => {
  const originalWidth = 80
  const originalHeight = 81
  const ratio = originalHeight / originalWidth
  const scaledWidth = size
  const scaledHeight = size / ratio
  return (
    <svg
      width={scaledWidth}
      height={scaledHeight}
      viewBox={`0 0 ${originalWidth} ${originalHeight}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M35.8333 15.9746C47.3392 15.9746 56.6667 25.302 56.6667 36.8079M59.4117 60.37L77.5 78.4746M69.1667 36.8079C69.1667 55.2175 54.2429 70.1413 35.8333 70.1413C17.4238 70.1413 2.5 55.2175 2.5 36.8079C2.5 18.3984 17.4238 3.47461 35.8333 3.47461C54.2429 3.47461 69.1667 18.3984 69.1667 36.8079Z"
        stroke={color}
        strokeWidth={5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
