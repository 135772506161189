import React from 'react'
import { SVGProps } from './types'

export const MultipleCards: React.FC<SVGProps> = ({
  size = 24,
  color = '#3A3A3C',
  onClick,
}) => {
  const originalWidth = 114
  const originalHeight = 163
  const ratio = originalWidth / originalHeight
  const scaledWidth = size
  const scaledHeight = size / ratio
  return (
    <svg
      width={scaledWidth}
      height={scaledHeight}
      viewBox={`0 0 ${originalWidth} ${originalHeight}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M76.9502 163C76.5441 163 76.1379 162.913 75.7532 162.731L1.66105 127.802C0.228937 127.125 -0.390932 125.401 0.264562 123.938C0.920055 122.475 2.61579 121.842 4.0479 122.512L74.1002 155.534V62.9804L1.66105 28.8377C0.228937 28.161 -0.390932 26.4364 0.264562 24.9737C0.920055 23.5111 2.61579 22.878 4.0479 23.5475L78.1472 58.4761C79.1589 58.949 79.8001 59.9823 79.8001 61.1175V160.082C79.8001 161.071 79.3014 161.996 78.4892 162.534C78.026 162.84 77.4917 162.993 76.9502 162.993V163Z"
        fill={color}
      />
      <path
        d="M59.8504 137.502C59.4514 137.502 59.0453 137.415 58.6534 137.233L18.7538 118.371C17.3288 117.695 16.7018 115.963 17.3645 114.507C18.0271 113.052 19.7228 112.412 21.1478 113.088L61.0474 131.95C62.4724 132.627 63.0994 134.358 62.4368 135.814C61.9523 136.876 60.9263 137.502 59.8504 137.502Z"
        fill={color}
      />
      <path
        d="M94.0501 145.536C93.8791 145.536 93.701 145.521 93.53 145.485L76.4302 142.225C74.8841 141.927 73.8652 140.406 74.1502 138.827C74.4423 137.248 75.9314 136.207 77.4775 136.498L91.2073 139.118V46.0766L2.32379 29.0562C0.777681 28.7579 -0.241184 27.237 0.0509378 25.658C0.34306 24.0789 1.83217 23.0383 3.37828 23.3367L94.5774 40.801C95.924 41.0557 96.9001 42.2636 96.9001 43.6607V142.625C96.9001 143.491 96.5225 144.313 95.8741 144.866C95.3611 145.303 94.7128 145.536 94.0501 145.536Z"
        fill={color}
      />
      <path
        d="M2.85111 29.1071C1.57575 29.1071 0.414383 28.2266 0.0866359 26.9022C-0.29811 25.345 0.635255 23.7659 2.15999 23.373L93.3591 0.0872639C94.207 -0.13104 95.1118 0.0654336 95.8029 0.61847C96.4941 1.17151 96.9002 2.01561 96.9002 2.91066V26.1964C96.9002 27.8046 95.6248 29.1071 94.0502 29.1071C92.4756 29.1071 91.2002 27.8046 91.2002 26.1964V6.63637L3.54223 29.0198C3.3071 29.078 3.07911 29.1071 2.85111 29.1071Z"
        fill={color}
      />
      <path
        d="M111.15 128.071H94.05C92.4754 128.071 91.2001 126.769 91.2001 125.161C91.2001 123.553 92.4754 122.25 94.05 122.25H108.3V29.1071H5.70092V125.161C5.70092 126.769 4.42556 128.071 2.85095 128.071C1.27634 128.071 0.000976562 126.769 0.000976562 125.161V26.1964C0.000976562 24.5882 1.27634 23.2856 2.85095 23.2856H111.15C112.724 23.2856 114 24.5882 114 26.1964V125.161C114 126.769 112.724 128.071 111.15 128.071Z"
        fill={color}
      />
      <path
        d="M59.8507 120.038C59.4445 120.038 59.0313 119.95 58.6537 119.768L18.7541 100.907C17.7494 100.434 17.1011 99.4007 17.1011 98.2655V51.6941C17.1011 50.7044 17.5998 49.773 18.4192 49.2418C19.2386 48.7106 20.2717 48.6378 21.1552 49.0526L61.0548 67.914C62.0594 68.387 62.7077 69.4203 62.7077 70.5555V117.127C62.7077 118.117 62.209 119.048 61.3896 119.579C60.9265 119.885 60.3921 120.038 59.8578 120.038H59.8507ZM22.801 96.4026L57.0007 112.572V72.4183L22.801 56.2493V96.4026Z"
        fill={color}
      />
      <path
        d="M59.8507 112.063C58.9744 112.063 58.1122 111.648 57.5494 110.877L49.1491 99.1827L41.3544 100.958C40.2287 101.213 39.0602 100.755 38.3976 99.7867L30.496 88.2457L21.3476 93.4996C19.9725 94.2855 18.2411 93.7906 17.4645 92.3862C16.695 90.9818 17.1795 89.2135 18.5546 88.4204L29.9545 81.8713C31.2299 81.1363 32.8472 81.5147 33.688 82.7372L41.9814 94.8458L49.7333 93.0775C50.8377 92.8301 51.9848 93.2667 52.6474 94.1981L62.1521 107.42C63.0854 108.715 62.8076 110.535 61.5393 111.488C61.0335 111.866 60.4421 112.055 59.8579 112.055L59.8507 112.063Z"
        fill={color}
      />
      <path
        d="M45.6003 87.3216C48.7483 87.3216 51.3003 84.7152 51.3003 81.5001C51.3003 78.2851 48.7483 75.6787 45.6003 75.6787C42.4523 75.6787 39.9004 78.2851 39.9004 81.5001C39.9004 84.7152 42.4523 87.3216 45.6003 87.3216Z"
        fill={color}
      />
    </svg>
  )
}
