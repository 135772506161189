import React from 'react'
import { useWindowWidth } from '@react-hook/window-size'
import {
  Div,
  Flex,
  Image,
  QuantumThemeWrapper,
  Spacer,
  Text,
} from '@sendoutcards/quantum-design-ui'
import Promo from 'src/promo/containers/Promo'
import { PromoContextProvider } from 'src/promo/context/promoContext'

type DividerSVGProps = {
  color1?: string
  color2?: string
  outset?: string
}
const DividerSVG = ({
  color1 = '#3754FB',
  color2 = '#FC01FF',
  outset = '48px',
}: DividerSVGProps) => {
  return (
    <Div outset={outset}>
      <svg
        width="80"
        height="36"
        viewBox="0 0 150 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3 6L27 30L51 6L75 30L99 6L123 30L147 6"
          stroke="url(#paint0_linear_131_203)"
          strokeWidth="8"
        />
        <defs>
          <linearGradient
            id="paint0_linear_131_203"
            x1="3"
            y1="18"
            x2="147"
            y2="18"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={color1} />
            <stop offset="1" stopColor={color2} />
          </linearGradient>
        </defs>
      </svg>
    </Div>
  )
}

type InlineTextGradientProps = {
  defaultText: string
  gradientText: string
  gradientDeg?: string
  gradientFirst?: string
  gradientSecond?: string
  fontSize?: string
}
const InlineTextGradient = ({
  defaultText,
  gradientText,
  gradientDeg = '25deg',
  gradientFirst = '#2600fc',
  gradientSecond = '#ff00ea',
  fontSize,
}: InlineTextGradientProps) => {
  const fontStyles = {
    fontFamily: "'Montserrat',sans-serif",
    fontWeight: 600,
    fontSize: fontSize ?? 'initial',
  }
  return (
    <h2 style={fontStyles}>
      {defaultText}
      <span
        style={{
          ...fontStyles,
          backgroundColor: gradientFirst,
          backgroundImage: `linear-gradient(${gradientDeg}, ${gradientFirst}, ${gradientSecond})`,
          backgroundClip: 'text',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
          marginLeft: 8,
        }}
      >
        {gradientText}
      </span>
    </h2>
  )
}

type GradientButtonType = {
  onClick: () => void
  title: string
  gradientDeg?: string
  gradientFirst?: string
  gradientSecond?: string
  maxWidth?: string
}

const GradientButton = ({
  onClick,
  title,
  gradientDeg = '25deg',
  gradientSecond = '#52c3e0',
  gradientFirst = '#31e4bd',
  maxWidth,
}: GradientButtonType) => {
  const buttonStyle = {
    fontFamily: "'Montserrat', sans-serif",
    letterSpacing: '',
    fontSize: '1.125rem',
    height: '64px',
    padding: '0 40px',
    color: 'White',
    fontWeight: 600,
    backgroundColor: gradientFirst,
    backgroundImage: `linear-gradient(${gradientDeg}, ${gradientFirst}, ${gradientSecond})`,
    borderRadius: 18,
    cursor: 'pointer',
    maxWidth: maxWidth,
  }
  return (
    <Flex
      onClick={onClick}
      style={buttonStyle}
      alignItems="center"
      cursor="pointer"
    >
      {title}
      <svg
        height="24"
        width="24"
        fill="none"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        style={{ cursor: 'pointer', marginLeft: 8, transform: 'rotate(30deg)' }}
      >
        <path
          d="M18.0691 10.3199C17.8791 10.3199 17.6891 10.2499 17.5391 10.0999L11.9991 4.55994L6.45914 10.0999C6.16914 10.3899 5.68914 10.3899 5.39914 10.0999C5.10914 9.80994 5.10914 9.32994 5.39914 9.03994L11.4691 2.96994C11.7591 2.67994 12.2391 2.67994 12.5291 2.96994L18.5991 9.03994C18.8891 9.32994 18.8891 9.80994 18.5991 10.0999C18.4591 10.2499 18.2591 10.3199 18.0691 10.3199Z"
          fill="#FFFFFF"
        />
        <path
          d="M12 21.2499C11.59 21.2499 11.25 20.9099 11.25 20.4999V3.66992C11.25 3.25992 11.59 2.91992 12 2.91992C12.41 2.91992 12.75 3.25992 12.75 3.66992V20.4999C12.75 20.9099 12.41 21.2499 12 21.2499Z"
          fill="#FFFFFF"
        />
      </svg>
    </Flex>
  )
}

const PartnershipSplashPage = () => {
  const [isOpen, setIsOpen] = React.useState(false)
  const windowWidth = useWindowWidth()
  const isSmallMobile = windowWidth < 991
  return (
    <QuantumThemeWrapper>
      <Flex flexDirection="column" alignItems="center" inset="x2_5">
        <Flex
          flexDirection="row"
          flexWrap={'wrap'}
          justifyContent={'center'}
          columnGap="32px"
        >
          <Flex
            width={isSmallMobile ? '100%' : '50%'}
            flexDirection="column"
            rowGap={'x3'}
            outset={{ bottom: 'x8' }}
            order={isSmallMobile ? 2 : 1}
          >
            <Text
              type={isSmallMobile ? 'title' : 'heroTitle'}
              content="Audere + SendOutCards"
            />
            <Text
              type={isSmallMobile ? 'largeBody' : 'subtitle'}
              weight="semiBold"
              isItalic={true}
              color="primaryBody"
              content="A Promptings Product"
            />
            <Div>
              <Text
                type={isSmallMobile ? 'body' : 'largeBody'}
                content="Vibrant hair meets personal touch!"
              />
              <Text
                type={isSmallMobile ? 'body' : 'largeBody'}
                content="Join the 10 card challenge and experience the magic of enriched, healthy hair combined with heartfelt, personal connections!"
              />
            </Div>
            <Spacer space="x1" />
            <GradientButton
              title="Get Started"
              onClick={() => setIsOpen(true)}
              maxWidth="220px"
            />
          </Flex>
          <Flex
            width={'50%'}
            flexDirection="column"
            columnGap="x8"
            justifyContent={'center'}
            order={isSmallMobile ? 1 : 2}
            outset={isSmallMobile ? { bottom: 'x9' } : {}}
            maxWidth="480px"
          >
            <Flex justifyContent={'flex-start'}>
              <Div
                style={{
                  transform: 'rotate(10.84deg)',
                  position: 'relative',
                  transition: 'box-shadow .1s ease-in-out',
                  boxSizing: 'border-box',
                  marginBottom: '0',
                }}
              >
                <Div
                  style={{
                    transform: 'translate(24px, 24px)',
                    borderRadius: '48px',
                    backgroundColor: '#1f152b',
                    opacity: '0.1',
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    width: isSmallMobile ? 200 : 300,
                  }}
                />
                <Image
                  width={isSmallMobile ? '200px' : '300px'}
                  height={isSmallMobile ? '200px' : '300px'}
                  image={{
                    url:
                      'https://lh3.googleusercontent.com/u/0/drive-viewer/AITFw-wEBumjRdN41kzgJP-6BMlU8jg5V_weH5e8iVLdIQGFoDN1vZzjg3hRZbz1MAGZ74cTNvi-GbA0WuDkZL0epyy4-P8Qzg=w1920-h1407',
                  }}
                  isActive={true}
                />
              </Div>
            </Flex>
            <Spacer space="x9" />
            <Flex justifyContent={'flex-end'}>
              <Div
                style={{
                  transform: 'rotate(-10.84deg)',
                  position: 'relative',
                  transition: 'box-shadow .1s ease-in-out',
                  boxSizing: 'border-box',
                  marginBottom: '0',
                }}
              >
                <Div
                  style={{
                    transform: 'translate(24px, 24px)',
                    borderRadius: '48px',
                    border: '2px solid #1f152b',
                    opacity: '0.1',
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    width: isSmallMobile ? 200 : 300,
                  }}
                />
                <Image
                  width={isSmallMobile ? '200px' : '300px'}
                  height={isSmallMobile ? '200px' : '300px'}
                  image={{
                    url:
                      'https://www.sendoutcards.com/thumbnail/4Mtvp1y6p1VzUXq9tni2cyLUKOs=/fit-in/700x0/system_images/2023/2/2/a93ce209-27ae-4142-92ca-4bcee3ceae74.png',
                  }}
                  isActive={true}
                />
              </Div>
            </Flex>
          </Flex>
        </Flex>
        <Flex
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          outset={{ bottom: 'x5' }}
        >
          <DividerSVG />
          <InlineTextGradient
            defaultText="Featured"
            gradientText="Cards"
            fontSize={isSmallMobile ? '40px' : ''}
          />
          <Spacer space="x3" />
          <Text
            type={isSmallMobile ? 'body' : 'largeBody'}
            alignment="center"
            weight="semiBold"
            content="Dazzle your clients with these amazing cards offered through the SendOutCards partnership."
          />
          <Flex
            justifyContent="center"
            columnGap={'30px'}
            rowGap={'30px'}
            outset="x9"
            flexWrap={'wrap'}
          >
            <Image
              borderRadius={'default'}
              isActive={true}
              width="210px"
              height="280px"
              image={{
                url:
                  'https://www.sendoutcards.com/thumbnail/foOdkvJwZC8HRH78iEv6ktl32Ss=/fit-in/320x0/system_images/2023/7/26/8cb1c99b-c8d1-4a4e-975a-87cb499a6c94.png',
              }}
            />
            <Image
              borderRadius={'default'}
              isActive={true}
              width="210px"
              height="280px"
              image={{
                url:
                  'https://www.sendoutcards.com/thumbnail/fjDvHPDb6dImYEtxNGVxuqwXMYY=/fit-in/320x0/system_images/2023/7/26/85c06d88-a199-4ad3-95ac-d79e95686dc2.png',
              }}
            />
            <Image
              borderRadius={'default'}
              isActive={true}
              width="210px"
              height="280px"
              image={{
                url:
                  'https://www.sendoutcards.com/thumbnail/OZWWmVZP9aOgP9g1Jp40XBurlcM=/fit-in/320x0/system_images/2023/7/26/592dbe25-14f4-4b20-9fa7-7ccdb0165b94.png',
              }}
            />
            <Image
              borderRadius={'default'}
              isActive={true}
              width="210px"
              height="280px"
              image={{
                url:
                  'https://www.sendoutcards.com/thumbnail/JIcXglStMhTjGiq-R6XZ-QMqYrY=/fit-in/320x0/system_images/2023/7/26/a9dd5b6a-5325-499c-961d-b83d72a314ca.png',
              }}
            />
          </Flex>
          <Spacer space="x3" />
          <GradientButton
            title="Join the Challenge"
            onClick={() => setIsOpen(true)}
          />
        </Flex>
        <Flex
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          outset={{ bottom: 'x9' }}
        >
          <DividerSVG outset={'x9'} />
          <Spacer space="x7" />
          <InlineTextGradient
            defaultText="Luxury +"
            gradientText="Connection"
            fontSize={isSmallMobile ? '32px' : ''}
          />
          <Spacer space="x3" />
          <Text
            type={isSmallMobile ? 'body' : 'largeBody'}
            alignment="center"
            weight="semiBold"
            content="Transform lives through uncompromising products and personal touch."
          />
          <Spacer space="x7" />
          <GradientButton title="Get Started" onClick={() => setIsOpen(true)} />
        </Flex>
        {isOpen && (
          <PromoContextProvider>
            <Promo
              forcedSponsorId={'726110'}
              onClose={() => setIsOpen(false)}
            />
          </PromoContextProvider>
        )}
      </Flex>
    </QuantumThemeWrapper>
  )
}

export default PartnershipSplashPage
