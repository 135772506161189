import {
  CatalogRouteContent,
  CatalogSubroute,
} from 'src/catalog/routes/CatalogRoute'
import { State, StateWithoutRoute } from 'src/redux/reducers'
import { Task } from 'src/app/types'
import {
  loadedCardCatalog,
  loadedSendableCards,
} from 'src/redux/actions/catalog'
import {
  GetCatalog,
  GetSendableCards,
  GetSendableCardsData,
  GetSendableCardsVariables,
  MinimalSendableCardFragment,
} from 'src/graphql'
import { CardCategoryRoute } from 'src/catalog/routes/CardCategoryRoute'
import { CardCollectionRoute } from 'src/catalog/routes/CardCollectionRoute'
import { PaginatedList } from 'src/utils/PaginatedList'
import { compact } from 'src/helpers'
import { PhotoDropCardsRoute } from 'src/catalog/routes/PhotoDropCardsRoute'
import { SendableCardRoute } from 'src/catalog/routes/SendableCardRoute'
import { StandardCardsRoute } from 'src/catalog/routes/StandardCardsRoute'
import { NormalizedCardCatalog } from 'src/catalog/types'
import { Normalized } from 'src/normalized-data/normalization'

// queries

const getCatalogTask = () => Task(GetCatalog(), loadedCardCatalog)

export type GetSendableCardsTaskType =
  | 'all'
  | { categoryId: string }
  | { collectionId: string }
  | 'standard'
  | 'photo-drop'

const GetSendableCardsTaskType = (
  route: CatalogSubroute,
): GetSendableCardsTaskType => {
  switch (route.path) {
    case CardCategoryRoute.path:
      return { categoryId: route.cardCategoryId }
    case CardCollectionRoute.path:
      return { collectionId: route.cardCollectionId }
    case StandardCardsRoute.path:
      return 'standard'
    case PhotoDropCardsRoute.path:
      return 'photo-drop'
    default:
      return 'all'
  }
}

const getSendableCardsTask = ({
  cards,
  type,
  search,
}: {
  cards: PaginatedList<Normalized<MinimalSendableCardFragment>>
  type: GetSendableCardsTaskType
  search: string
}) =>
  Task<GetSendableCardsData, GetSendableCardsVariables>(
    GetSendableCards({
      offset: cards.elements.length,
      limit: 20,
      search: search !== '' ? search : undefined,
      category:
        typeof type === 'object' && 'categoryId' in type
          ? type.categoryId
          : undefined,
      collection:
        typeof type === 'object' && 'collectionId' in type
          ? type.collectionId
          : undefined,
      isPremium:
        type === 'photo-drop' ? true : type === 'standard' ? false : undefined,
    }),
    result => loadedSendableCards(result.select('sendableCards'), search, type),
  )

// A card catalog task that we should load given the current route
const cardCatalogTask = (
  cardCatalog: NormalizedCardCatalog,
  route: CatalogRouteContent,
): Task | undefined | false =>
  route.subroute
    ? catalogSubrouteTask(route.subroute, cardCatalog, route.search)
    : route.search !== '' && allCardsTask(cardCatalog, route.search)

const allCardsTask = (
  cardCatalog: NormalizedCardCatalog,
  search: string,
): Task | false => {
  const cards = cardCatalog.allCards.get(search)
  return (
    cards.shouldLoadMore && getSendableCardsTask({ cards, search, type: 'all' })
  )
}

const catalogSubrouteTask = (
  route: CatalogSubroute,
  cardCatalog: NormalizedCardCatalog,
  search: string,
): Task | undefined | false => {
  switch (route.path) {
    case SendableCardRoute.path:
      return undefined
    default:
      const cards =
        subroutePaginatedCards(route, cardCatalog, search) || PaginatedList()
      return (
        cards.shouldLoadMore &&
        getSendableCardsTask({
          cards,
          search,
          type: GetSendableCardsTaskType(route),
        })
      )
  }
}

const subroutePaginatedCards = (
  route: CatalogSubroute,
  cardCatalog: NormalizedCardCatalog,
  search: string,
): PaginatedList<Normalized<MinimalSendableCardFragment>> | undefined => {
  switch (route.path) {
    case CardCollectionRoute.path:
      return cardCatalog.collectionCards[route.cardCollectionId]
    case CardCategoryRoute.path:
      return cardCatalog.categoryCards[route.cardCategoryId].get(search)
    case PhotoDropCardsRoute.path:
      return cardCatalog.photoDropCards.get(search)
    case StandardCardsRoute.path:
      return cardCatalog.standardCards.get(search)
    default:
      return undefined
  }
}

// queries

// mutations
export const catalogRouteTasks = (
  route: CatalogRouteContent,
  { cardCatalog }: StateWithoutRoute,
): Task[] =>
  compact(
    cardCatalog.normalizedCardCatalog.isUnresolved && getCatalogTask(),
    cardCatalog.normalizedCardCatalog.value &&
      cardCatalogTask(cardCatalog.normalizedCardCatalog.value, route),
  )

export const catalogPrefetchTasks = ({ cardCatalog }: State): Task[] =>
  compact<Task>(
    cardCatalog.normalizedCardCatalog.isUnresolved && getCatalogTask(),
  )
