import { CardOption } from 'src/catalog/components/CardOptions/CardOptions'
import {
  AddressFragment,
  CardPaperType,
  CardType,
  DetailedCountryFragment,
} from 'src/graphql'

// if a new card type is added, it needs to be added here
// and then the logic in the getPaperTypes fcn below might
// need to change
const paperTypes: CardOption<CardPaperType>[] = [
  {
    value: CardPaperType.STD,
    label: 'Standard',
    gradient: '',
    icon: 'STANDARDPAPER',
    iconSize: 30,
    viewBox: '0 0 25 32',
  },
  {
    value: CardPaperType.HVY,
    label: 'Satin',
    gradient: '',
    icon: 'FLATPANEL',
    iconSize: 30,
    viewBox: '0 0 25 32',
    upgrade: '50¢',
  },
  {
    value: CardPaperType.PRE,
    label: 'Pearl',
    gradient: '',
    icon: 'PEARLPAPER',
    iconSize: 30,
    viewBox: '0 0 24 32',
    upgrade: '50¢',
  },
]

// get a list of all the paper types that are possible
// by looking at the card type
export const getPaperTypes = (cardType: CardType) => {
  switch (cardType) {
    case CardType.POSTCARD:
      return paperTypes.filter(paper => paper.value === CardPaperType.STD)
    case CardType.TWO_PANEL_BIG:
      return paperTypes.map(paper =>
        paper.upgrade ? { ...paper, upgrade: '$1.50' } : paper,
      )
    default:
      return paperTypes
  }
}

// Product item category IDs
export const ACCOUNT_TOOLS_ID = '22'

export const emptyShippingAddress: AddressFragment = {
  firstName: '',
  lastName: '',
  address1: '',
  address2: '',
  company: '',
  city: '',
  state: '',
  country: 'United States',
  postalCode: '',
}

export const initialCountries: DetailedCountryFragment[] = [
  {
    postalName: 'United States',
    subdivisions: [
      {
        abbreviationOrName: 'AL',
      },
      {
        abbreviationOrName: 'AK',
      },
      {
        abbreviationOrName: 'AS',
      },
      {
        abbreviationOrName: 'AZ',
      },
      {
        abbreviationOrName: 'AR',
      },
      {
        abbreviationOrName: 'CA',
      },
      {
        abbreviationOrName: 'CO',
      },
      {
        abbreviationOrName: 'CT',
      },
      {
        abbreviationOrName: 'DE',
      },
      {
        abbreviationOrName: 'DC',
      },
      {
        abbreviationOrName: 'FL',
      },
      {
        abbreviationOrName: 'GA',
      },
      {
        abbreviationOrName: 'GU',
      },
      {
        abbreviationOrName: 'HI',
      },
      {
        abbreviationOrName: 'ID',
      },
      {
        abbreviationOrName: 'IL',
      },
      {
        abbreviationOrName: 'IN',
      },
      {
        abbreviationOrName: 'IA',
      },
      {
        abbreviationOrName: 'KS',
      },
      {
        abbreviationOrName: 'KY',
      },
      {
        abbreviationOrName: 'LA',
      },
      {
        abbreviationOrName: 'ME',
      },
      {
        abbreviationOrName: 'MD',
      },
      {
        abbreviationOrName: 'MA',
      },
      {
        abbreviationOrName: 'MI',
      },
      {
        abbreviationOrName: 'MN',
      },
      {
        abbreviationOrName: 'MS',
      },
      {
        abbreviationOrName: 'MO',
      },
      {
        abbreviationOrName: 'MT',
      },
      {
        abbreviationOrName: 'NE',
      },
      {
        abbreviationOrName: 'NV',
      },
      {
        abbreviationOrName: 'NH',
      },
      {
        abbreviationOrName: 'NJ',
      },
      {
        abbreviationOrName: 'NM',
      },
      {
        abbreviationOrName: 'NY',
      },
      {
        abbreviationOrName: 'NC',
      },
      {
        abbreviationOrName: 'ND',
      },
      {
        abbreviationOrName: 'MP',
      },
      {
        abbreviationOrName: 'OH',
      },
      {
        abbreviationOrName: 'OK',
      },
      {
        abbreviationOrName: 'OR',
      },
      {
        abbreviationOrName: 'PA',
      },
      {
        abbreviationOrName: 'PR',
      },
      {
        abbreviationOrName: 'RI',
      },
      {
        abbreviationOrName: 'SC',
      },
      {
        abbreviationOrName: 'SD',
      },
      {
        abbreviationOrName: 'TN',
      },
      {
        abbreviationOrName: 'TX',
      },
      {
        abbreviationOrName: 'UM',
      },
      {
        abbreviationOrName: 'UT',
      },
      {
        abbreviationOrName: 'VT',
      },
      {
        abbreviationOrName: 'VI',
      },
      {
        abbreviationOrName: 'VA',
      },
      {
        abbreviationOrName: 'WA',
      },
      {
        abbreviationOrName: 'WV',
      },
      {
        abbreviationOrName: 'WI',
      },
      {
        abbreviationOrName: 'WY',
      },
    ],
  },
]
