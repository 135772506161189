export interface Gradients {
  orange: string
  pink: string
  limeade: string
  blue: string
  facebook: string
  pink2: string
  green: string
  brown: string
  blueGreen: string
}

export type GradientChoices = keyof Gradients

const gradients: Gradients = {
  orange: 'linear-gradient(28deg, #dd2476 0%, #fe5030 100%)',
  pink: 'linear-gradient(115deg, #ff5689, #e687ff)',
  limeade: 'linear-gradient(137deg, #75deb0, #cce4a8)',
  blue: 'linear-gradient(115deg, #02C3E2, #02C3E2)',
  facebook: 'linear-gradient(9deg, #176be0, #262b9a)',
  pink2: 'linear-gradient(111deg, #f237ef, #fc5252)',
  green: 'linear-gradient(109deg, #56DCDE, #61E2AD)',
  brown: 'linear-gradient(109deg, #E094C0, #D5776C)',
  blueGreen: 'linear-gradient(99deg, #2DC9EB, #14D2B8)',
}

export default gradients
