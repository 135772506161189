import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import {
  clearToken,
  loginDone,
  saveToken,
  updatedAccount,
} from 'src/redux/actions/user'
import Action from 'src/redux/action'
import { AccountFragment } from 'src/graphql'

const LOGIN_EXPIRATION_TIME = 1000 * 60 * 15 // 15 minutes

type ControlData = {
  isLoggedIn: boolean
  token: string | null | undefined
  shouldNotRememberMe?: true
  loginExpirationDate?: number
  shouldShowPhoneVerification?: true
  isBlankCardModalOpen?: true
  hasPostcardPromoModal?: boolean
}

type UnpersistedAccountState = {
  account?: AccountFragment
  controlData: ControlData
}

const initialState: UnpersistedAccountState = {
  controlData: {
    isLoggedIn: false,
    token: undefined,
  },
}

const unpersistedAccount = (
  state: UnpersistedAccountState = initialState,
  action: Action,
): UnpersistedAccountState => {
  switch (action.type) {
    case loginDone.type:
      return action.result.value
        ? {
            account: action.result.value,
            controlData: {
              isLoggedIn: true,
              token: action.result.value.token,
              shouldNotRememberMe: action.rememberMe ? undefined : true,
              loginExpirationDate: !action.rememberMe
                ? Date.now() + LOGIN_EXPIRATION_TIME
                : undefined,
              hasPostcardPromoModal: state.controlData.hasPostcardPromoModal,
            },
          }
        : state
    case updatedAccount.type:
      return action.account
        ? {
            account: action.account,
            controlData: {
              isLoggedIn: true,
              token: action.account.token,
              hasPostcardPromoModal: state.controlData.hasPostcardPromoModal,
            },
          }
        : state
    case saveToken.type:
      return action.token
        ? {
            ...state,
            controlData: {
              ...state.controlData,
              token: action.token,
            },
          }
        : state

    case clearToken.type:
      return {
        ...state,
        controlData: {
          ...state.controlData,
          token: undefined,
        },
      }
    default:
      return state
  }
}

const isLoggedIn = (persistedState: UnpersistedAccountState) => {
  // has account information?
  if (!persistedState.account) {
    return false
  }

  // does login expire?
  if (!persistedState.controlData.shouldNotRememberMe) {
    return true
  }

  // is it expired?
  if (persistedState.controlData.loginExpirationDate) {
    const now = Date.now()
    return now - persistedState.controlData.loginExpirationDate < 0
  }

  // return false by default
  return false
}

const stateReconciler = (
  persistedState: UnpersistedAccountState & {
    token?: string /* backwards compatibility*/
  },
  currentState: UnpersistedAccountState,
): UnpersistedAccountState => {
  if (persistedState && !persistedState.controlData) {
    return {
      controlData: {
        isLoggedIn: false,
        token: persistedState.token,
        hasPostcardPromoModal: false,
      },
    }
  } else if (currentState.controlData.token) {
    return currentState
  } else if (isLoggedIn(persistedState)) {
    return {
      ...persistedState,
      controlData: {
        ...persistedState.controlData,
        hasPostcardPromoModal: currentState.controlData.hasPostcardPromoModal,
      },
    }
  } else {
    return {
      controlData: {
        isLoggedIn: false,
        token:
          currentState.controlData.token ||
          persistedState.controlData.shouldNotRememberMe
            ? undefined
            : persistedState.controlData.token,
        shouldShowPhoneVerification:
          currentState.controlData.shouldShowPhoneVerification,
      },
    }
  }
}

export default persistReducer(
  {
    key: 'account',
    storage,
    stateReconciler,
  },
  unpersistedAccount,
)
