import React from 'react'
import { SVGProps } from './types'

export const Danger: React.FC<SVGProps> = ({
  size = 24,
  color = '#323232',
  onClick,
}) => {
  return (
    <svg
      width={`${size}px`}
      height="auto"
      viewBox="0 0 10 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.66509 2.8919C5.87365 1.70249 4.12638 1.7025 3.33494 2.89191L2.13526 4.69485L1.33853 5.89221C0.454062 7.22144 1.40701 9.00016 3.0036 9.00016H5.00001H6.99643C8.59302 9.00016 9.54597 7.22144 8.6615 5.89221L7.86477 4.69485L6.66509 2.8919ZM4.47175 4.07718C4.45852 3.89353 4.62655 3.73827 4.83852 3.73827H5.15864C5.37061 3.73827 5.53864 3.89353 5.52541 4.07718L5.37684 6.14079C5.37078 6.22486 5.29048 6.29035 5.19345 6.29035H4.80371C4.70668 6.29035 4.62638 6.22486 4.62032 6.14079L4.47175 4.07718ZM4.99859 7.56638C5.30302 7.56638 5.54982 7.35214 5.54982 7.08787C5.54982 6.82359 5.30302 6.60936 4.99859 6.60936C4.69415 6.60936 4.44735 6.82359 4.44735 7.08787C4.44735 7.35214 4.69415 7.56638 4.99859 7.56638Z"
        fill={color}
      />
    </svg>
  )
}
