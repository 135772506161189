import { Operation } from '../../../__generated__/globalTypes'

import {
  MarketingMultiParagraphFragment,
  marketingMultiParagraphFragmentString,
} from '../../fragments/__generated__/MarketingMultiParagraphFragment'

import getUpsaleDialogMarketingContentRawString from '../GetUpsaleDialogMarketingContent.graphql'

const getUpsaleDialogMarketingContentString = [
  getUpsaleDialogMarketingContentRawString,
  marketingMultiParagraphFragmentString,
].join('\n\n')

export type GetUpsaleDialogMarketingContentData = {
  upsaleDialogContent: MarketingMultiParagraphFragment
}

export type GetUpsaleDialogMarketingContent = Operation<
  GetUpsaleDialogMarketingContentData
>

export const GetUpsaleDialogMarketingContent = (): GetUpsaleDialogMarketingContent => ({
  query: getUpsaleDialogMarketingContentString,
  variables: undefined,
})
