import React from 'react'
import { SVGProps } from './types'

export const Cancel: React.FC<SVGProps> = ({ onClick, size, color }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick && (() => onClick())}
    >
      <path
        d="M1.70711 0.292893C1.31658 -0.0976311 0.683417 -0.0976311 0.292893 0.292893C-0.0976311 0.683417 -0.0976311 1.31658 0.292893 1.70711L6.58586 8.00008L0.292893 14.293C-0.0976306 14.6836 -0.0976309 15.3167 0.292893 15.7073C0.683418 16.0978 1.31658 16.0978 1.70711 15.7073L8.00008 9.41429L14.2929 15.7071C14.6834 16.0976 15.3166 16.0976 15.7071 15.7071C16.0976 15.3166 16.0976 14.6834 15.7071 14.2929L9.41429 8.00008L15.7071 1.70726C16.0976 1.31673 16.0976 0.683569 15.7071 0.293044C15.3166 -0.09748 14.6834 -0.09748 14.2929 0.293044L8.00008 6.58586L1.70711 0.292893Z"
        fill={color}
      />
    </svg>
  )
}
