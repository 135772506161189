import { ContentMediaType } from 'src/home_page/components/ContentPop/ContentPop'
import { BentoContentExpandType } from 'src/marketing/components/BentoGrid/BentoCard'
import { CardTypeImageDiffData } from 'src/marketing/mockData/CardTypeImageDiffData'

const photoDropContentStream: ContentMediaType[] = [
  {
    content: {
      type: 'content',
      title: 'Photo Drop Card',
      description:
        'These cards feature beautiful professionally designed templates that you can drop your own photos into.',
    },
    width: '100%',
    height: 'auto',
  },
  {
    content: {
      type: 'content',
      title: '',
      description:
        'We have curated our Photo Drop Cards with ease and beauty in mind. Choose one of our Photo Drop cards for the best way to feature your photos and complement both your photo and message. ',
    },
    width: '100%',
    height: 'auto',
  },
  {
    content: {
      type: 'content',
      title: '',
      description:
        'Let us take on the work of creating a frame-worthy card that you simply drag and drop your memory into. We are sure the next time you talk with the person you sent it to, they will talk about how it’s displayed in their home. ',
    },
    width: '100%',
    height: 'auto',
  },
]
export const photoDropExpandData: BentoContentExpandType = {
  bannerImages: [
    'https://soc-website-public.s3.us-west-2.amazonaws.com/static/soc-website/images/popups/Photo-drop-main-image.png',
  ],
  contentStream: photoDropContentStream,
  type: 'content',
  imageDiff: CardTypeImageDiffData[1],
}
