import React from 'react'

export const HomePageBanner: React.FC = () => {
  return (
    <svg
      width="100vw"
      height={824}
      viewBox="0 0 1512 824"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_f_640_1871)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M764 -374C1098.13 -374 1369 -103.132 1369 231C1369 565.132 1098.13 836 764 836C429.868 836 159 565.132 159 231C159 -103.132 429.868 -374 764 -374ZM764 -402C1113.6 -402 1397 -118.596 1397 231C1397 580.596 1113.6 864 764 864C414.404 864 131 580.596 131 231C131 -118.596 414.404 -402 764 -402Z"
          fill="url(#paint0_linear_640_1871)"
          fillOpacity={0.6}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M764 -461C1146.18 -461 1456 -151.181 1456 231C1456 613.181 1146.18 923 764 923C381.819 923 72 613.181 72 231C72 -151.181 381.819 -461 764 -461ZM764 -489C1161.65 -489 1484 -166.645 1484 231C1484 628.645 1161.65 951 764 951C366.355 951 44 628.645 44 231C44 -166.645 366.355 -489 764 -489Z"
          fill="url(#paint1_linear_640_1871)"
          fillOpacity={0.6}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M764 -541C1190.36 -541 1536 -195.364 1536 231C1536 657.364 1190.36 1003 764 1003C337.636 1003 -8 657.364 -8 231C-8 -195.364 337.636 -541 764 -541ZM764 -569C1205.83 -569 1564 -210.828 1564 231C1564 672.828 1205.83 1031 764 1031C322.172 1031 -36 672.828 -36 231C-36 -210.828 322.172 -569 764 -569Z"
          fill="url(#paint2_linear_640_1871)"
          fillOpacity={0.6}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M764 -615C1231.23 -615 1610 -236.233 1610 231C1610 698.233 1231.23 1077 764 1077C296.767 1077 -82 698.233 -82 231C-82 -236.233 296.767 -615 764 -615ZM764 -643C1246.7 -643 1638 -251.697 1638 231C1638 713.697 1246.7 1105 764 1105C281.303 1105 -110 713.697 -110 231C-110 -251.697 281.303 -643 764 -643Z"
          fill="url(#paint3_linear_640_1871)"
          fillOpacity={0.6}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M764 -84.1926C938.076 -84.1926 1079.19 56.924 1079.19 231C1079.19 405.076 938.076 546.193 764 546.193C589.924 546.193 448.807 405.076 448.807 231C448.807 56.924 589.924 -84.1926 764 -84.1926ZM764 -112.193C953.54 -112.193 1107.19 41.46 1107.19 231C1107.19 420.54 953.54 574.193 764 574.193C574.46 574.193 420.807 420.54 420.807 231C420.807 41.46 574.46 -112.193 764 -112.193Z"
          fill="url(#paint4_linear_640_1871)"
          fillOpacity={0.6}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M764 -225.283C1016 -225.283 1220.28 -20.9982 1220.28 231C1220.28 482.998 1016 687.283 764 687.283C512.002 687.283 307.717 482.998 307.717 231C307.717 -20.9982 512.002 -225.283 764 -225.283ZM764 -253.283C1031.46 -253.283 1248.28 -36.4622 1248.28 231C1248.28 498.462 1031.46 715.283 764 715.283C496.538 715.283 279.717 498.462 279.717 231C279.717 -36.4622 496.538 -253.283 764 -253.283Z"
          fill="url(#paint5_linear_640_1871)"
          fillOpacity={0.6}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M764 30.2048C874.896 30.2048 964.795 120.104 964.795 231C964.795 341.896 874.896 431.795 764 431.795C653.104 431.795 563.205 341.896 563.205 231C563.205 120.104 653.104 30.2048 764 30.2048ZM764 2.20483C890.36 2.20483 992.795 104.64 992.795 231C992.795 357.36 890.36 459.795 764 459.795C637.64 459.795 535.205 357.36 535.205 231C535.205 104.64 637.64 2.20483 764 2.20483Z"
          fill="url(#paint6_linear_640_1871)"
          fillOpacity={0.6}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M764 -293.922C1053.91 -293.922 1288.92 -58.9063 1288.92 231C1288.92 520.906 1053.91 755.922 764 755.922C474.094 755.922 239.079 520.906 239.079 231C239.079 -58.9063 474.094 -293.922 764 -293.922ZM764 -321.922C1069.37 -321.922 1316.92 -74.3703 1316.92 231C1316.92 536.37 1069.37 783.922 764 783.922C458.63 783.922 211.079 536.37 211.079 231C211.079 -74.3703 458.63 -321.922 764 -321.922Z"
          fill="url(#paint7_linear_640_1871)"
          fillOpacity={0.6}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M764 -23.1808C904.38 -23.1808 1018.18 90.6198 1018.18 231C1018.18 371.38 904.38 485.181 764 485.181C623.62 485.181 509.819 371.38 509.819 231C509.819 90.6198 623.62 -23.1808 764 -23.1808ZM764 -51.1808C919.844 -51.1808 1046.18 75.1558 1046.18 231C1046.18 386.844 919.844 513.181 764 513.181C608.156 513.181 481.819 386.844 481.819 231C481.819 75.1558 608.156 -51.1808 764 -51.1808Z"
          fill="url(#paint8_linear_640_1871)"
          fillOpacity={0.6}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M764 -149.018C973.878 -149.018 1144.02 21.1218 1144.02 231C1144.02 440.878 973.878 611.018 764 611.018C554.122 611.018 383.982 440.878 383.982 231C383.982 21.1218 554.122 -149.018 764 -149.018ZM764 -177.018C989.342 -177.018 1172.02 5.65784 1172.02 231C1172.02 456.342 989.342 639.018 764 639.018C538.658 639.018 355.982 456.342 355.982 231C355.982 5.65784 538.658 -177.018 764 -177.018Z"
          fill="url(#paint9_linear_640_1871)"
          fillOpacity={0.6}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M764 87.4037C843.306 87.4037 907.596 151.694 907.596 231C907.596 310.306 843.306 374.596 764 374.596C684.694 374.596 620.403 310.306 620.403 231C620.403 151.694 684.694 87.4037 764 87.4037ZM764 59.4037C858.77 59.4037 935.596 136.23 935.596 231C935.596 325.77 858.77 402.596 764 402.596C669.23 402.596 592.403 325.77 592.403 231C592.403 136.23 669.23 59.4037 764 59.4037Z"
          fill="url(#paint10_linear_640_1871)"
          fillOpacity={0.6}
        />
      </g>
      <defs>
        <filter
          id="filter0_f_640_1871"
          x={-142}
          y={-675}
          width={1812}
          height={1812}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation={16}
            result="effect1_foregroundBlur_640_1871"
          />
        </filter>
        <linearGradient
          id="paint0_linear_640_1871"
          x1={764}
          y1={864}
          x2={764}
          y2={-402}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity={0.08} />
          <stop offset={1} stopColor="#FFF3F3" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="paint1_linear_640_1871"
          x1={764}
          y1={951}
          x2={764}
          y2={-489}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity={0.08} />
          <stop offset={1} stopColor="#FFF3F3" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="paint2_linear_640_1871"
          x1={764}
          y1={1031}
          x2={764}
          y2={-569}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity={0.08} />
          <stop offset={1} stopColor="#FFF3F3" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="paint3_linear_640_1871"
          x1={764}
          y1={1105}
          x2={764}
          y2={-643}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity={0.08} />
          <stop offset={1} stopColor="#FFF3F3" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="paint4_linear_640_1871"
          x1={764}
          y1={574.193}
          x2={764}
          y2={-112.193}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity={0.08} />
          <stop offset={1} stopColor="#FFF3F3" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="paint5_linear_640_1871"
          x1={764}
          y1={715.283}
          x2={764}
          y2={-253.283}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity={0.08} />
          <stop offset={1} stopColor="#FFF3F3" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="paint6_linear_640_1871"
          x1={764}
          y1={459.795}
          x2={764}
          y2={2.20483}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity={0.08} />
          <stop offset={1} stopColor="#FFF3F3" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="paint7_linear_640_1871"
          x1={764}
          y1={783.922}
          x2={764}
          y2={-321.922}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity={0.08} />
          <stop offset={1} stopColor="#FFF3F3" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="paint8_linear_640_1871"
          x1={764}
          y1={513.181}
          x2={764}
          y2={-51.1808}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity={0.08} />
          <stop offset={1} stopColor="#FFF3F3" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="paint9_linear_640_1871"
          x1={764}
          y1={639.018}
          x2={764}
          y2={-177.018}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity={0.08} />
          <stop offset={1} stopColor="#FFF3F3" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="paint10_linear_640_1871"
          x1={764}
          y1={402.596}
          x2={764}
          y2={59.4037}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity={0.08} />
          <stop offset={1} stopColor="#FFF3F3" stopOpacity={0} />
        </linearGradient>
      </defs>
    </svg>
  )
}
