import { Maybe, Operation } from '../../../__generated__/globalTypes'

import getCategoryGiftsString from '../GetCategoryGifts.graphql'

export type GetCategoryGiftsData = {
  gifts: {
    id: string
    name: string
    defaultImageUrl: string
  }[]
}

export type GetCategoryGiftsVariables = {
  categoryId?: Maybe<string>
  offset?: Maybe<number>
  limit?: Maybe<number>
}

export type GetCategoryGifts = Operation<
  GetCategoryGiftsData,
  GetCategoryGiftsVariables
>

export const GetCategoryGifts = ({
  categoryId,
  offset,
  limit,
}: GetCategoryGiftsVariables = {}): GetCategoryGifts => ({
  query: getCategoryGiftsString,
  variables: {
    categoryId,
    offset,
    limit,
  },
})
