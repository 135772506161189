import mapValues from 'lodash/mapValues'
import { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as Actions from 'src/redux/actions'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type MapReturnType<T extends (...a: any[]) => any, NewResult> = (
  ...a: Parameters<T>
) => NewResult

type BoundActionCreators = {
  [Key in keyof typeof Actions]: MapReturnType<typeof Actions[Key], void>
}

const useActions = () => {
  const dispatch = useDispatch()
  return useMemo(() => {
    return mapValues(
      bindActionCreators(Actions, dispatch),
      actionCreator => (...args: unknown[]) => {
        ;(actionCreator as (...args: unknown[]) => unknown)(...args)
      },
    ) as BoundActionCreators
  }, [dispatch])
}

export default useActions
