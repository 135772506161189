import { BentoCardData } from 'src/marketing/components/BentoGrid/BentoCard'
import { catalogCardExpandData } from './expands/catalogCard'
import { buildYourOwnExpandData } from './expands/buildYourOwn'
import { photoDropExpandData } from './expands/photoDrop'

export const CardTypeBentoCards = (isMobile: boolean) => {
  const cardData = [
    {
      id: 1,
      title: 'Catalog Cards',
      description:
        'Thousands of cards beautifully designed by professional artists.',
      icon: 'catalog_card_2',
      isFullWidth: true,
      details: catalogCardExpandData,
      grid: {
        colStart: '3',
        colEnd: '4',
        rowStart: '4',
        rowEnd: '6',
      },
    },
    {
      id: 2,
      title: 'Photo Drop',
      description:
        'Highlight your special moment with designed templates that you can drop your own photos into.',
      icon: 'photo_drop',
      details: photoDropExpandData,
      isFullWidth: true,
      grid: {
        colStart: '4',
        colEnd: '5',
        rowStart: '4',
        rowEnd: '6',
      },
    },
    {
      id: 3,
      title: 'Build your own',
      description:
        'Completely customize your card from beginning to end. Add photos, templates, and content - make it yours!',
      icon: 'build_your_own_card',
      isFullWidth: true,
      details: buildYourOwnExpandData,
      grid: {
        colStart: '5',
        colEnd: '6',
        rowStart: '4',
        rowEnd: '6',
      },
    },
  ] as BentoCardData[]
  return cardData
}
