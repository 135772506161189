import deepmerge from 'deepmerge'

import { Action } from 'src/redux/action'
import {
  deepmergeOptions,
  gatherNormalizedData,
} from 'src/normalized-data/normalization'
import { objectSize } from 'src/helpers'
import { NormalizedData } from 'src/graphql'
import { normalizedSendableCardsReducer } from 'src/redux/reducers/catalog'

type NormalizedDataReducers = {
  [K in keyof NormalizedData]?: (
    data: NormalizedData[K],
    action: Action,
  ) => NormalizedData[K]
}

const normalizedDataReducers: NormalizedDataReducers = {
  SendableCard: normalizedSendableCardsReducer,
}

export default (
  data: NormalizedData = NormalizedData(),
  action: Action,
): NormalizedData => {
  const newData = gatherNormalizedData(action)
  return Object.keys(data).reduce(
    (data, k) => {
      const key = k as keyof NormalizedData
      const reducer = normalizedDataReducers[key]
      const nextSubset = reducer
        ? (reducer as any)(data[key], action)
        : data[key as keyof NormalizedData]
      return nextSubset !== data[key] ? { ...data, [key]: nextSubset } : data
    },
    objectSize(newData) > 0 ? deepmerge(data, newData, deepmergeOptions) : data,
  )
}
