import React from 'react'
import { SVGProps } from './types'

export const PersonalSignatures: React.FC<SVGProps> = ({
  size = 24,
  onClick,
  color = '#1C1C1C',
  opacity,
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 94 88"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <g filter="url(#filter0_d_2349_4761)">
        <path
          d="M12.5579 63.1967C12.5579 68.0518 16.5066 72.0005 21.3617 72.0005C24.5112 72.0005 27.4387 70.3025 28.9973 67.5695L31.1271 63.8474C35.1248 56.8349 38.0507 49.2958 39.8222 41.4397L40.1177 40.1306L60.094 34.1425L56.7807 44.0809C56.5143 44.8724 56.649 45.7436 57.1374 46.4189C57.6289 47.0987 58.4205 47.5044 59.2565 47.5044H78.8392C80.2739 47.5044 81.4421 46.3362 81.4421 44.9015C81.4421 43.4669 80.2739 42.2987 78.8392 42.2987H62.8515L66.618 31.0221C66.9273 30.1019 66.6945 29.0914 66.0101 28.384C65.3365 27.6858 64.3305 27.4256 63.3843 27.7057L41.3089 34.3431L41.7116 30.8736C42.0668 27.8068 42.2475 24.6864 42.2475 21.5997V20.4055C42.2459 13.5645 36.6819 8.00049 29.844 8.00049C23.0061 8.00049 17.4421 13.5645 17.4421 20.4024V25.3019C17.4421 26.7366 18.6103 27.9048 20.045 27.9048C21.4796 27.9048 22.6478 26.7366 22.6478 25.3019V20.4024C22.6478 16.4338 25.8754 13.2062 29.844 13.2062C33.8126 13.2062 37.0402 16.4338 37.0402 20.4024V21.5967C37.0402 25.9343 36.6635 30.3163 35.9194 34.6187L35.6775 36.0196L21.3939 40.3021C18.8216 41.0753 16.6138 42.6248 15.0107 44.7821C13.4077 46.9394 12.5594 49.501 12.5594 52.1896V63.1982L12.5579 63.1967ZM17.733 52.188C17.733 49.0416 19.8383 46.206 22.853 45.2904L24.1744 44.8893L24.1927 44.9077L34.497 41.8164L33.2323 45.9274C31.5895 51.2663 29.3525 56.4307 26.5858 61.2736L24.4576 64.9957C23.8099 66.118 22.6142 66.8101 21.3311 66.8101C19.3468 66.8101 17.733 65.1963 17.733 63.212V52.188Z"
          fill="#6B7280"
        />
        <mask
          id="mask0_2349_4761"
          style={{
            maskType: 'alpha',
          }}
          maskUnits="userSpaceOnUse"
          x={12}
          y={8}
          width={70}
          height={65}
        >
          <path
            d="M12.5579 63.1967C12.5579 68.0518 16.5066 72.0005 21.3617 72.0005C24.5112 72.0005 27.4387 70.3025 28.9973 67.5695L31.1271 63.8474C35.1248 56.8349 38.0507 49.2958 39.8222 41.4397L40.1177 40.1306L60.094 34.1425L56.7807 44.0809C56.5143 44.8724 56.649 45.7436 57.1374 46.4189C57.6289 47.0987 58.4205 47.5044 59.2565 47.5044H78.8392C80.2739 47.5044 81.4421 46.3362 81.4421 44.9015C81.4421 43.4669 80.2739 42.2987 78.8392 42.2987H62.8515L66.618 31.0221C66.9273 30.1019 66.6945 29.0914 66.0101 28.384C65.3365 27.6858 64.3305 27.4256 63.3843 27.7057L41.3089 34.3431L41.7116 30.8736C42.0668 27.8068 42.2475 24.6864 42.2475 21.5997V20.4055C42.2459 13.5645 36.6819 8.00049 29.844 8.00049C23.0061 8.00049 17.4421 13.5645 17.4421 20.4024V25.3019C17.4421 26.7366 18.6103 27.9048 20.045 27.9048C21.4796 27.9048 22.6478 26.7366 22.6478 25.3019V20.4024C22.6478 16.4338 25.8754 13.2062 29.844 13.2062C33.8126 13.2062 37.0402 16.4338 37.0402 20.4024V21.5967C37.0402 25.9343 36.6635 30.3163 35.9194 34.6187L35.6775 36.0196L21.3939 40.3021C18.8216 41.0753 16.6138 42.6248 15.0107 44.7821C13.4077 46.9394 12.5594 49.501 12.5594 52.1896V63.1982L12.5579 63.1967ZM17.733 52.188C17.733 49.0416 19.8383 46.206 22.853 45.2904L24.1744 44.8893L24.1927 44.9077L34.497 41.8164L33.2323 45.9274C31.5895 51.2663 29.3525 56.4307 26.5858 61.2736L24.4576 64.9957C23.8099 66.118 22.6142 66.8101 21.3311 66.8101C19.3468 66.8101 17.733 65.1963 17.733 63.212V52.188Z"
            fill="#6B7280"
          />
        </mask>
        <g mask="url(#mask0_2349_4761)">
          <g opacity={0.6} filter="url(#filter1_f_2349_4761)">
            <circle cx={19.5579} cy={33.0005} r={7} fill="#F9FAFB" />
          </g>
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_2349_4761"
          x={0.557892}
          y={0.000488281}
          width={92.8842}
          height={88}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={4} />
          <feGaussianBlur stdDeviation={6} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2349_4761"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2349_4761"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_f_2349_4761"
          x={8.55789}
          y={22.0005}
          width={22}
          height={22}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation={2}
            result="effect1_foregroundBlur_2349_4761"
          />
        </filter>
      </defs>
    </svg>
  )
}
