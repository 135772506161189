import { Operation } from '../../../__generated__/globalTypes'

import {
  SponsorFragment,
  sponsorFragmentString,
} from '../../fragments/__generated__/SponsorFragment'

import getStorefrontRawString from '../GetStorefront.graphql'

const getStorefrontString = [
  getStorefrontRawString,
  sponsorFragmentString,
].join('\n\n')

export type GetStorefrontData = {
  user: SponsorFragment
}

export type GetStorefrontVariables = {
  slug: string
}

export type GetStorefront = Operation<GetStorefrontData, GetStorefrontVariables>

export const GetStorefront = ({
  slug,
}: GetStorefrontVariables): GetStorefront => ({
  query: getStorefrontString,
  variables: {
    slug,
  },
})
