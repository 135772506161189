import React, { ButtonHTMLAttributes, CSSProperties } from 'react'

import { Icon, Icons, IconType } from 'src/chrome'
import { ButtonColorChoices } from 'src/theme/buttonColors'

import styles from './styles'

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  buttonColor?: ButtonColorChoices
  title?: string
  titleStyle?: CSSProperties
  innerStyle?: CSSProperties
  icon?: IconType | Icons
  iconSize?: number
  iconColor?: string
}

const Button: React.FC<ButtonProps> = props => {
  const {
    buttonColor,
    disabled,
    icon,
    iconColor,
    iconSize,
    innerStyle,
    title,
    titleStyle,
    ...restProps
  } = props

  return (
    <button
      css={theme => styles.gradientButton(theme, buttonColor, disabled)}
      {...restProps}
    >
      <div css={theme => styles.inner(theme, buttonColor)} style={innerStyle}>
        {icon && <Icon icon={icon} size={iconSize} color={iconColor} />}
        <span style={titleStyle}>{title}</span>
      </div>
    </button>
  )
}

export default Button
