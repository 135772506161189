import React from 'react'

import { Div, Flex } from '@sendoutcards/quantum-design-ui'
import { Frame } from '../Frame/Frame'

const blobs = [
  '',
  'https://soc-website-public.s3.us-west-2.amazonaws.com/static/soc-website/images/blobs01.png',
  'https://soc-website-public.s3.us-west-2.amazonaws.com/static/soc-website/images/blobs02.png',
  'https://soc-website-public.s3.us-west-2.amazonaws.com/static/soc-website/images/blobs03.png',
  'https://soc-website-public.s3.us-west-2.amazonaws.com/static/soc-website/images/blobs04.png',
]
export type BlobPositionType = {
  top?: string
  right?: string
  bottom?: string
  left?: string
}
export type BlobSettingType = {
  option: number
  width: string
  height: string
  position?: BlobPositionType
  glassBlur?: number
}

type GlassBackgroundSvgContainerProps = {
  blob: BlobSettingType
  blobTransform?: string
  style?: React.CSSProperties
}

export const GlassBackgroundSvgContainer: React.FC<GlassBackgroundSvgContainerProps> = ({
  blob,
  blobTransform,
  children,
  style,
}) => {
  // how many pixels beyond visible area the glass should consider when calculating blur
  const blobPosition = {
    top: blob.position?.top ?? 0,
    right: blob.position?.right ?? 0,
    bottom: blob.position?.bottom ?? 0,
    left: blob.position?.left ?? 0,
    transform: blobTransform ?? 'initial',
  }

  const glassBlurSetting =
    blob.option === 0 ? 0 : blob.glassBlur ? blob.glassBlur : 69

  const backdropFilterStyle = {
    backdropFilter: `blur(${glassBlurSetting}px)`,
    // Add the -webkit-backdrop-filter for Safari
    WebkitBackdropFilter: `blur(${glassBlurSetting}px)`,
  }
  return (
    <Flex
      width="100%"
      height="100%"
      position="relative"
      justifyContent="center"
      alignItems="center"
      backgroundColor="foreground"
      overflow="hidden"
      style={style}
    >
      {blob.option !== 0 && (
        <Div
          position="absolute"
          width={blob.width}
          height={blob.height}
          style={blobPosition}
        >
          <Frame
            width={blob.width}
            height={blob.height}
            type="image"
            src={blobs[blob.option]}
          />
        </Div>
      )}
      <Flex
        style={{
          backgroundColor: 'rgba(255, 255, 255, 0.2)',
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: 2,
          ...backdropFilterStyle,
        }}
      />
      <Flex
        position="relative"
        width="100%"
        height="100%"
        zIndex={5}
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        {children}
      </Flex>
    </Flex>
  )
}
