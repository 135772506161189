import React from 'react'
import { IconSizeType } from './PersonalTouch'

export const GiftCard: React.FC<IconSizeType> = ({
  width = 56,
  height = 56,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
    >
      <path
        fill="#2EC298"
        d="M8.105 2.728c.764-1.798 2.02-2.883 4.04-2.69 1.78.169 2.832 1.26 3.324 2.978h.37c3.896 0 7.79.019 11.686-.013 1.09-.008 1.84.777 1.834 1.848-.025 4.434-.01 8.869-.01 13.303 0 1.185-.663 1.847-1.854 1.847H3.854c-1.207 0-1.862-.657-1.862-1.87 0-3.716-.002-7.433.004-11.15a.772.772 0 00-.241-.597C.719 5.311.386 4.033.837 2.613 1.292 1.181 2.312.296 3.79.057 5.265-.18 6.503.322 7.401 1.541c.263.357.456.765.704 1.188zm.973 6.824c-.011.143-.02.202-.02.261-.001 2.74.003 5.48-.007 8.22-.002.289.136.288.347.288 5.977-.004 11.954-.003 17.93-.004.107 0 .213-.009.319-.013v-9.62H10.591c.091.106.151.18.216.25.343.361.694.715 1.029 1.084.341.376.332.883-.005 1.205-.343.328-.836.31-1.203-.049-.121-.118-.235-.243-.352-.366L9.078 9.552zM3.69 7.612c-.004.043-.01.091-.01.14-.002 3.437 0 6.874-.006 10.311 0 .225.092.26.285.258 1.056-.007 2.112-.002 3.168-.004.074 0 .15-.017.223-.026v-8.62c-.472.458-.893.875-1.323 1.285-.383.365-.892.378-1.228.042-.353-.354-.333-.851.065-1.245.452-.45.913-.89 1.369-1.336.05-.05.092-.108.11-.13L3.69 7.61zm23.966-2.9c-.078-.008-.127-.016-.176-.016-3.956 0-7.91 0-11.866-.005-.173 0-.238.06-.289.217a3.578 3.578 0 01-1.446 1.918c-.05.034-.091.081-.152.135h13.93l-.001-2.25zM2.331 3.858c.038.628.303 1.288 1.03 1.62a29.18 29.18 0 002.439.955c.37.13.774.172 1.166.235.238.038.349-.06.298-.327-.084-.44-.123-.888-.217-1.325-.195-.909-.453-1.8-1.035-2.55a2.053 2.053 0 00-2.284-.676c-.824.282-1.389 1.063-1.397 2.068zm11.498.007c0-1.059-.606-1.856-1.51-2.108-.977-.272-1.91.16-2.463 1.159-.567 1.028-.794 2.158-.94 3.308-.054.432.008.482.445.437.03-.003.06-.005.088-.011a11.842 11.842 0 003.342-1.15c.707-.368 1.007-.984 1.038-1.634z"
      />
      <path
        fill="#2EC298"
        d="M22.14 13.26c-.995 0-1.99.009-2.985-.003-.619-.008-1.013-.588-.788-1.14a.81.81 0 01.794-.532c1.99-.006 3.98-.008 5.97.002.5.002.863.393.851.856-.012.47-.373.813-.886.816-.985.007-1.97.002-2.956.002v-.001zm-.97 1.685c.646 0 1.292-.01 1.938.003.65.013 1.048.523.867 1.088a.787.787 0 01-.659.573 1.613 1.613 0 01-.267.022c-1.262.002-2.525.007-3.787-.001-.69-.005-1.103-.517-.91-1.104.119-.363.424-.574.879-.579.646-.008 1.292-.002 1.939-.002z"
      />
    </svg>
  )
}
