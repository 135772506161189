import React from 'react'
import { Icon } from 'src/chrome'
// @src imports
import { IconTypeOrIconString } from 'src/app/types'

import styles from './filterButtons.module.scss'

type FilterButtonProps = {
  id: string
  filter: {
    name: string
    isPremium?: boolean
    icon: {
      icon: IconTypeOrIconString
      color?: string
      viewBox?: string
      size?: number
    }
    uniqueHandler?: boolean
  }
  uniqueHandlerFunction: () => void
  onClick: (isPremium?: boolean) => void
}

const FilterButton: React.FC<FilterButtonProps> = props => {
  const { id, filter, onClick, uniqueHandlerFunction } = props
  return (
    <div
      id={id}
      className={styles.filterButton}
      onClick={
        !filter.uniqueHandler
          ? () => onClick(filter.isPremium)
          : () => uniqueHandlerFunction()
      }
    >
      <div className={styles.badge}>
        <Icon
          icon={filter.icon.icon || 'ADD'}
          color={filter.icon.color}
          viewBox={filter.icon.viewBox}
          size={filter.icon.size ?? 30}
          onClick={() => null}
        />
      </div>
      <p className={styles.filterTitle}>{filter.name}</p>
    </div>
  )
}

export default FilterButton
