import React from 'react'
import { SVGProps } from './types'

export const Database: React.FC<SVGProps> = ({
  size = 24,
  color = '#323232',
  onClick,
}) => {
  return (
    <svg
      width={size}
      height={size * 1.121}
      viewBox="0 0 138 157"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M91.5 60.5996C91.5 72.6807 71.9123 82.4746 47.75 82.4746C23.5876 82.4746 4 72.6807 4 60.5996M91.5 60.5996C91.5 48.5184 71.9123 38.7246 47.75 38.7246C23.5876 38.7246 4 48.5184 4 60.5996M91.5 60.5996V95.5996M4 60.5996V95.5996M91.5 95.5996C91.5 107.681 71.9123 117.475 47.75 117.475C23.5876 117.475 4 107.681 4 95.5996M91.5 95.5996V130.6C91.5 142.681 71.9123 152.475 47.75 152.475C23.5876 152.475 4 142.681 4 130.6V95.5996M134 26.8496C134 14.7684 114.412 4.97461 90.25 4.97461C72.9329 4.97461 57.966 10.0052 50.875 17.3031M134 26.8496C134 34.9464 125.202 42.0158 112.125 45.7981M134 26.8496V101.225C134 107.702 125.202 113.357 112.125 116.383M134 66.2246C134 72.8457 124.808 78.6076 111.249 81.5809"
        stroke={color}
        strokeWidth={8}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
