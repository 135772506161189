import colors from './colors'

export interface Borders {
  selectedBorder: {
    border: string
    borderRadius: string | number
  }
  grey: string
}

const borders: Borders = {
  selectedBorder: {
    border: `${colors.green} 3px solid`,
    borderRadius: 2,
  },
  grey: '1px solid #e7e7e7',
}

export default borders
