import React from 'react'
import { SVGProps } from './types'

export const ShoppingCart: React.FC<SVGProps> = ({
  size = 24,
  color = '#323232',
  onClick,
}) => {
  return (
    <svg
      width={`${size}px`}
      height="auto"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M8 11C8.55228 11 9 11.4477 9 12C9 13.6569 10.3431 15 12 15C13.6569 15 15 13.6569 15 12C15 11.4477 15.4477 11 16 11C16.5523 11 17 11.4477 17 12C17 14.7614 14.7614 17 12 17C9.23858 17 7 14.7614 7 12C7 11.4477 7.44772 11 8 11Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17 7H19.1378C20.7117 7 22.018 8.21634 22.1301 9.78626L22.6914 17.6438C22.8981 20.5381 20.6058 23 17.7041 23H6.29591C3.3942 23 1.10188 20.5381 1.30862 17.6438L1.86987 9.78626C1.98201 8.21634 3.28833 7 4.86224 7H7L7 6C7 3.23858 9.23858 1 12 1C14.7614 1 17 3.23858 17 6V7ZM9 7H15V6C15 4.34315 13.6569 3 12 3C10.3431 3 9 4.34315 9 6L9 7ZM4.86224 9C4.33761 9 3.90217 9.40545 3.86479 9.92875L3.30354 17.7863C3.17949 19.5229 4.55489 21 6.29591 21H17.7041C19.4451 21 20.8205 19.5229 20.6965 17.7863L20.1352 9.92875C20.0978 9.40545 19.6624 9 19.1378 9H4.86224Z"
        fill={color}
      />
    </svg>
  )
}
