import { compact } from 'src/helpers'
import { AccessLevel, Route, RouteMeta, SubrouteMeta } from './Route'

type RouteTypes<T> = { [K in keyof T]: Route<T[K]> }
type Unionize<T extends any[], U = never> = T[number] | U

const withMeta = <U>(
  metas: RouteMeta<any, any, any>[],
  route: { path: string } | undefined,
  withMeta: (meta: RouteMeta<any, any, any>) => U,
): U | undefined => {
  if (!route) {
    return undefined
  }
  const meta = metas.find(meta => meta.path === route.path)
  return meta && withMeta(meta)
}

export type Subroute<T> = T extends SubrouteMeta<infer T> ? T : never

export const Subroute = <T extends RouteMeta<any, any, any>[]>(
  ...metas: T
): SubrouteMeta<Unionize<RouteTypes<T>>> => ({
  fromPath: (path, location) =>
    compact(...metas.map(meta => meta.fromPath(path, location)))[0],
  toPath: route => withMeta(metas, route, meta => meta.toPath(route)) ?? '',
  tasks: (route, state) =>
    withMeta(metas, route, meta => meta.tasks(route, state)) ?? [],
  reducer: (route, action, state) =>
    withMeta(metas, route, meta => meta.reducer(route, action, state)),
  accessLevel: route =>
    withMeta(metas, route, meta => meta.accessLevel(route)) ??
    AccessLevel.PUBLIC,
  hideSidebar: route =>
    withMeta(metas, route, meta => meta.hideSidebar(route)) ?? false,
  hideTopBar: route =>
    withMeta(metas, route, meta => meta.hideTopBar(route)) ?? false,
  doNotResetWindowScroll: route =>
    withMeta(metas, route, meta => meta.doNotResetWindowScroll(route)) ?? false,
})
