import React from 'react'
import { IconSizeType } from './PersonalTouch'

export const PaperPlane2: React.FC<IconSizeType> = ({
  width = 40,
  height = 40,
}) => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width={width}
      height={height}
      viewBox="0 0 40 40"
      enable-background="new 0 0 40 40"
      xmlSpace="preserve"
    >
      <g>
        <path
          shape-rendering="crispEdges"
          fill="#FFFFFF"
          d="M4,0h32c2.209,0,4,1.791,4,4v32c0,2.209-1.791,4-4,4H4c-2.209,0-4-1.791-4-4
		V4C0,1.791,1.791,0,4,0z"
        />
        <g>
          <defs>
            <rect id="SVGID_1_" x="10" y="10" width="20" height="20" />
          </defs>
          <clipPath id="SVGID_2_">
            <use xlinkHref="#SVGID_1_" overflow="visible" />
          </clipPath>
          <g clip-path="url(#SVGID_2_)">
            <path
              fill="#1EBFDC"
              d="M30,10.429c-0.13,0.405-0.267,0.807-0.39,1.214c-0.669,2.217-1.334,4.434-2.001,6.652
				c-0.087,0.29-0.147,0.333-0.46,0.333c-1.717,0-3.435,0.002-5.152-0.003c-0.136,0-0.227,0.042-0.317,0.14
				c-0.801,0.872-1.604,1.742-2.413,2.606c-0.075,0.08-0.183,0.154-0.287,0.176c-0.163,0.034-0.267-0.086-0.333-0.333
				c-0.341-1.278-0.683-2.557-1.024-3.835c-0.017-0.062-0.036-0.122-0.059-0.197H17.33c-1.841,0-3.682,0-5.524,0
				c-0.052,0-0.104,0-0.156,0c-0.166,0.001-0.276-0.081-0.308-0.241c-0.032-0.164,0.054-0.278,0.204-0.334
				c0.425-0.16,0.852-0.314,1.279-0.47c5.516-2.012,11.031-4.024,16.545-6.037c0.069-0.025,0.133-0.067,0.2-0.101
				c0.143,0,0.286,0,0.429,0V10.429z M20.42,18.033h6.652c0.692-2.298,1.381-4.587,2.085-6.929
				C26.229,13.426,23.344,15.714,20.42,18.033L20.42,18.033z M26.555,12.41l-0.019-0.034c-0.065,0.035-0.132,0.069-0.197,0.104
				c-1.541,0.849-3.083,1.698-4.624,2.547c-0.068,0.038-0.137,0.075-0.209,0.104c-0.148,0.059-0.273,0.016-0.353-0.116
				c-0.085-0.141-0.058-0.277,0.071-0.381c0.055-0.044,0.12-0.077,0.182-0.111c1.455-0.803,2.911-1.605,4.366-2.407
				c0.057-0.032,0.114-0.065,0.171-0.097c-0.005-0.011-0.011-0.022-0.017-0.033c-4.182,1.526-8.364,3.053-12.545,4.579
				c1.297,0.034,2.585,0.026,3.873,0.039c0.311,0.003,0.576-0.064,0.843-0.222c0.682-0.403,1.376-0.784,2.067-1.172
				c0.196-0.11,0.367-0.076,0.461,0.081c0.092,0.154,0.032,0.311-0.168,0.433c-0.039,0.024-0.08,0.044-0.119,0.066
				c-0.679,0.382-1.356,0.769-2.04,1.143c-0.131,0.072-0.15,0.141-0.112,0.275c0.145,0.518,0.279,1.039,0.419,1.559
				c0.101,0.374,0.203,0.746,0.306,1.12c0.131-0.512,0.227-1.021,0.344-1.525c0.027-0.116,0.103-0.243,0.196-0.317
				c1.83-1.459,3.667-2.911,5.501-4.364C25.487,13.257,26.021,12.834,26.555,12.41L26.555,12.41z M19.494,20.195
				c0.013,0.008,0.025,0.016,0.038,0.023c0.478-0.517,0.957-1.034,1.465-1.583c-0.397,0-0.735-0.003-1.072,0.003
				c-0.046,0.001-0.124,0.04-0.131,0.073C19.688,19.205,19.592,19.7,19.494,20.195z"
            />
            <path
              fill="#1EBFDC"
              d="M13.205,25.433c-0.341,0.036-0.679,0.101-1.018,0.1c-1.091-0.001-1.956-1.062-1.759-2.135
				c0.177-0.964,0.933-1.585,1.909-1.566c0.927,0.017,1.752,0.841,1.769,1.769c0.002,0.14,0,0.28,0,0.458
				c0.081-0.076,0.132-0.121,0.181-0.17c0.75-0.749,1.499-1.499,2.249-2.249c0.055-0.055,0.11-0.113,0.175-0.153
				c0.126-0.08,0.251-0.061,0.355,0.044c0.104,0.105,0.118,0.229,0.039,0.356c-0.03,0.049-0.073,0.091-0.114,0.132
				c-0.878,0.879-1.747,1.766-2.641,2.629c-0.26,0.25-0.438,0.508-0.543,0.858c-0.248,0.827-0.701,1.545-1.304,2.166
				c-0.226,0.233-0.454,0.466-0.688,0.691c-0.144,0.139-0.313,0.139-0.433,0.017c-0.12-0.122-0.117-0.288,0.021-0.433
				c0.264-0.279,0.537-0.548,0.799-0.828c0.444-0.477,0.763-1.031,0.992-1.639C13.202,25.458,13.204,25.433,13.205,25.433
				L13.205,25.433z M13.52,23.886c-0.005-0.083-0.004-0.239-0.026-0.392c-0.086-0.603-0.568-1.031-1.201-1.074
				c-0.537-0.037-1.07,0.35-1.246,0.903c-0.174,0.547,0.07,1.168,0.579,1.454c0.528,0.296,1.052,0.169,1.575-0.024
				c0.165-0.061,0.27-0.151,0.266-0.349C13.463,24.256,13.496,24.107,13.52,23.886z"
            />
            <path
              fill="#1EBFDC"
              d="M23.315,24.143c-1.162,0.581-1.981-0.015-2.246-0.671c-0.292-0.723,0.069-1.563,0.781-1.87
				c0.652-0.281,1.663-0.076,2.012,1.057c0.046-0.069,0.087-0.126,0.123-0.184c0.363-0.592,0.726-1.185,1.09-1.778
				c0.037-0.061,0.075-0.124,0.126-0.172c0.107-0.101,0.236-0.114,0.356-0.03c0.116,0.081,0.172,0.196,0.111,0.339
				c-0.027,0.066-0.061,0.129-0.098,0.189c-0.525,0.844-1.051,1.687-1.574,2.531c-0.029,0.046-0.047,0.108-0.047,0.162
				c-0.006,1.025-0.428,1.899-0.985,2.723c-0.094,0.139-0.264,0.176-0.394,0.1c-0.14-0.082-0.189-0.247-0.103-0.408
				c0.089-0.166,0.193-0.323,0.291-0.484C23.038,25.19,23.241,24.703,23.315,24.143L23.315,24.143z M22.568,23.789
				c0.119-0.051,0.355-0.146,0.587-0.254c0.148-0.069,0.221-0.182,0.175-0.358c-0.04-0.156-0.046-0.322-0.09-0.476
				c-0.123-0.436-0.547-0.686-0.998-0.6c-0.425,0.081-0.717,0.483-0.677,0.932C21.604,23.463,21.982,23.793,22.568,23.789
				L22.568,23.789z"
            />
            <path
              fill="#1EBFDC"
              d="M18.708,25.215c-0.049,0.109-0.069,0.178-0.108,0.235c-0.356,0.51-0.714,1.019-1.076,1.524
				c-0.126,0.175-0.296,0.213-0.436,0.115c-0.152-0.106-0.173-0.276-0.042-0.462c0.353-0.505,0.709-1.006,1.066-1.508
				c0.09-0.126,0.213-0.216,0.363-0.142C18.571,25.025,18.634,25.136,18.708,25.215L18.708,25.215z"
            />
            <path
              fill="#1EBFDC"
              d="M10.996,28.68c0.07,0.055,0.178,0.101,0.215,0.18c0.037,0.08,0.043,0.227-0.006,0.285
				c-0.234,0.276-0.484,0.539-0.744,0.791c-0.104,0.1-0.245,0.078-0.353-0.022c-0.114-0.106-0.15-0.249-0.049-0.366
				c0.237-0.273,0.492-0.532,0.745-0.79C10.84,28.72,10.908,28.714,10.996,28.68z"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}
