import React from 'react'
import { Flex } from '@sendoutcards/quantum-design-ui'
import {
  GradientSettingsType,
  useGradientBorderStyle,
} from 'src/hooks/useAnimateGradientBorder'
import {
  BlobSettingType,
  GlassBackgroundSvgContainer,
} from 'src/design_system/components/GlassBackgroundSvgContainer/GlassBackgroundSvgContainer'

type BentoContainerProps = {
  onClick: () => void
  isActive: boolean
  width?: string
  height?: string
  isSmallMobile?: boolean
  activeGradient?: string
  blobs?: BlobSettingType
  paddingOverride?: string
}

export const BentoContainer: React.FC<BentoContainerProps> = ({
  onClick,
  isActive,
  width,
  height,
  isSmallMobile,
  activeGradient,
  children,
  blobs = { option: 1, width: '90%', height: '90%' },
  paddingOverride,
}) => {
  const gradientBorderSettings: GradientSettingsType = {
    gradient:
      activeGradient ??
      'linear-gradient(60deg, #f79533, #f37055, #ef4e7b, #a166ab, #5073b8, #1098ad, #07b39b, #6fba82)',
    backgroundColor: '#1D1F20',
    radius: 14,
    thickness: 2,
    duration: 3,
    shouldAnimate: true,
  }

  const { borderStyles, gradientBorderOverlay } = useGradientBorderStyle(
    gradientBorderSettings,
  )

  const activeGradientBorderStyles = isActive
    ? {
        ...borderStyles,
        ...gradientBorderOverlay,
      }
    : {}

  return (
    <Flex
      flexDirection="column"
      width={isSmallMobile ? '100%' : width}
      height="inherit"
      onClick={onClick}
      borderStyle="solid"
      borderWidth="default"
      position="relative"
      borderColor="transparent"
      borderRadius="14px"
      justifyContent="flex-start"
      alignItems="flex-start"
    >
      <Flex style={activeGradientBorderStyles} justifyContent="flex-start" />
      <Flex
        id="bento_content_container"
        flexDirection="column"
        zIndex={1}
        width="100%"
        height="100%"
        position="relative"
        overflow="hidden"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <GlassBackgroundSvgContainer
          blob={blobs}
          style={{
            height: '100%',
            alignItems: 'flex-start',
            padding: paddingOverride ?? '20px',
            borderRadius: '12px',
            backgroundColor: isActive ? 'rgba(255,255,255,0.95)' : '#FFF',
          }}
        >
          {children}
        </GlassBackgroundSvgContainer>
      </Flex>
    </Flex>
  )
}
