import { Flex, Image, Spacer, Text } from '@sendoutcards/quantum-design-ui'
import React from 'react'
// import { Button } from 'src/design_system/components/Button'
import useGetCategoryGiftQuery from 'src/home_page/hooks/useGetCategoryGiftQuery'
import { Autoplay, Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

/*tslint:disable:no-import-side-effect*/
import 'swiper/swiper-bundle.css'
/*tslint:disable:no-import-side-effect*/
import './styles.css'

export type ProductType = {
  type: 'product'
  title: string
  description: string
  categoryId: string
  link?: {
    title: string
    url: string
  }
}

type ProductPopProps = {
  product: ProductType
  action: { title: string; onClick: () => void }
}

export const ProductPop: React.FC<ProductPopProps> = ({ product, action }) => {
  const categories = useGetCategoryGiftQuery(product.categoryId)
  return (
    <Flex
      flexDirection="column"
      width="100%"
      borderRadius="default"
      position="relative"
      overflow="hidden"
    >
      <Flex
        id="product_swiper_container"
        backgroundColor="foreground"
        flexDirection="column"
        inset={{ vertical: 'x3' }}
      >
        <Swiper
          className="product-pop-swiper"
          modules={[Pagination, Autoplay, Navigation]}
          slidesPerView={1}
          grabCursor={true}
          initialSlide={1}
          speed={500}
          loop={true}
          autoplay={{ delay: 4500, disableOnInteraction: false }}
          spaceBetween={0}
          centeredSlides={true}
          pagination={{ dynamicBullets: true, clickable: true }}
          navigation={true}
        >
          {categories?.gifts.map((gift, index) => {
            return (
              <SwiperSlide key={index} className="swiper-slide">
                <Image
                  isActive={true}
                  width="263px"
                  height="300px"
                  image={{
                    url: gift.defaultImageUrl,
                  }}
                />
              </SwiperSlide>
            )
          })}
        </Swiper>
      </Flex>
      <Flex
        id="product_detail_container"
        flexDirection="column"
        inset={{ vertical: 'x4', horizontal: 'x4' }}
      >
        <Text
          type="largeBody"
          weight="bold"
          color="primaryHeading"
          content={product.title}
        />
        <Spacer space="x2" />
        <Text type="body" color="primaryBody" content={product.description} />
        <Spacer space="x6" />
        {/* <Flex width="100%">
          <Div style={{ marginLeft: 'auto' }}>
            <Button
              title={action.title}
              onClick={action.onClick}
              backgroundColor="#fff"
              textColor="#000"
              boxShadow="rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"
              shouldDisplayBoxShadow={true}
            />
          </Div>
        </Flex> */}
      </Flex>
    </Flex>
  )
}
