import React from 'react'
// @src imports
import { CatalogRibbon, Reason } from 'src/catalog/components'
import { CollectionFragment, MinimalSendableCardFragment } from 'src/graphql'
import { PaginatedList } from 'src/utils/PaginatedList'
// relative imports
import styles from './collection.module.scss'

interface CollectionProps {
  cards: PaginatedList<MinimalSendableCardFragment>
  collection: CollectionFragment
  onClick: (id: string) => void
  onClose: () => void
}

const Collection: React.FC<CollectionProps> = props => {
  const { collection, cards, onClick, onClose } = props

  return (
    <div className={styles.collectionContainer}>
      <Reason
        title={collection.title}
        description={collection.description}
        textColor={collection.textColor}
        backgroundStartColor={collection.backgroundStartColor}
        backgroundEndColor={collection.backgroundEndColor}
        card={collection.cards[0]}
        onClose={onClose}
      />
      <div className={styles.cardGrid}>
        <CatalogRibbon
          type={`collection_${collection.id}_card`}
          title={collection.title}
          items={cards.elements}
          grid={true}
          onClick={onClick}
          onClose={onClose}
          hasMore={cards.hasMore}
        />
      </div>
    </div>
  )
}

export default Collection
