import React, { useState } from 'react'

import get from 'lodash/get'
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  Elements,
  injectStripe,
  ReactStripeElements,
} from 'react-stripe-elements'

import styles from './editCard.module.scss'
import { Button, Div, Icon, Spacer } from '@sendoutcards/quantum-design-ui'

interface StripeCardFormProps extends ReactStripeElements.InjectedStripeProps {
  onSuccess: (token: stripe.Token) => void
  onError: (msg: string) => void
  isSubmitButtonVisible: boolean
  isAddingCard: boolean
}

const StripeCardForm: React.FC<StripeCardFormProps> = props => {
  const {
    stripe,
    onError,
    onSuccess,
    children,
    isAddingCard,
    isSubmitButtonVisible,
  } = props

  const [isComplete, setIsComplete] = useState(false)

  const onSubmit = async (formEvent: React.FormEvent<HTMLFormElement>) => {
    if (formEvent) {
      formEvent.preventDefault()
    }

    if (!stripe) {
      return
    }

    const { error, token } = await stripe.createToken()

    if (error) {
      const message = get(error, 'message', 'There has been a payment error.')
      return onError(message)
    }

    if (token) {
      return onSuccess(token)
    }
  }

  const handleOnChange = (event: stripe.elements.ElementChangeResponse) =>
    setIsComplete(event.complete)

  const stripeElementWrapperProps = {
    borderRadius: '12px',
    backgroundColor: 'background',
    maxHeight: '50px',
    inset: '0.5rem',
    outset: { bottom: '0.5rem' },
  }
  const stripeElementWithIconsProps = {
    style: {
      display: 'grid',
      gridTemplateColumns: '12px auto',
      columnGap: '12px',
      alignItems: 'center',
      width: '90px',
    },
  }
  const stripeElementProps = {
    onChange: handleOnChange,
    style: {
      base: {
        fontFamily: "'Montserrat',sans-serif",
        fontWeight: '500',
        color: '#6f8394',
        lineHeight: '24px',
      },
    },
  }

  return (
    <form id={'addCardForm'} onSubmit={onSubmit}>
      <Div>
        <Div {...stripeElementWrapperProps}>
          <CardNumberElement
            {...stripeElementProps}
            showIcon={true}
            placeholder="Credit Card Number"
          />
        </Div>
        <Div
          display="flex"
          columnGap="0.5rem"
          css={{
            '@media (max-width: 480px)': { justifyContent: 'space-between' },
          }}
        >
          <Div {...stripeElementWrapperProps} {...stripeElementWithIconsProps}>
            <Icon
              name="campaigns"
              size="xSmall"
              primaryColor="primaryBodyText"
            />
            <CardExpiryElement {...stripeElementProps} placeholder="EXP" />
          </Div>
          <Div {...stripeElementWrapperProps} {...stripeElementWithIconsProps}>
            <Icon name="lock" size="xSmall" primaryColor="primaryBodyText" />
            <CardCvcElement {...stripeElementProps} placeholder="CVC" />
          </Div>
        </Div>
      </Div>
      {children}
      {!children && isSubmitButtonVisible && (
        <>
          <Spacer space="x2_5" />
          <Button
            type={'primary'}
            title={isAddingCard ? 'Adding Card...' : 'Add Card'}
            id={'add_cc_btn'}
            disabled={isAddingCard || !isComplete}
            onClick="submitForm"
          />
        </>
      )}
    </form>
  )
}

const CardForm = injectStripe(StripeCardForm)

interface Props {
  onSuccess: (token: stripe.Token) => void
  onError: (error: string) => void
  isSubmitButtonVisible: boolean
  isAddingCard: boolean
}

const EditCard: React.FC<Props> = props => {
  const {
    isAddingCard,
    children,
    onError,
    onSuccess,
    isSubmitButtonVisible,
  } = props

  return (
    <div className={styles.walletContents}>
      <div className={styles.payment}>
        <Elements>
          <CardForm
            onSuccess={onSuccess}
            onError={onError}
            isSubmitButtonVisible={isSubmitButtonVisible}
            isAddingCard={isAddingCard}
            stripe={null}
            elements={null}
          >
            {children}
          </CardForm>
        </Elements>
      </div>
    </div>
  )
}

export default EditCard
