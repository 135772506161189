import { Route } from 'src/routes/Route'
import { Subroute } from 'src/routes/Subroute'
import { CardCategoryRoute } from 'src/catalog/routes/CardCategoryRoute'
import { CardCollectionRoute } from 'src/catalog/routes/CardCollectionRoute'
import { FavoriteCardsRoute } from 'src/catalog/routes/FavoriteCardsRoute'
import { LegacyPicturePlusCardsRoute } from 'src/catalog/routes/LegacyPicturePlusCardsRoute'
import { PhotoDropCardsRoute } from 'src/catalog/routes/PhotoDropCardsRoute'
import { StandardCardsRoute } from 'src/catalog/routes/StandardCardsRoute'
import { SendableCardRoute } from 'src/catalog/routes/SendableCardRoute'
import {
  dismissedCatalogSubroute,
  selectedCardCategory,
  selectedCardCollection,
  selectedCatalogCard,
  selectedPhotoDropCards,
  selectedStandardCards,
  updatedCatalogSearch,
} from 'src/redux/actions/catalog'

import { catalogRouteTasks } from 'src/catalog/tasks'

export const CatalogSubroute = Subroute(
  CardCategoryRoute,
  CardCollectionRoute,
  FavoriteCardsRoute,
  LegacyPicturePlusCardsRoute,
  PhotoDropCardsRoute,
  SendableCardRoute,
  StandardCardsRoute,
)
export type CatalogSubroute = Subroute<typeof CatalogSubroute>

export type CatalogRouteContent = {
  subroute?: CatalogSubroute
  search: string
  postcardPromo?: true
  bulk?: true
}

export const CatalogRoute = Route({
  path: '/catalog',
  init: (
    subroute?: CatalogSubroute,
    postcardPromo?: true,
    bulk?: true,
    promotion?: true,
  ) => ({
    subroute,
    search: '',
    postcardPromo,
    bulk,

    promotion,
  }),
  fromPath: ({ postcardPromo, bulk, promotion }) => ({
    search: '',
    postcardPromo: undefined,
    bulk: undefined,
    promotion: undefined,
  }),
  subroute: CatalogSubroute,
  reducer: (route, action) => {
    switch (action.type) {
      case updatedCatalogSearch.type:
        return { ...route, search: action.search }
      case dismissedCatalogSubroute.type:
        return { ...route, search: '', subroute: undefined }
      case selectedCardCategory.type:
        return { ...route, subroute: CardCategoryRoute(action.categoryId) }
      case selectedCardCollection.type:
        return { ...route, subroute: CardCollectionRoute(action.collectionId) }
      case selectedPhotoDropCards.type:
        return { ...route, subroute: PhotoDropCardsRoute() }
      case selectedStandardCards.type:
        return { ...route, subroute: StandardCardsRoute() }
      case selectedCatalogCard.type:
        return route.subroute === undefined
          ? {
              ...route,
              subroute: SendableCardRoute(action.id),
            }
          : route
      default:
        return route
    }
  },
  tasks: catalogRouteTasks,
})
export type CatalogRoute = Route<typeof CatalogRoute>
