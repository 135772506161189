import colorFragmentRawString from '../ColorFragment.graphql'

export const colorFragmentString = colorFragmentRawString

export type ColorFragment = {
  red: number
  green: number
  blue: number
}

export const isColorFragment = (fragment: any): fragment is ColorFragment =>
  fragment &&
  typeof fragment.red === 'number' &&
  typeof fragment.green === 'number' &&
  typeof fragment.blue === 'number'
