import {
  SendableCardImageFragment,
  isSendableCardImageFragment,
} from './SendableCardImageFragment'

import largeMinimalSendableCardFragmentRawString from '../LargeMinimalSendableCardFragment.graphql'

export const largeMinimalSendableCardFragmentString = largeMinimalSendableCardFragmentRawString

export type LargeMinimalSendableCardFragment = {
  __typename: 'SendableCard'
  id: string
  title: string
  variationColor: string
  isFavorite: boolean
  isHorizontal: boolean
  isPremium: boolean
  frontImage: SendableCardImageFragment
}

export const isLargeMinimalSendableCardFragment = (
  fragment: any,
): fragment is LargeMinimalSendableCardFragment =>
  fragment &&
  fragment.__typename === 'SendableCard' &&
  typeof fragment.id === 'string' &&
  typeof fragment.title === 'string' &&
  typeof fragment.variationColor === 'string' &&
  typeof fragment.isFavorite === 'boolean' &&
  typeof fragment.isHorizontal === 'boolean' &&
  typeof fragment.isPremium === 'boolean' &&
  (isSendableCardImageFragment(fragment.frontImage) as boolean)
