import React, { useRef } from 'react'

type VideoSettingsType = {
  loop?: boolean
  preload?: 'auto' | 'metadata' | 'none'
  autoplay?: boolean
  controls?: boolean
}

type FramePropsType = {
  src: string
  alt?: string
  type: 'image' | 'video' | 'svg'
  videoSettings?: VideoSettingsType
  borderRadius?: number
  height?: string
  width?: string
  maxWidth?: string
  maxHeight?: string
}

export const Frame: React.FC<FramePropsType> = ({
  src,
  alt = 'media',
  type,
  videoSettings = {
    loop: true,
    preload: 'auto',
    autoplay: true,
    controls: false,
  },
  borderRadius = 0,
  width = '100%',
  height = 'auto',
  maxWidth,
  maxHeight,
}) => {
  const containerRef = useRef<HTMLDivElement>(null)

  const commonStyle = {
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
    width: 'auto',
    height: 'auto',
    objectFit: 'contain',
  }

  const renderMedia = () => {
    switch (type) {
      case 'image':
        return <img src={src} alt={alt} {...commonStyle} />
      case 'video':
        return <video src={src} {...videoSettings} {...commonStyle} />
      case 'svg':
        return (
          <object
            data={src}
            type="image/svg+xml"
            aria-label={alt}
            style={{ ...commonStyle, objectFit: 'none' }}
          />
        )
      default:
        return null
    }
  }

  return (
    <div
      ref={containerRef}
      style={{
        width: width,
        height: height,
        borderRadius: borderRadius,
        overflow: 'hidden',
        maxHeight: maxHeight,
        maxWidth: maxWidth,
      }}
    >
      {renderMedia()}
    </div>
  )
}
