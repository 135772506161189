import React from 'react'
import { SVGProps } from './types'

export const Contact: React.FC<SVGProps> = ({ size, onClick, color }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick && (() => onClick())}
    >
      <path
        d="M12.9166 20H5.08146C3.35772 20 1.92127 19.8407 0.706811 19.4977C0.262817 19.3752 -0.0244735 18.9709 0.00164385 18.5421C0.32811 14.879 4.28488 12 8.99902 12C13.7132 12 17.6569 14.8668 17.9964 18.5421C18.0356 18.9832 17.7483 19.3752 17.2913 19.4977C16.0768 19.8407 14.6404 20 12.9166 20ZM2.10409 17.9296C2.96596 18.0888 3.94536 18.1623 5.08146 18.1623H12.9166C14.0527 18.1623 15.0321 18.0888 15.894 17.9296C15.2019 15.5773 12.342 13.8377 8.99902 13.8377C5.65604 13.8377 2.7962 15.5773 2.10409 17.9296Z"
        fill={color}
      />
      <path
        d="M8.99999 11C5.68823 11 3 8.52595 3 5.49366C3 2.46139 5.68823 0 8.99999 0C12.3118 0 15 2.46139 15 5.49366C15 8.52595 12.3118 11 8.99999 11ZM8.99999 1.90311C6.83832 1.90311 5.07853 3.51444 5.07853 5.49366C5.07853 7.48558 6.83832 9.09689 8.99999 9.09689C11.1617 9.09689 12.9215 7.48558 12.9215 5.49366C12.9215 3.51444 11.1617 1.90311 8.99999 1.90311Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 5.5C18.4477 5.5 18 5.94772 18 6.5V8.99995L15.5 8.99995C14.9477 8.99995 14.5 9.44767 14.5 9.99995C14.5 10.5522 14.9477 11 15.5 11L18 11V13.5C18 14.0523 18.4477 14.5 19 14.5C19.5523 14.5 20 14.0523 20 13.5V11L22.5 11C23.0523 11 23.5 10.5522 23.5 9.99995C23.5 9.44767 23.0523 8.99995 22.5 8.99995L20 8.99995V6.5C20 5.94772 19.5523 5.5 19 5.5Z"
        fill={color}
      />
    </svg>
  )
}
