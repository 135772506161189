// this needs to be loaded before React
import { hot } from 'react-hot-loader/root'

import React, { useEffect, useLayoutEffect, useState } from 'react'
import { BoundActionCreator } from '@sendoutcards/core'
// @src imports
import connect from 'src/redux/connect'
import Router from 'src/routes/Router'
import { HelpMenu, LoadingTask, LoadingTaskComponent } from 'src/chrome'
import { GetSponsor, GetStorefront } from 'src/graphql'
import {
  loadedStorefront,
  loadedStorefrontError,
} from 'src/redux/actions/storefront'
import { BaseRoute, isFunnelPageRoute } from 'src/routes/BaseRoute'
import { StorefrontState } from 'src/redux/reducers/storefront'
import StaticNav from 'src/chrome/StaticNav/StaticNav'
// relative imports

// tslint:disable-next-line: no-import-side-effect
import 'normalize.css'
// tslint:disable-next-line: no-import-side-effect
import 'src/app/styles/resets.scss'
import styles from 'src/chrome/styles/Chrome.module.scss'
import {
  Div,
  QuantumThemeWrapper,
  Spacer,
} from '@sendoutcards/quantum-design-ui'
import Favicon from 'src/chrome/Favicon/Favicon'
import { HomePageFooter } from 'src/home_page/components/Footer/HomePageFooter'

type StorefrontActions = {
  loadedStorefront: BoundActionCreator<typeof loadedStorefront>
  loadedStorefrontError: BoundActionCreator<typeof loadedStorefrontError>
}

type AppProps = {
  route: BaseRoute
  storefront: StorefrontState
} & StorefrontActions

const App: React.FC<AppProps> = props => {
  const { route, storefront, loadedStorefront, loadedStorefrontError } = props
  const editor = BaseRoute.hideSidebar(route)
  const noSidebarOverflow = route.subroute
    ? isFunnelPageRoute(route.subroute)
    : false
  const showTopBar = !BaseRoute.hideTopBar(route)
  const peContainerStyles: React.CSSProperties = !noSidebarOverflow
    ? { height: '100%' }
    : noSidebarOverflow
    ? { height: '100vh', overflow: 'auto' }
    : {}

  const [isDarkMode, setIsDarkMode] = useState(
    window.matchMedia('(prefers-color-scheme: dark)').matches,
  )

  useEffect(() => {
    const darkModeQuery = window.matchMedia('(prefers-color-scheme: dark)')

    const handleColorSchemeChange = (event: MediaQueryListEvent) => {
      setIsDarkMode(event.matches)
    }

    darkModeQuery.addEventListener('change', handleColorSchemeChange)

    return () => {
      darkModeQuery.removeEventListener('change', handleColorSchemeChange)
    }
  }, [])

  useEffect(() => {
    const favicon = document.getElementById('favicon') as HTMLLinkElement
    if (favicon) {
      const newFavicon = document.createElement('link')
      newFavicon.setAttribute('rel', 'icon')
      newFavicon.setAttribute('id', 'favicon')
      newFavicon.setAttribute(
        'href',
        isDarkMode
          ? `${process.env.PUBLIC_URL}/favicon-light.ico`
          : `${process.env.PUBLIC_URL}/favicon-dark.ico`,
      )

      const parent = favicon.parentNode
      if (parent) {
        parent.replaceChild(newFavicon, favicon)
      }
    }
  }, [isDarkMode])

  useLayoutEffect(() => {
    window.intercomSettings = {
      hide_default_launcher: true,
      vertical_padding: 145,
    }
    if (!!window.Intercom) {
      window.Intercom('update')
    }
  })
  return (
    <QuantumThemeWrapper>
      <Favicon />
      <Div
        style={peContainerStyles}
        className={`peContainer ${editor ? styles.hideSidebar : ''} ${
          styles.noSidebar
        }`}
      >
        {showTopBar && <StaticNav storefront={storefront} />}
        <Spacer space="x7" />
        {storefront.storefront && <Spacer space="x8" />}
        <HelpMenu />
        <Router />
        {storefront.loading && storefront.sponsorSlug && (
          <LoadingTaskComponent
            key={`Loading Storefront: ${storefront.sponsorSlug}`}
            task={LoadingTask({
              operation: GetStorefront({ slug: storefront.sponsorSlug }),
              completion: result =>
                result
                  .select('user')
                  .map(user => ({ user: user }))
                  .match(loadedStorefront, loadedStorefrontError),
            })}
          />
        )}
        {storefront.loading && storefront.sponsorId && (
          <LoadingTaskComponent
            key={`Loading Sponsor: ${storefront.sponsorId}`}
            task={LoadingTask({
              operation: GetSponsor({ id: storefront.sponsorId }),
              completion: result =>
                result
                  .select('sponsor')
                  .map(sponsor => ({ user: sponsor }))
                  .match(loadedStorefront, loadedStorefrontError),
            })}
          />
        )}
        <HomePageFooter />
      </Div>
    </QuantumThemeWrapper>
  )
}

export { App as PureApp }
export default hot(
  connect(
    ({ route, storefront }) => ({
      route,
      storefront,
    }),
    { loadedStorefront, loadedStorefrontError },
  )(App),
)
