import { Route } from 'src/routes/Route'
import { SendableCardRoute } from 'src/catalog/routes/SendableCardRoute'
import { selectedSendableCardReducer } from './reducers'

export const CardCollectionRoute = Route({
  path: '/collections/:cardCollectionId',
  init: (cardCollectionId: string, subroute?: SendableCardRoute) => ({
    cardCollectionId,
    subroute,
  }),
  fromPath: ({ cardCollectionId }) =>
    cardCollectionId ? { cardCollectionId } : undefined,
  subroute: SendableCardRoute,
  reducer: selectedSendableCardReducer,
})
export type CardCollectionRoute = Route<typeof CardCollectionRoute>
