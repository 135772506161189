import { Div, Flex, Image, Spacer } from '@sendoutcards/quantum-design-ui'
import React from 'react'
// import { Button } from 'src/design_system/components/Button'
import { Autoplay, Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
/*tslint:disable:no-import-side-effect*/
import 'swiper/swiper-bundle.css'
/*tslint:disable:no-import-side-effect*/
import './styles.css'
import { ContentBlock } from './components/ContentBlock'
import { ImageContent } from './components/ImageContent'
import { VideoPlayer } from '../VideoPlayer/VideoPlayer'
import useContainerWidth from 'src/hooks/useContainerWidth'

export type ContentType = {
  type: 'content'
  title?: string
  description: string
}

export type VideoContentType = {
  type: 'video'
  src: string
  thumbnail: string
}

export type ImageContentType = {
  type: 'image'
  image: string
}

export type ContentMediaType = {
  content: ContentType | VideoContentType | ImageContentType
  width: string
  height: string
}

export type ContentPopProps = {
  bannerImages: string[]
  contentStream: ContentMediaType[]
  action: { title: string; onClick: () => void }
  children?: React.ReactNode
  shouldStackColumns: boolean
  isMobile: boolean
  isSmallMobile: boolean
}

export const ContentPop: React.FC<ContentPopProps> = ({
  bannerImages,
  contentStream,
  action,
  children,
  shouldStackColumns,
  isMobile,
  isSmallMobile,
}) => {
  const [containerRef, containerWidth] = useContainerWidth()
  const isTablet = containerWidth <= 700
  return (
    <Div
      ref={containerRef}
      display="flex"
      flexDirection="column"
      borderRadius="default"
      position="relative"
      overflow="scroll"
      style={{ scrollbarWidth: 'none' }}
      width="100%"
      height="auto"
    >
      <Flex
        id="content_swiper_container"
        backgroundColor="foreground"
        flexDirection="column"
      >
        <Swiper
          className="banner-pop-swiper"
          modules={[Pagination, Autoplay, Navigation]}
          slidesPerView={1}
          grabCursor={true}
          initialSlide={1}
          speed={500}
          loop={true}
          autoplay={{
            delay: 5000,
            disableOnInteraction: true,
            pauseOnMouseEnter: true,
          }}
          spaceBetween={0}
          centeredSlides={true}
          pagination={{ dynamicBullets: true, clickable: true }}
          navigation={true}
        >
          {bannerImages.map((image, index) => {
            return (
              <SwiperSlide key={index} className="swiper-slide">
                <Image
                  isActive={true}
                  width="100%"
                  height={
                    isSmallMobile ? '200px' : isMobile ? '300px' : '500px'
                  }
                  image={{
                    url: image,
                  }}
                />
              </SwiperSlide>
            )
          })}
        </Swiper>
      </Flex>
      <Flex
        id="product_detail_container"
        flexDirection={children ? 'row' : 'column'}
        columnGap={children ? 'x2' : 'x0'}
        rowGap={children ? 'x2' : 'x0'}
        inset={{ vertical: 'x4', horizontal: 'x4' }}
        width="100%"
        flexWrap={isTablet || shouldStackColumns ? 'wrap' : 'nowrap'}
      >
        <Flex
          id="content_stream"
          flexDirection="column"
          width={!isTablet && !shouldStackColumns && children ? '50%' : '100%'}
          maxWidth={
            !isTablet && !shouldStackColumns && !children ? '880px' : '100%'
          }
          alignItems={'center'}
          rowGap={'x3'}
          order={isTablet || shouldStackColumns ? 2 : 1}
        >
          {contentStream.map((content, index) => {
            return content.content.type === 'video' ? (
              <VideoPlayer
                playbackOption={{
                  iframeSrc: content.content.src,

                  iframeTitle: `${index}_vc_player`,
                }}
                width={content.width}
                height={content.height}
                thumbnailUrl={content.content.thumbnail}
                btnColor="#fff"
              />
            ) : content.content.type === 'image' ? (
              <ImageContent
                width={content.width}
                height={content.height}
                image={content.content.image}
              />
            ) : (
              <ContentBlock
                title={content.content.title}
                description={content.content.description}
              />
            )
          })}
          <Spacer space="x6" />
          {/* <Flex width="100%">
            <Div style={{ marginLeft: 'auto' }}>
              <Button
                title={action.title}
                backgroundColor="#fff"
                textColor="#000"
                boxShadow="rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"
                shouldDisplayBoxShadow={true}
                onClick={action.onClick}
              />
            </Div>
          </Flex> */}
        </Flex>
        {children && (
          <Flex
            id="children_block"
            width={isTablet || shouldStackColumns ? '100%' : '50%'}
            alignItems="flex-start"
            justifyContent="center"
            order={isTablet || shouldStackColumns ? 1 : 2}
          >
            {children}
          </Flex>
        )}
      </Flex>
    </Div>
  )
}
