import { Maybe } from '../../../__generated__/globalTypes'

import subscriptionMetadataRawString from '../SubscriptionMetadata.graphql'

export const subscriptionMetadataString = subscriptionMetadataRawString

export type SubscriptionMetadata = {
  planOnCancel: Maybe<string>
}

export const isSubscriptionMetadata = (
  fragment: any,
): fragment is SubscriptionMetadata =>
  fragment &&
  (fragment.planOnCancel === null || typeof fragment.planOnCancel === 'string')
