import mapValues from 'lodash/mapValues'

type SearchesContent<T> = {
  defaultValue: T
  searches: {
    [search: string]: T | undefined
  }
}

const map = <T, U>(
  content: SearchesContent<T>,
  transform: (value: T, search: string) => U,
): SearchesContent<U> => ({
  searches: mapValues(content.searches, (value, key) =>
    transform(value ?? content.defaultValue, key),
  ),
  defaultValue: transform(content.defaultValue, ''),
})

const get = <T>(content: SearchesContent<T>, search?: string) =>
  content.searches[search ?? ''] ?? content.defaultValue

const update = <T>(
  content: SearchesContent<T>,
  search: string | undefined,
  update: (value: T) => T,
): SearchesContent<T> => ({
  defaultValue: content.defaultValue,
  searches: {
    ...content.searches,
    [search ?? '']: update(get(content, search)),
  },
})
type Searches<T> = {
  get: (search?: string) => T
  map: <U>(transform: (value: T, search: string) => U) => Searches<U>
  update: (search: string | undefined, update: (value: T) => T) => Searches<T>
}

const createSearches = <T>(content: SearchesContent<T>): Searches<T> => ({
  ...content,
  get: search => get(content, search),
  map: transform => createSearches(map(content, transform)),
  update: (search, updater) => createSearches(update(content, search, updater)),
})

const Searches = <T>(
  defaultValue: T, // Searches will return this object if absent
  emptySearch?: T, // The value for the empty search ''
  searches: { [search: string]: T } = {}, // Additional search results
): Searches<T> =>
  createSearches({ defaultValue, searches: { ...searches, '': emptySearch } })

export default Searches
