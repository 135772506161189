import { Route } from 'src/routes/Route'
import { Subroute } from 'src/routes/Subroute'
import { BusinessRoute } from 'src/marketing/routes/BusinessRoute'
import { CatalogRoute } from 'src/catalog/routes/CatalogRoute'
import { PricingRoute } from 'src/pricing_page/routes/PricingRoute'
import { PromoRoute } from 'src/promo/routes/PromoRoute'
import { TrainingRoute } from 'src/training/routes/TrainingRoute'
import { AudereRoute } from 'src/marketing/routes/AudereRoute'

export const AppSubroute = Subroute(
  AudereRoute,
  BusinessRoute,
  CatalogRoute,
  PricingRoute,
  PromoRoute,
  TrainingRoute,
)
export type AppSubroute = Subroute<typeof AppSubroute>

export const AppRoute = Route({
  path: '/app',
  init: (subroute: AppSubroute | undefined) => ({ subroute }),
  subroute: AppSubroute,
})
export type AppRoute = Route<typeof AppRoute>
