import { CurrencyType, Price } from 'src/graphql'

const getTotal = (cost: { total: Price[] }, multiplier: number = 1): string => {
  if (!cost) {
    return '$0.00'
  }
  return cost.total
    .filter((x: any) => x.asString !== '$0.00')
    .map((x: any) => {
      if (multiplier) {
        if (x.currency === CurrencyType.POINT) {
          return x.asString.replace(x.amount, x.amount * multiplier)
        } else {
          return x.asString.replace(
            (x.amount / 100).toFixed(2),
            ((x.amount / 100) * multiplier).toFixed(2),
          )
        }
      }
      return x.asString
    })
    .join(' + ')
}

export default getTotal
