import { Operation } from '../../../__generated__/globalTypes'

import {
  DetailedSendableCardFragment,
  detailedSendableCardFragmentString,
} from '../../fragments/__generated__/DetailedSendableCardFragment'

import { panelFragmentString } from '../../fragments/__generated__/PanelFragment'

import { sendableCardWithTemplateFragmentString } from '../../fragments/__generated__/SendableCardWithTemplateFragment'

import { sendableCardFragmentString } from '../../fragments/__generated__/SendableCardFragment'

import { largeMinimalSendableCardFragmentString } from '../../fragments/__generated__/LargeMinimalSendableCardFragment'

import { imageFragmentString } from '../../fragments/__generated__/ImageFragment'

import { elementFragmentString } from '../../fragments/__generated__/ElementFragment'

import { textFragmentFragmentString } from '../../fragments/__generated__/TextFragmentFragment'

import { elementPictureFragmentString } from '../../fragments/__generated__/ElementPictureFragment'

import { colorFragmentString } from '../../fragments/__generated__/ColorFragment'

import { sendableCardImageFragmentString } from '../../fragments/__generated__/SendableCardImageFragment'

import { elementTextFragmentString } from '../../fragments/__generated__/ElementTextFragment'

import { paragraphFragmentString } from '../../fragments/__generated__/ParagraphFragment'

import getSendableCardRawString from '../GetSendableCard.graphql'

const getSendableCardString = [
  getSendableCardRawString,
  detailedSendableCardFragmentString,
  panelFragmentString,
  sendableCardWithTemplateFragmentString,
  sendableCardFragmentString,
  largeMinimalSendableCardFragmentString,
  imageFragmentString,
  elementFragmentString,
  textFragmentFragmentString,
  elementPictureFragmentString,
  colorFragmentString,
  sendableCardImageFragmentString,
  elementTextFragmentString,
  paragraphFragmentString,
].join('\n\n')

export type GetSendableCardData = {
  sendableCard: DetailedSendableCardFragment
}

export type GetSendableCardVariables = {
  id: string
}

export type GetSendableCard = Operation<
  GetSendableCardData,
  GetSendableCardVariables
>

export const GetSendableCard = ({
  id,
}: GetSendableCardVariables): GetSendableCard => ({
  query: getSendableCardString,
  variables: {
    id,
  },
})
