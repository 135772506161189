import React, { useState } from 'react'
// @src imports
import connect from 'src/redux/connect'
import { openCatalog, openPlans } from 'src/redux/actions/routeAliases'
import VideoRibbon, { VideoObject } from '../components/VideoRibbon/VideoRibbon'
import { StorefrontState } from 'src/redux/reducers/storefront'
import { Button, Icon, VideoModal } from 'src/chrome'
import { BusinessRoute } from 'src/marketing/routes/BusinessRoute'

import styles from './launch.module.scss'

interface RoutesActions {
  openCatalog: () => void
  openPlans: () => void
}

interface BusinessPageProps extends RoutesActions {
  route: BusinessRoute
  storefront: StorefrontState
}

const BusinessPage: React.FC<BusinessPageProps> = props => {
  const [play, togglePlay] = useState(true)
  const [selectedVideo, setSelectedVideo] = useState<VideoObject | null>(null)
  const { openCatalog, openPlans } = props

  const onSelectVideo = (video: VideoObject) => {
    setSelectedVideo(video)
  }

  const sendCardBtn = (
    <Button
      title={'Send a Card'}
      buttonColor={'blue'}
      innerStyle={{
        background: 'transparent',
        boxShadow: 'none',
      }}
      onClick={() => openCatalog()}
    />
  )

  const viewPlansBtn = (
    <Button
      title={'View Plans'}
      buttonColor={'blue'}
      innerStyle={{
        background: 'transparent',
        boxShadow: 'none',
      }}
      className={styles.business}
      onClick={() => openPlans()}
    />
  )

  const SOCLOGO = (
    <Icon icon={'SOCLOGO'} viewBox={'0 0 77 72'} size={50} color={'#02A7FF'} />
  )

  return (
    <main className={styles.launchPageMain}>
      {/* {storefront &&
          storefront.storefront && (
            <SponsorBar storefront={storefront} topNav={true} />
          )} */}
      <section
        className={styles.heroSection}
        style={{
          backgroundImage: 'linear-gradient(115deg, #02A7FF, #02A7FF)',
        }}
      >
        <div className={`${styles.heroContent} ${styles.campaigns}`}>
          <div className={styles.heroCaption}>
            <h1>Increase Customers Through Appreciation</h1>
            <div className={styles.buttonBlock}>
              {sendCardBtn}
              {viewPlansBtn}
            </div>
          </div>
          <div className={styles.deviceAsset}>
            <div className={styles.device}>
              <img
                src={
                  'https://www.sendoutcards.com/soc-media/images/campaign.original.png'
                }
                alt={'campaign'}
              />
            </div>
          </div>
        </div>
      </section>
      <section className={styles.rowFeatures}>
        <h2 style={{ marginTop: 60 }}>Appreciation you can touch</h2>
        <div className={styles.features}>
          <div className={styles.feature}>
            <Icon
              icon={'HANDSHAKE'}
              viewBox={'0 0 49 32'}
              size={80}
              color={'#02A7FF'}
            />
            <h3>Relationship Marketing</h3>
            <p>
              Many customers leave a company, not because they don't like the
              product, but because they feel unappreciated. Appreciation is the
              key to loyal customers and more referrals.
            </p>
          </div>
          <div className={styles.feature}>
            <Icon
              icon={'REMINDERS'}
              viewBox={'0 0 51 32'}
              size={80}
              color={'#02A7FF'}
            />
            <h3>Customer Appreciation</h3>
            <p>
              As a customer, when you feel appreciated, you buy more, you keep
              coming back and you tell others.
            </p>
          </div>
          <div className={styles.feature}>
            <Icon
              icon={'CAMPAIGNS'}
              viewBox={'0 0 25 32'}
              size={80}
              color={'#02A7FF'}
            />
            <h3>Campaign System</h3>
            <p>
              People do business with people they know, like and trust,{' '}
              <strong> if they remember you! </strong>
              Our Campaign follow up system will keep you top of mind with your
              customers.
            </p>
          </div>
        </div>
      </section>
      <section className={styles.grid2x}>
        {play ? (
          <div className={styles.contentRight}>
            <div className={styles.player}>
              <iframe
                title={'embed'}
                src="//fast.wistia.net/embed/iframe/atpgcf16m8"
                width="640"
                height="360"
              />
            </div>
          </div>
        ) : (
          <div
            className={`${styles.contentRight} ${styles.fullImage}`}
            style={{
              backgroundImage:
                'url(https://preview.ibb.co/fceEPn/Screen_Shot_2018_03_27_at_12_53_01_AM.png})',
            }}
            onClick={() => {
              togglePlay(true)
            }}
          >
            <div className={styles.playTrigger}>
              <Icon
                icon={'PLAY'}
                viewBox={'0 0 24 24'}
                color={'#02A7FF'}
                size={34}
              />
            </div>
          </div>
        )}
        <div
          className={styles.contentLeft}
          style={{
            paddingTop: 0,
            paddingRight: 100,
          }}
        >
          {SOCLOGO}
          <h2>Relationship Marketing</h2>
          <p>
            Relationship Marketing is about forming long term relationships with
            customers, rather than trying to encourage a one-time sale. The best
            way to acquire new customers is through referrals.
          </p>
        </div>
      </section>
      <section className={styles.grid2x}>
        <div className={styles.contentLeft}>
          {SOCLOGO}
          <h2>Customer Appreciation</h2>
          <p>
            To show appreciation, our system will never let you forget your
            customers' birthdays or anniversaries again. Schedule your cards to
            arrive just in time for any special occasion.
          </p>
        </div>
        <div
          className={styles.contentRight}
          style={{
            backgroundImage:
              'url(https://www.sendoutcards.com/soc-media/images/relationshipManager.original.png)',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
          }}
        />
      </section>
      <section className={styles.grid2x}>
        <div
          className={`${styles.contentRight} ${styles.reOrder2} ${styles.noPadding}`}
        >
          <div className={styles.screen}>
            <img
              src={
                'https://www.sendoutcards.com/soc-media/images/campaigns.original.png'
              }
              alt={'catalog view'}
            />
          </div>
        </div>
        <div className={`${styles.contentLeft} ${styles.reOrder1}`}>
          {SOCLOGO}
          <h2>Campaign System</h2>
          <p>
            Cards scheduled to go out automatically throughout the year, saves
            you time and money. By appreciating your customers, you become their
            go to business solution.
          </p>
        </div>
      </section>
      <section className={styles.grid2x}>
        <div className={`${styles.contentLeft} ${styles.reOrder1}`}>
          {SOCLOGO}
          <h2>Campaign Store</h2>
          <p>
            Don't worry about creating your own card campaigns. Our campaign
            store includes professionally designed Campaign Cards that are ready
            to send for any business niche.
          </p>
        </div>
        <div
          className={`${styles.contentRight} ${styles.reOrder2} ${styles.noPadding}`}
        >
          <div className={styles.screen}>
            <img
              src={
                'https://www.sendoutcards.com/soc-media/images/campaignstore.original.png'
              }
              alt={'catalog view'}
            />
          </div>
        </div>
      </section>
      <div className={`${styles.intro} ${styles.fullIntro}`}>
        {SOCLOGO}
        <h1>See what others are saying</h1>
        <p>
          "SendOutCards is the best customer appreciation and customer retention
          program in the world."
        </p>
        <VideoRibbon onPlay={onSelectVideo} />
      </div>

      <section className={styles.callToAction}>
        <h2>Try it out now!</h2>
        <div
          className={styles.buttonBlock}
          style={{ justifyContent: 'center' }}
        >
          {sendCardBtn}
          {viewPlansBtn}
        </div>
      </section>

      {selectedVideo && (
        <VideoModal
          video={selectedVideo}
          onClose={() => {
            setSelectedVideo(null)
          }}
        />
      )}
    </main>
  )
}

export default connect(
  state => ({
    storefront: state.storefront,
  }),
  { openCatalog, openPlans },
)(BusinessPage)
