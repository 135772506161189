import React from 'react'
import { SVGProps } from './types'

export const ExpCalendar: React.FC<SVGProps> = ({ size, color, onClick }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 19 18"
      width={size}
      height={size}
      onClick={onClick}
    >
      <path
        fill={color}
        d="M0 3.902c.096-.304.151-.63.293-.91.501-.99 1.324-1.52 2.435-1.58.314-.018.63-.004.964-.004V.764c.004-.448.293-.761.7-.763.409-.002.702.312.706.757.002.21 0 .419 0 .638h3.166c0-.233-.007-.471.002-.708A.7.7 0 019.193.04a.698.698 0 01.474.654c.007.228.002.456.002.699h3.202c0-.234-.009-.472.002-.71A.699.699 0 0113.946.11a.68.68 0 01.328.588c.006.227.002.455.002.708.206 0 .403.004.6-.001.442-.01.876.023 1.296.179 1.05.388 1.807 1.403 1.824 2.523.021 1.371.01 2.743.006 4.113a.695.695 0 01-.7.708c-.395 0-.701-.307-.703-.724-.003-1.3 0-2.601-.002-3.902 0-.603-.247-1.067-.795-1.339a1.416 1.416 0 00-.52-.139c-.326-.021-.655-.006-1.005-.006v.63c-.003.456-.297.773-.711.769-.407-.005-.692-.319-.695-.768v-.625h-3.2V3.4c0 .494-.275.814-.7.817-.425.002-.705-.319-.707-.809v-.586H5.1c0 .232.007.47-.002.706a.699.699 0 01-.926.65.7.7 0 01-.477-.67c-.004-.227 0-.453 0-.715-.415.02-.819-.004-1.204.069-.629.119-1.045.666-1.08 1.325-.003.047-.002.094-.002.14v10.758c0 .609.236 1.089.797 1.352.214.1.473.147.711.149 1.73.012 3.458.007 5.187.005.227 0 .43.049.593.214a.7.7 0 01-.202 1.124c-.052.025-.106.046-.159.07h-5.84c-.026-.011-.05-.028-.078-.032-.989-.16-1.71-.69-2.141-1.589-.131-.273-.187-.583-.277-.875V3.9v.001z"
      />
      <path
        fill={color}
        d="M13.397 18c-.401-.1-.816-.163-1.202-.305-1.826-.668-2.983-2.69-2.642-4.602.3-1.687 1.265-2.852 2.887-3.378 1.668-.542 3.175-.148 4.389 1.12 1.117 1.167 1.454 2.59.982 4.13-.503 1.643-1.654 2.632-3.35 2.968-.12.024-.24.044-.36.067h-.704zm.345-7.101a2.857 2.857 0 00-2.84 2.858 2.857 2.857 0 002.844 2.836 2.856 2.856 0 002.85-2.849 2.857 2.857 0 00-2.854-2.845zM4.4 6.68a.706.706 0 01.697.712.706.706 0 01-.716.693.705.705 0 01-.687-.703c0-.387.32-.704.707-.701zm3.756.71a.706.706 0 01-.713.695.706.706 0 01-.69-.718.706.706 0 01.704-.686.706.706 0 01.7.709zm2.357-.71c.387 0 .705.32.703.707a.706.706 0 01-.711.698.706.706 0 01-.694-.697.706.706 0 01.702-.707zm3.062 1.405a.705.705 0 01-.704-.704c0-.387.322-.704.708-.7.38.003.692.315.696.695a.705.705 0 01-.7.709zM4.39 9.74a.705.705 0 01.707.7.706.706 0 01-.706.703.706.706 0 01-.698-.692.706.706 0 01.697-.712zm3.061 0a.705.705 0 01.705.702.706.706 0 01-.708.7.706.706 0 01-.696-.694.706.706 0 01.7-.709zM4.4 12.798a.706.706 0 01.697.71.706.706 0 01-.715.694.706.706 0 01-.689-.702c0-.386.32-.704.706-.702zm3.048 0a.706.706 0 01.709.7.705.705 0 01-.705.704.706.706 0 01-.7-.692.706.706 0 01.696-.712z"
      />
      <path
        fill={color}
        d="M14.45 13.045c.139 0 .25-.006.36.002a.7.7 0 01.66.709.692.692 0 01-.663.688 14.25 14.25 0 01-1.105-.001.675.675 0 01-.651-.66 30.241 30.241 0 010-1.509.69.69 0 01.696-.671.695.695 0 01.7.669c.012.25.003.5.003.773z"
      />
    </svg>
  )
}
