import { BentoCardData } from 'src/marketing/components/BentoGrid/BentoCard'

export const AutomateBentoCards = (isMobile: boolean) => {
  const cardData = [
    {
      id: 16,
      title: 'Virtual Assistant services',
      description:
        'Our professional virtual assistants are here to help you organize contacts, design, and brand your cards. ',
      icon: 'virtual_assistance',
      isFullWidth: true,
      grid: {
        colStart: '1',
        colEnd: '7',
        rowStart: '1',
        rowEnd: '2',
      },
    },
    {
      id: 17,
      title: 'Organize your contact manager',
      description:
        'Get your contacts organized into groups, details added with our notes feature, and ready for sending.',
      icon: 'contact_card',
      isFullWidth: true,
      grid: {
        colStart: '7',
        colEnd: '13',
        rowStart: '1',
        rowEnd: '2',
      },
    },
    {
      id: 18,
      title: 'Designed multi-touch drip campaigns',
      description:
        'We offer a catalog of pre-designed card campaigns for a variety of business niches. It’s perfect for getting started on your network follow-up fast.',
      icon: 'paper_plane_2',
      isFullWidth: true,
      grid: {
        colStart: '1',
        colEnd: '7',
        rowStart: '2',
        rowEnd: '4',
      },
    },
    {
      id: 19,
      title: 'Custom branded to you or your business',
      description:
        'Branding on any of your follow-up is important. We get that! We can customize your card with your logos and brand colors, front to back.',
      icon: 'custom_brand',
      isFullWidth: true,
      grid: {
        colStart: '7',
        colEnd: '13',
        rowStart: '2',
        rowEnd: '4',
      },
    },
  ] as BentoCardData[]
  return cardData
}
