import layoutFragmentRawString from '../LayoutFragment.graphql'

export const layoutFragmentString = layoutFragmentRawString

export type LayoutFragment = {
  id: string
  thumbnail: {
    url: string
  }
}

export const isLayoutFragment = (fragment: any): fragment is LayoutFragment =>
  fragment &&
  typeof fragment.id === 'string' &&
  fragment.thumbnail &&
  typeof fragment.thumbnail.url === 'string'
