import React from 'react'
import { BoundActionCreator } from '@sendoutcards/core'
// @src imports
import { openApp } from 'src/helpers/appHelpers'
import connect from 'src/redux/connect'
import NotYourCard from 'src/assets/svg/NotYourCard'
import {
  Button,
  EmptyState,
  FloatingDrawer,
  Icon,
  Transition,
} from 'src/chrome'
import {
  ConfigurationCard,
  Div,
  Flex,
  HStack,
  Icon as QdsIcon,
  Spacer,
  Text,
} from '@sendoutcards/quantum-design-ui'
import { FlexColumn, FlexRow } from 'src/styled'
import { getPaperTypes } from 'src/app/constants'
import VariationToggle from 'src/editor/components/VariationToggle/VariationToggle'
import {
  CardFlip,
  CardTypeOptions,
  PaperTypeOptions,
} from 'src/catalog/components'
import { getTotal } from 'src/helpers'
import { clearTourId, setTourId } from 'src/redux/actions/helpmenu'
import {
  CardFragment,
  CardPaperType,
  CardType,
  CreateSparseUser,
  CreateSparseUserData,
  DetailedSendableCardFragment,
  GetPlans,
  GetPlansData,
  Price,
  SendableCardVariationFragment,
  SendableCardWithTemplateFragment,
  SponsorFragment,
} from 'src/graphql'
import { CardOption } from 'src/catalog/components/CardOptions/CardOptions'
// relative imports
import styles from './cardPreview.module.scss'
import { InviteFragment } from 'src/graphql/fragments/__generated__/InviteFragment'
import { performOperation } from 'src/app/api'

const cardTypes: CardOption<CardType>[] = [
  {
    value: CardType.FLATCARD,
    label: 'Flat Card',
    icon: 'FLATPANEL',
    iconSize: 30,
    viewBox: '0 0 25 32',
  },
  {
    value: CardType.TWO_PANEL,
    label: '2-Panel',
    icon: 'STANDARDCARD',
    iconSize: 30,
    viewBox: '0 0 18 32',
  },
  {
    value: CardType.THREE_PANEL,
    label: '3-Panel',
    icon: 'TRIFOLD',
    iconSize: 30,
    viewBox: '0 0 27 32',
  },
  {
    value: CardType.TWO_PANEL_BIG,
    label: 'Big Card',
    icon: 'BIGCARD',
    iconSize: 30,
    viewBox: '0 0 41 32',
    upgrade: '$1.50',
  },
  {
    value: CardType.POSTCARD,
    label: 'Post Card',
    icon: 'POSTCARD',
    iconSize: 30,
    viewBox: '0 0 48 32',
  },
]

const getVariations = (
  sendableCard: DetailedSendableCardFragment,
): SendableCardWithTemplateFragment[] => {
  return [sendableCard, ...sendableCard.variations]
}

const getSelectedVariation = (
  sendableCard: DetailedSendableCardFragment,
  variationId?: string,
): SendableCardVariationFragment =>
  (variationId &&
    getVariations(sendableCard).find(
      variation => variation.id === variationId,
    )) ||
  sendableCard

type HelpMenuActions = {
  setTourId: BoundActionCreator<typeof setTourId>
  clearTourId: BoundActionCreator<typeof clearTourId>
}

type CardPreviewProps = {
  card?: CardFragment
  sendableCard?: DetailedSendableCardFragment
  onClose: () => void
  editCard: (id: string, cardType: CardType, paperType: CardPaperType) => void
  loadingCard: boolean
  sponsor?: SponsorFragment
  isMobile: boolean | undefined
  invite?: InviteFragment
  isBulk?: boolean
} & HelpMenuActions

type State = {
  bulkCardCost: number | undefined
  variationId?: string
  selectedCard: boolean
  cardType: CardType
  cardFlipped: boolean
  paperType: CardPaperType
  configurations: boolean
  isCreatingAccount: boolean
  isRedirecting: boolean
  isCustomizeOpen: boolean
}

class CardPreview extends React.Component<CardPreviewProps, State> {
  state: State = {
    bulkCardCost: undefined,
    isCustomizeOpen: false,
    selectedCard: false,
    cardType: CardType.TWO_PANEL,
    cardFlipped: false,
    paperType: CardPaperType.STD,
    configurations: false,
    isCreatingAccount: false,
    isRedirecting: false,
  }

  get getCardTypes() {
    return cardTypes
  }

  get getBulkCardTypes() {
    return cardTypes.filter(cardType =>
      [CardType.FLATCARD, CardType.TWO_PANEL].includes(cardType.value),
    )
  }

  get formattedPaperUpgrade() {
    const paperCost: number = this.paperUpgradeCost()
    const paperCostString = paperCost.toString()
    return paperCost < 100
      ? `${paperCost}¢`
      : `$${paperCostString.slice(0, 1)}.${paperCostString.slice(1)}`
  }

  get paperTypes(): CardOption<CardPaperType>[] {
    return getPaperTypes(this.state.cardType).map(type => ({
      ...type,
      upgrade: type.upgrade ? this.formattedPaperUpgrade : type.upgrade,
    }))
  }

  getPrice = (
    cost: { total: Price[] } | undefined,
    bulkCardCost: number | undefined,
  ): string | undefined => {
    const { isBulk } = this.props
    return isBulk && bulkCardCost !== undefined
      ? `$${bulkCardCost.toFixed(2)}`
      : cost
      ? getTotal(cost)
      : undefined
  }

  handleSelectCard = () => {
    this.props.onClose()
    this.setState(
      {
        selectedCard: !this.state.selectedCard,
        configurations: !this.state.configurations,
        isCustomizeOpen: !this.state.isCustomizeOpen,
      },
      this.handleAppcues,
    )
  }

  handleClose = () => {
    this.setState({
      selectedCard: false,
      configurations: false,
      isCustomizeOpen: false,
    })
    this.props.onClose()
  }

  handleAppcues = () => {
    if (!this.state.selectedCard) {
      this.props.setTourId('-LBXiFzIGUsyytnR23-s')
    } else {
      this.props.setTourId('-LB2LReuyJSgtvsgG-06')
    }
  }

  handleSetCardType = (value: CardType) => {
    this.setState(
      {
        cardType: value,
      },
      () => {
        this.handleSetPaperType(
          // Postcards may only have standard paper type
          value === CardType.POSTCARD
            ? CardPaperType.STD
            : this.state.paperType,
          value,
        )
      },
    )
  }

  handleSetPaperType = (value: CardPaperType, cardType?: CardType) => {
    this.setState({ paperType: value })
  }

  handleCardFlip = () => {
    this.setState({
      cardFlipped: !this.state.cardFlipped,
    })
  }

  handleDeleteUpgrade = (type: 'cardType' | 'paperType') => {
    this.setState({
      cardType: type === 'cardType' ? CardType.TWO_PANEL : this.state.cardType,
      paperType:
        type === 'paperType' ? CardPaperType.STD : this.state.paperType,
    })
  }

  goToApp = (id: string, query: { [key: string]: string } = {}) => {
    this.setState({ isRedirecting: true })
    openApp(`catalog/preview/${id}`, query)
  }

  goToAppBulk = (id: string, cardType: CardType, paperType: CardPaperType) => {
    this.setState({ isRedirecting: true })
    const bulkQueryParams = {
      join: '',
      client: 'soc',
      bulkOrderPromo: `${id}__${cardType}__${paperType}`,
    }
    openApp(`promo`, bulkQueryParams)
  }

  handleVariation = (variation: any) => {
    this.setState({
      variationId: variation.id,
    })
  }

  paperUpgradeCost(cardType?: CardType) {
    const type = cardType ?? this.state.cardType
    return type === CardType.TWO_PANEL_BIG ? 150 : 50
  }

  UNSAFE_componentWillMount() {
    if (window.Appcues) {
      this.handleAppcues()
    }
  }

  componentWillUnmount() {
    this.props.clearTourId()
  }

  componentDidMount() {
    performOperation<GetPlansData>(GetPlans(), result => {
      if (result.error || !result.value) {
        this.setState({
          bulkCardCost: undefined,
        })
      } else {
        this.setState({
          bulkCardCost: result.value.plans.find(p => p.id === '1')
            ?.bulkCardCost,
        })
      }
    })
  }

  // tslint:disable-next-line: cyclomatic-complexity score 37
  render() {
    const {
      bulkCardCost,
      configurations,
      variationId,
      selectedCard,
      cardFlipped,
      isCreatingAccount,
      isRedirecting,
      isCustomizeOpen,
      cardType,
      paperType,
    } = this.state

    const {
      loadingCard,
      onClose,
      card,
      sendableCard,
      isMobile,
      invite,
      isBulk,
    } = this.props
    const variations = sendableCard ? getVariations(sendableCard) : undefined
    const selectedVariation = sendableCard
      ? getSelectedVariation(sendableCard, variationId)
      : undefined
    const mergedSendableCard: DetailedSendableCardFragment | undefined =
      sendableCard && selectedVariation
        ? { ...sendableCard, ...selectedVariation }
        : undefined

    if (loadingCard || isCreatingAccount || isRedirecting) {
      return (
        <Transition
          message={
            loadingCard
              ? 'Loading your selection...'
              : isRedirecting
              ? 'Loading...'
              : 'Creating guest account...'
          }
          messageStyle={{ color: 'black', fontWeight: 500 }}
        />
      )
    }
    if (!loadingCard && !mergedSendableCard) {
      return (
        <EmptyState
          action={onClose}
          actionTitle={'Find a new card'}
          message={
            "Oops, either this card doesn't exist or it is not your card"
          }
          artWorkWidth={'70%'}
          artWorkMaxWidth={600}
        >
          <NotYourCard />
        </EmptyState>
      )
    }

    const orientation = (mergedSendableCard || { isHorizontal: false })
      .isHorizontal
      ? 'horizontal'
      : 'portrait'
    const cardSizing: string = (mergedSendableCard || { isHorizontal: false })
      .isHorizontal
      ? styles.landscape
      : styles.portrait

    const price = this.getPrice(this.props.sendableCard?.cost, bulkCardCost)

    return isCustomizeOpen ? (
      <div className={styles.cardPreview}>
        <div
          className={
            orientation === 'horizontal'
              ? `${styles.cardModal} ${styles.horizontalCard}`
              : orientation === 'portrait'
              ? `${styles.cardModal} ${styles.portraitCard}`
              : styles.cardModal
          }
        >
          <div
            className={
              isCustomizeOpen
                ? `${styles.previewContainer} ${styles.configurations}`
                : styles.previewContainer
            }
            id={'preview_container'}
          >
            {isMobile && <h4 style={{ textAlign: 'center' }}>Card Preview</h4>}
            <div className={styles.cardDetails}>
              <div className={styles.closePreview} onClick={() => onClose()}>
                <Icon icon={'CLOSE'} size={18} />
                <Text type="caption" color="primaryHeading">
                  Close
                </Text>
              </div>
              {mergedSendableCard && (
                <div
                  className={styles.actions}
                  style={
                    isMobile
                      ? {
                          position: 'fixed',
                          bottom: '0px',
                          left: '0px',
                          right: '0px',
                        }
                      : { marginBottom: '25%' }
                  }
                >
                  <FloatingDrawer
                    isOpen={isCustomizeOpen}
                    triggerTitle={'Customize'}
                    triggerCloseTitle={'Cancel'}
                    onClick={this.handleClose}
                    className={styles.action}
                  >
                    {
                      <div className={styles.options}>
                        <Div inset="x2" backgroundColor="white">
                          <Text type="title" alignment="center">
                            {!['3666591', '3666592'].includes(
                              mergedSendableCard ? mergedSendableCard.id : '',
                            )
                              ? 'Customize Card Options'
                              : 'Enjoy the creative process!'}
                          </Text>
                        </Div>
                        <Spacer space="x2" />
                        <CardTypeOptions
                          options={
                            isBulk ? this.getBulkCardTypes : this.getCardTypes
                          }
                          optionTitle={'Pick a card type:'}
                          selectedOption={cardType}
                          selectOption={this.handleSetCardType}
                        />
                        {[CardType.FLATCARD, CardType.TWO_PANEL].includes(
                          cardType,
                        ) && (
                          <Flex style={{ marginLeft: '10px' }}>
                            <HStack
                              alignItems="center"
                              inset="x1"
                              justify="flex-start"
                              gap="x_5"
                            >
                              <Div
                                borderStyle="solid"
                                borderColor={{
                                  swatch: 'success',
                                  shade: 'base',
                                }}
                                borderRadius="circle"
                                style={{
                                  lineHeight: 0,
                                  borderWidth: '2px',
                                  padding: '2px',
                                }}
                              >
                                <QdsIcon
                                  primaryColor="success"
                                  size="small"
                                  name="check"
                                />
                              </Div>
                              <Text
                                weight="bold"
                                type="body"
                                style={{ margin: 0 }}
                              >
                                Eligible for bulk send
                              </Text>
                            </HStack>
                          </Flex>
                        )}
                        <PaperTypeOptions
                          options={this.paperTypes}
                          optionTitle={'Choose Paper Type:'}
                          selectedOption={paperType}
                          selectOption={this.handleSetPaperType}
                        />
                        <div className={styles.actionButton}>
                          <div
                            className={`${styles.action} ${styles.chooseCard}`}
                            id={'card_preview_personalize_btn'}
                            onClick={() => {
                              const id = selectedVariation?.id ?? card?.id
                              if (id) {
                                this.goToAppBulk(
                                  mergedSendableCard.id,
                                  cardType,
                                  paperType,
                                )
                              }
                            }}
                          >
                            <Text
                              type="largeBody"
                              color="accent"
                              alignment="center"
                              weight="bold"
                              style={{ margin: 0 }}
                            >
                              Next
                            </Text>
                          </div>
                        </div>
                      </div>
                    }
                  </FloatingDrawer>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={styles.modalWindow} onClick={() => onClose()} />
      </div>
    ) : (
      <div className={styles.cardPreview}>
        <div
          className={
            orientation === 'horizontal'
              ? `${styles.cardModal} ${styles.horizontalCard}`
              : orientation === 'portrait'
              ? `${styles.cardModal} ${styles.portraitCard}`
              : styles.cardModal
          }
        >
          <div
            className={
              configurations
                ? `${styles.previewContainer} ${styles.configurations}`
                : styles.previewContainer
            }
            id={'preview_container'}
          >
            <div
              id="preview_header_container"
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                width: 'auto',
              }}
            >
              {isMobile && (
                <h4 style={{ textAlign: 'center' }}>Card Preview</h4>
              )}
              <div id="card_image" className={styles.card}>
                {mergedSendableCard && (
                  <CardFlip
                    flipped={cardFlipped}
                    card={mergedSendableCard}
                    hideButton={true}
                    customCardSize={cardSizing}
                  />
                )}
                <div
                  style={
                    {
                      position: 'absolute',
                      bottom: 10,
                      left: '50%',
                      transform: 'translateX(-50%)',
                    } as React.CSSProperties
                  }
                >
                  {selectedVariation && variations && variations.length > 1 && (
                    <VariationToggle
                      style={{
                        bottom: 0,
                        boxShadow: '0px 2px 12px 0 #595959',
                        background: '#FFF',
                      }}
                      className={
                        orientation === 'horizontal'
                          ? `${styles.catalogVariation} ${styles.horizontalVariation}`
                          : `${styles.catalogVariation} ${styles.portraitVariation}`
                      }
                      variations={variations}
                      selectedVariationId={selectedVariation.id}
                      selectVariation={this.handleVariation}
                    />
                  )}
                </div>
              </div>
            </div>
            <div
              className={styles.cardDetails}
              style={!isMobile ? { justifyContent: 'center' } : {}}
            >
              <div className={styles.closePreview} onClick={() => onClose()}>
                <Icon icon={'CLOSE'} size={18} />
                <span>Close</span>
              </div>
              <div className={styles.nonMobile}>
                {!selectedCard && (
                  <div className={styles.details}>
                    <h4>{mergedSendableCard?.title ?? 'Custom Card'}</h4>
                    {mergedSendableCard?.isPremium && (
                      <div className={styles.premiumTag}>
                        <Icon icon={'TAG'} size={18} color={'#F171CC'} />
                        Premium Card
                      </div>
                    )}
                    <p>{mergedSendableCard?.description ?? ''}</p>
                    <FlexRow style={{ justifyContent: 'space-between' }}>
                      <FlexRow style={{ alignItems: 'flex-end' }}>
                        {price && (
                          <h6>
                            <span className={styles.price}>Price</span>
                            {price}{' '}
                          </h6>
                        )}
                        <span className={styles.postageLabel}>
                          {' '}
                          <span>+</span> {isBulk ? 'shipping' : 'plus postage'}
                        </span>
                      </FlexRow>
                      <FlexColumn css={{ justifyContent: 'center' }}>
                        {mergedSendableCard?.insideRightImage && (
                          <Button
                            title={cardFlipped ? 'See Front' : 'See Inside'}
                            id={
                              cardFlipped ? 'see_front_btn' : 'see_inside_btn'
                            }
                            onClick={this.handleCardFlip}
                          />
                        )}
                      </FlexColumn>
                    </FlexRow>
                    {!isBulk && (
                      <div style={{ marginTop: '5px' }}>
                        As a guest, your first card is free.
                      </div>
                    )}
                  </div>
                )}
              </div>
              {isBulk && (
                <>
                  <ConfigurationCard
                    graphic="addressed"
                    title="Send in bulk"
                    description="Create this as a bulk order and have the cards and envelopes shipped directly to you."
                    isSelected={true}
                    onSelect={() => {}}
                    configurationId="bulk_configuration"
                  />
                </>
              )}
              {isBulk && (
                <div
                  className={styles.actions}
                  style={
                    isMobile
                      ? {
                          position: 'fixed',
                          bottom: '20px',
                          left: '0px',
                          right: '0px',
                          marginTop: '20px',
                        }
                      : {
                          marginBottom: '25%',
                          marginTop: '20px',
                        }
                  }
                >
                  <Button
                    title={'Continue'}
                    id={'continue_btn'}
                    onClick={() => {
                      this.setState({ isCustomizeOpen: true })
                    }}
                    buttonColor={'pink'}
                  />
                </div>
              )}
              {!isBulk && mergedSendableCard && (
                <div
                  className={styles.actions}
                  style={
                    isMobile
                      ? {
                          position: 'fixed',
                          bottom: '20px',
                          left: '0px',
                          right: '0px',
                        }
                      : { marginBottom: '25%' }
                  }
                >
                  <Button
                    title={'Login'}
                    id={'sign_in_btn'}
                    onClick={() => this.goToApp(mergedSendableCard.id)}
                    buttonColor={'pink'}
                  />
                  <FloatingDrawer
                    isOpen={configurations}
                    triggerTitle={'Continue As Guest'}
                    triggerCloseTitle={'Cancel'}
                    onClick={() => {
                      const id = selectedVariation?.id ?? card?.id

                      if (!id) {
                        return
                      }

                      if (invite) {
                        this.setState({ isCreatingAccount: true })
                        performOperation<CreateSparseUserData>(
                          CreateSparseUser({ inviteId: invite.id }),
                          result => {
                            this.setState({ isCreatingAccount: false })

                            if (result.value) {
                              this.goToApp(id, {
                                access_token:
                                  result.value.createSparseUser.account.token,
                              })
                            } else {
                              this.goToApp(id, { verify: '' })
                            }
                          },
                        )
                      } else {
                        this.goToApp(id, { verify: '', join: '' })
                      }
                    }}
                    className={styles.action}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={styles.modalWindow} onClick={() => onClose()} />
      </div>
    )
  }
}

export default connect(
  state => ({
    sponsor: state.storefront.storefront?.user,
    isMobile: state.window.isMobile,
    invite: state.invites.invite,
  }),
  {
    setTourId,
    clearTourId,
  },
)(CardPreview)
