import { Operation } from '../../../__generated__/globalTypes'

import {
  MinimalSendableCardFragment,
  minimalSendableCardFragmentString,
} from '../../fragments/__generated__/MinimalSendableCardFragment'

import { sendableCardImageFragmentString } from '../../fragments/__generated__/SendableCardImageFragment'

import getCardCategoriesRawString from '../GetCardCategories.graphql'

const getCardCategoriesString = [
  getCardCategoriesRawString,
  minimalSendableCardFragmentString,
  sendableCardImageFragmentString,
].join('\n\n')

export type GetCardCategoriesData = {
  cardCategories: {
    id: string
    description: string
    cards: MinimalSendableCardFragment[]
  }[]
}

export type GetCardCategories = Operation<GetCardCategoriesData>

export const GetCardCategories = (): GetCardCategories => ({
  query: getCardCategoriesString,
  variables: undefined,
})
