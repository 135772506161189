import React from 'react'
import { Button } from 'src/design_system/components/Button'
import { Div, Flex, Text } from '@sendoutcards/quantum-design-ui'
import { Icon } from 'src/design_system/atoms/icons/components/Icon'
import useContainerWidth from 'src/hooks/useContainerWidth'

export type BannerCardProps = {
  title: string
  subtitle: string
  imageUrl: string
  action?: { title: string; onClick: () => void }
  themeColor?: string
  themeBackgroundColor?: string
  titleColorOverride?: string
  buttonColorOverride?: string
}

export const BannerCard: React.FC<BannerCardProps> = ({
  title,
  subtitle,
  imageUrl,
  action,
  themeColor = '#f6b26b',
  themeBackgroundColor = '#f6b26b',
  titleColorOverride,
  buttonColorOverride,
}) => {
  const [containerRef, containerWidth] = useContainerWidth()
  const isTabletBreakPoint = containerWidth <= 1025
  const isMobile = containerWidth <= 747
  const isSmallMobile = containerWidth <= 627

  const fontFamily = 'Montserrat, sans-serif'
  return (
    <Div
      ref={containerRef}
      display="flex"
      position="relative"
      inset={isSmallMobile ? '3rem' : '4.2rem'}
      width="90%"
      maxWidth="1205px"
      minHeight="460px"
      alignItems="center"
      justifyContent="center"
      style={{
        borderRadius: '1.25rem',
        background: isSmallMobile
          ? `linear-gradient(to bottom, #fff 70%, ${themeBackgroundColor} 70%, ${themeBackgroundColor} 100%)`
          : `linear-gradient(to right, #fff 70%, ${themeBackgroundColor} 70%, ${themeBackgroundColor} 100%)`,
      }}
    >
      <Div position="absolute" style={{ transform: 'rotate(-6deg)' }}>
        <Icon name="promptings_logo" color="#93BED81A" size={620} />
      </Div>
      <Flex
        flexDirection={isSmallMobile ? 'column' : 'row'}
        rowGap={isSmallMobile ? 'x2' : 'x0'}
        columnGap="x2"
        alignItems="center"
      >
        <Flex width="70%" flexDirection="column" rowGap="x4">
          <Text
            type="body"
            alignment={isMobile ? 'center' : 'left'}
            content={title}
            lineHeight={1}
            style={{
              fontSize: '2.75rem',
              fontWeight: 500,
              color: titleColorOverride ?? themeBackgroundColor,
              fontFamily: fontFamily,
            }}
          />
          <Text
            type="subtitle"
            alignment={isMobile ? 'center' : 'left'}
            content={subtitle}
            lineHeight={1}
            style={{
              lineHeight: '1.4',
              color: '#808080',
              fontFamily: fontFamily,
            }}
          />
          {!isSmallMobile && action && (
            <Div zIndex={5}>
              <Button
                title={action.title}
                backgroundColor={buttonColorOverride ?? themeColor}
                textColor="#fff"
                onClick={action.onClick}
              />
            </Div>
          )}
        </Flex>
        <Flex
          maxWidth={isMobile ? '320px' : isTabletBreakPoint ? '370px' : '460px'}
          maxHeight={
            isMobile ? '195px' : isTabletBreakPoint ? '245px' : '325px'
          }
          zIndex={1}
          alignItems="center"
        >
          <img alt="#" width="100%" height="100%" src={imageUrl} />
        </Flex>
        {isSmallMobile && action && (
          <Div zIndex={5} inset={{ top: 'x2_5' }}>
            <Button
              title={action.title}
              backgroundColor="#000"
              textColor="#fff"
              onClick={action.onClick}
            />
          </Div>
        )}
      </Flex>
    </Div>
  )
}
