import React, { useEffect, useState } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'

import DialogContent from '@material-ui/core/DialogContent'
import { Button, Dialog } from 'src/chrome'

import styles from './progressModal.module.scss'

interface ProgressModalProps {
  size: number
  open: boolean
  currentCount: number
  totalCount: number
  errorTitle: string
  errorList?: string[]
  message: string
  infoChild?: React.ReactNode
  onClose: () => void
  close?: () => void
}

const ProgressModal: React.FC<ProgressModalProps> = props => {
  const [completed, setCompleted] = useState(0)
  const {
    open,
    close,
    currentCount,
    totalCount,
    message,
    errorList,
    size,
    errorTitle,
    infoChild,
    onClose,
  } = props

  useEffect(() => {
    setCompleted(Math.ceil((currentCount / totalCount) * 100))
  }, [currentCount, totalCount])

  return (
    <Dialog disableBackdropClick={true} open={open} onClose={close}>
      <DialogContent className={styles.progressModal}>
        <div className={styles.progressTitle}>
          <h1>{`${currentCount} / ${totalCount} ${message}`}</h1>
        </div>
        <div className={styles.body}>
          <CircularProgress
            variant={'determinate'}
            classes={{
              root: styles.circularProgress,
            }}
            value={completed}
            thickness={10}
            size={size || 50}
          />
        </div>
        {errorList && errorList.length > 0 && (
          <div className={styles.errors}>
            <h2 className={styles.errorTitle}>{errorTitle}</h2>
            <div className={styles.errorList}>
              <List>
                {errorList.map((error, index) => (
                  <ListItem key={index}>
                    <ListItemText primary={error} />
                  </ListItem>
                ))}
              </List>
            </div>
          </div>
        )}
        {infoChild && <div>{infoChild}</div>}
        <div className={styles.actions}>
          {errorList && errorList.length + currentCount >= totalCount && (
            <Button title={'Okay'} onClick={() => onClose()} />
          )}
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default ProgressModal
