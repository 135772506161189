import { ActionCreator } from '@sendoutcards/core'
// @src imports
import { Result } from 'src/utils/Result'
import { AccountFragment } from 'src/graphql'

export const updatedAccount = ActionCreator(
  'UPDATED_ACCOUNT',
  (account: AccountFragment | undefined) => ({ account }),
)

export const saveToken = ActionCreator(
  'SAVE_TOKEN',
  (token: string | undefined) => ({ token }),
)

export const loginDone = ActionCreator(
  'LOGIN_DONE',
  (result: Result<AccountFragment>, rememberMe: boolean = true) => ({
    result,
    rememberMe,
  }),
)

export const clearToken = ActionCreator('CLEAR_TOKEN')
