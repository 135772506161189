// future.ts
import { isResult, NotNullOrUndefined, Result } from 'src/utils/Result'
const FUTURE = 'FUTURE'

export type Future<T> = (
  | { isUnresolved: true; error?: undefined; value?: undefined }
  | { isUnresolved: false; error: Error; value?: undefined }
  | { isUnresolved: false; error?: undefined; value: T }
) & {
  type: typeof FUTURE
  match<U>(
    success: (value: T) => U,
    failure: (error: Error) => U,
    unresolved: () => U,
  ): U
  map<U>(transform: (value: T) => U): Future<U>
  withDefault(defaultValue: T | Error | Result<T>): Future<T>
  select<K extends keyof T>(key: K): Future<NotNullOrUndefined<T[K]>>
}

export function Future<T>(arg?: T | Error | Result<T>): Future<T> {
  if (!arg) {
    return unresolved()
  } else if (arg instanceof Error) {
    return failure(arg)
  } else if (isResult(arg)) {
    return arg.match(
      value => success(value),
      error => failure(error),
    )
  } else {
    return success(arg)
  }
}

const success = <T>(value: T): Future<T> => ({
  value,
  type: FUTURE,
  get isUnresolved(): false {
    return false
  },
  map: <U>(transform: (value: T) => U): Future<U> => {
    try {
      return success(transform(value))
    } catch (error) {
      return failure(error as Error)
    }
  },
  withDefault: () => success(value),
  match: <U>(success: (value: T) => U, failure: (error: Error) => U) => {
    try {
      return success(value)
    } catch (error) {
      return failure(error as Error)
    }
  },
  select: <K extends keyof T>(key: K): Future<NotNullOrUndefined<T[K]>> => {
    const selected = value[key]
    return selected === null
      ? failure(Error(`${String(key)} is null on ${value}`))
      : selected === undefined
      ? failure(Error(`${String(key)} is undefined on ${value}`))
      : success<NotNullOrUndefined<T[K]>>(selected as NotNullOrUndefined<T[K]>)
  },
})

const failure = <T>(error: Error): Future<T> => ({
  error,
  type: FUTURE,
  get isUnresolved(): false {
    return false
  },
  map: () => failure(error),
  withDefault: (defaultValue: T | Error | Result<T>) => Future(defaultValue),
  match: <U>(_: any, failure: (error: Error) => U) => failure(error),
  select: () => failure(error),
})

const unresolved = <T>(): Future<T> => ({
  isUnresolved: true,
  type: FUTURE,
  map: <U>(value: (transform: T) => U) => unresolved<U>(),
  withDefault: (defaultValue: T) => Future(defaultValue),
  match: <U>(success: any, failure: any, unresolved: () => U) => unresolved(),
  select: () => unresolved(),
})

export default Future
