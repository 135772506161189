import { Route } from 'src/routes/Route'
import { SendableCardRoute } from './SendableCardRoute'
import { selectedSendableCardReducer } from './reducers'

export const PhotoDropCardsRoute = Route({
  path: '/photo-drop-cards',
  init: (subroute?: SendableCardRoute) => ({ subroute }),
  subroute: SendableCardRoute,
  reducer: (route, action) => selectedSendableCardReducer(route, action),
})
export type PhotoDropCardsRoute = Route<typeof PhotoDropCardsRoute>
