import React from 'react'

import styles from './loadingIndicator.module.scss'

interface LoadingIndicatorProps {
  message: string
  messageStyle?: React.CSSProperties
  subMessage?: string
}

const LoadingIndicator: React.FC<LoadingIndicatorProps> = props => {
  const { message, messageStyle, subMessage } = props
  const sub = subMessage ? <h6>{`${subMessage}`}</h6> : null
  return (
    <div className={styles.loadingContainer}>
      <div className={styles.loadingIndicator}>
        <div className={styles.circle} />
        <div className={styles.circle} />
        <div className={styles.circle} />
      </div>
      <h3 style={messageStyle}>{`${message}`}</h3>
      {sub}
    </div>
  )
}

export default LoadingIndicator
