import { Maybe } from '../../../__generated__/globalTypes'

import storefrontRawString from '../Storefront.graphql'

export const storefrontString = storefrontRawString

export type Storefront = {
  mugshot: Maybe<{
    url: string
  }>
  biography: Maybe<string>
  slug: Maybe<string>
}

export const isStorefront = (fragment: any): fragment is Storefront =>
  fragment &&
  (fragment.mugshot === null ||
    (fragment.mugshot && typeof fragment.mugshot.url === 'string')) &&
  (fragment.biography === null || typeof fragment.biography === 'string') &&
  (fragment.slug === null || typeof fragment.slug === 'string')
