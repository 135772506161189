import { openRoute as openBaseRoute } from 'src/redux/actions/routes'
import { BaseRoute, BaseSubroute } from '../../routes/BaseRoute'
import { AppRoute } from 'src/app/routes/AppRoute'
import { CatalogRoute } from 'src/catalog/routes/CatalogRoute'
import { PricingRoute } from 'src/pricing_page/routes/PricingRoute'
// marketing routes to remove
import { BusinessRoute } from 'src/marketing/routes/BusinessRoute'

const openRoute = (subroute?: BaseSubroute) =>
  openBaseRoute(BaseRoute(subroute))

export const openLaunchPage = () => openRoute()

export const openBusinessPage = () => openRoute(AppRoute(BusinessRoute()))

export const openCatalog = (bulk?: true) =>
  openRoute(AppRoute(CatalogRoute(undefined, undefined, bulk)))

export const openPlans = () => openRoute(AppRoute(PricingRoute()))
