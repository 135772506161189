import {
  LayoutCategory,
  Maybe,
  LayoutDimensions,
  Owner,
  Operation,
} from '../../../__generated__/globalTypes'

import {
  LayoutFragment,
  layoutFragmentString,
} from '../../fragments/__generated__/LayoutFragment'

import getLayoutsRawString from '../GetLayouts.graphql'

const getLayoutsString = [getLayoutsRawString, layoutFragmentString].join(
  '\n\n',
)

export type GetLayoutsData = {
  layouts: LayoutFragment[]
}

export type GetLayoutsVariables = {
  category: LayoutCategory
  dimensions?: Maybe<LayoutDimensions>
  owner?: Maybe<Owner>
}

export type GetLayouts = Operation<GetLayoutsData, GetLayoutsVariables>

export const GetLayouts = ({
  category,
  dimensions,
  owner,
}: GetLayoutsVariables): GetLayouts => ({
  query: getLayoutsString,
  variables: {
    category,
    dimensions,
    owner,
  },
})
