import * as Moment from 'moment'
import { Result } from '../utils/Result'
import { Action } from 'src/redux/action'
import { Operation } from 'src/graphql'
import { Icons, IconType } from 'src/chrome/Icon/icons'

export type SmallOrLarge = 'small' | 'large'

// Filter Stuff
interface FilterValue {
  id?: string
  name: string
  start?: Moment.Moment
  end?: Moment.Moment
}
type FilterTypes = 'DATE' | 'BOOLEAN' | 'GROUP' | 'CONTACT' | 'STATUS' | 'TYPE'
export interface Filter {
  name: string
  active?: boolean
  type: FilterTypes
  values?: FilterValue[]
  id: string
  icon?: Icon
}

// Icon Stuff
export type IconTypeOrIconString = IconType | Icons

interface Icon {
  svg: IconTypeOrIconString
  color?: string
  size?: number
  viewBox?: string
}

export type Task<Data = any, Variables = any> = {
  operation: Operation<Data, Variables>
  completion: (result: Result<Data>) => Action
}

export const Task = <Data, Variables>(
  operation: Operation<Data, Variables>,
  completion: (result: Result<Data>) => Action,
): Task<Data> => ({
  operation,
  completion,
})

export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>
