import { FC } from 'react'
import { SVGProps } from './types'

export const PlayButton: FC<SVGProps> = ({ size, color, onClick }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 132 139"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <g filter="url(#filter0_bd_640_1929)">
        <path
          d="M93 53.3757C102.333 58.7643 102.333 72.2359 93 77.6245L53.25 100.574C43.9167 105.963 32.25 99.227 32.25 88.4498L32.25 42.5504C32.25 31.7732 43.9167 25.0375 53.25 30.4261L93 53.3757Z"
          fill={color}
          fill-opacity="0.4"
          shape-rendering="crispEdges"
        />
      </g>
      <defs>
        <filter
          id="filter0_bd_640_1929"
          x="0.25"
          y="0.529541"
          width="131.75"
          height="137.941"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="12" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_640_1929"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="16" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_backgroundBlur_640_1929"
            result="effect2_dropShadow_640_1929"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_640_1929"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  )
}
