import React, { useState } from 'react'
import { useWindowWidth } from '@react-hook/window-size'
import {
  Button,
  Div,
  DropdownLink,
  DropDownMenuProps,
  Flex,
  Link,
  LogoProps,
  QuantumThemeWrapper,
  Spacer,
  StaticNavigation,
  Text,
  UserCard,
  UserCardLoader,
} from '@sendoutcards/quantum-design-ui'
import { StorefrontState } from 'src/redux/reducers/storefront'
import { SponsorFragment } from 'src/graphql'
import AccountSection from './AccountSection'
import { Icon } from 'src/design_system/atoms/icons/components/Icon'

type StaticNavProps = {
  storefront: StorefrontState
}

const StaticNav: React.FC<StaticNavProps> = props => {
  const { storefront } = props

  const isLoading = storefront.loading && !storefront.storefront?.user
  const hasSponsor = storefront.storefront?.user.genealogyId

  const [isBioDisplayed, toggleIsBioDisplayed] = useState<boolean>(false)

  const width = useWindowWidth()
  const isMobile = width < 600

  const drawerOffset = hasSponsor ? 100 : 60

  const getSponsorSuffix = (sponsor: SponsorFragment | undefined): string => {
    if (!sponsor) return ''
    return sponsor.genealogyId
      ? `${sponsor.genealogyId}`
      : sponsor.storefront && sponsor.storefront.slug
      ? `${sponsor.storefront.slug}`
      : ''
  }

  const logo: LogoProps = {
    brand: 'soc',
    color: 'color',
    href: '/',
    width: '200px',
    shouldShowParentBrand: true,
    secondaryLogo: {
      width: '80px',
    },
  }

  const links: (DropdownLink | Link)[] = [
    {
      title: '3 Card Sampler',
      href: `https://www.promptings.com/3cardsampler/?sponsor=${getSponsorSuffix(
        storefront.storefront?.user,
      )}`,
    },
    {
      title: 'Premium Membership Plans',
      href: `https://www.promptings.com/solopreneur/?sponsor=${getSponsorSuffix(
        storefront.storefront?.user,
      )}`,
    },
    {
      title: 'Pricing',
      href: `https://app.sendoutcards.com/pricing${
        storefront.storefront?.user
          ? '?sponsor=' + getSponsorSuffix(storefront.storefront?.user)
          : ''
      }`,
    },
  ]

  const dropDownMenuLinks: DropDownMenuProps = {
    linkColumn: {
      title: '',
      links: [
        {
          title: (
            <Flex flexDirection="column">
              <Icon name="soc_logo" size={200} color="#000" />
              <Flex
                alignItems="center"
                style={{
                  fontFamily: 'Ubuntu',
                  fontStyle: 'italic',
                  fontSize: '14px',
                }}
                justifyContent="flex-end"
                flexDirection="row"
                columnGap="x_25"
              >
                The
                <Icon name="promptings_logo_full" size={90} color="#000" />
                Mechanism
              </Flex>
            </Flex>
          ),
          href: '/app',
          target: '_self',
        },
        {
          title: (
            <Flex flexDirection="column">
              <Icon name="promptings_logo_full" size={170} color="#000" />
              <Flex
                alignItems="center"
                style={{
                  fontFamily: 'Ubuntu',
                  fontStyle: 'italic',
                  fontSize: '14px',
                }}
                justifyContent="flex-end"
                flexDirection="row"
                columnGap="x_5"
              >
                Powered by
                <Icon name="soc_logo" size={120} color="#000" />
              </Flex>
            </Flex>
          ),
          onClick: () =>
            window.open(
              `https://www.promptings.com/${
                hasSponsor
                  ? `?sponsor=${getSponsorSuffix(storefront.storefront?.user)}`
                  : ''
              }`,
            ),
          href: '',
          target: '_blank',
        },
      ],
    },
    top: 110,
    isOpen: false,
  }

  return (
    <QuantumThemeWrapper>
      <Div position="fixed" width="100%" zIndex={102} minWidth="320px">
        {hasSponsor && (
          <Flex
            inset={{ left: 'x3', top: 'x1' }}
            minHeight="60px"
            position="relative"
            backgroundColor="background"
            flexDirection="column"
            zIndex={22}
          >
            <Flex
              justifyContent="flex-start"
              alignItems="center"
              alignContent="center"
            >
              {isLoading ? (
                <UserCardLoader size="small" />
              ) : (
                <>
                  <UserCard
                    profileImage={
                      storefront.storefront?.user.storefront?.mugshot?.url ??
                      storefront.storefront?.user.profileImageUrl ??
                      ''
                    }
                    firstName={storefront.storefront?.user.firstName ?? ''}
                    lastName={storefront.storefront?.user.lastName ?? ''}
                    caption={`Thanks for visiting my ${
                      storefront.storefront?.user.isAffiliate
                        ? 'affiliate'
                        : 'referral'
                    } site.`}
                    size="small"
                  />
                  <Spacer orientation="horizontal" space="x2" />
                  <Button
                    title={isBioDisplayed ? 'Show Less' : 'About Me'}
                    size="xSmall"
                    type="secondary"
                    onClick={() => toggleIsBioDisplayed(!isBioDisplayed)}
                  />
                  <Spacer orientation="horizontal" space="x2" />
                  {isBioDisplayed && !isMobile && (
                    <Text type="body">
                      {storefront.storefront?.user.storefront?.biography}
                    </Text>
                  )}
                </>
              )}
            </Flex>
            {isMobile && isBioDisplayed && (
              <Flex justifyContent="center" alignItems="center">
                <Text type="body">
                  {storefront.storefront?.user.storefront?.biography}
                </Text>
              </Flex>
            )}
          </Flex>
        )}
        <StaticNavigation
          links={links}
          logo={logo}
          drawerOffset={drawerOffset}
          accountSection={<AccountSection />}
          menuDropdownLinks={dropDownMenuLinks}
        />
      </Div>
    </QuantumThemeWrapper>
  )
}

export default StaticNav
