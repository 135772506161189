import { Maybe } from '../../../__generated__/globalTypes'

import { ImageFragment, isImageFragment } from './ImageFragment'

import elementPictureFragmentRawString from '../ElementPictureFragment.graphql'

export const elementPictureFragmentString = elementPictureFragmentRawString

export type ElementPictureFragment = {
  image: Maybe<ImageFragment>
  position: {
    x: number
    y: number
  }
  scale: number
  filter: Maybe<{
    name: string
  }>
}

export const isElementPictureFragment = (
  fragment: any,
): fragment is ElementPictureFragment =>
  fragment &&
  (fragment.image === null || (isImageFragment(fragment.image) as boolean)) &&
  fragment.position &&
  typeof fragment.position.x === 'number' &&
  typeof fragment.position.y === 'number' &&
  typeof fragment.scale === 'number' &&
  (fragment.filter === null ||
    (fragment.filter && typeof fragment.filter.name === 'string'))
