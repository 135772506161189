import { ContentMediaType } from 'src/home_page/components/ContentPop/ContentPop'
import { BentoContentExpandType } from 'src/marketing/components/BentoGrid/BentoCard'
import { CardTypeImageDiffData } from 'src/marketing/mockData/CardTypeImageDiffData'

const catalogContentStream: ContentMediaType[] = [
  {
    content: {
      type: 'content',
      title: 'Catalog Card',
      description:
        'Our Catalog Cards are designed for the quick prompt that spark emotions for all scenarios. Just type your message and send!',
    },
    width: '100%',
    height: 'auto',
  },
  {
    content: {
      type: 'content',
      title: '',
      description:
        'Whether you want to send something heartfelt, funny, or share sympathy, choose one of our professionally designed catalog cards that will help drive your message to the heart. ',
    },
    width: '100%',
    height: 'auto',
  },
  {
    content: {
      type: 'content',
      title: '',
      description:
        'Be inspired to send a card to someone as you scroll through the selection of cards. One is sure to make you think of someone you need to reach out to!',
    },
    width: '100%',
    height: 'auto',
  },
]
export const catalogCardExpandData: BentoContentExpandType = {
  bannerImages: [
    'https://soc-website-public.s3.us-west-2.amazonaws.com/static/soc-website/images/popups/Catalog-cards-main-image.png',
  ],
  contentStream: catalogContentStream,
  type: 'content',
  imageDiff: CardTypeImageDiffData[0],
}
