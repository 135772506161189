import keyBy from 'lodash/keyBy'
import mapValues from 'lodash/mapValues'

import { PaginatedList } from 'src/utils/PaginatedList'
import Searches from 'src/utils/Searches'
import {
  CardCategoryFragment,
  CollectionFragment,
  CustomCardFragment,
  GetCatalogData,
  MinimalSendableCardFragment,
  NormalizedData,
} from 'src/graphql'
// @src imports
import {
  denormalize,
  normalize,
  Normalized,
} from 'src/normalized-data/normalization'

export const isMinimalSendableCardFragment = (
  value: MinimalSendableCardFragment | CustomCardFragment,
): value is MinimalSendableCardFragment => value.__typename === 'SendableCard'

export type NormalizedCardCatalog = {
  collections: Normalized<CollectionFragment>[]
  categories: Normalized<CardCategoryFragment>[]
  allCards: Searches<PaginatedList<Normalized<MinimalSendableCardFragment>>>
  collectionCards: {
    [id: string]: PaginatedList<Normalized<MinimalSendableCardFragment>>
  }
  categoryCards: {
    [id: string]: Searches<
      PaginatedList<Normalized<MinimalSendableCardFragment>>
    >
  }
  photoDropCards: Searches<
    PaginatedList<Normalized<MinimalSendableCardFragment>>
  >
  standardCards: Searches<
    PaginatedList<Normalized<MinimalSendableCardFragment>>
  >
  featuredCards: Normalized<MinimalSendableCardFragment>[]
}

export const NormalizedCardCatalog = ({
  collections,
  cardCategories,
  featuredCards,
}: GetCatalogData): NormalizedCardCatalog => ({
  collections: collections.map(normalize),
  categories: cardCategories
    .filter(category => category.cards.length > 0)
    .map(normalize),
  allCards: Searches(PaginatedList()),
  collectionCards: mapValues(
    keyBy(collections, collection => collection.id),
    ({ cards }) => PaginatedList(cards.map(normalize), cards.length === 1),
  ),
  categoryCards: mapValues(
    keyBy(cardCategories, category => category.id),
    ({ cards }) =>
      Searches(
        PaginatedList<Normalized<MinimalSendableCardFragment>>(),
        PaginatedList(cards.map(normalize), cards.length === 10),
      ),
  ),
  photoDropCards: Searches(PaginatedList()),
  standardCards: Searches(PaginatedList()),
  featuredCards: featuredCards.map(normalize),
})

export type DenormalizedCardCatalog = {
  collections: CollectionFragment[]
  categories: CardCategoryFragment[]
  allCards: Searches<PaginatedList<MinimalSendableCardFragment>>
  collectionCards: {
    [id: string]: PaginatedList<MinimalSendableCardFragment>
  }
  categoryCards: {
    [id: string]: Searches<PaginatedList<MinimalSendableCardFragment>>
  }
  photoDropCards: Searches<PaginatedList<MinimalSendableCardFragment>>
  standardCards: Searches<PaginatedList<MinimalSendableCardFragment>>
  featuredCards: MinimalSendableCardFragment[]
}

export const DenormalizedCardCatalog = (
  {
    collections,
    categories,
    allCards,
    collectionCards,
    categoryCards,
    photoDropCards,
    standardCards,
    featuredCards,
  }: NormalizedCardCatalog,
  data: NormalizedData,
): DenormalizedCardCatalog => ({
  collections: collections.map(denormalize(data)),
  categories: categories.map(denormalize(data)),
  allCards: allCards.map(cards => cards.map(denormalize(data))),
  collectionCards: mapValues(collectionCards, cards =>
    cards.map(denormalize(data)),
  ),
  categoryCards: mapValues(categoryCards, searches =>
    searches.map(cards => cards.map(denormalize(data))),
  ),
  photoDropCards: photoDropCards.map(cards => cards.map(denormalize(data))),
  standardCards: standardCards.map(cards => cards.map(denormalize(data))),
  featuredCards: featuredCards.map(denormalize(data)),
})

export type CardCatalog = {
  collections: CollectionFragment[]
  collectionCards: {
    [id: string]: PaginatedList<MinimalSendableCardFragment>
  }
  categories: CardCategoryFragment[]
  featuredCards: MinimalSendableCardFragment[]
}

export const CardCatalog = ({
  collections,
  collectionCards,
  categories,
  featuredCards,
}: DenormalizedCardCatalog): CardCatalog => ({
  collections,
  categories,
  collectionCards,
  featuredCards,
})
