import React from 'react'
import { SVGProps } from './types'

export const Package: React.FC<SVGProps> = ({
  size = 24,
  color = '#3A3A3C',
  onClick,
  opacity = 0.1,
}) => {
  const originalWidth = 192
  const originalHeight = 193
  const ratio = originalHeight / originalWidth
  const scaledHeight = size
  const scaledWidth = size / ratio
  return (
    <svg
      width={scaledWidth}
      height={scaledHeight}
      viewBox={`0 0 ${originalWidth} ${originalHeight}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M4 192C3.37 192 2.75 191.85 2.17 191.56C0.84 190.87 0 189.5 0 188V44C0 42.71 0.62 41.5 1.67 40.75L57.67 0.74997C58.93 -0.15003 60.59 -0.24002 61.94 0.50998L131.73 39.28L185.67 0.74997C186.89 -0.12003 188.5 -0.240018 189.83 0.449982C191.16 1.13998 192 2.50998 192 4.00998V148.01C192 149.3 191.38 150.51 190.33 151.26L134.33 191.26C133.07 192.16 131.41 192.25 130.06 191.5L60.27 152.73L6.33 191.26C5.64 191.75 4.82 192.01 4 192.01V192ZM60 144C60.67 144 61.34 144.17 61.94 144.5L131.73 183.27L184 145.94V11.77L134.33 47.25C133.07 48.15 131.41 48.24 130.06 47.49L60.27 8.72L8 46.05V180.22L57.67 144.74C58.36 144.25 59.18 143.99 60 143.99V144Z"
        fill={color}
        fillOpacity={opacity}
      />
      <path
        d="M108 154.64C107.34 154.64 106.68 154.48 106.07 154.14L82.07 140.86C80.14 139.79 79.44 137.36 80.51 135.42C81.58 133.49 84.02 132.79 85.95 133.86L109.95 147.14C111.88 148.21 112.58 150.64 111.51 152.58C110.78 153.9 109.41 154.64 108.01 154.64H108Z"
        fill={color}
        fillOpacity={opacity}
      />
      <path
        d="M108 122.64C107.34 122.64 106.68 122.48 106.07 122.14L82.07 108.86C80.14 107.79 79.44 105.36 80.51 103.42C81.58 101.49 84.02 100.79 85.95 101.86L109.95 115.14C111.88 116.21 112.58 118.64 111.51 120.58C110.78 121.9 109.41 122.64 108.01 122.64H108Z"
        fill={color}
        fillOpacity={opacity}
      />
      <path
        d="M108 90.6401C107.34 90.6401 106.68 90.4801 106.07 90.1401L82.07 76.8601C80.14 75.7901 79.44 73.3601 80.51 71.4201C81.58 69.4901 84.02 68.7901 85.95 69.8601L109.95 83.1401C111.88 84.2101 112.58 86.6401 111.51 88.5801C110.78 89.9001 109.41 90.6401 108.01 90.6401H108Z"
        fill={color}
        fillOpacity={opacity}
      />
      <path
        d="M108 58.6401C107.34 58.6401 106.68 58.4801 106.07 58.1401L82.07 44.8601C80.14 43.7901 79.44 41.3601 80.51 39.4201C81.58 37.4901 84.02 36.7901 85.95 37.8601L109.95 51.1401C111.88 52.2101 112.58 54.6401 111.51 56.5801C110.78 57.9001 109.41 58.6401 108.01 58.6401H108Z"
        fill={color}
        fillOpacity={opacity}
      />
      <path
        d="M148 156.56C147.37 156.56 146.75 156.41 146.17 156.12C144.84 155.43 144 154.06 144 152.56V56.5602C144 55.2701 144.62 54.0601 145.68 53.3001L169.68 36.1801C170.9 35.3101 172.5 35.1902 173.83 35.8802C175.16 36.5702 176 37.9402 176 39.4402V135.44C176 136.73 175.38 137.94 174.32 138.7L150.32 155.82C149.63 156.31 148.82 156.56 148 156.56ZM152 58.6201V144.79L168 133.37V47.2002L152 58.6201Z"
        fill={color}
        fillOpacity={opacity}
      />
      <path
        d="M20 60.5598C18.75 60.5598 17.52 59.9798 16.74 58.8798C15.46 57.0798 15.87 54.5798 17.67 53.2998L41.67 36.1798C43.47 34.8998 45.96 35.3098 47.25 37.1098C48.53 38.9098 48.12 41.4098 46.32 42.6898L22.32 59.8098C21.62 60.3098 20.8 60.5498 20 60.5498V60.5598Z"
        fill={color}
        fillOpacity={opacity}
      />
      <path
        d="M20 156.56C19.37 156.56 18.75 156.41 18.17 156.12C16.84 155.43 16 154.06 16 152.56V80.5602C16 79.2701 16.62 78.0601 17.68 77.3001L41.68 60.1801C42.9 59.3101 44.5 59.1902 45.83 59.8802C47.16 60.5702 48 61.9402 48 63.4402V135.44C48 136.73 47.38 137.94 46.32 138.7L22.32 155.82C21.63 156.31 20.82 156.56 20 156.56ZM24 82.6201V144.79L40 133.37V71.2002L24 82.6201Z"
        fill={color}
        fillOpacity={opacity}
      />
      <path
        d="M60 16C57.79 16 56 14.21 56 12V4C56 1.79 57.79 0 60 0C62.21 0 64 1.79 64 4V12C64 14.21 62.21 16 60 16Z"
        fill={color}
        fillOpacity={opacity}
      />
      <path
        d="M60 40C57.79 40 56 38.21 56 36V28C56 25.79 57.79 24 60 24C62.21 24 64 25.79 64 28V36C64 38.21 62.21 40 60 40Z"
        fill={color}
        fillOpacity={opacity}
      />
      <path
        d="M60 64C57.79 64 56 62.21 56 60V52C56 49.79 57.79 48 60 48C62.21 48 64 49.79 64 52V60C64 62.21 62.21 64 60 64Z"
        fill={color}
        fillOpacity={opacity}
      />
      <path
        d="M60 88C57.79 88 56 86.21 56 84V76C56 73.79 57.79 72 60 72C62.21 72 64 73.79 64 76V84C64 86.21 62.21 88 60 88Z"
        fill={color}
        fillOpacity={opacity}
      />
      <path
        d="M60 112C57.79 112 56 110.21 56 108V100C56 97.79 57.79 96 60 96C62.21 96 64 97.79 64 100V108C64 110.21 62.21 112 60 112Z"
        fill={color}
        fillOpacity={opacity}
      />
      <path
        d="M60 136C57.79 136 56 134.21 56 132V124C56 121.79 57.79 120 60 120C62.21 120 64 121.79 64 124V132C64 134.21 62.21 136 60 136Z"
        fill={color}
        fillOpacity={opacity}
      />
      <path
        d="M132 72C129.79 72 128 70.21 128 68V60C128 57.79 129.79 56 132 56C134.21 56 136 57.79 136 60V68C136 70.21 134.21 72 132 72Z"
        fill={color}
        fillOpacity={opacity}
      />
      <path
        d="M132 96C129.79 96 128 94.21 128 92V84C128 81.79 129.79 80 132 80C134.21 80 136 81.79 136 84V92C136 94.21 134.21 96 132 96Z"
        fill={color}
        fillOpacity={opacity}
      />
      <path
        d="M132 120C129.79 120 128 118.21 128 116V108C128 105.79 129.79 104 132 104C134.21 104 136 105.79 136 108V116C136 118.21 134.21 120 132 120Z"
        fill={color}
        fillOpacity={opacity}
      />
      <path
        d="M132 144C129.79 144 128 142.21 128 140V132C128 129.79 129.79 128 132 128C134.21 128 136 129.79 136 132V140C136 142.21 134.21 144 132 144Z"
        fill={color}
        fillOpacity={opacity}
      />
      <path
        d="M132 168C129.79 168 128 166.21 128 164V156C128 153.79 129.79 152 132 152C134.21 152 136 153.79 136 156V164C136 166.21 134.21 168 132 168Z"
        fill={color}
        fillOpacity={opacity}
      />
      <path
        d="M132 192C129.79 192 128 190.21 128 188V180C128 177.79 129.79 176 132 176C134.21 176 136 177.79 136 180V188C136 190.21 134.21 192 132 192Z"
        fill={color}
        fillOpacity={opacity}
      />
    </svg>
  )
}
